import { combineReducers } from "redux";
import { loadHotels } from "./getAllHotels";
import { loaderReducer } from "./loader";
import { monthlyLoaderReducer } from "./monthlyLoader";

export const rootReducer = combineReducers({
    loader: loaderReducer,
    monthlyLoader:monthlyLoaderReducer,
    hotelList:loadHotels
 
  });