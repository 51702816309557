import "../Index.scss";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

function CustomerSuccessManager(props) {


  const history = useHistory();

  return (
    <div>
      {localStorage.getItem("token") !== null ? (
        <div>
          <img
            src="/images/ComingSoon.svg"
            alt="ComingSoon"
            width="600px"
            height="600px"
          />
          <Typography
            variant="h4"
            noWrap
            component="div"
            style={{ fontWeight: "bolder" }}
          >
            Coming Soon!
          </Typography>
        </div>
      ) : (
        history.push("/SignInPage")
      )}
    </div>
  );
}

CustomerSuccessManager.propTypes = {
  window: PropTypes.func,
};

export default CustomerSuccessManager;
