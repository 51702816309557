import { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import PiGraphBi from "../PiGraphBi";
import { Typography } from "@material-ui/core";
import noData from "../../../../images/noDataSellStats.png";

const AssignedRoomShare = ({ roomTypeByShared }) => {
  const [data, setData] = useState(roomTypeByShared);

  useEffect(() => {
    setData(roomTypeByShared);
  }, [roomTypeByShared]);

  const series = data.map((da) => Math.round(da.percentage));
  const labels = data.map((da) => da.parent_roomType);
  return (
    <div>
      {roomTypeByShared.length > 0 ? (
        <>
          <Typography variant="subtitle1" align="center">
            ASSIGNED ROOM TYPE SHARE
          </Typography>
          <PiGraphBi seriesData={series} labels={labels} />
        </>
      ) : (
        <div style={{textAlign:"center"}}>
          <img src={noData} style={{textAlign:"center"}}></img>
          <Typography variant="subtitle1" align="center">
            No data available for this KPI
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AssignedRoomShare;
