import { Redirect, Route } from "react-router-dom";

import { useAuth } from "../Context/AuthUserContext";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { currentUser } = useAuth();
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        currentUser ? <Component {...props} /> : <Redirect to="/SignInPage" />
      }
    />
  );
}

export default ProtectedRoute;