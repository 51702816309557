import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import "./dailyView.scss";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Input,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";

import DoneIcon from "@material-ui/icons/Done";
import { formatInEuropeanNoSymbol } from "../../utils/index";
import roomIcon from "../../../images/roomIcon.png";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db2 } from "../../../Auth/firebase";
import { sendCalculatePricesMessage } from "./utils/dailyViewApiCalls";

Chart.register(annotationPlugin);

const DayPricesRoomType = ({ dayPriceEachRoomV2, date, propertyId }) => {
  const [data, setData] = useState({});
  const [modifiersEditable, setModifiersEditable] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  //Use effect calls
  useEffect(() => {
    setData(dayPriceEachRoomV2);
  }, [dayPriceEachRoomV2]);

  useEffect(() => {
    //timeout is added to wait for the user to complete typing
    const timeout = setTimeout(() => {
      const updatedData = [];
      //reformats the data to the format in firebase
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        updatedData.push({
          parentRoomId: parseInt(element.id),
          iRevPrice: element.price,
          minPrice: element.minPrice || null,
          maxPrice: element.maxPrice || null,
          fixedPrice: element.fixedPrice || null,
        });
      }
      createIrevPriceDataStructure(updatedData); // update data in firebase
    }, 1000); // 1sec delay

    return () => {
      clearTimeout(timeout);
    };
  }, [data]);
  //Use effect calls end

  //function to update modifiers value
  const updateModifiers = async (data) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      propertyId,
      "days",
      date
    );
    const docSnap = await getDoc(docRef);
    const updatedData = [];
    //reformats the data to the format in firebase
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      updatedData.push({
        parentRoomId: parseInt(element.id),
        iRevPrice: element.price,
        minPrice: element.minPrice || null,
        maxPrice: element.maxPrice || null,
        fixedPrice: element.fixedPrice || null,
      });
    }
    if (docSnap.exists()) {
      if (docRef.path.split("/")[3] === String(date)) {
        updateDoc(docRef, { irevPerRoomType: updatedData });
        setModifiersEditable(false);
      }
      sendCalculatePricesMessage("1", propertyId, date).catch((err) =>
        console.error(err)
      );
    }
  };
  const createIrevPriceDataStructure = async (data) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      propertyId,
      "days",
      date
    );
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      setDoc(docRef, { irevPerRoomType: data, systemImportance: 0.1 });
    }
  };

  // handle changes in fields
  const handleFixedPrice = (event, index) => {
    const value = event.target.value;
    // changes value for particular room type
    setData((data) =>
      data.map((roomType, i) => {
        if (i == index) {
          roomType.fixedPrice = parseInt(value) || "";
          return roomType;
        } else {
          return roomType;
        }
      })
    );
  };

  const handleMaxPrice = (event, index) => {
    const value = event.target.value;
    // changes value for particular room type
    setData((data) =>
      data.map((roomType, i) => {
        if (i == index) {
          if (roomType.minPrice && roomType.minPrice > parseInt(value)) {
            setDisableSave(true);
          } else {
            setDisableSave(false);
          }
          roomType.maxPrice = parseInt(value) || "";
          return roomType;
        } else {
          return roomType;
        }
      })
    );
  };

  const handleMinPrice = (event, index) => {
    const value = event.target.value;
    // changes value for particular room type
    setData((data) =>
      data.map((roomType, i) => {
        if (i == index) {
          if (roomType.MaxPrice && roomType.maxPrice < parseInt(value)) {
            setDisableSave(true);
          } else {
            setDisableSave(false);
          }
          roomType.minPrice = parseInt(value) || "";
          return roomType;
        } else {
          return roomType;
        }
      })
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" style={{ margin: "32px 0px" }}>
          <b className="dailyview_title_typo">
            Suggested Day Prices With Room Type
          </b>
        </Typography>
        <div>
          {modifiersEditable ? (
            <div>
              <Button
                variant="outlined"
                startIcon={<DoneIcon />}
                style={{
                  height: 50,
                  marginRight: 2,
                  backgroundColor: disableSave? "#e0e0e0" :  localStorage.getItem("primaryColor"),
                  color: "white",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  updateModifiers(data);
                }}
                disabled={disableSave}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                // startIcon={<CloseIcon />}
                style={{
                  height: 50,
                  marginRight: 2,
                  backgroundColor: "#e0e0e0",
                  color: "#333333",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setModifiersEditable(false);
                }}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div
              style={{
                height: "50px",
                width: "70px",
                marginRight: 2,
                backgroundColor: "#e0e0e0",
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setModifiersEditable(true);
              }}
            >
              <img src="/images/edit.svg" height="25" width="70" />
            </div>
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        {dayPriceEachRoomV2.map((room, index) => {
          return (
            <Grid item xs={12} md={6} className="suggested-prices">
              <Accordion
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  backgroundColor: "rgb(247, 248, 252)",
                  "&:hover": { border: `2px solid ${room.color}` },
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="room-accordion-header">
                    <div className="roomNameContainer">
                      <p>{room.name}</p>
                      {(room.maxPrice || room.minPrice || room.fixedPrice) ? (
                        <p className="modifier">Modifiers Added</p>
                      ):null}
                    </div>
                    <p>€{formatInEuropeanNoSymbol(room.price)}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} className="room-accordion-detail">
                    <Grid xs={12} md={6}>
                      <TextField
                        value={room.fixedPrice}
                        variant="outlined"
                        label="Fixed Price"
                        style={{ maxWidth: "95%", marginBottom: "10px" }}
                        onChange={(e) => handleFixedPrice(e, index)}
                        type="number"
                        disabled={!modifiersEditable || (room.maxPrice || room.minPrice)}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField
                        value={room.maxPrice}
                        variant="outlined"
                        label="Maximum Price"
                        style={{ maxWidth: "95%", marginBottom: "10px" }}
                        error={
                          room.minPrice &&
                          room.maxPrice &&
                          room.maxPrice < room.minPrice
                        }
                        onChange={(e) => handleMaxPrice(e, index)}
                        type="number"
                        helperText={
                          (room.minPrice &&
                            room.maxPrice &&
                            room.maxPrice < room.minPrice &&
                            "Max Price cannot be less than min price") ||
                          null
                        }
                        disabled={!modifiersEditable || room.fixedPrice}
                        InputProps={{ inputProps: { min: room.minPrice } }}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <TextField
                        value={room.minPrice}
                        variant="outlined"
                        label="Minimum Price"
                        style={{ maxWidth: "95%", marginBottom: "10px" }}
                        onChange={(e) => handleMinPrice(e, index)}
                        type="number" // helperText={room.minPrice && room.maxPrice && (room.maxPrice < room.minPrice) &&"Min Price cannot be more than max price"}
                        disabled={!modifiersEditable || room.fixedPrice}
                        InputProps={{ inputProps: { max: room.maxPrice } }}
                      />
                    </Grid>
                    {/* to be uncommented when fix price field is required */}
                    {/* <Grid xs={12} md={6}>
                      <TextField
                        value={fixPrice}
                        variant="outlined"
                        label="Increase / lower"
                        style={{maxWidth:'95%', marginBottom:"10px"}}
                      />
                    </Grid> */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default DayPricesRoomType;
