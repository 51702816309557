import '../LandingPage/Header.scss';

import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import { Divider } from '@material-ui/core';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import Header from '../LandingPage/Header';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import React from 'react';

function PrivacyPolicy() {
    return (
      <div>
        <Header/>
   
<div  style={{fontSize:"2vh",padding:"150px 15px 0px 10px", color:"rgba(126, 126, 126, 1)", display:"flex", flexWrap:"wrap", justifyContent:"center"}} className="content">
<div className="container">
   <div  className="text-justify ">
   <b>Privacy Policy of <b>brainyrms.com</b></b>
   <p>This Application collects some Personal Data from its Users.</p>
   <br/>
   <Divider />
   <br/>
   <b>Personal Data collected for the following purposes and using the following services:</b>
   <br/>
   <div >
     <div >

     <div style={{display:"flex"}}>
      <BarChartOutlinedIcon style={{marginLeft:"10px",marginRight:"10px", fontSize:"30", paddingTop:"15px"}}/>
      <b>Analytics</b>
      </div>
      <b style={{marginLeft:"48px", marginBottom:"0px"}}>Google Analytics</b>
      <p style={{marginLeft:"48px" , marginTop:"0px"}}>Personal Data: Tracker; Usage Data</p>
     </div>

     <div>
     <div style={{display:"flex"}}>
      <DraftsOutlinedIcon style={{marginLeft:"10px",marginRight:"10px", fontSize:"30", paddingTop:"15px"}}/>
      <b>Contacting the User</b>
      </div>
      <b style={{marginLeft:"48px", marginBottom:"0px"}}>Contact form</b>
      <p style={{marginLeft:"48px" , marginTop:"0px"}}>Personal Data: email address; first name; last name; various types of Data</p> 
      <br/>
      <b style={{marginLeft:"48px", marginBottom:"0px"}}>Mailing list or newsletter</b>
      <p style={{marginLeft:"48px" , marginTop:"0px"}}>Personal Data: email address; first name; last name; phone number; Tracker</p> 
     </div>
   </div>

   <br/>
   <Divider />
   <br/>
   <b>Contact information</b>
   <br/>
   <br/>
   <div>
     <div >

     <div style={{display:"flex"}}>
      <PermIdentityOutlinedIcon style={{marginLeft:"0px",marginRight:"10px", fontSize:"40", paddingTop:"0px"}}/>
      <b style={{marginLeft:"53px", marginBottom:"0px"}}>Owner and Data Controller</b>
      
      </div>
      <p style={{marginLeft:"105px" , marginTop:"0px"}}>Brainy SRLS ROMA (RM) VIA DI S CROCE<br />
                                                        IN GERUSALEMME 63 CAP<br />
                                                        00185
      </p>
      <p style={{marginLeft:"105px" , marginTop:"0px"}}><b>Owner contact email:</b> pietro.gabbuti@brainyrms.com</p>
     </div>
   </div>

   <br/>
   <Divider />
   <br/>
   <div style={{display:"flex"}}>
    <p style={{marginLeft:"0px" , marginTop:"0px"}}>Latest update: June 18, 2021</p>
    <p style={{marginLeft:"750px" , marginTop:"0px", display:"grid", width:"260px"}}><a href="/CompletePolicy" style={{color:"black"}}>Show the Complete Privacy Policy</a></p>
   </div>
  
   </div>

   <br/>
  
   <br/>



 </div>

 </div></div>
    );
  }

  
   
  export default PrivacyPolicy;