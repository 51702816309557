import '../bi.css'

import {
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import { useEffect, useState } from 'react'

import Chart from 'react-apexcharts'
import { DateRange } from 'react-date-range'
import HistoricalLineChart from './HistoricalLineChart'
import { revenuePace } from '../utils/revenueStatisticsAPiCalls'
import { useTheme } from '@emotion/react'
import axios from 'axios'
import FutureLineChart from './FutureLineChart'
import { toast } from 'react-toastify'
import { abbreviateNumber, dataFormatting } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      margin: 'auto',
    },
  },
}))
const YearToDateOverview = ({
  propertyId,
  futureYearDataApiCalls,
  historicalYearDataApiCalls,
  historicalYearDataGraph,
  futureYearDataGraph,
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  //----------------- year to date historical -----------------

  const [showDateRangeHistoryYear, setShowDateRangeHistoryYear] =
    useState(false)
  const [historicalYearStartDate, setHistoricalYearStartDate] = useState(
    dataFormatting(new Date(new Date().getFullYear(), 0, 1))
  )
  const [historicalYearEndDate, setHistoricalYearEndDate] = useState(
    dataFormatting(new Date())
  )
  const [historicalYearData, setHistoricalYearData] = useState({})
  const [historicalYearGraphData, setHistoricalYearGraphData] = useState([])
  const [startUpdatingHistoricalYearData, setStartUpdatingHistoricalYearData] =
    useState(false)

  const [dateRangeStateHistorical, setDateRangeStateHistorical] = useState([
    {
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const handleSelectHistoryYear = (ranges) => {
    setDateRangeStateHistorical([ranges.selection])
    setHistoricalYearStartDate(dataFormatting(ranges.selection.startDate))
    setHistoricalYearEndDate(dataFormatting(ranges.selection.endDate))
  }

  const updatehandleSelectHistoryYear = () => {
    setShowDateRangeHistoryYear(false)
    setStartUpdatingHistoricalYearData(true)
    const historicalYearlyPayload = {
      start_date: historicalYearStartDate,
      end_date: historicalYearEndDate,
      periodicity: 'yearly',
      time_stage: 'historical',
      propertyId: propertyId,
    }
    const historicalYearlyGraphPayload = {
      start_date: historicalYearStartDate,
      end_date: historicalYearEndDate,
      periodicity: 'yearly_week_graph',
      time_stage: 'historical',
      propertyId: propertyId,
    }
    axios
      .all([
        revenuePace(historicalYearlyGraphPayload),
        revenuePace(historicalYearlyPayload),
      ])
      .then(
        axios.spread((...responses) => {
          setHistoricalYearGraphData(responses[0].data.payload)
          setHistoricalYearData(responses[1].data.payload)
          setStartUpdatingHistoricalYearData(false)
          toast.success('Data updated successfully')
        })
      )
      .catch((errors) => {
        toast.error('Error updating data')
        setStartUpdatingHistoricalYearData(false)
      })
  }

  //----------------- year to date future -----------------
  const [futureYearStartDate, setFutureYearStartDate] = useState(
    dataFormatting(new Date())
  )
  const [futureYearEndDate, setFutureYearEndDate] = useState(
    dataFormatting(new Date(new Date().getFullYear(), 11, 31))
  )
  const [futureYearData, setFutureYearData] = useState({})
  const [showDateRangFutureYear, setShowDateRangeFutureYear] = useState(false)
  const [futureYearGraphData, setFutureYearGraphData] = useState([])
  const [startUpdatingFutureYearData, setStartUpdatingFutureYearData] =
    useState(false)
  const [dateRangeStateFuture, setDateRangeStateFuture] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().getFullYear(), 11, 31),
      key: 'selection',
    },
  ])
  const handleSelectFutureYear = (ranges) => {
    setDateRangeStateFuture([ranges.selection])

    setFutureYearStartDate(dataFormatting(ranges.selection.startDate))
    setFutureYearEndDate(dataFormatting(ranges.selection.endDate))
  }
  const updatehandleSelectFutureYear = () => {
    setShowDateRangeFutureYear(false)
    setStartUpdatingFutureYearData(true)
    const futureYearlyPayload = {
      start_date: futureYearStartDate,
      end_date: futureYearEndDate,
      periodicity: 'yearly',
      time_stage: 'future',
      propertyId: propertyId,
    }
    const futureYearlyGraphPayload = {
      start_date: futureYearStartDate,
      end_date: futureYearEndDate,
      periodicity: 'yearly_month_graph',
      time_stage: 'future',
      propertyId: propertyId,
    }
    axios
      .all([
        revenuePace(futureYearlyGraphPayload),
        revenuePace(futureYearlyPayload),
      ])
      .then(
        axios.spread((...responses) => {
          setFutureYearGraphData(responses[0].data.payload)
          setFutureYearData(responses[1].data.payload)
          setStartUpdatingFutureYearData(false)
          toast.success('Data updated successfully')
        })
      )
      .catch((errors) => {
        toast.error('Error updating data')
        setStartUpdatingFutureYearData(false)
      })
  }
  // options for historical
  const series = [34]
  const options = {
    chart: {
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          style: {
            fontSize: '24px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            marginBottom: '30px',
          },
          name: {
            show: true,
          },
          value: {
            fontSize: '22px',
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: [`${abbreviateNumber(historicalYearData?.total_revenue)} €`],
  }
  // options for future

  const series2 = [44]
  const options2 = {
    chart: {
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          style: {
            fontSize: '24px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            marginBottom: '30px',
          },
          name: {
            show: true,
          },
          value: {
            fontSize: '22px',
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: [`${abbreviateNumber(futureYearData?.total_revenue)} €`],
  }

  // -------Use Effect calls-----------

  useEffect(() => {
    setHistoricalYearData(historicalYearDataApiCalls)
    setFutureYearData(futureYearDataApiCalls)
    setHistoricalYearGraphData(historicalYearDataGraph)
    setFutureYearGraphData(futureYearDataGraph)
  }, [
    futureYearDataApiCalls,
    historicalYearDataApiCalls,
    historicalYearDataGraph,
    futureYearDataGraph,
  ])
  return (
    <div>
      <Typography
        className='mb-4'
        variant='h4'
        align='center'
        style={{ fontWeight: 600 }}
      >
        YEAR-TO-DATE (YTD) OVERVIEW
      </Typography>

      <Grid container spacing={2}>
        {/* Historical */}
        <Grid item xs={12} lg={5}>
          {startUpdatingHistoricalYearData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
              }}
            >
              <div
                className='loading setting_title_typo'
                data-loading-text='Processing Data ...'
              ></div>
            </div>
          ) : (
            <>
              <Typography
                variant='h5'
                align='center'
                style={{ fontWeight: 600 }}
              >
                Historical
              </Typography>
              <Typography variant='subtitle1' align='center'>
                Select a different date range from the data picker or click on
                the graph
              </Typography>
              <div align='center' className='mt-4'>
                <TextField
                  id='standard-basic'
                  onClick={() => setShowDateRangeHistoryYear(true)}
                  value={`${historicalYearStartDate} - ${historicalYearEndDate}`}
                  variant='outlined'
                />
              </div>
              {showDateRangeHistoryYear && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '350px',
                    margin: '0 auto 0 auto',
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelectHistoryYear}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRangeStateHistorical}
                  />{' '}
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant='contained'
                      onClick={() => setShowDateRangeHistoryYear(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      onClick={() => updatehandleSelectHistoryYear()}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}{' '}
              {/* chart guage*/}
              <Chart options={options} series={series} type='radialBar' />
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <div>
                  <Typography variant='subtitle1'> Revenue</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(historicalYearData?.total_revenue)} €
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'> Room Nights</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(historicalYearData.room_nights)}
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'> ADR</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(historicalYearData.gross_adr)} €
                  </Typography>
                </div>
              </div>
              {/* line chart with average */}
              <HistoricalLineChart
                historicalYearGraphData={historicalYearGraphData}
              />
            </>
          )}
        </Grid>
        <Grid xs={12} lg={2} item>
          <Divider
            className={classes.divider}
            orientation='vertical'
            variant='middle'
            textAlign='center'
          />
        </Grid>
        {/* Future */}
        <Grid item xs={12} lg={5}>
          {startUpdatingFutureYearData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
              }}
            >
              <div
                className='loading setting_title_typo'
                data-loading-text='Processing Data ...'
              ></div>
            </div>
          ) : (
            <>
              <Typography
                variant='h5'
                align='center'
                style={{ fontWeight: 600 }}
              >
                Future{' '}
              </Typography>
              <Typography variant='subtitle1' align='center'>
                Select a different date range from the data picker or click on
                the graph
              </Typography>

              <div align='center' className='mt-4'>
                <TextField
                  id='standard-basic'
                  onClick={() => setShowDateRangeFutureYear(true)}
                  value={`${futureYearStartDate} - ${futureYearEndDate}`}
                  variant='outlined'
                />
              </div>
              {showDateRangFutureYear && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '350px',
                    margin: '0 auto 0 auto',
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelectFutureYear}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRangeStateFuture}
                  />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant='contained'
                      onClick={() => setShowDateRangeFutureYear(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      onClick={() => updatehandleSelectFutureYear()}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
              {/* chart guage*/}
              <Chart options={options2} series={series2} type='radialBar' />
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <div>
                  <Typography variant='subtitle1'>
                    {' '}
                    Revenue On The Book
                  </Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(futureYearData?.total_revenue)} €
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'>
                    {' '}
                    Room Nights On the book
                  </Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(futureYearData.room_nights)}
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'> Adr On the Book</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(futureYearData.gross_adr)} €
                  </Typography>
                </div>
              </div>

              {/* line chart with average */}
              <FutureLineChart futureYearGraphData={futureYearGraphData} />
            </>
          )}
        </Grid>{' '}
      </Grid>
    </div>
  )
}

export default YearToDateOverview
