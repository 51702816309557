import { ACTION_TYPES } from "../actions/types";
const initialState = {
    monthlyLoader: true
}

export const monthlyLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.setMonthlyLoader:
      return action.payload;

    default:
      return state;
  }
};
