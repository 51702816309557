import { DefaultUrl } from '../../BaseUrl'

import { collection, getDoc, doc } from "firebase/firestore";
import axios from 'axios'
// getting Data

export const getMonthlyViewCards = async (
  propertyId,
  sellStatCardMonth,
  sellStatCardYear
) => {
  const response = await axios.post(`${DefaultUrl}/get_monthly_view_cards`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      propertyId: propertyId,
      year: sellStatCardYear,
      month: sellStatCardMonth,
      pms_name: localStorage.getItem('pmsName'),
    },
  })
  return response
}
export const getMonthlyViewCardsFirestore = async (propertyId,db) => {
  const docRef = doc(db, "get_monthly_cards_data",propertyId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data
}
export const getRoomTypes = async (propertyId) => {
  const response = await axios.post(`${DefaultUrl}/get_room_types`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      propertyId: propertyId,
      pms_name: localStorage.getItem('pmsName'),
    },
  })
  return response
}
export const getRoomTypesFirebase = async (propertyId,db) => {
  // const col = collection(db, 'temp_price_collection');
  // const snapshot = await getDocs(col);
  // const list = snapshot.docs.map(doc => doc.data());
  const docRef = doc(db, "octorate",propertyId);

  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data?.roomtypes;
}
export const getCalendar = async (propertyId, room, year, month) => {
  const response = await axios.post(`${DefaultUrl}/get_calendar`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      propertyId: propertyId,
      roomType: room,
      year: year,
      month: month,
      pms_name: localStorage.getItem('pmsName'),
    },
  })
  return response
}
export const getCalendarFirestore = async (propertyId,db) => {
  const docRef = doc(db, "get_calendar_data",propertyId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data
}
export const monthlyPriceProgress = async (propertyId, year, month) => {
  const response = await axios.post(
    `${DefaultUrl}/get_monthly_price_progress`,
    {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        propertyId: propertyId,
        year: year,
        month: month,
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )
  return response
}
export const monthlyPriceProgressFirestore = async (propertyId, db) => {
  const docRef = doc(db, "get_monthly_price_progress_data",propertyId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data
}


// setting Data

export const setCalendar = async (propertyId, roomFromParent, year, month) => {
  const response = await axios.post(`${DefaultUrl}/get_calendar`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      propertyId: propertyId,
      roomType: roomFromParent,
      year: year,
      month: month,
      pms_name: localStorage.getItem('pmsName'),
    },
  })
  return response
}
export const setCalendarFirestore = async (propertyId,db) => {
  const docRef = doc(db, "get_calendar_data",propertyId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data
}
export const setMonthlyPriceProgress = async (propertyId, year, month) => {
  const response = await axios.post(
    `${DefaultUrl}/get_monthly_price_progress`,
    {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        propertyId: propertyId,
        year: year,
        month: month,
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )
  return response
}
