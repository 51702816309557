import { DefaultUrl } from '../../../../BaseUrl'
import axios from 'axios'
// -------------------- yesterday ----------------
export const yesterdayPaceFunc = async (
  yesterdayPaceStartDate,
  yesterdayPaceEndDate,
  propertyId
) => {
  const result = await axios.post(`${DefaultUrl}/yesterday_pace`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      start_date: yesterdayPaceStartDate,
      end_date: yesterdayPaceEndDate,
      propertyId: propertyId,
      pms_name: localStorage.getItem('pmsName'),
    },
  })

  return result
}

// -------------------- Monthly ----------------
export const monthlyPaceFunc = async (
  monthlyPaceStartDate,
  monthlyPaceEndDate,
  propertyId
) => {
  const result = await axios.post(`${DefaultUrl}/last_days_revenue_pace`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      start_date: monthlyPaceStartDate,
      end_date: monthlyPaceEndDate,
      propertyId: propertyId,
      pms_name: localStorage.getItem('pmsName'),
    },
  })

  return result
}
export const revenuePace = async (payload) => {
  const result = await axios.post(`${DefaultUrl}/revenue_pace`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      end_date: payload.end_date,
      periodicity: payload.periodicity,
      start_date: payload.start_date,
      time_stage: payload.time_stage,
      propertyId: payload.propertyId,
      pms_name: localStorage.getItem('pmsName'),
    },
  })

  return result
}
