import { DefaultUrl } from "../../../BaseUrl";
import axios from "axios";
export const getWeatherInfo = async (reqDate) => {
  const result = await axios.get(
    "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/45.4351%2C%2010.9897/" +
      reqDate +
      "/" +
      reqDate +
      `?unitGroup=us&key=YKM9NHBVBWGU3ZY674WKEWXBQ&contentType=json`,
    { method: "GET" }
  );

  return await result;
};

export const getCompetitors = async (reqDate, propertyId) => {
  const result = await axios.post(`${DefaultUrl}/get_competitors`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: {
      reqDate: reqDate,
      propertyId: propertyId,
      pms_name: localStorage.getItem("pmsName"),
    },
  });
  return result;
};

export const dailySuggestedPrices = async (reqDate, propertyId) => {
  const result = await axios.post(`${DefaultUrl}/get_daily_suggested_prices`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: {
      propertyId: propertyId,
      reqDate: reqDate,
      pms_name: localStorage.getItem("pmsName"),
    },
  });

  return result;
};

export async function sendCalculatePricesMessage(pmsId, propertyId, date) {
  const dates = [];
  dates.push(date);
  axios.post(
    "https://publish-message-3cuixb5lia-ew.a.run.app/calculatePrices",
    {
      topicName: "calculatePrices",
      payload: {
        pmsId: pmsId,
        propertyId: propertyId,
        dates: dates,
      },
    }
  );
}
