import "../Index.scss";
import "./dailyView.scss";

import React, { useEffect, useState } from "react";

import FadeIn from "react-fade-in";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import { useStylesReservation } from "./muiStyles";
import { formatInEuropeanNoSymbol } from "../../utils";

export default function LatestReservationsTable({ latestReservationsData }) {
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState(latestReservationsData);

  const classes = useStylesReservation();
  const [orderDirectionReservationDate, setOrderDirectionReservationDate] =
    useState("desc");
  const [orderDirectionStatus, setOrderDirectionReservationDateStatus] =
    useState("asc");
  const [orderDirectionRevenue, setOrderDirectionReservationDateRevenue] =
    useState("asc");
  const [orderDirectionCheckIn, setOrderDirectionReservationDateCheckIn] =
    useState("asc");
  const [orderDirectionLengthStay, setOrderDirectionReservationDateLengthStay] =
    useState("asc");
  const [orderDirectionADR, setOrderDirectionReservationDateADR] =
    useState("asc");

  // -------------- order by reservation date --------------------

  const sortArrayByReservationDate = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          new Date(a.creationDate) > new Date(b.creationDate)
            ? 1
            : new Date(b.creationDate) > new Date(a.creationDate)
            ? -1
            : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          new Date(a.creationDate) < new Date(b.creationDate)
            ? 1
            : new Date(b.creationDate) < new Date(a.creationDate)
            ? -1
            : 0
        );
    }
  };

  const handleSortRequest = () => {
    setRowData(sortArrayByReservationDate(data, orderDirectionReservationDate));
    setOrderDirectionReservationDate(
      orderDirectionReservationDate === "asc" ? "desc" : "asc"
    );
  };
  // -------------- order by status --------------------

  const sortArrayByStatus = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a.status > b.status ? 1 : b.status > a.status ? -1 : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a.status < b.status ? 1 : b.status < a.status ? -1 : 0
        );
    }
  };
  const handleSortRequestStatus = () => {
    setRowData(sortArrayByStatus(data, orderDirectionStatus));
    setOrderDirectionReservationDateStatus(
      orderDirectionStatus === "asc" ? "desc" : "asc"
    );
  };
  // -------------- order by revenue --------------------

  const sortArrayByREVENUE = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a.revenue > b.revenue ? 1 : b.revenue > a.revenue ? -1 : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a.revenue < b.revenue ? 1 : b.revenue < a.revenue ? -1 : 0
        );
    }
  };
  const handleSortRequestREVENUE = () => {
    setRowData(sortArrayByREVENUE(data, orderDirectionRevenue));
    setOrderDirectionReservationDateRevenue(
      orderDirectionRevenue === "asc" ? "desc" : "asc"
    );
  };

  // -------------- order by checkin --------------------

  const sortArrayByCheckIn = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a.checkInDate > b.checkInDate
            ? 1
            : b.checkInDate > a.checkInDate
            ? -1
            : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a.checkInDate < b.checkInDate
            ? 1
            : b.checkInDate < a.checkInDate
            ? -1
            : 0
        );
    }
  };
  const handleSortRequestCheckIn = () => {
    setRowData(sortArrayByCheckIn(data, orderDirectionCheckIn));
    setOrderDirectionReservationDateCheckIn(
      orderDirectionCheckIn === "asc" ? "desc" : "asc"
    );
  };
  // -------------- order by lengthof stay --------------------

  const sortArrayByLengthStay = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a.length_of_stay > b.length_of_stay
            ? 1
            : b.length_of_stay > a.length_of_stay
            ? -1
            : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a.length_of_stay < b.length_of_stay
            ? 1
            : b.length_of_stay < a.length_of_stay
            ? -1
            : 0
        );
    }
  };
  const handleSortRequestLengthStay = () => {
    setRowData(sortArrayByLengthStay(data, orderDirectionLengthStay));
    setOrderDirectionReservationDateLengthStay(
      orderDirectionLengthStay === "asc" ? "desc" : "asc"
    );
  };

  // -------------- order by ADR--------------------

  const sortArrayByADR = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) => (a.adr > b.adr ? 1 : b.adr > a.adr ? -1 : 0));
      case "desc":
        return arr.sort((a, b) => (a.adr < b.adr ? 1 : b.adr < a.adr ? -1 : 0));
    }
  };
  const handleSortRequestADR = () => {
    setRowData(sortArrayByADR(data, orderDirectionADR));
    setOrderDirectionReservationDateADR(
      orderDirectionADR === "asc" ? "desc" : "asc"
    );
  };
  //Use effect calls
  useEffect(() => {
    setRowData(
      sortArrayByReservationDate(
        latestReservationsData,
        orderDirectionReservationDate
      )
    );
    setOrderDirectionReservationDate(
      orderDirectionReservationDate === "asc" ? "desc" : "asc"
    );
    setData(rowData);
  }, [latestReservationsData, rowData]);
  //Use effect calls end
  return (
    <div>
      <FadeIn transitionDuration="4000">
        <TableContainer
          className={classes.tablescroll}
          component={Paper}
          elevation={0}
        >
          {data?.length > 1 ? (
            <Table
              className={classes.table}
              styles={{ minWidth: "450px" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="table_head">
                    NAME
                  </TableCell>
                  <TableCell align="left" className="table_head">
                    ROOM TYPE
                  </TableCell>

                  <TableCell
                    align="left"
                    className="table_head"
                    onClick={handleSortRequest}
                  >
                    <TableSortLabel
                      active={true}
                      direction={orderDirectionReservationDate}
                    >
                      RESERVATION DATE
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="table_head"
                    onClick={handleSortRequestStatus}
                  >
                    <TableSortLabel
                      active={true}
                      direction={orderDirectionStatus}
                    >
                      STATUS
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="table_head"
                    onClick={handleSortRequestCheckIn}
                  >
                    <TableSortLabel
                      active={true}
                      direction={orderDirectionCheckIn}
                    >
                      CHECK IN
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="table_head"
                    onClick={handleSortRequestLengthStay}
                  >
                    <TableSortLabel
                      active={true}
                      direction={orderDirectionLengthStay}
                    >
                      LENGTH OF STAY
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="table_head"
                    onClick={handleSortRequestADR}
                  >
                    <TableSortLabel active={true} direction={orderDirectionADR}>
                      ADR
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="table_head"
                    onClick={handleSortRequestREVENUE}
                  >
                    <TableSortLabel
                      active={true}
                      direction={orderDirectionRevenue}
                    >
                      REVENUE
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>

              {rowData?.map((row, index) => (
                <TableBody key={index}>
                  <TableRow hover={true} className={classes.tableRow}>
                    <TableCell align="left" className={classes.cellTypo}>
                      {row?.booker}
                    </TableCell>
                    <TableCell align="left" className={classes.cellTypo}>
                      {row?.roomTypeAssigned}
                    </TableCell>
                    <TableCell align="left" className={classes.cellTypo}>
                      {row?.creationDate}
                    </TableCell>
                    <TableCell
                      className={classes.cellTypo}
                      align="left"
                      style={{
                        color:
                          row?.status === "CONFIRMED"
                            ? "green"
                            : row?.status === "WAITING"
                            ? "#D39232"
                            : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {row?.status}
                    </TableCell>
                    <TableCell align="left" className={classes.cellTypo}>
                      {row?.checkInDate}
                    </TableCell>
                    <TableCell align="left" className={classes.cellTypo}>
                      {row?.length_of_stay}
                    </TableCell>
                    <TableCell align="left" className={classes.cellTypo}>
                      {formatInEuropeanNoSymbol(row?.adr)}
                    </TableCell>
                    <TableCell align="left" className={classes.cellTypo}>
                      {formatInEuropeanNoSymbol(row?.revenue)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          ) : (
            <Typography
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#f8f8f8",
                borderRadius: "8px",
                padding: "10px 10px",
                fontWeight: "bold",
              }}
            >
              Reservation Data Not Found
            </Typography>
          )}
        </TableContainer>
      </FadeIn>
    </div>
  );
}
