// OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE |||||
// OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE |||||
// OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE |||||
// OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE |||||
// OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE |||||
// OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE ||||| OLD CODE |||||



// DELETE THE WHOLE DOCUMENTATION/COMPONENTS FOLDER WHEN SWITCHING TO NEW CODE



import './documentation.css'

import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import GetMarketImportances from './components/GetMarketImportances/GetmarketImportances' //this
import GetMarketPrices from './components/GetMarketPrices/GetMarketPrices'//this
import GetSuggestedPrices from './components/GetSuggestedPrices/GetSuggestedPrices'//this
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ReservationCaptureWebHook from './components/ReservationCaptureWebhook/ReservationCaptureWebhook'//this
import Toolbar from '@material-ui/core/Toolbar'
import TopBar from './components/TopBar'
import Typography from '@material-ui/core/Typography'
import LoadInternalImportance from './components/LoadInternalImportance/LoadInternalImportance'//this
import UpdateSeason from './components/UpdateSeason/UpdateSeason'//this
import clsx from 'clsx'
import logo from '../../images/logo.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'


const drawerWidth = 220

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#111',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  navItem: {
    textAlign: 'left',
  },
  appBarText: {
    color: '#111',
  },
  anchorTag: {
    textDecoration: 'none',
    color: '#111',
  },
  appBarClr: {
    backgroundColor: 'rgb(255 255 255)',
    paddingTop: '10px',
  },
  sidebarHeading: {
    fontWeight: 'bold',
    fontSize: '15px',
    marginBottom: '-8px',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export default function Documentation() {
  const codeOptions = ['nodeJs', 'jsFetch', 'pythonHttp', 'pythonRequest']
  const [language, changeLanguage] = useState('jsFetch')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        elevation={0}
        className={clsx([classes.appBar, classes.appBarClr])}
      >
        <Toolbar>
          <Typography variant='h6' className={classes.appBarText}>
            Documentation
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor='left'
      >
        <div className={classes.toolbar} />
        <Link to='/LandingPage' className='MonthlyLogo'>
          <img src={logo} alt='logo' width='210' height='30px' />
        </Link>
        <List>
          <ListItem button className={classes.navItem}>
            <a
              className={classes.anchorTag}
              href='/documentation#reservationCaptureWebhook'
            >
              Reservation Capture Webhook
            </a>
          </ListItem>{' '}
          <ListItem button className={classes.navItem}>
            <a
              className={classes.anchorTag}
              href='/documentation#getMarketImportances'
            >
              Get Market Importance
            </a>
          </ListItem>{' '}
          <ListItem button className={classes.navItem}>
            <a
              className={classes.anchorTag}
              href='/documentation#getMarketPrices'
            >
              Get Market Prices
            </a>
          </ListItem>{' '}
          <ListItem button className={classes.navItem}>
            <a
              className={classes.anchorTag}
              href='/documentation#getSuggestedPrices'
            >
              Get Suggested Prices
            </a>
          </ListItem>{' '}
          <ListItem button className={classes.navItem}>
            <a
              className={classes.anchorTag}
              href='/documentation#loadInternalImportance'
            >
              Load Internal Importance
            </a>
          </ListItem>{' '}
          <ListItem button className={classes.navItem}>
            <a className={classes.anchorTag} href='/documentation#updateSeason'>
              Update Season
            </a>
          </ListItem>{' '}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <TopBar
          language={{
            value: language,
            onChange: (e) => {
              changeLanguage(e.target.value)
            },
            options: codeOptions.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            )),
          }}
        />{' '}
        <br /> <br />
        <div id='reservationCaptureWebhook'>
          <ReservationCaptureWebHook language={language} />
        </div>{' '}
        <hr className='hori__line' />
        <div id='getMarketImportances'>
          <GetMarketImportances language={language} />
        </div>{' '}
        <hr className='hori__line' />
        <div id='getMarketPrices'>
          <GetMarketPrices language={language} />
        </div>{' '}
        <hr className='hori__line' />
        <div id='getSuggestedPrices'>
          <GetSuggestedPrices language={language} />
        </div>{' '}
        <hr className='hori__line' />
        <div id='loadInternalImportance'>
          <LoadInternalImportance language={language} />
        </div>{' '}
        <hr className='hori__line' />
        <div id='updateSeason'>
          <UpdateSeason language={language} />
        </div>{' '}
      </main>
    </div>
  )
}






// NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| 
// NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| 
// NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| 
// NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| 
// NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| NEW CODE ||||| 






// import AppBar from '@material-ui/core/AppBar'
// import CssBaseline from '@material-ui/core/CssBaseline'
// import Drawer from '@material-ui/core/Drawer'
// import { Link } from 'react-router-dom'
// import List from '@material-ui/core/List'
// import ListItem from '@material-ui/core/ListItem'
// import Toolbar from '@material-ui/core/Toolbar'
// import clsx from 'clsx'
// import logo from '../../images/logo.svg'
// // import { useState } from 'react'
// import './documentation.css'
// import Manual from './Manual'
// import { documentationStyle } from './musiStyles'
// import { jsonSchema } from './schema'
// import { Chip } from '@material-ui/core'
// import Accordion from '@material-ui/core/Accordion'
// import AccordionSummary from '@material-ui/core/AccordionSummary'
// import AccordionDetails from '@material-ui/core/AccordionDetails'
// import Typography from '@material-ui/core/Typography'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// export default function Documentation() {
// 	const classes = documentationStyle()

// 	return (
// 		<div className={classes.root}>
// 			<CssBaseline />
// 			<AppBar position="fixed" elevation={0} className={clsx([classes.appBar, classes.appBarClr])}>
// 				<Toolbar>
// 					<Typography variant="h6" className={classes.appBarText}>
// 						Documentation
// 					</Typography>
// 				</Toolbar>
// 			</AppBar>

// 			<Drawer
// 				className={classes.drawer}
// 				variant="permanent"
// 				classes={{
// 					paper: classes.drawerPaper,
// 				}}
// 				anchor="left"
// 			>
// 				<div className={classes.toolbar} />
// 				<Link to="/LandingPage" className="MonthlyLogo">
// 					<img src={logo} alt="logo" width="210" height="30px" />
// 				</Link>

// 				<List>
// 					{jsonSchema.map((item, index) => {
// 						return (
// 							<ListItem button className={classes.navItem}>
// 								<a className={classes.anchorTag} href="#">
// 									{item.title}
// 								</a>
// 							</ListItem>
// 						)
// 					})}
// 				</List>
// 			</Drawer>

// 			<main className={classes.content}>
// 				<div className={classes.toolbar} />

// 				{/* left bar */}

// 				<div>
// 					<Manual />

// 					{/* endpoints detail */}

// 					<div className="box">
// 						{jsonSchema.map((da, index) => {
// 							return (
// 								<>
// 									<h2 className="main__headings">{da.title}</h2>

// 									{da.endpoints.map((endpoint, index) => {
// 										return (
// 											<>
// 												<h3 className="sub__headings"> {endpoint.title} </h3>
// 												<p className="paragraphs">{endpoint.description}</p>
// 												<h3 className="sub__headings "> Request </h3>

// 												<p className="paragraphs">Request Body Schema</p>

// 												{endpoint.requestedParameters.map((endpoint, index) => {
// 													return (
// 														<div style={{ display: 'flex', alignItems: 'center' }}>
// 															<div style={{ display: 'flex' }}>
// 																<p>{endpoint.name}</p>
// 																<Chip
// 																	className={endpoint.required ? 'required__chip' : 'optional__chip'}
// 																	label={endpoint.required ? 'required' : 'optional'}
// 																/>
// 															</div>
// 															<div>
// 																<Chip className="type__chip" label={endpoint.type} />

// 																<p>{endpoint.detail}</p>
// 															</div>
// 														</div>
// 													)
// 												})}
// 												{endpoint.responses.map((endpoint, index) => {
// 													return (
// 														<>
// 															<h3 className="sub__headings "> Responses </h3>
// 															<Accordion>
// 																<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
// 																	<Typography className={classes.heading}>{endpoint.message}</Typography>
// 																</AccordionSummary>
// 																<AccordionDetails>
// 																	<p>
// 																		<span>Response Schema: </span>
// 																		<span>{endpoint.responseType}</span>
// 																	</p>

// 																	<div
// 																		style={{
// 																			display: 'flex',
// 																			alignItems: 'center',
// 																		}}
// 																	>
// 																		<div style={{ display: 'flex' }}>
// 																			<p>message</p>
// 																		</div>
// 																		<div>
// 																			<Chip className="type__chip" label={endpoint.type} />

// 																			<p>{endpoint.detail}</p>
// 																		</div>
// 																	</div>
// 																</AccordionDetails>
// 															</Accordion>
// 														</>
// 													)
// 												})}
// 											</>
// 										)
// 									})}
// 								</>
// 							)
// 						})}
// 					</div>
// 				</div>

// 				{/* right bar */}
// 				<div>Right</div>
// 			</main>
// 		</div>
// 	)
// }
