import DailyBookingPace from './dailyBookingPace/DailyBookingPace'
import { auth } from '../../../../Auth/firebase'
import { useEffect } from 'react'

const BiStats = ({ propertyId, propertyStatus }) => {
  useEffect(() => {
    // checking for expiration if idToken and get new one
    if (
      new Date().getTime() >
      new Date(localStorage.getItem('expirationDate')).getTime()
    ) {
      auth.currentUser.getIdToken(/* forceRefresh */ true).then((response) => {
        localStorage.setItem('token', response)
        const expirationDate = new Date(new Date().getTime() + 3600000)
        localStorage.setItem('expirationDate', expirationDate)
      })
    }
  }, [])

  return (
    <>
      {propertyId && (
        <DailyBookingPace
          propertyId={propertyId}
          propertyStatus={propertyStatus}
        />
      )}
    </>
  )
}

export default BiStats
