import "../LandingPage/Header.scss";

import React, { Component } from "react";

import { Grid } from "@material-ui/core";
import Header from "../LandingPage/Header";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import SignInForm from "./SignInForm";

class Image extends Component {

  render() {
    return (
      <div>
       
          <img
            className="img-responsive"
            src="./images/signIn.png"
            alt="HeroImage"
            width="100%"
           
        
          />
      
      </div>
    );
  }
}

export default function SignInPage() {

  return (
    <div>
      <Header />
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
        className="content"
      >
        <Hidden mdDown>
          <Grid item xs={12} lg={4}>
            <Paper elevation={0} className="signup_form_img">
              <Image />
            </Paper>
          </Grid>
        </Hidden>{" "}
        <Grid item xs={12} lg={5}>
          <Paper elevation={0}>
           
              <SignInForm />
          
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
