import { Grid, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import FadeIn from "react-fade-in";
import Typography from "@material-ui/core/Typography";
import annotationPlugin from "chartjs-plugin-annotation";
import { useStylesBootstrap } from "../../utils/tooltipDesign";
import { formatInEuropeanNoSymbol } from "../../utils";
import { ReactComponent as NoData } from "../../../images/No_data.svg";
import { primaryColor } from "../../../colors";

Chart.register(annotationPlugin);
// Material UI style

// Material UI style end
export default function CompetetorsTable({ competitorsData, noData }) {
  const toolTipClasses = useStylesBootstrap();
  const [data, setDate] = useState([]);

  useEffect(() => {
    setDate(competitorsData);
  }, [competitorsData]);

  // const arrayCompPrice = data?.compPrice;
  // const maxValueecompPrice = arrayCompPrice && Math.max(...arrayCompPrice);
  // const maxValBar = Math.max(maxValueecompPrice, data?.roomPrice) * 1.1;

  const Mapprices = [];
  const state = {
    labels: ["My Price", "Market Leaders Median Price"],
    datasets: [
      {
        backgroundColor: ["rgba(81,91,133,1)", "rgba(72 , 217, 165 ,1)"],
        borderColor: ["rgba(81,91,133,1)", "rgba(72 , 217, 165 ,1)"],
        borderWidth: 1,
        barPercentage: 0.5,
        barThickness: 100,
        borderRadius: 10,
        data: [data?.roomPrice, data?.medianPrice],
      },
    ],
  };
  const NoDataComponent = () => {
    return (
      <div className="noData-container">
        <NoData
          className="noData-svg"
          fill={localStorage.getItem("primaryColor")}
        />
        <p>
          Sorry!
          <br />
          No Data available for selected parameters
        </p>
      </div>
    );
  };
  //Getting Min and Max value for graph

  const MinValue = () => {
    if (data?.length !== 0 && data !== undefined) {
      Mapprices.push(...data?.compPrice);
      Mapprices.push(data?.roomPrice);
      return Math.min(...Mapprices);
    } else {
      return 0;
    }
  };

  const MaxValue = () => {
    if (data?.length !== 0 && data !== undefined) {
      Mapprices.push(...data?.compPrice);
      Mapprices.push(data?.roomPrice);
      return Math.max(...Mapprices);
    } else {
      return 0;
    }
  };
  //Getting Min and Max value for graph end
  return (
    <div>
      <>
        <FadeIn transitionDuration="4000">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" style={{ margin: "32px 0px" }}>
              <Tooltip
                arrow
                title="Comparison with market leaders"
                classes={toolTipClasses}
              >
                <b className="dailyview_title_typo">Market Leaders</b>
              </Tooltip>
            </Typography>
          </div>
          {noData ? (
            <NoDataComponent />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={8}>
                <Bar
                  data={state}
                  options={{
                    responsive: true,
                    plugins: {
                      autocolors: false,
                      annotation: {
                        annotations: {
                          box1: {
                            fill: false,
                            // Indicates the type of annotation
                            type: "box",
                            xMin: "My Price",
                            xMax: "Market Leaders Median Price",
                            yMin: MinValue,
                            yMax: MaxValue,
                            backgroundColor: "rgba(255, 255, 225, 0.35)",
                            borderDash: [5, 5],
                            borderDashOffset: 2,
                            borderColor: "blue",
                            borderWidth: 2,
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 10,
                            bottomRight: 10,
                          },
                        },
                      },
                      legend: {
                        maintainAspectRatio: true,
                        display: false,
                        labels: {
                          color: "rgb(255, 99, 132)",
                        },
                      },
                    },

                    title: {
                      display: false,
                    },
                    legend: {
                      labels: { display: false },
                      position: "bottom",
                    },
                    scales: {
                      x: {
                        grid: { display: false },
                      },
                    },
                  }}
                />
              </Grid>
              <br />
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                style={{
                  padding: "30px 40px",
                  backgroundColor: "aliceblue",
                  width: "30%",
                  borderRadius: "11px",
                  height: "fit-content",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      width: "8px",
                      height: "20px",
                      backgroundColor: "rgba(81 , 91 , 133 ,1)",
                      borderRadius: "5px",
                      marginRight: "10px",
                      marginTop: "7px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ padding: "0", margin: "0", fontSize: "15px" }}>
                      My Suggested Price
                    </p>
                    <h1 style={{ padding: "0", margin: "0", fontSize: "15px" }}>
                      €{formatInEuropeanNoSymbol(Math.round(data?.roomPrice))}
                    </h1>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      width: "8px",
                      height: "20px",
                      backgroundColor: "rgba(72 , 217 , 165 ,1)",
                      borderRadius: "5px",
                      marginRight: "10px",
                      marginTop: "7px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ padding: "0", margin: "0", fontSize: "15px" }}>
                      Market Leaders Median Price
                    </p>
                    <h1 style={{ padding: "0", margin: "0", fontSize: "15px" }}>
                      €{formatInEuropeanNoSymbol(data?.medianPrice)}
                    </h1>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100% !important",
                  }}
                >
                  {data?.compNames?.map(
                    (comp, index) =>
                      index < 6 && (
                        <div
                          key={index}
                          style={{
                            marginTop: "5px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ padding: "0", margin: "0" }}>{comp} </p>
                          <p style={{ padding: "0", margin: "0" }}>
                            €{Math.round(data?.compPrice[index])}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </FadeIn>
      </>
    </div>
  );
}
