import React, { useEffect } from "react";

import { useAuth } from "../Context/AuthUserContext";
import {useHistory} from 'react-router-dom'

const AutoSignIn = () => {
  const { signin } = useAuth();
  const history = useHistory();
  let params = new URL(document.location).searchParams;

  // var obj = {email: 'dev@brainyrms.com', password: 'dev@brainyrms.com'};
  // var params = btoa(JSON.stringify(obj))

  let token = params.get("token");
  var actual = JSON.parse(atob(token));
  const email = actual.email;
  const password = actual.password;

useEffect(() => {
  try {
    
    signin( email,  password);
    history.push("/dashboard/monthlyPrices")
  } catch (e) {
  }
 // eslint-disable-next-line
},[])

  return <div>{token}</div>;
};

export default AutoSignIn;
