import {
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import {
  bookerInsight,
  bookWindow,
  salesRatio,
  salesRatioRoomType,
  salesReportInsight,
} from './utils/salesStat'
import { useEffect, useState } from 'react'
import AssignedRoomADR from './AssignedRoomADR'
import AssignedRoomRevenue from './AssignedRoomRevenue'
import AssignedRoomShare from './AssignedRoomShare'
import BookerInsight from './bookerInsight/BookerInsight'
import Bookwindow from './bookwindow/Bookwindow'
import CancellationRatio from './CancellationRatio'
import Chart from 'chart.js/auto'
import { DateRange } from 'react-date-range'
import { Line } from 'react-chartjs-2'
import RateType from './RateType'
import ReservationByWeek from './ReservationByWeek'
import annotationPlugin from 'chartjs-plugin-annotation'
import { auth } from '../../../../Auth/firebase'
import { useTheme } from '@emotion/react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { dataFormatting, formatInEuropeanNoSymbol } from '../../../utils'

Chart.register(annotationPlugin)

const useStyles = makeStyles((theme) => ({
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      margin: 'auto',
    },
  },
}))
const SalesStatistic = ({ propertyId, propertyStatus }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [loading, setLoading] = useState(true)
  const [hotel, setHotel] = useState({})

  // ----------------- States -------------------------

  const [showDateSalesStat, setShowDateSalesStat] = useState(false)
  const [salesStatStartDates, setSalesStatStartDates] = useState(
    dataFormatting(new Date(new Date().getFullYear(), 0, 1))
  )
  const [salesStatEndDates, setSalesStatEndDates] = useState(
    dataFormatting(new Date())
  )
  const [salesReportInsightData, setSalesReportInsight] = useState([])
  //---------------------- booker insight ----------------------

  const [bookerInsightNationality, setBookerInsightNationality] = useState({})
  const [bookerInsightOrigin, setBookerInsightOrigin] = useState({})

  //---------------------- booker insight ----------------------

  const [bookWindowApiCall, setBookWindowApiCall] = useState([])
  //---------------------- ratio ----------------------
  const [salesStatCancellationData, setSalesStatCancellationData] = useState([])
  const [salesStatRevenueWeekData, setSalesStatRevenueWeekData] = useState([])
  const [salesStatFreeCancellationData, setSalesStatFreeCancellationData] =
    useState([])

  //---------------------- rooms ratio ----------------------

  const [roomTypeByShared, setRoomTypeByShared] = useState([])
  const [roomTypeByRevenue, setRoomTypeByRevenue] = useState([])
  const [roomTypeByADR, setRoomTypeByADR] = useState([])

  // ---------------- date picker ----------------------

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  const [startUpdating, setStartUpdating] = useState(true)
  // --------------------- change date ---------------------
  const [dateRangeStateSalesStatistics, setDateRangeStateSalesStatistics] =
    useState([
      {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(),
        key: 'selection',
      },
    ])
  const handleSelectSalesStatistics = (ranges) => {
    setDateRangeStateSalesStatistics([ranges.selection])
    setSalesStatStartDates(dataFormatting(ranges.selection.startDate))
    setSalesStatEndDates(dataFormatting(ranges.selection.endDate))
  }

  const updatehandleSelectSalesStatistics = () => {
    setShowDateSalesStat(false)
    setStartUpdating(false)
  }
  // -----------------------use Effect calls-----------------------

  const hotelInfo = useSelector((state) => state.hotelList.currentHotel)

  useEffect(() => {
    setHotel(hotelInfo)
    // checking for expiration if idToken and get new one
    if (
      new Date().getTime() >
      new Date(localStorage.getItem('expirationDate')).getTime()
    ) {
      auth.currentUser.getIdToken(/* forceRefresh */ true).then((response) => {
        localStorage.setItem('token', response)
        const expirationDate = new Date(new Date().getTime() + 3600000)
        localStorage.setItem('expirationDate', expirationDate)
      })
    }
    //cancellation type
    const cancellationPayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      sales_type: 'cancellation',
    }

    // Revenue week type
    const revenueWeekPayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      sales_type: 'revenue_by_day_of_week',
    }
    // type free cancellation
    const freeCancellationPayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      sales_type: 'free_cancellation',
    }
    // roomtype by share
    const roomTypeOnePayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      room_type: 'shared',
    }

    // roomtype two

    const roomTypeTwoPayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      room_type: 'revenue',
    }
    // roomtype three
    const roomTypeThreePayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      room_type: 'ADR',
    }
    //nationality
    const nationalityPayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      insight_type: 'nationality',
    }
    //originDataTable

    const originPayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      insight_type: 'origin',
    }
    const salesInsightPayload = {
      start_date: salesStatStartDates,
      end_date: salesStatEndDates,
      propertyId: propertyId,
      pace_type: 'sales_report',
    }
    if (propertyStatus) {
      propertyId &&
        axios
          .all([
            salesRatio(cancellationPayload),
            salesRatio(revenueWeekPayload),
            salesRatio(freeCancellationPayload),
            salesRatioRoomType(roomTypeOnePayload),
            salesRatioRoomType(roomTypeTwoPayload),
            salesRatioRoomType(roomTypeThreePayload),
            bookerInsight(nationalityPayload),
            bookerInsight(originPayload),
            bookWindow(propertyId),
            salesReportInsight(salesInsightPayload),
          ])
          .then(
            axios.spread((...responses) => {
              setSalesStatCancellationData(responses[0].data.payload)
              setSalesStatRevenueWeekData(responses[1].data.payload)
              setSalesStatFreeCancellationData(responses[2].data.payload)
              setRoomTypeByShared(responses[3].data.payload)
              setRoomTypeByRevenue(responses[4].data.payload)
              setRoomTypeByADR(responses[5].data.payload)
              setBookerInsightNationality(responses[6].data.payload)
              setBookerInsightOrigin(responses[7].data.payload)
              setBookWindowApiCall(responses[8].data.payload.book_window_table)
              setSalesReportInsight(responses[9].data.payload)
              setLoading(false)
            })
          )
          .catch((err) => {
            setSalesStatCancellationData([])
            setSalesStatRevenueWeekData([])
            setSalesStatFreeCancellationData([])
            setRoomTypeByShared([])
            setRoomTypeByRevenue([])
            setRoomTypeByADR([])
            setBookerInsightNationality({})
            setBookerInsightOrigin([])
            setBookWindowApiCall([])
            setLoading(false)
          })
    }
  }, [startUpdating, propertyId, propertyStatus])
  // stop loading when propertyStatus is false

  useEffect(() => {
    if (propertyStatus === false) {
      setLoading(false)
    }
  }, [propertyStatus])

  // ----------------line graph data ---------------

  const labels = salesReportInsightData?.month_of_stay?.map(
    (da) => da.time_period
  )
  const data = salesReportInsightData?.month_of_stay?.map((da) =>
    Math.round(da.revenue)
  )

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <img
            src='/images/loading.gif'
            alt='competatorTableList'
            width='350'
          />
        </div>
      ) : propertyStatus ? (
        <>
          <Typography variant='h4' align='center' style={{ fontWeight: 600 }}>
            SALES STATISTICS
          </Typography>
          <Typography variant='subtitle1' align='center'>
            View your sales from guests that have stayed at your property
          </Typography>

          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <div>
                  <Typography variant='subtitle1'> Revenue</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {formatInEuropeanNoSymbol(
                      Math.round(salesReportInsightData?.total_revenue)
                    )}{' '}
                    €
                  </Typography>
                </div>
                <div>
                  <Divider
                    className={classes.divider}
                    orientation='vertical'
                    variant='middle'
                    textAlign='center'
                  />
                </div>
                <div>
                  <Typography variant='subtitle1'> Adr</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {formatInEuropeanNoSymbol(
                      Math.round(salesReportInsightData?.gross_adr)
                    )}{' '}
                    €
                  </Typography>
                </div>{' '}
                <div>
                  <Divider
                    className={classes.divider}
                    orientation='vertical'
                    variant='middle'
                    textAlign='center'
                  />
                </div>
                <div>
                  <Typography variant='subtitle1'>Room Nights</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {salesReportInsightData?.total_nights}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item sm={12} md={1}></Grid>
            <Grid item sm={12} md={5}>
              <div align='center' className='mt-4'>
                <TextField
                  id='standard-basic'
                  onClick={() => setShowDateSalesStat(true)}
                  value={`${salesStatStartDates} - ${salesStatEndDates}`}
                  variant='outlined'
                />
              </div>
              {showDateSalesStat && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '350px',
                    margin: '0 auto 0 auto',
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelectSalesStatistics}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRangeStateSalesStatistics}
                  />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant='contained'
                      onClick={() => setShowDateSalesStat(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      onClick={() => updatehandleSelectSalesStatistics()}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>

          {/* line graph for salesStatistics */}

          <Line
            data={{
              labels: labels,
              datasets: [
                {
                  label: hotel.hotel_name,
                  data: data,
                  fill: false,
                  backgroundColor: 'rgba(72 , 217, 165 ,1)',
                  borderColor: 'rgba(72 , 217, 165 ,1)',
                  lineTension: 0.5,
                },
              ],
            }}
            options={{
              animations: {
                radius: {
                  duration: 400,
                  easing: 'linear',
                  loop: (context) => context.active,
                },
              },
              hoverRadius: 12,
              hoverBackgroundColor: '#84bdd7',
              interaction: {
                mode: 'nearest',
                intersect: false,
                axis: 'x',
              },
              plugins: {
                tooltip: {
                  enabled: true,
                },
              },
            }}
          />

          <Grid container spacing={2}>
            {/* CANCELLATION RATIO */}

            <Grid item sm={12} md={6} lg={4} className='mt-5'>
              <CancellationRatio
                salesStatCancellationData={salesStatCancellationData}
              />
            </Grid>

            {/* RESERVATION BY DAY OF THE WEEK */}
            <Grid item sm={12} md={6} lg={4} className='mt-5'>
              <ReservationByWeek
                salesStatRevenueWeekData={salesStatRevenueWeekData}
              />
            </Grid>

            {/* RATE TYPE:  */}
            <Grid item sm={12} md={6} lg={4} className='mt-5'>
              <RateType
                salesStatFreeCancellationData={salesStatFreeCancellationData}
              />
            </Grid>

            {/* ASSIGNED ROOM TYPE SHARE */}
            <Grid item sm={12} md={6} lg={4} className='mt-5'>
              <AssignedRoomShare roomTypeByShared={roomTypeByShared} />
            </Grid>
            {/* ASSIGNED ROOM TYPE REVENUE */}
            <Grid item sm={12} md={6} lg={4} className='mt-5'>
              <AssignedRoomRevenue roomTypeByRevenue={roomTypeByRevenue} />
            </Grid>

            {/* ASSIGNED ROOM TYPE ADR */}
            <Grid item sm={12} md={6} lg={4} className='mt-5'>
              <AssignedRoomADR roomTypeByADR={roomTypeByADR} />
            </Grid>
          </Grid>
          {propertyId && (
            <BookerInsight
              bookerInsightNationality={bookerInsightNationality}
              bookerInsightOrigin={bookerInsightOrigin}
            />
          )}

          <Bookwindow bookWindowApiCall={bookWindowApiCall} />
        </>
      ) : (
        <>
          <h3>Contact support to activate your Property</h3>
        </>
      )}
    </>
  )
}

export default SalesStatistic
