import Select from "./Select";

export default function({ language }) {
  return (
    <div >
 
      <Select {...language} />

    </div>
  );
}
