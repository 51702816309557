import { useEffect, useState } from "react";

import PiGraphBi from "../PiGraphBi";
import { Typography } from "@material-ui/core";
import noData from "../../../../images/noDataSellStats.png";

const AssignedRoomADR = ({ roomTypeByADR }) => {
  const [data, setData] = useState(roomTypeByADR);

  useEffect(() => {
    setData(roomTypeByADR);
  }, [roomTypeByADR]);
  const series = data.map((da) => Math.round(da.percentage));
  const labels = data.map((da) => da.parent_roomType);
  return (
    <div>
      {roomTypeByADR.length > 0 ? (
        <>
          <Typography variant="subtitle1" align="center">
            ASSIGNED ROOM TYPE ADR
          </Typography>
          <PiGraphBi seriesData={series} labels={labels} />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noData} style={{ textAlign: "center" }}></img>
          <Typography variant="subtitle1" align="center">
            No data available for this KPI
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AssignedRoomADR;
