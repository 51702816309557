import "chartjs-adapter-moment";
import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import FadeIn from "react-fade-in";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Button,
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { useStylesMonthlyPrices } from "./muiStyles";
import { doc, onSnapshot } from "firebase/firestore";
import { db2 } from "../../../Auth/firebase";
import { ReactComponent as NoData } from "../../../images/No_data.svg";
import { reformatDate } from "./utils/utils";

Chart.register(annotationPlugin);

export default function LatestChanges({ propertyId, roomsData }) {
  const classes = useStylesMonthlyPrices();
  const [open, setOpen] = React.useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(1 + new Date().getMonth());
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const currentYear = new Date().getFullYear();
  const nextYear = new Date().getFullYear() + 1;
  const [dataFromParents, setDataFromParents] = useState([]);

  // V2 states
  const [priceProgressFetchedData, setPriceProgressFetchedData] = useState([]);
  const placeholderData = [
    {
      iRevPrice: "",
      parentRoomId: 397354,
    },
    {
      iRevPrice: "",
      parentRoomId: 397353,
    },
    {
      iRevPrice: "",
      parentRoomId: 397357,
    },
    {
      parentRoomId: 397356,
      iRevPrice: "",
    },
    {
      iRevPrice: "",
      parentRoomId: 397358,
    },
    {
      parentRoomId: 397355,
      iRevPrice: "",
    },
    {
      iRevPrice: "",
      parentRoomId: 456159,
    },
  ];

  // colors for chart
  const colors = [
    "#67B7DC",
    "#6794DC",
    "#6771DC",
    "#8067DC",
    "#A367DC",
    "#C767DC",
    "#DC67CE",
    "#DC67AB",
    "#DC6788",
    "#DC6967",
    "#DC8C67",
    "#DCAF67",
  ];

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleUpdateClick = () => {
    if (propertyId != undefined) {
      setLoading(true);
      getPriceProgressDataV2(propertyId, month, year).then(() =>
        setLoading(false)
      );
    }
  };

  // getting v2 price progress data
  const getPriceProgressDataV2 = async (propertyId, month, year) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    // making sure the earlier data is emptied before making next call
    setPriceProgressFetchedData([]);
    // this loops runs equal to the days in the selected month sending request to fetch data of a particular day
    for (let i = 0; i < daysInMonth; i++) {
      //Uses the loop index to get the date on that day and reformat it to YYYY-MM-DD format
      const date = reformatDate(new Date(year, month - 1, i + 1));
      // reference to each day document
      const docRef = doc(
        db2,
        "octorate",
        String(propertyId),
        "days",
        String(date)
      );
      const unsubscribe = onSnapshot(docRef, (doc) => {
        // if the data exists for a particular day
        if (doc.exists()) {
          const data = doc.get("irevPerRoomType");
          if (data) {
            data.date = date; // adding date data for a particular day
            // pushing the new data to the state
            setPriceProgressFetchedData((priceProgressFetchedData) => [
              ...priceProgressFetchedData,
              data,
            ]);
          } else {
            setPriceProgressFetchedData((oldData) => [
              ...oldData,
              placeholderData,
            ]);
          }
        } else {
          // when the data is not available an empty data of the similar format is pushed
          setPriceProgressFetchedData((oldData) => [
            ...oldData,
            placeholderData,
          ]);
        }
      });
    }
    // ends loading of data loader
    setLoading(false);
  };

  //useEffect calls
  useEffect(() => {
    if (
      priceProgressFetchedData.length === new Date(year, month, 0).getDate()
    ) {
      var reformattedData = []; //Dummy array to store reformatted Data

      // sets the structure for the data for all room types
      for (let i = 0; i < roomsData.length; i++) {
        reformattedData.push({
          color: colors[i],
          dates: [],
          name: roomsData[i].roomName,
          prices: [],
          id: roomsData[i].roomId,
        });
      }

      // reformats the data by filtering out the day data by room types
      for (let i = 0; i < priceProgressFetchedData.length; i++) {
        const date = reformatDate(new Date(year, month - 1, i + 1));
        const dayPrices = priceProgressFetchedData[i];

        for (let j = 0; j < dayPrices.length; j++) {
          const roomTypePrice = dayPrices[j];

          for (let k = 0; k < reformattedData.length; k++) {
            const element = reformattedData[k];
            if (
              roomTypePrice.parentRoomId === parseInt(element.id) &&
              roomTypePrice.iRevPrice !== ""
            ) {
              element.prices.push(roomTypePrice.iRevPrice);
              element.dates.push(date);
            }
          }
        }
      }
      setDataFromParents(reformattedData);
    }
    if (priceProgressFetchedData.length > new Date(year, month, 0).getDate()) {
      // gets the changed data in the array
      const lastElement =
        priceProgressFetchedData[priceProgressFetchedData.length - 1];
      // updates the array to replace the old data with changed data
      setPriceProgressFetchedData((priceProgressFetchedData) =>
        priceProgressFetchedData.map((oldData, index) => {
          /*  
              checks date to replace data 
              and an additional check to remove 
              any excess elements from the array 
          */
          if (
            oldData?.date === lastElement.date &&
            index < new Date(year, month, 0).getDate()
          ) {
            return lastElement;
          } else {
            return oldData;
          }
        })
      );
    }
  }, [priceProgressFetchedData]);

  useEffect(() => {
    if (propertyId != undefined) {
      setLoading(true);
      getPriceProgressDataV2(propertyId, month, year).then(() =>
        setLoading(false)
      );
    }
  }, [propertyId, roomsData]);

  // labels , min and max of graph

  const setLabels = () => {
    const dataSets = [];

    dataFromParents?.map((item, index) => {
      if (index < 3) {
        dataSets.push({
          label: item.name,
          data: item.prices,
          fill: false,
          backgroundColor: item.color,
          borderColor: item.color,
          lineTension: 0.5,
          hidden: false,
        });
      } else {
        dataSets.push({
          label: item.name,
          data: item.prices,
          fill: false,
          backgroundColor: item.color,
          borderColor: item.color,
          lineTension: 0.5,
          hidden: true,
        });
      }
    });
    return dataSets;
  };
  const setMin = () => {
    let min = 500;

    dataFromParents?.map((item) => {
      let temp = Math.min(...item.prices);
      if (min > temp) {
        min = temp;
      }
    });
    return Math.round(min * 0.8);
  };
  const setMax = () => {
    let max = 0;

    dataFromParents?.map((item) => {
      let temp = Math.max(...item.prices);
      if (max < temp) {
        max = temp;
      }
    });
    return Math.round(max + max * 0.1);
  };
  return (
    <div style={{ width: "100%", marginBottom: "20px" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <div
            class="loading setting_title_typo"
            data-loading-text="Processing Data ..."
          ></div>
        </div>
      ) : (
        <div>
          <FadeIn transitionDuration="4000">
            <Grid container spacing={1}>
              <Grid item xs={12} md={2}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectBorder}
                >
                  <InputLabel className="select_label" id="month">
                    Month
                  </InputLabel>
                  <Select
                    MenuProps={{ disableScrollLock: false }}
                    labelId="month"
                    id="month"
                    defaultValue={month}
                    label="month"
                    onChange={handleChangeMonth}
                    style={{
                      width: "120px",
                    }}
                  >
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Data updated succesfully"
              />
              <Grid item xs={12} md={2}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectBorder}
                >
                  <InputLabel className="select_label" id="year">
                    Year
                  </InputLabel>
                  <Select
                    MenuProps={{ disableScrollLock: false }}
                    labelId="year"
                    id="year"
                    defaultValue={year}
                    label="year"
                    onChange={handleChangeYear}
                    style={{
                      width: "120px",
                    }}
                    sx={{
                      "&:hover": {
                        "&& fieldset": {
                          border: `1px solid ${localStorage.getItem(
                            "primaryColor"
                          )}`,
                          borderRadius: "8px",
                        },
                      },
                      "& .MuiSelectSelect:focus": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <MenuItem value={currentYear}>{currentYear}</MenuItem>
                    <MenuItem value={nextYear}>{nextYear}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    marginTop: "0px",
                    width: "120px",
                    height: "40px",
                    background: localStorage.getItem("primaryColor"),
                  }}
                  onClick={() => handleUpdateClick()}
                >
                  Update
                </Button>
              </Grid>{" "}
            </Grid>
            {noData ? (
              <div className="noData-container">
                <NoData
                  className="noData-svg"
                  fill={localStorage.getItem("primaryColor")}
                />
                <p>
                  Sorry!
                  <br />
                  No Data available for selected parameters
                </p>
              </div>
            ) : (
              <Line
                data={{
                  labels: dataFromParents[0]?.dates,
                  datasets: setLabels(),
                }}
                options={{
                  responsive: true,
                  spanGaps: 1000 * 60 * 60 * 24 * 2, // 2 days
                  bezierCurve: true,
                  interaction: {
                    mode: "nearest",
                  },

                  plugins: {
                    autocolors: false,
                    legend: {
                      position: "right",
                      maintainAspectRatio: true,
                      display: true,
                      labels: {
                        color: "black",
                      },
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      display: true,
                      grid: { display: false },
                      time: {
                        unit: "day",
                        stepSize: 4,
                        tooltipFormat: "MMM DD , YYYY",
                      },
                      title: {
                        display: false,
                      },
                    },
                    y: {
                      min: setMin(),
                      max: setMax,
                      grid: {
                        borderDash: [8, 4],

                        drawBorder: false,
                      },
                    },
                  },
                }}
              />
            )}
          </FadeIn>
        </div>
      )}
    </div>
  );
}
