import { useEffect, useState } from "react";
import PiGraphBi from "../../PiGraphBi";
import { Typography } from "@material-ui/core";

const GuestNationality = ({ paceReportNationality }) => {
  const [data, setData] = useState([]);

  //series
  const seriesNationality = data.slice(0, 5).map((da) => da.percentage);
  const seriesNationalityOthers = data.slice(6).map((da) => da.percentage);
  const seriesNationalityOthersSum = seriesNationalityOthers.reduce(
    (a, b) => a + b,
    0
  );
  const seriesNationalityValues = [
    ...seriesNationality,
    seriesNationalityOthersSum,
  ];

  //labels
  const nationalityLabels = data.slice(0, 5).map((da) => da.nationalityGuest);
  const nationalityLabelsOthers = [...nationalityLabels, "others"];

  const series = seriesNationalityValues;
  const labels = nationalityLabelsOthers;
  useEffect(() => {
    setData(paceReportNationality);
  }, [paceReportNationality]);

  return (
    <div>
      <Typography
        className="mt-3 mb-3"
        variant="h5"
        align="center"
        style={{ fontWeight: 600 }}
      >
        Guest Nationality
      </Typography>
      <PiGraphBi seriesData={series} labels={labels} />
    </div>
  );
};

export default GuestNationality;
