import { CopyBlock, solarizedLight } from "react-code-blocks";
import React, { useEffect, useState } from "react";
import { jsFetch, nodeJs, pythonHttp, pythonRequest } from "../ApiCode";

const GetMarketImportances = ({language}) => {
  const [languageDemo, setLanguageDemo] = useState("");
  const [highlightLanguage, setHighlightLanguage] = useState("javascript");

  const argumentsToPass =
  "{\r\n    \"hotel_id\": \"1612456\",\r\n    \"start_date\": \"2022-02-01\",\r\n    \"end_date\": \"2022-02-20\"\r\n  }"
  const method = "GET";
  const baseUrl = "base_url";
  const endpoint = "get_market_importances";
  useEffect(() => {
    if (language === "jsFetch") {
      setHighlightLanguage("javascript");
      setLanguageDemo(jsFetch(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "nodeJs") {
      setHighlightLanguage("javascript");
      setLanguageDemo(nodeJs(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonHttp") {
      setHighlightLanguage("python");
      setLanguageDemo(pythonHttp(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonRequest") {
      setHighlightLanguage("python");

      setLanguageDemo(
        pythonRequest(argumentsToPass, method, endpoint, baseUrl)
      );
    }
  }, [language]);
  return (
    <div >
      {" "}
      <h3>Get Market Importance</h3 >
      <p>Obtain the all the market importances for a hotel, using the booking.com property id. Date range will also have to provided (current or next year inclusive). </p>
      <div className="">

        <div className="demo">
          <CopyBlock
            language={highlightLanguage}            text={languageDemo}
            showLineNumbers={true}
            theme={solarizedLight}
            wrapLines={true}
            codeBlock
          />
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default GetMarketImportances;
