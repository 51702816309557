import { useEffect, useState } from 'react'

import { Line } from 'react-chartjs-2'

const WeeklyPaceReportGraph = ({ weeklyPaceReportInsightGraph }) => {
    const [currentYearData, setCurrentYearData] = useState([])
    const [sortedCurrentYearData, setSortedCurrentYearData] = useState([])
    const [previousYearData, setPreviousYearData] = useState([])
    const [sortedPreviousYearData, setSortedPreviousYearData] = useState([])


    const currentYearLabel = currentYearData?.map((da) => da.week_start)
    const currentYearValues = currentYearData?.map((da) => da.revenue)
    const previousYearValues = previousYearData?.map((da) => da.revenue)
    
    useEffect(() => {
        setCurrentYearData(weeklyPaceReportInsightGraph.current_year)
        setPreviousYearData(weeklyPaceReportInsightGraph.previous_year)
    }, [weeklyPaceReportInsightGraph])
    useEffect(()=>{
        setSortedCurrentYearData(currentYearData.sort((a,b)=> new Date(a.week_start) - new Date(b.week_start)));
        setSortedPreviousYearData(previousYearData.sort((a,b)=> new Date(a.week_start) - new Date(b.week_start)));
    },[currentYearData,previousYearData])
    return (
        <div>
            <Line
                data={{
                    labels: currentYearLabel,
                    datasets: [
                        {
                            label: "Current Year",
                            data: currentYearValues,
                            fill: false,
                            backgroundColor: "rgba(72 , 217, 165 ,1)",
                            borderColor: "rgba(72 , 217, 165 ,1)",
                            lineTension: 0.5,

                        },
                        {
                            label: "Previous Year",
                            data: previousYearValues,
                            fill: false,
                            lineTension: 0.5,
                            backgroundColor: "rgb(133 189 215)",
                            borderColor: "rgb(133 189 215)",
                            lineTension: 0.5,
                        },
                    ],
                }}
                options={{
                    responsive: true,
                    animations: {
                        radius: {
                            duration: 400,
                            easing: "linear",
                            loop: (context) => context.active,
                        },
                    },
                    hoverRadius: 12,
                    hoverBackgroundColor: "#84bdd7",
                    interaction: {
                        mode: "nearest",
                        intersect: false,
                        axis: "x",
                    },
                    plugins: {
                        tooltip: {
                            enabled: true,
                        },
                    },
                }}
            />
        </div>
    )
}

export default WeeklyPaceReportGraph