import '../bi.css'

import {
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import { useEffect, useState } from 'react'

import Chart from 'react-apexcharts'
import { DateRange } from 'react-date-range'
import HistoricalLineChart from './HistoricalLineChart'
import { revenuePace } from '../utils/revenueStatisticsAPiCalls'
import { useTheme } from '@emotion/react'
import axios from 'axios'
import FutureLineChart from './FutureLineChart'
import { toast } from 'react-toastify'
import { abbreviateNumber, dataFormatting } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      margin: 'auto',
    },
  },
}))
const MonthToDateOverview = ({
  propertyId,
  historicalMonthDataApiCall,
  futureMonthDataApiCall,
  historicalMonthGraphDataApiCall,
  futureMonthGraphDataApiCall,
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  //------------------ Month to date historical ------------------
  const [showDateRangeHistoryMonthly, setShowDateRangeHistoryMonthly] =
    useState(false)
  const [historicalMonthStartDate, setHistoricalMonthlyStartDate] = useState(
    dataFormatting(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  )
  const [historicalMonthEndDate, setHistoricalMonthlyEndDate] = useState(
    dataFormatting(new Date())
  )
  const [historicalMonthData, setHistoricalMonthData] = useState({})
  const [historicalMonthGraph, setHistoricalMonthGraph] = useState([])
  const [historicalMonthUpdate, setHistoricalMonthUpdate] = useState(false)

  const [dateRangeStateHistorical, setDateRangeStateHistorical] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const handleSelectHistoryMonthly = (ranges) => {
    setDateRangeStateHistorical([ranges.selection])
    setHistoricalMonthlyStartDate(dataFormatting(ranges.selection.startDate))
    setHistoricalMonthlyEndDate(dataFormatting(ranges.selection.endDate))
  }

  const updatehandleSelectHistoryMonthly = () => {
    setShowDateRangeHistoryMonthly(false)
    setHistoricalMonthUpdate(true)
    const historicalMonthPayload = {
      start_date: historicalMonthStartDate,
      end_date: historicalMonthEndDate,
      periodicity: 'monthly',
      time_stage: 'historical',
      propertyId: propertyId,
    }
    const historicalMonthGraphPayload = {
      start_date: historicalMonthStartDate,
      end_date: historicalMonthEndDate,
      periodicity: 'monthly_graph',
      time_stage: 'historical',
      propertyId: propertyId,
    }

    axios
      .all([
        revenuePace(historicalMonthPayload),
        revenuePace(historicalMonthGraphPayload),
      ])
      .then(
        axios.spread((...responses) => {
          setHistoricalMonthData(responses[0].data.payload)
          setHistoricalMonthGraph(responses[1].data.payload)
          setHistoricalMonthUpdate(false)
          toast.success('Data updated successfully')
        })
      )
      .catch((errors) => {
        setHistoricalMonthUpdate(false)
        toast.error('Error updating data')
      })
  }

  //------------------ Month to date future ------------------

  const [futureMonthStartDate, setFutureMonthlyStartDate] = useState(
    dataFormatting(new Date())
  )
  const [futureMonthEndDate, setFutureMonthlyEndDate] = useState(
    dataFormatting(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    )
  )
  const [futureMonthData, setFutureMonthData] = useState({})
  const [futureMonthDataGraph, setFutureMonthDataGraph] = useState([])
  const [showDateRangFutureMonthly, setShowDateRangeFutureMonthly] =
    useState(false)
  const [futureMonthUpdate, setFutureMonthUpdate] = useState(false)
  const [dateRangeStateFuture, setDateRangeStateFuture] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection',
    },
  ])
  const handleSelectFutureMonthly = (ranges) => {
    setDateRangeStateFuture([ranges.selection])
    setFutureMonthlyStartDate(dataFormatting(ranges.selection.startDate))
    setFutureMonthlyEndDate(dataFormatting(ranges.selection.endDate))
  }

  const updatehandleSelectFutureMonthly = () => {
    setShowDateRangeFutureMonthly(false)
    setFutureMonthUpdate(true)
    const futureMonthPayload = {
      start_date: futureMonthStartDate,
      end_date: futureMonthEndDate,
      periodicity: 'monthly',
      time_stage: 'future',
      propertyId: propertyId,
    }
    //future month graph
    const futureMonthGraphPayload = {
      start_date: futureMonthStartDate,
      end_date: futureMonthEndDate,
      periodicity: 'monthly_graph',
      time_stage: 'future',
      propertyId: propertyId,
    }
    axios
      .all([
        revenuePace(futureMonthPayload),
        revenuePace(futureMonthGraphPayload),
      ])
      .then(
        axios.spread((...responses) => {
          setFutureMonthData(responses[0].data.payload)
          setFutureMonthDataGraph(responses[1].data.payload)
          setFutureMonthUpdate(false)
          toast.success('Data updated successfully')
        })
      )
      .catch((errors) => {
        setFutureMonthUpdate(false)
        toast.error('Error updating data')
      })
  }

  // options for historical
  const series = [34]
  const options = {
    chart: {
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          style: {
            fontSize: '24px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            marginBottom: '30px',
          },
          name: {
            show: true,
          },
          value: {
            fontSize: '22px',
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: [`${abbreviateNumber(historicalMonthData?.total_revenue)} €`],
  }
  // options for future

  const series2 = [344]
  const options2 = {
    chart: {
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          style: {
            fontSize: '24px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            marginBottom: '30px',
          },
          name: {
            show: true,
          },
          value: {
            fontSize: '22px',
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: [`${abbreviateNumber(futureMonthData?.total_revenue)} €`],
  }

  // -------Use Effect calls-----------

  useEffect(() => {
    setHistoricalMonthData(historicalMonthDataApiCall)
    setFutureMonthData(futureMonthDataApiCall)
    setHistoricalMonthGraph(historicalMonthGraphDataApiCall)
    setFutureMonthDataGraph(futureMonthGraphDataApiCall)
  }, [
    historicalMonthDataApiCall,
    futureMonthDataApiCall,
    historicalMonthGraphDataApiCall,
    futureMonthGraphDataApiCall,
  ])
  return (
    <div>
      <Typography
        className='mb-4'
        variant='h4'
        align='center'
        style={{ fontWeight: 600 }}
      >
        MONTH-TO-DATE (MTD) OVERVIEW
      </Typography>

      <Grid container spacing={2}>
        {/* Historical */}
        <Grid item xs={12} lg={5}>
          {historicalMonthUpdate ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
              }}
            >
              <div
                className='loading setting_title_typo'
                data-loading-text='Processing Data ...'
              ></div>
            </div>
          ) : (
            <>
              <Typography
                variant='h5'
                align='center'
                style={{ fontWeight: 600 }}
              >
                Historical
              </Typography>
              <Typography variant='subtitle1' align='center'>
                Select a different date range from the data picker or click on
                the graph
              </Typography>
              <div align='center' className='mt-4'>
                <TextField
                  id='standard-basic'
                  onClick={() => setShowDateRangeHistoryMonthly(true)}
                  value={`${historicalMonthStartDate} - ${historicalMonthEndDate}`}
                  variant='outlined'
                />
              </div>
              {showDateRangeHistoryMonthly && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '350px',
                    margin: '0 auto 0 auto',
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelectHistoryMonthly}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRangeStateHistorical}
                  />{' '}
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant='contained'
                      onClick={() => setShowDateRangeHistoryMonthly(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      onClick={() => updatehandleSelectHistoryMonthly()}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}{' '}
              {/* chart guage*/}
              <Chart options={options} series={series} type='radialBar' />
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <div>
                  <Typography variant='subtitle1'> Revenue</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(historicalMonthData.total_revenue)} €
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'>Room Nights</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(historicalMonthData.room_nights)}
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'> Adr</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(historicalMonthData.gross_adr)} €
                  </Typography>
                </div>
              </div>
              {/* line chart with average */}
              <HistoricalLineChart
                historicalMonthGraph={historicalMonthGraph}
              />
            </>
          )}{' '}
        </Grid>
        <Grid xs={12} lg={2} item>
          <Divider
            className={classes.divider}
            orientation='vertical'
            variant='middle'
            textAlign='center'
          />
        </Grid>
        {/* Future */}
        <Grid item xs={12} lg={5}>
          {futureMonthUpdate ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
              }}
            >
              <div
                className='loading setting_title_typo'
                data-loading-text='Processing Data ...'
              ></div>
            </div>
          ) : (
            <>
              <Typography
                variant='h5'
                align='center'
                style={{ fontWeight: 600 }}
              >
                Future{' '}
              </Typography>
              <Typography variant='subtitle1' align='center'>
                Select a different date range from the data picker or click on
                the graph
              </Typography>

              <div align='center' className='mt-4'>
                <TextField
                  id='standard-basic'
                  onClick={() => setShowDateRangeFutureMonthly(true)}
                  value={`${futureMonthStartDate} - ${futureMonthEndDate}`}
                  variant='outlined'
                />
              </div>
              {showDateRangFutureMonthly && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '350px',
                    margin: '0 auto 0 auto',
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelectFutureMonthly}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRangeStateFuture}
                  />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant='contained'
                      onClick={() => setShowDateRangeFutureMonthly(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      onClick={() => updatehandleSelectFutureMonthly()}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
              {/* chart guage*/}
              <Chart options={options2} series={series2} type='radialBar' />
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <div>
                  <Typography variant='subtitle1'>
                    {' '}
                    Revenue On The Book
                  </Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(futureMonthData?.total_revenue)} €
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'>
                    Room Nights On the book
                  </Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(futureMonthData.room_nights)}
                  </Typography>
                </div>
                <div>
                  <Typography variant='subtitle1'> Adr On the Book</Typography>
                  <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {abbreviateNumber(futureMonthData?.total_revenue)} €
                  </Typography>
                </div>
              </div>

              {/* line chart with average */}
              <FutureLineChart futureMonthDataGraph={futureMonthDataGraph} />
            </>
          )}
        </Grid>{' '}
      </Grid>
    </div>
  )
}

export default MonthToDateOverview
