import "react-circular-progressbar/dist/styles.css";
import "../dashboard.scss";
import "../Index.scss";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import AnimatedProgressProvider from "./AnimatedProgressProvider";
import Card from "react-bootstrap/Card";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import { easeQuadInOut } from "d3-ease";
import { useStylesBootstrap } from "../../utils/tooltipDesign";
import { formatInEuropeanNoSymbol } from "../../utils";

export default function SellStatsCards(props) {
  const toolTipClasses = useStylesBootstrap();
  let { sideBar } = props;
  let trail;
  let path;
  let counter;
  if (props.value < 0) {
    trail = Math.abs(props.value);
    path = "red";
    counter = true;
  } else {
    path = "#1FE279";
    trail = props.value;
    counter = false;
  }

  const SellStatProgressTooltip =
    props.value < 0
      ? `We are ${props.value}% behind from our target`
      : props.value > 0
      ? `We are ${props.value}% ahead of our target`
      : `We have acheived our target`;

  return (
    <div>
      <Card
        style={{
          minWidth: "280px",
          maxWidth: "440px",
          height: "260px",
          background: "#F7F8FC",
          borderRadius: "8px",
          padding: "8px 10px 10px 23px",
          borderWidth: "0",
        }}
      >
        <div className="row" style={{display:"flex"}}>
          <img
            className="img-responsive"
            src={
              props.title === "Total Rooms Sold (Yearly)"
                ? "/images/Bed.svg"
                : props.title === "Total Occupancy"
                ? "/images/House.svg"
                : props.title === "Occupancy"
                ? "/images/House.svg"
                : "/images/Emoji.svg"
            }
            alt="img"
            style={{ padding: "0px 16px" }}
          />
          <span
            id="title"
            className="sell_stat_card_title"
            style={{ maxWidth: "calc(100% - 150px)" }}
          >
            {props.title}
          </span>
          <span className="i__icon">
            <Tooltip
              arrow
              title={SellStatProgressTooltip}
              classes={toolTipClasses}
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </span>
        </div>
        <div className="row" style={{display:"flex"}}>
          {props.title === "Total Occupancy" || props.title === "Occupancy" ? (
            <span id="heading">{props.amount}%</span>
          ) : props.title === "Total Rooms Sold(Yearly)" ? (
            <span id="heading">{props.amount}</span>
          ) : (
            <span id="heading">€{formatInEuropeanNoSymbol(props.amount)}</span>
          )}

          {(props.text !== "Total" && props.title === "Total Occupancy") ||
          props.title === "Occupancy" ? (
            <span id="heading2">
              {/* eslint-disable-next-line */}

              {`/${props.actualamount}%`}
            </span>
          ) : props.actualamount != "" ? (
            <span id="heading2">
              {`/€${formatInEuropeanNoSymbol(props.actualamount)} `}
            </span>
          ) : (
            ""
          )}
        </div>
        {props.textone && props.texttwo.length > 6 ? (
          <div
            className="row"
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              width: "100%",
              padding: "8px 10px 10px 23px",
            }}
          >
            <img
              className="img-responsive"
              //src="/images/event_note.svg"
              src={props.subimg}
              alt="arrow_up"
              style={{ margin: "8px 0px 0px 15px" }}
            />
            <span
              id="heading2"
              style={{ marginTop: "20px", marginLeft: "5px" }}
            >
              {props.text}
            </span>
            <p
              id="heading2"
              className="sell-stat-card-date"
            >
              {props.textone} {props.texttwo}
            </p>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            // sideBar
            //   ? "sell_cards_progress_bar_on_opensidebar"
            //   :
               "sell_cards_progress_bar"
          }
        >
          {props.value === "" ? (
            ""
          ) : (
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={trail}
              duration={1.4}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                return (
                  <CircularProgressbar
                    value={value}
                    text={
                      // eslint-disable-next-line
                      props.value == "NaN" ? (
                        <>{`${props.value}`}</>
                      ) : (
                        <>{`${props.value}%`}</>
                      )
                    }
                    counterClockwise={counter}
                    styles={
                      buildStyles({
                      pathTransition: "none",
                      pathColor: path,
                      trailColor: "#000000",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}
        </div>
      </Card>
    </div>
  );
}
