import "./dashboard.scss";
import "./Index.scss";

import * as React from "react";

import { Avatar, Divider, Grid } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import BiStats from "./biDashbord/bi/BiStats";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CssBaseline from "@material-ui/core/CssBaseline";
import CustomerSuccessManager from "./customerSuccessManager/CustomerSuccessManager";
import DailyView from "./dailyview/DailyView";
import DevTools from "./devTools/DevTools";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Documentation from "./documentation/Documentation";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import MonthlyPrices from "./monthlyPrices/MonthlyPrices";
import { OnMonthlyLoaderStart } from "../redux/actions/monthlyLoader";
import { OnSettingLoaderStart } from "../redux/actions/loader";
import PaceReport from "./biDashbord/paceReport/PaceReport";
import SalesStatistic from "./biDashbord/salesStatistics/SalesStatistic";
import Settings from "./setting/Settings";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { getAuth } from "firebase/auth";
import logo from "../../images/logo_new.png";
// import logo from "../../images/logo.svg";
import { useAuth } from "../Context/AuthUserContext";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { OnGetAllHotels } from "../redux/actions/getAllHotels";
import { getDisplayName, gettingPropertyStatus } from "../utils";
import RevenueTargets from "./revenueTargets/RevenueTargets";
import { useHistory } from "react-router-dom";
import { gettingHotelInfo, updateHotelInfo } from "../utils/getHotelInfo";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { auth2, db, db2 } from "../../Auth/firebase";
import UserProgress from "../UserProgress/UserProgress";
import { ReactComponent as DailySelected } from "../../images/DailySelected.svg";
import { ReactComponent as MonthlySelected } from "../../images/MonthlySelected.svg";
import { ReactComponent as RevenueStatSelected } from "../../images/revenuestat_selected.svg";
import { ReactComponent as RevenueSelected } from "../../images/RevenueStatsSelected.svg";
import { ReactComponent as SellStatSelected } from "../../images/sellstat_selected.svg";
import { ReactComponent as SettingsSelected } from "../../images/settingsSelected.svg";
import { ReactComponent as ToolsSelected } from "../../images/ToolSelected.svg";
const drawerWidth = 250;
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: "rgb(255 255 255)",
      maxWidth: "1800px",
    },
    active: {
      textDecoration: "none",
      fontFamily: "Plus Jakarta Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "18px",
      color: localStorage.getItem("primaryColor"),
    },
    activeItem: {
      borderRadius: "8px",
      backgroundColor: "#efefff",
      borderLeft: `7px solid ${localStorage.getItem("primaryColor")}`,
      "&:focus": {
        borderRadius: "8px",
        backgroundColor: "#efefff",
        borderLeft: `7px solid ${localStorage.getItem("primaryColor")}`,
      },
    },
    avatarClr: {
      backgroundColor: "rgb(133 189 215) ",
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarClr: {
      backgroundColor: "rgb(255 255 255)",
      paddingTop: "10px",
    },
    selectBorder: {
      "& .MuiInput-input": {
        borderBottom: "none",
      },
    },
    formControl: {
      marginRight: theme.spacing(1),

      "&::-webkit-scrollbar": {
        height: 5,
      },
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: "#000",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      width: "100%",
    },
    contentShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    pos: {
      marginBottom: 12,
      fontSize: 14,
    },
    dialogBox: {
      minWidth: "600px",
      maxHeight: "300px",
      "&::-webkit-scrollbar": {
        width: 2,
        height: 2,
      },
    },
  })
);

export default function PersistentDrawerLeft(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const auth = getAuth();
  const dispatch = useDispatch();
  const user = auth.currentUser;
  const { logOut } = useAuth();
  const [hotel, setHotel] = React.useState({});
  const [totalHotel, settotalHotels] = React.useState([]);
  const [openProperty, setOpenProperty] = React.useState(false);
  const [displayName, setDisplayName] = React.useState("");
  const [updateHotelFlag, setUpdateHotelFlag] = React.useState(false);
  const [firstTime, setFirstTime] = React.useState(false);
 const [propertyLogo,setPropertyLogo] = React.useState("")
  const [propertyStatus, setPropertyStatus] = React.useState();
  const handleClickOpen = () => {
    setOpenProperty(true);
  };
  const handleClose = () => {
    setOpenProperty(false);
  };
  const handlePropertySet = (val) => {
    setHotel(val);
    localStorage.setItem("propertyId", val.hotel_id);
    setUpdateHotelFlag(true);
    setOpenProperty(false);
    dispatch(OnSettingLoaderStart());
    dispatch(OnMonthlyLoaderStart());
    CheckFirstTime(val.hotel_id);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleScssColorChange = ()=> {
    const root = document.documentElement;
    root.style.setProperty('--background-color', localStorage.getItem("primaryColor")||"#6260EF");
  }
  const CheckFirstTime = async (propertyId) => {
    const docRef = doc(db2, "first_time_status",`1_${propertyId}`);
    const docSnap = await getDoc(docRef);
 
    if (docSnap.exists()) {
      history.push("/dashboard/Userprogress");
    } else {
      if (location.pathname !== "/dashboard/monthlyprices") {
        history.push("/dashboard/monthlyprices");
      }
    }
  };
  useEffect(() => {
    console.log(hotel);
  }, [hotel]);
  useEffect(() => {
    getDisplayName().then((response) => {
      setDisplayName(response);
    });
    handleScssColorChange();
    gettingHotelInfo().then((response) => {
      dispatch(OnGetAllHotels(response));
      settotalHotels(response.hotelsExtractedVal);
      setHotel(response.currentHotel);
      gettingPropertyStatus(response.currentHotel.hotel_id).then((res) => {
        setPropertyStatus(res);
      });
      response.logo && setPropertyLogo(response.logo)
      handleScssColorChange();
      localStorage.setItem("propertyId", response.currentHotel.hotel_id);
      localStorage.setItem("pmsId", response.pmsId);
      CheckFirstTime(response.currentHotel.hotel_id);
    });
    // eslint-disable-next-line
    if (updateHotelFlag == true) {
      updateHotelInfo(hotel);
      setUpdateHotelFlag(false);
    }
    // eslint-disable-next-line

    if (
      new Date().getTime() >
      new Date(localStorage.getItem("expirationDate")).getTime()
    ) {
      auth2.currentUser.getIdToken(/* forceRefresh */ true).then((response) => {
        localStorage.setItem("token", response);
        const expirationDate = new Date(new Date().getTime() + 3600000);
        localStorage.setItem("expirationDate", expirationDate);
      });
    }
    // eslint-disable-next-line
  }, [updateHotelFlag]);

  const { location } = props;
  const drawer = (
    <div>
      <Link to="/LandingPage" className="MonthlyLogo">
        {propertyLogo &&
        <img src={propertyLogo} alt="logo" width="150px" />}
      </Link>
      <List className="List">
        <ListItem
          button
          component={Link}
          to={"/dashboard/monthlyprices"}
          className={
            location.pathname === "/dashboard/monthlyprices"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          <ListItemIcon>
            {location.pathname === "/dashboard/monthlyprices" ? (
              <MonthlySelected fill={localStorage.getItem("primaryColor")} />
            ) : (
              <img src={"/images/mothly.svg"} alt="monthlyprices" />
            )}
          </ListItemIcon>

          <ListItemText
            style={{ color: localStorage.getItem("primaryColor") }}
            className={
              location.pathname === "/dashboard/monthlyprices"
                ? classes.active
                : "notActive"
            }
          >
            {" "}
            Monthly Prices
          </ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to={"/dashboard/dailyview"}
          style={{ borderLeft: `7px solid ${localStorage.getItem("primaryColor")}` }}
          className={
            location.pathname === "/dashboard/dailyview"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          <ListItemIcon>
            {location.pathname === "/dashboard/dailyview" ? (
              <DailySelected fill={localStorage.getItem("primaryColor")} />
            ) : (
              <img src={"/images/daily_view.svg"} alt="daily_view" />
            )}
          </ListItemIcon>
          <ListItemText
            className={
              location.pathname === "/dashboard/dailyview"
                ? classes.active
                : "notActive"
            }
          >
            {" "}
            Daily Prices
          </ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to={"/dashboard/revenuetargets"}
          className={
            location.pathname === "/dashboard/revenuetargets"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          {/* <ListItemIcon>
            <img
              src={
                location.pathname === "/dashboard/revenuetargets"
                  ? "/images/RevenueStatsSelected.svg"
                  : "/images/revenue_statistics.svg"
              }
              alt="daily_view"
            />
          </ListItemIcon> */}
          <ListItemIcon>
            {location.pathname === "/dashboard/revenuetargets" ? (
              <RevenueSelected stroke={localStorage.getItem("primaryColor")} height={24} width={24} />
            ) : (
              <img src={"/images/revenue_statistics.svg"} alt="revenue_stats" />
            )}
          </ListItemIcon>
          <ListItemText
            className={
              location.pathname === "/dashboard/revenuetargets"
                ? classes.active
                : "notActive"
            }
          >
            {" "}
            Revenue Targets
          </ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          to={"/dashboard/revenuestatistics"}
          className={
            location.pathname === "/dashboard/revenuestatistics"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          <ListItemIcon>
            {location.pathname === "/dashboard/revenuestatistics" ? (
              <RevenueStatSelected
                stroke={localStorage.getItem("primaryColor")}
                height={24}
                width={24}
              />
            ) : (
              <img
                src={"/images/revenuestat.svg"}
                height={24}
                alt="revenue_stats"
              />
            )}
          </ListItemIcon>
          <ListItemText
            className={
              location.pathname === "/dashboard/revenuestatistics"
                ? classes.active
                : "notActive"
            }
          >
            Revenue Statistics
          </ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          to={"/dashboard/salesstatistics"}
          className={
            location.pathname === "/dashboard/salesstatistics"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          <ListItemIcon>
            {location.pathname === "/dashboard/salesstatistics" ? (
              <SellStatSelected fill={localStorage.getItem("primaryColor")} height={24} width={24} />
            ) : (
              <img
                src={"/images/salesstat.svg"}
                height={24}
                alt="revenue_stats"
              />
            )}
          </ListItemIcon>
          <ListItemText
            className={
              location.pathname === "/dashboard/salesstatistics"
                ? classes.active
                : "notActive"
            }
          >
            {" "}
            Sales Statistics
          </ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to={"/dashboard/pacereport"}
          className={
            location.pathname === "/dashboard/pacereport"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          <ListItemIcon>
            <img
              height={22}
              src={
                location.pathname === "/dashboard/pacereport"
                  ? "/images/pacereport.svg"
                  : "/images/pacereport.svg"
              }
              alt="pacereport"
            />
          </ListItemIcon>
          <ListItemText
            className={
              location.pathname === "/dashboard/pacereport"
                ? classes.active
                : "notActive"
            }
          >
            {" "}
            Pace Report
          </ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          to={"/dashboard/devtools"}
          className={
            location.pathname === "/dashboard/devtools"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          <ListItemIcon>
            {location.pathname === "/dashboard/devtools" ? (
              <ToolsSelected fill={localStorage.getItem("primaryColor")} height={24} width={24} />
            ) : (
              <img
                src={"/images/developer_tools.svg"}
                height={24}
                alt="revenue_stats"
              />
            )}
          </ListItemIcon>
          <ListItemText
            className={
              location.pathname === "/dashboard/devtools"
                ? classes.active
                : "notActive"
            }
          >
            {" "}
            Dev Tools
          </ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          to={"/documentation"}
          className={
            location.pathname === "/dashboard/documentation"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          <ListItemIcon>
            <img
              src={
                location.pathname === "/dashboard/documentation"
                  ? "/images/DocumentationSelected.svg"
                  : "/images/documentation.svg"
              }
              alt="documentation"
            />
          </ListItemIcon>
          <ListItemText className="notActive"> Documentation</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to={"/dashboard/setting"}
          className={
            location.pathname === "/dashboard/setting"
              ? "activeItem"
              : "notactiveItem"
          }
        >
          {/* <ListItemIcon>
            <img
              src={
                location.pathname === "/dashboard/setting"
                  ? "/images/settingsSelected.svg"
                  : "/images/settings.svg"
              }
              alt="settings"
            />
          </ListItemIcon> */}
          <ListItemIcon>
            {location.pathname === "/dashboard/setting" ? (
              <SettingsSelected fill={localStorage.getItem("primaryColor")} height={24} width={24} />
            ) : (
              <img
                src={"/images/settings.svg"}
                height={24}
                alt="revenue_stats"
              />
            )}
          </ListItemIcon>
          <ListItemText
            className={
              location.pathname === "/dashboard/setting"
                ? classes.active
                : "notActive"
            }
          >
            {" "}
            Settings
          </ListItemText>
        </ListItem>

        <ListItem button component={Link} to={"/"} onClick={logOut}>
          <ListItemIcon>
            <img src="/images/login.svg" alt="login" />
          </ListItemIcon>
          <ListItemText className="notActive"> Logout</ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx([classes.appBar, classes.appBarClr], {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <Tooltip title="Select Property">
            <Button
              variant="outlined"
              className={classes.formControl}
              color="primary"
              onClick={handleClickOpen}
            >
              {hotel.hotel_name}
            </Button>
          </Tooltip>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Avatar className={classes.avatarClr}>
              {" "}
              {displayName.charAt(0)}
            </Avatar>
          </div>
          {location.pathname === "/dashboard/Userprogress" &&
          <button
            style={{
              backgroundColor: "#6260EF",
              fontFamily: "Plus Jakarta Sans",
              border: "solid #6260EF",
              padding:"10px 15px",
              outline:"none",
              fontSize:"14px",
              color:"#fff",
              fontWeight:"600",
              borderRadius:"11px",
              width:"150px"
            }}
            className="nav_items"
            onClick={() => logOut()}
          >
            Log Out
          </button>}
        </Toolbar>
      </AppBar>

      {/* select property dialog */}
      <Dialog
        open={openProperty}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.dialogBox}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography className="dialogue_items_heading">
                Property{" "}
              </Typography>
              <Divider className="dialogue_divider" />

              <Typography
                variant="h5"
                component="h2"
                className="dialogue_items_heading_child"
              >
                {hotel.hotel_name}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                {hotel.hotel_id}
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography className="dialogue_items_heading">
                Master account{" "}
              </Typography>
              <Divider className="dialogue_divider" />
              <Typography
                variant="h5"
                component="h2"
                className="dialogue_items_heading_child"
              >
                {user?.displayName}
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography className="dialogue_items_heading">
                Property{" "}
              </Typography>{" "}
              <Divider className="dialogue_divider" />
              {totalHotel?.map((hotel) => (
                <div
                  key={hotel.hotel_id}
                  className="propertyList"
                  onClick={() => handlePropertySet(hotel)}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    className="dialogue_items_heading_child"
                  >
                    {hotel.hotel_name}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {hotel.hotel_id}
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {location.pathname !== "/dashboard/Userprogress" && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>

          {drawer}
        </Drawer>
      )}
      {/* <Main open={open} className={open ?"dashboard_sidebar_opened":"dashboard_sidebar_closed"}> */}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        <div>
          <Switch>
            <Route path="/dashboard/monthlyprices">
              <MonthlyPrices
                sideBar={open}
                propertyId={hotel?.hotel_id}
                propertyStatus={propertyStatus}
              />
            </Route>
            <Route path="/dashboard/Userprogress">
              <UserProgress sideBar={open} propertyId={hotel?.hotel_id} />
            </Route>
            <Route path="/dashboard/dailyview">
              <DailyView
                sideBar={open}
                location={location}
                propertyId={hotel?.hotel_id}
                propertyStatus={propertyStatus}
              />
            </Route>
            <Route path="/dashboard/revenuetargets">
              <RevenueTargets
                propertyId={hotel?.hotel_id}
                propertyStatus={propertyStatus}
              />
            </Route>
            <Route path="/dashboard/devtools">
              <DevTools totalHotel={totalHotel} />
            </Route>
            <Route path="/dashboard/documentation">
              <Documentation />
            </Route>
            <Route path="/dashboard/customer-success-manager">
              <CustomerSuccessManager />
            </Route>

            <Route path="/dashboard/setting">
              <Settings
                propertyIdP={hotel?.hotel_id}
                propertyStatus={propertyStatus}
              />
            </Route>
            <Route path="/dashboard/revenuestatistics">
              <BiStats
                propertyId={hotel?.hotel_id}
                propertyStatus={propertyStatus}
              />
            </Route>
            <Route path="/dashboard/salesstatistics">
              <SalesStatistic
                propertyId={hotel?.hotel_id}
                propertyStatus={propertyStatus}
              />
            </Route>
            <Route path="/dashboard/pacereport">
              <PaceReport
                propertyId={hotel?.hotel_id}
                propertyStatus={propertyStatus}
              />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  );
}
