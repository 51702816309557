import React,{useEffect} from 'react'
import './components/LandingPage/Header.scss'
import './App.css'
import '@fontsource/plus-jakarta-sans'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { AuthProvider } from './components/Context/AuthUserContext'
import AutoSignIn from './components/auth/autoSignIn'
import CompletePolicy from './components/PrivacyPolicy/CompletePolicy'
import Dashboard from './components/Dashboard/Dashboard'
import Documentation from './components/Documentation/Documentation'
import LandingPage from './components/LandingPage/LandingPage'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoutes'
import SignInPage from './components/SignIn/SignInPage'
import SignUp from './components/SignupComponents/SignUp'
import NoProperty from './components/NotFound/NoProperty'
import WrongRoute from './components/NotFound/404'
import UserProgress from './components/UserProgress/UserProgress'
import PmsDashboard from './components/PmsDashboard/PmsDashboard'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DocumentationPage from './components/Documentation/DocumentationPage'
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions'
// import { signInAnonymously } from "firebase/auth";
// import { auth } from './Firebase'

function App() {

	// useEffect(()=>{
	// 	signIn()
	// },[])

	// const signIn = () => {
	// 	signInAnonymously(auth).catch((err) => {
	// 	  console.log(err);
	// 	});
	//   };
	return (
		<AuthProvider>
			<ToastContainer />
			<div>
				<Router>
					<Switch>
						<Route exact path="/" component={LandingPage} />
						<Route path="/LandingPage" component={LandingPage} />
						<Route path="/PrivacyPolicy" component={PrivacyPolicy} />
						<Route path="/CompletePolicy" component={CompletePolicy} />
						<Route path="/SignInPage" component={SignInPage} />
						<Route path="/SignUp" component={SignUp} />
						<ProtectedRoute path="/dashboard" component={Dashboard} />
						{/* <ProtectedRoute path="/UserProgress" component={UserProgress} /> */}
						<Route path="/pmsdashboard" component={PmsDashboard} />
						<Route path="/autosignin" component={AutoSignIn} />
						<Route path="/noproperty" component={NoProperty} />
						<Route path="/documentation" component={Documentation} />
						<Route path="/documentation-new" component={DocumentationPage} />
						<Route path="/termsAndConditions" component={TermsAndConditions} />
						<Route path="*" component={WrongRoute} />
					</Switch>
				</Router>
			</div>
		</AuthProvider>
	)
}

export default App
