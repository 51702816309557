import { createStyles, makeStyles } from "@material-ui/core";
const drawerWidth = 250

export const PmsDashboardStyle = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: 'rgb(255 255 255)',
      maxWidth: '1800px',
    },
    avatarClr: {
      backgroundColor: 'rgb(133 189 215) ',
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarClr: {
      backgroundColor: 'rgb(255 255 255)',
      paddingTop: '10px',
    },
    selectBorder: {
      '& .MuiInput-input': {
        borderBottom: 'none',
      },
    },
    formControl: {
      marginRight: theme.spacing(1),

      '&::-webkit-scrollbar': {
        height: 5,
      },
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: '#000',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      width: '100%',
    },
    contentShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    pos: {
      marginBottom: 12,
      fontSize: 14,
    },
    dialogBox: {
      minWidth: '500px',
      maxHeight: '300px',
      '&::-webkit-scrollbar': {
        width: 2,
        height: 2,
      },
    },
  })
)