import { auth, auth2, db, db2 } from "../../../../Auth/firebase";

import { doc, onSnapshot, updateDoc } from "firebase/firestore";
const gettingHotelID = async (propertyId) => {
  const hotel_id = propertyId;

  const accountType = localStorage.getItem("accountType");

  const getUserData = await db2
    .collection(`users`)
    .doc(auth2?.currentUser?.uid)
    .get();
  const getGroupId =
    accountType === "pms" ? getUserData?.data()?.currentSelectedGroup.id : "";

  const filterAccordingToGroupType =
    accountType === "group"
      ? getUserData
      : accountType === "pms"
      ? await db2.collection(`users`).doc(getGroupId).get()
      : "";
  //getting hotels

  const userHotels = await filterAccordingToGroupType.data()?.hotels;

  const filterHotels = userHotels?.filter((hotel) => {
    // eslint-disable-next-line
    return hotel?.hotel_id == hotel_id;
  });

  if (filterHotels) {
    const pmsName = filterHotels[0]?.pms;
    const pmsData = await db2.collection(pmsName).doc(hotel_id).get();
    const getPropertyId = pmsData?.data()?.booking_property_id;
    return getPropertyId;
  }
};

const updateHotelID = async (propertyId, updatedHotelId) => {
  const hotel_id = propertyId;
  const accountType = localStorage.getItem("accountType");
  const getUserData = await db
    .collection(`users`)
    .doc(auth?.currentUser?.uid)
    .get();

  const getGroupId =
    accountType === "pms" ? getUserData?.data()?.currentSelectedGroup.id : "";

  const filterAccordingToGroupType =
    accountType === "group"
      ? getUserData
      : accountType === "pms"
      ? await db.collection(`users`).doc(getGroupId).get()
      : "";
  const userHotels = await filterAccordingToGroupType.data()?.hotels;
  const filterHotels = userHotels?.filter((hotel) => {
    return hotel?.hotel_id == hotel_id;
  });
  if (filterHotels) {
    const pmsName = filterHotels[0]?.pms;
    const pmsRef = db.collection(pmsName).doc(hotel_id);

    await pmsRef.update({
      // eslint-disable-next-line
      ["booking_property_id"]: updatedHotelId,
    });
    return "Hotel ID updated successfully";
  }
};

const getCurrentSeasons = async (propertyId) => {
  const hotel_id = propertyId;
  const accountType = localStorage.getItem("accountType");
  const getUserData = await db
    .collection(`users`)
    .doc(auth?.currentUser?.uid)
    .get();

  const getGroupId =
    accountType === "pms" ? getUserData?.data()?.currentSelectedGroup.id : "";

  const filterAccordingToGroupType =
    accountType === "group"
      ? getUserData
      : accountType === "pms"
      ? await db.collection(`users`).doc(getGroupId).get()
      : "";

  //getting hotels

  const userHotels = await filterAccordingToGroupType.data()?.hotels;

  const filterHotels = userHotels?.filter((hotel) => {
    // eslint-disable-next-line
    return hotel?.hotel_id == hotel_id;
  });

  if (filterHotels) {
    const pmsName = filterHotels[0]?.pms;
    const pmsData = await db.collection(pmsName).doc(hotel_id).get();
    const getSasonData = pmsData?.data()?.season;
    return getSasonData;
  }
};

const gettingBasePrice = (propertyId) => {
  const docRef = doc(db2, localStorage.getItem("pmsName"), propertyId);

  const unSubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      const basePrice = doc.data().base_prices_irev[1];
      return basePrice;
    }
  });
};

export { gettingHotelID, updateHotelID, getCurrentSeasons, gettingBasePrice };
