import "./index.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import * as ReactDOMClient from "react-dom/client";

import App from "./App";
import { Provider } from "react-redux";
import { createStore } from "./components/redux/store/index";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-239110582-1');
const container = document.getElementById("root");
// Create a root.
const root = ReactDOMClient.createRoot(container);

// import { stopReportingRuntimeErrors } from "react-error-overlay";
// stopReportingRuntimeErrors(); // disables error overlays

root.render(
  // <React.StrictMode>

    <Provider store={createStore()}>
      <App />
    </Provider>
    // </React.StrictMode>

);
