import { Divider, Grid, Typography } from '@material-ui/core'

import PiGraphBi from '../../PiGraphBi'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'
import { abbreviateNumber } from '../../../../utils'
import { primaryColor } from '../../../../../colors'

const useStyles = makeStyles((theme) => ({
  tablescroll: {
    minWidth: 350,
    maxHeight: 900,
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
  table: {
    minWidth: 350,
  },
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      margin: 'auto',
    },
  },
  statDetails: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  tableRow: {
    '&:nth-child(odd)': { backgroundColor: 'white !important' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  cellTypo: {
    border: 0,

    color: '#000000',
  },
}))
const GuestInsight = ({ guestInsightOrigin, guestInsightNationality }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [guestInsightOriginData, setGuestInsightOriginData] = useState([])
  const [guestInsightNationalityData, setGuestInsightNationalityData] =
    useState({})
  const [nationalityDataTable, setNationalityDataTable] = useState([])

  useEffect(() => {
    setGuestInsightOriginData(guestInsightOrigin.booking_table)
    setGuestInsightNationalityData(guestInsightNationality)
    setNationalityDataTable(guestInsightNationality.nationality_table)
  }, [guestInsightOrigin, guestInsightNationality])

  //  --------------------Graphs data extraction Nationality -----------------------------

  //series
  const seriesNationality = nationalityDataTable
    .slice(0, 5)
    .map((da) => da['% Room Nights OTB'])
  const seriesNationalityOthers = nationalityDataTable
    .slice(6)
    .map((da) => da['% Room Nights OTB'])
  const seriesNationalityOthersSum = seriesNationalityOthers.reduce(
    (a, b) => a + b,
    0
  )
  const seriesNationalityValues = [
    ...seriesNationality,
    seriesNationalityOthersSum,
  ]

  //labels
  const nationalityLabels = nationalityDataTable
    .slice(0, 5)
    .map((da) => da['Guest Nationality'])
  const nationalityLabelsOthers = [...nationalityLabels, 'others']

  //  --------------------Graphs data extraction Origin -----------------------------
  //series
  const seriesOrigin = guestInsightOriginData
    .slice(0, 5)
    .map((da) => da['% Room Nights OTB'])
  const seriesOriginOthers = guestInsightOriginData
    .slice(6)
    .map((da) => da['% Room Nights OTB'])
  const seriesOriginOthersSum = seriesOriginOthers.reduce((a, b) => a + b, 0)
  const seriesOriginValues = [...seriesOrigin, seriesOriginOthersSum]

  //labels
  const originLabels = guestInsightOriginData
    .slice(0, 5)
    .map((da) => da['origin'])
  const originLabelsOthers = [...originLabels, 'others']
  return (
    <div className='mt-4'>
      {' '}
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
        {/* left graphs */}
        <Grid item xs={12} md={4} className='mt-4'>
          <div style={{ margin: '0 0 5vh 0' }}>
            {' '}
            <Typography
              className='mt-3 mb-3'
              variant='h4'
              align='left'
              style={{ fontWeight: 600 }}
            >
              Guest Insight
            </Typography>
          </div>

          <PiGraphBi
            seriesData={seriesNationalityValues}
            labels={nationalityLabelsOthers}
          />
          <PiGraphBi
            seriesData={seriesOriginValues}
            labels={originLabelsOthers}
          />
        </Grid>
        {/* left table */}
        <Grid item xs={12} md={8} className='mt-5'>
          <div style={{ margin: '0 0 5vh 0' }}>
            <Typography
              className='mt-3 mb-3'
              variant='h6'
              align='left'
              style={{ fontWeight: 600 }}
            >
              KPI COMPARISON VS PREVIOUS YEAR
            </Typography>{' '}
          </div>
          <div
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              border: `2px solid ${localStorage.getItem("primaryColor")}`,
              borderRadius: '50px',
              padding: '20px',
            }}
          >
            <div>
              <Typography variant='subtitle1'> Revenue</Typography>
              <Typography variant='h5' style={{ fontWeight: 500 }}>
                {abbreviateNumber(
                  Math.round(guestInsightNationalityData.revenue_otb)
                )}{' '}
                €
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation='vertical'
                variant='middle'
                textAlign='center'
              />
            </div>
            <div>
              <Typography variant='subtitle1'> Adr</Typography>
              <Typography variant='h5' style={{ fontWeight: 500 }}>
                {abbreviateNumber(
                  Math.round(guestInsightNationalityData.adr_otb)
                )}{' '}
                €
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation='vertical'
                variant='middle'
                textAlign='center'
              />
            </div>
            <div>
              <Typography variant='subtitle1'>Lead time</Typography>
              <Typography variant='h5' style={{ fontWeight: 500 }}>
                {abbreviateNumber(
                  Math.round(guestInsightNationalityData.lead_time_otb)
                )}
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation='vertical'
                variant='middle'
                textAlign='center'
              />
            </div>
            <div>
              <Typography variant='subtitle1'>Room Nights</Typography>
              <Typography variant='h5' style={{ fontWeight: 500 }}>
                {abbreviateNumber(
                  Math.round(guestInsightNationalityData.room_nights_otb)
                )}
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation='vertical'
                variant='middle'
                textAlign='center'
              />
            </div>
            <div>
              <Typography variant='subtitle1'>Lenght of stay</Typography>
              <Typography variant='h5' style={{ fontWeight: 500 }}>
                {abbreviateNumber(
                  Math.round(guestInsightNationalityData.length_of_stay_otb)
                )}
              </Typography>
            </div>
          </div>

          {/* table */}

          <TableContainer className={classes.tablescroll}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.cellTypo}>
                    Nationality OTB
                  </TableCell>
                  <TableCell className={classes.cellTypo} align='left'>
                    %Room Night OTB
                  </TableCell>
                  <TableCell className={classes.cellTypo} align='left'>
                    Room Nights OTB
                  </TableCell>
                  <TableCell className={classes.cellTypo} align='left'>
                    Revenue OTB
                  </TableCell>
                  <TableCell className={classes.cellTypo} align='left'>
                    LOS OTB
                  </TableCell>
                  <TableCell className={classes.cellTypo} align='left'>
                    ADR OTB
                  </TableCell>{' '}
                  <TableCell className={classes.cellTypo} align='left'>
                    Lead Time OTB
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {guestInsightNationalityData?.nationality_table?.map((row) => (
                  <TableRow key={row.name} className={classes.tableRow}>
                    <TableCell
                      className={classes.cellTypo}
                      component='th'
                      scope='row'
                    >
                      {row['Guest Nationality']}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      {abbreviateNumber(Math.round(row['% Room Nights OTB']))}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      {row['Room Nights OTB']}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      {abbreviateNumber(Math.round(row['Revenue OTB']))}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      {abbreviateNumber(Math.round(row['LOS OTB']))}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      {abbreviateNumber(Math.round(row['ADR OTB']))}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      {abbreviateNumber(Math.round(row['Lead OTB']))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {/* seconf table */}
      <TableContainer className={classes.tablescroll}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.cellTypo}>Origin</TableCell>
              <TableCell className={classes.cellTypo} align='left'>
                %Room Night OTB
              </TableCell>
              <TableCell className={classes.cellTypo} align='left'>
                Room Nights OTB
              </TableCell>
              <TableCell className={classes.cellTypo} align='left'>
                Revenue OTB
              </TableCell>
              <TableCell className={classes.cellTypo} align='left'>
                LOS OTB
              </TableCell>
              <TableCell className={classes.cellTypo} align='left'>
                ADR OTB
              </TableCell>{' '}
              <TableCell className={classes.cellTypo} align='left'>
                Lead OTB
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guestInsightOriginData.map((row) => (
              <TableRow key={row.name} className={classes.tableRow}>
                <TableCell
                  className={classes.cellTypo}
                  component='th'
                  scope='row'
                >
                  {row['origin']}
                </TableCell>
                <TableCell className={classes.cellTypo} align='left'>
                  {abbreviateNumber(Math.round(row['% Room Nights OTB']))}
                </TableCell>
                <TableCell className={classes.cellTypo} align='left'>
                  {row['Room Nights OTB']}
                </TableCell>
                <TableCell className={classes.cellTypo} align='left'>
                  {abbreviateNumber(Math.round(row['Revenue OTB']))}
                </TableCell>
                <TableCell className={classes.cellTypo} align='left'>
                  {abbreviateNumber(Math.round(row['LOS OTB']))}
                </TableCell>
                <TableCell className={classes.cellTypo} align='left'>
                  {abbreviateNumber(Math.round(row['ADR OTB']))}
                </TableCell>
                <TableCell className={classes.cellTypo} align='left'>
                  {abbreviateNumber(Math.round(row['Lead OTB']))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default GuestInsight
