// -------------- Date Reformat Functions ------------------
function appendLeadingZeroes(n) {
  // Add zeros if month single digit
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

// Returns Date in the format needed
export const reformatDate = (date, onlyMonth) => {
  let formattedDate;
  if (onlyMonth) {
    // If Date is needed in the format YYYY-MM
    formattedDate =
      date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1);
  } else {
    // If Date is needed in the format YYYY-MM-DD
    formattedDate =
      date.getFullYear() +
      "-" +
      appendLeadingZeroes(date.getMonth() + 1) +
      "-" +
      appendLeadingZeroes(date.getDate());
  }

  return formattedDate;
};
const cardsDataScaffolding = {
  achievedValue: null,
  date: "",
  name: "",
  progressValue: null,
  startDate: "",
  targetValue: null,
  type: "",
};
export function getSellStatsDummyData(year, month) {
  const dummyData = [];

  // reformatting to older format
  let revOtb = {};
  revOtb.achievedValue = 0;
  revOtb.targetValue = 0;
  revOtb.name = "Revenue OTB";
  revOtb.type = "Current Month";
  revOtb.date = reformatDate(new Date(year, month, 0));
  revOtb.startDate =
    month + 1 === new Date().getMonth() + 2
      ? reformatDate(new Date())
      : reformatDate(new Date(year, parseInt(month - 1), 1));
  revOtb.progressValue = 0;
  dummyData.push(revOtb);

  let adrOtb = {};
  adrOtb.achievedValue = 0;
  adrOtb.targetValue = 0;
  adrOtb.name = "Average Daily Revenue OTB";
  adrOtb.type = "Current Month";
  adrOtb.date = reformatDate(new Date(year, month, 0));
  adrOtb.startDate =
    month + 1 === new Date().getMonth() + 2
      ? reformatDate(new Date())
      : reformatDate(new Date(year, parseInt(month - 1), 1));
  adrOtb.progressValue = 0;
  dummyData.push(adrOtb);

  let occupancy = {};
  occupancy.achievedValue = 0;
  occupancy.targetValue = 0;
  occupancy.name = "Occupancy";
  occupancy.type = "Current Month";
  occupancy.date = reformatDate(new Date(year, month, 0));
  occupancy.startDate =
    month + 1 === new Date().getMonth() + 2
      ? reformatDate(new Date())
      : reformatDate(new Date(year, parseInt(month - 1), 1));
  occupancy.progressValue = 0;
  dummyData.push(occupancy);

  let revOtbYearly = cardsDataScaffolding;
  revOtbYearly.achievedValue = 0;
  revOtbYearly.targetValue = 0;
  revOtbYearly.name = "Revenue OTB(Yearly)";
  revOtbYearly.type = "Total";
  revOtbYearly.date = 0;
  revOtbYearly.startDate =
    month + 1 === new Date().getMonth() + 2
      ? reformatDate(new Date())
      : reformatDate(new Date(year, parseInt(month - 1), 1));
  revOtbYearly.progressValue = 0;
  dummyData.push(revOtbYearly);

  let adrOtbYearly = {};
  adrOtbYearly.achievedValue = 0;
  adrOtbYearly.targetValue = 0;
  adrOtbYearly.name = "Average Daily Revenue OTB(Yearly)";
  adrOtbYearly.type = "Total";
  adrOtbYearly.date = "N/A";
  adrOtbYearly.startDate =
    month + 1 === new Date().getMonth() + 2
      ? reformatDate(new Date())
      : reformatDate(new Date(year, parseInt(month - 1), 1));
  adrOtbYearly.progressValue = 0;
  dummyData.push(adrOtbYearly);

  let roomsSoldYearly = {};
  roomsSoldYearly.achievedValue = 0;
  roomsSoldYearly.name = "Total Rooms Sold(Yearly)";
  roomsSoldYearly.type = "Total";
  roomsSoldYearly.targetValue = "";
  roomsSoldYearly.date = "";
  roomsSoldYearly.startDate = "";
  roomsSoldYearly.progressValue = "";
  dummyData.push(roomsSoldYearly);

  return dummyData;
}
