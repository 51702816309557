import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  GOSPaceReportInsight,
  GOSPaceReportInsightTable,
  bookReportFuture,
  paceReportInsight,
  weeklyPaceReportInsight,
  guestInsight,
} from '../utils/apiCalls'
import { useEffect, useState } from 'react'

import BookReport from '../bookReport/BookReport'
import { Chart } from 'chart.js'
import { DateRange } from 'react-date-range'
import GuestNationality from './GuestNationality'
import Origin from './Origin'
import Status from './Status'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import WeeklyPaceReportGraph from './WeeklyPaceReportGraph'
import annotationPlugin from 'chartjs-plugin-annotation'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import GuestInsight from '../bookReport/GuestInsight'
import { dataFormatting, formatInEuropeanNoSymbol } from '../../../../utils'
import { primaryColor } from '../../../../../colors'

// function generateRandomColorHex() {
//   return "#" + ("FF0000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);
// }

const useStyles = makeStyles((theme) => ({
  tablescroll: {
    minWidth: 350,
    maxHeight: 500,
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
  table: {
    minWidth: 350,
  },
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      margin: 'auto',
    },
  },
  statDetails: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  tableRow: {
    '&:nth-child(odd)': { backgroundColor: 'white !important' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  cellTypo: {
    border: 0,
    minWidth:'120px',
    color: '#000000',
  },
}))
const   IncrementoPace = ({ propertyId, propertyStatus }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  Chart.register(annotationPlugin)

  // ------------------- Pace report states -------------------
  const [loading, setLoading] = useState(true)
  const [paceReport, setPaceReport] = useState({})
  const [paceReportNationality, setPaceReportNationality] = useState([])
  const [paceReportOrigin, setPaceReportOrigin] = useState([])
  const [paceReportStatus, setPaceReportStatus] = useState([])
  const [weeklyPaceReportInsightGraph, setWeeklyPaceReportInsightGraph] =
    useState({})
  const [weeklyPaceReportInsightTable, setWeeklyPaceReportInsightTable] =
    useState([])
  const [onBookReportFuture, setOnBookReportFuture] = useState({})
  // ------------------- Date ranges ------------------------
  const [showDateRange, setShowDateRange] = useState(false)
  const [paceReportStartDate, setPaceReportStartDate] = useState(
    dataFormatting(new Date(new Date().setDate(new Date().getDate() - 30)))
  )
  const [paceReportEndDate, setPaceReportEndDate] = useState(
    dataFormatting(new Date())
  )
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  const [startUpdating, setStartUpdating] = useState(false)
  //--------------------- Set date range ---------------------

  const [dateRangeStatePaceReport, setDateRangeStatePaceReport] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const handleSelectDateRange = (ranges) => {
    setDateRangeStatePaceReport([ranges.selection])
    setPaceReportStartDate(dataFormatting(ranges.selection.startDate))
    setPaceReportEndDate(dataFormatting(ranges.selection.endDate))
  }

  const updatehandleSelectDateRange = () => {
    setShowDateRange(false)
    setStartUpdating(true)
  }
  // ------------------- Guest Insight states -------------------

  const [guestInsightOrigin, setGuestInsightOrigin] = useState([])
  const [guestInsightNationality, setGuestInsightNationality] = useState({})
  //--------------------- Use Effect Calls ---------------------
  // stop loading when propertyStatus is false

  useEffect(() => {
    if (propertyStatus === false) {
      setLoading(false)
    }
  }, [propertyStatus])
  useEffect(() => {
    // payloads

    const guest_nationality_payload = {
      start_date: paceReportStartDate,
      end_date: paceReportEndDate,
      propertyId: propertyId,
      pace_report: 'guest_nationality',
    }
    const origin_payload = {
      start_date: paceReportStartDate,
      end_date: paceReportEndDate,
      propertyId: propertyId,
      pace_report: 'origin',
    }
    const status_payload = {
      start_date: paceReportStartDate,
      end_date: paceReportEndDate,
      propertyId: propertyId,
      pace_report: 'status',
    }

    const guestInsightOriginPayload = {
      insight_type: 'origin',
      propertyId: propertyId,
    }
    const guestInsightNationalityPayload = {
      insight_type: 'nationality',
      propertyId: propertyId,
    }
    // -------------- payloads end -------------
    axios
      .all([
        paceReportInsight(paceReportStartDate, paceReportEndDate, propertyId),
        weeklyPaceReportInsight(
          paceReportStartDate,
          paceReportEndDate,
          propertyId
        ),
        GOSPaceReportInsight(guest_nationality_payload),
        GOSPaceReportInsight(origin_payload),
        GOSPaceReportInsight(status_payload),
        GOSPaceReportInsightTable(
          paceReportStartDate,
          paceReportEndDate,
          propertyId
        ),
        bookReportFuture(paceReportStartDate, paceReportEndDate, propertyId),
        guestInsight(guestInsightOriginPayload),
        guestInsight(guestInsightNationalityPayload),
      ])
      .then(
        axios.spread((...responses) => {
          setPaceReport(responses[0].data.payload)
          setWeeklyPaceReportInsightGraph(responses[1].data.payload)
          setPaceReportNationality(responses[2].data.payload)
          setPaceReportOrigin(responses[3].data.payload)
          setPaceReportStatus(responses[4].data.payload)
          setWeeklyPaceReportInsightTable(responses[5].data.payload)
          setOnBookReportFuture(responses[6].data.payload)
          setGuestInsightOrigin(responses[7].data.payload)
          setGuestInsightNationality(responses[8].data.payload)
          setLoading(false)
        })
      )
      .catch((errors) => {})
  }, [startUpdating, propertyId])

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <img
            src='/images/loading.gif'
            alt='competatorTableList'
            width='350'
          />
        </div>
      ) : propertyStatus ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <Typography
                align='center'
                variant='h4'
                style={{ fontWeight: 600 }}
              >
                {' '}
                Pace Report
              </Typography>
            </div>
            <div>
              <div>
                <TextField
                  id='standard-basic'
                  onClick={() => setShowDateRange(true)}
                  value={`${paceReportStartDate} - ${paceReportEndDate}`}
                  variant='outlined'
                />
              </div>
              {showDateRange && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '350px',
                    margin: '0 auto 0 auto',
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelectDateRange}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRangeStatePaceReport}
                  />

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant='contained'
                      onClick={() => setShowDateRange(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      onClick={() => updatehandleSelectDateRange()}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Grid container spacing={2} className='mt-4'>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid xs={12} lg={4} item>
                  <Typography align='center' variant='subtitle1'>
                    {' '}
                    Total Gross for the selected time period
                  </Typography>

                  <div
                    style={{ marginTop: '20px' }}
                    className={classes.statDetails}
                  >
                    <div
                      className='pace_spacing'
                      style={{
                        border: '2px solid red',
                        borderRadius: '50px',
                        padding: '20px',
                        margin: '2px 0 15px 0',
                      }}
                    >
                      <Typography align='center' variant='subtitle1'>
                        {' '}
                        Gross Revenue
                      </Typography>
                      <Typography
                        align='center'
                        variant='h5'
                        style={{ fontWeight: 500 }}
                      >
                        {formatInEuropeanNoSymbol(
                          Math.round(paceReport?.total_revenue || 0)
                        )}{' '}
                        €
                      </Typography>
                    </div>
                    <div
                      className='pace_spacing'
                      style={{
                        border: '2px solid green',
                        borderRadius: '50px',
                        padding: '20px',
                        margin: '2px 0 15px 0',
                      }}
                    >
                      <Typography align='center' variant='subtitle1'>
                        {' '}
                        Gross Room Nights
                      </Typography>
                      <Typography
                        align='center'
                        variant='h5'
                        style={{ fontWeight: 500 }}
                      >
                        {paceReport?.total_nights || 0}
                      </Typography>
                    </div>

                    <div
                      className='pace_spacing'
                      style={{
                        border: `2px solid ${localStorage.getItem("primaryColor")}`,
                        borderRadius: '50px',
                        padding: '20px',
                        margin: '2px 0 15px 0',
                      }}
                    >
                      <Typography align='center' variant='subtitle1'>
                        {' '}
                        Gross ADR
                      </Typography>
                      <Typography
                        align='center'
                        variant='h5'
                        style={{ fontWeight: 500 }}
                      >
                        {formatInEuropeanNoSymbol(
                          Math.round(paceReport?.gross_adr || 0)
                        )}{' '}
                        €
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} lg={8} item>
                  <TableContainer>
                    <Table className={classes.table} aria-label='simple table'>
                      <TableHead>
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.cellTypo}>
                            Month of stay
                          </TableCell>
                          <TableCell className={classes.cellTypo} align='left'>
                            Revenue Pace
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paceReport?.month_of_stay?.map((row, index) => (
                          <TableRow key={index} className={classes.tableRow}>
                            <TableCell
                              className={classes.cellTypo}
                              component='th'
                              scope='row'
                            >
                              {row.time_period}
                            </TableCell>
                            <TableCell
                              className={classes.cellTypo}
                              align='left'
                            >
                              {parseFloat(row.revenue).toFixed(3)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <WeeklyPaceReportGraph
                weeklyPaceReportInsightGraph={weeklyPaceReportInsightGraph}
              />
            </Grid>
          </Grid>

          {/* graphs */}

          <Grid container spacing={2} className='mb-4'>
            <Grid item xs={12} md={4} className='mt-4'>
              <GuestNationality paceReportNationality={paceReportNationality} />
            </Grid>
            <Grid item xs={12} md={4} className='mt-4'>
              <Origin paceReportOrigin={paceReportOrigin} />
            </Grid>
            <Grid item xs={12} md={4} className='mt-4'>
              <Status paceReportStatus={paceReportStatus} />
            </Grid>
          </Grid>

          {/* table */}
          <div>
            <TableContainer className={classes.tablescroll}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.cellTypo}>No.</TableCell>
                    <TableCell className={classes.cellTypo}>
                      Book Date & Time
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Check-in
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Check-out
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Booker
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Octorate ID
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Property Name
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Origin
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Status
                    </TableCell>
                    <TableCell className={classes.cellTypo} align='left'>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weeklyPaceReportInsightTable.map((row, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.cellTypo} align='left'>
                        {index}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                        {`${new Date(row.BookingTime).getFullYear()}-${new Date(row.BookingTime).getUTCMonth()}-${new Date(row.BookingTime).getDate()} ${new Date(row.BookingTime).getHours()}:${new Date(row.BookingTime).getMinutes()}:${new Date(row.BookingTime).getSeconds()}`}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                      {`${new Date(row.CheckIN).getFullYear()}-${new Date(row.CheckIN).getUTCMonth()}-${new Date(row.CheckIN).getDate()} ${new Date(row.CheckIN).getHours()}:${new Date(row.CheckIN).getMinutes()}:${new Date(row.CheckIN).getSeconds()}`}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                      {`${new Date(row.CheckOUT).getFullYear()}-${new Date(row.CheckOUT).getUTCMonth()}-${new Date(row.CheckOUT).getDate()} ${new Date(row.CheckOUT).getHours()}:${new Date(row.CheckOUT).getMinutes()}:${new Date(row.CheckOUT).getSeconds()}`}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                        {row.booker}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                        {row.octorateID}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                        {row.propertyName}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                        {row.origin}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                        {row.status}
                      </TableCell>
                      <TableCell className={classes.cellTypo} align='left'>
                        {formatInEuropeanNoSymbol(Math.round(row.revenue))}€
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/*------------- Book report  ----------------*/}
          <br />
          <Divider />
          <br />
          <BookReport onBookReportFuture={onBookReportFuture} />
          <GuestInsight
            guestInsightOrigin={guestInsightOrigin}
            guestInsightNationality={guestInsightNationality}
          />
        </>
      ) : (
        <>
          <h3>Contact support to activate your Property</h3>
        </>
      )}
    </div>
  )
}

export default IncrementoPace
