import React, { useContext } from 'react'

import { AppContext } from '../Context'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Success from './Success'
import ThirdStep from './ThirdStep'
import Typography from '@material-ui/core/Typography'

// Step titles
const labels = ['Personal Info', 'Business type', 'Business Info']
const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <FirstStep />
    case 1:
      return <SecondStep />
    case 2:
      return <ThirdStep />
    default:
      throw new Error('Unknown step')
  }
}

const StepForm = () => {
  const { activeStep } = useContext(AppContext)

  return (
    <div>
      {activeStep === labels.length ? (
        <Success />
      ) : (
        <>
          <div style={{ margin: '30px 0 50px' }}>
            <Typography variant="h4" align="center">
         <b style={{
           fontFamily: "Plus Jakarta Sans",
           fontStyle: "normal",
           fontWeight: "bold",
           fontSize: "32px",
           lineHeight: "40px",
           color:"#000000",
         }}> Sign Up </b>    
            </Typography>
          </div>
          <Stepper
            activeStep={activeStep}
            style={{ margin: '30px 0 15px' }}
            alternativeLabel
          >
            {labels.map((label) => (
              <Step key={label}>
                <StepLabel style={{
           fontFamily: "Plus Jakarta Sans",
           fontStyle: "normal",
           fontWeight: "normal",
           fontSize: "16px",
           lineHeight: "20px",
           color:"#000000",
         }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {handleSteps(activeStep)}
        </>
      )}
    </div>
  )
}

export default StepForm
