import { CopyBlock, solarizedLight } from "react-code-blocks";
import React, { useEffect, useState } from "react";
import { jsFetch, nodeJs, pythonHttp, pythonRequest } from "../ApiCode";

const ReservationCaptureWebHook = ({language}) => {
  const [languageDemo, setLanguageDemo] = useState("");
  const [highlightLanguage, setHighlightLanguage] = useState("javascript");

  const argumentsToPass =
  "{      \r\n        \"creationDate\" : \"2022-09-20 01:55:19\",\r\n        \"checkInDate\" : \"2022-09-20 01:55:19\",\r\n        \"checkOutDate\" : \"2022-09-20 01:55:19\",\r\n        \"cancellationDate\" : \"2022-09-20 01:55:19\",\r\n        \"referralId\" : \"1234567\",\r\n        \"lastModify\" : \"2022-09-20 01:55:19\",\r\n        \"numGuestsAdult\" : 5,\r\n        \"numGuestsChildren\" : 14,\r\n        \"reservationId\" : \"4567\",\r\n        \"booker\" : \"Sid space walker\",\r\n        \"freeCancellation\" : true,\r\n        \"status\":\"confirmed\",\r\n        \"currency\" : \"euro\",\r\n        \"origin\" : \"Punjab\",\r\n        \"revenue\" : 50000.00,\r\n        \"propertyId\" : \"47575\",\r\n        \"priceBreakdown\":\r\n        [{\"date\" : \"2022-01-20 01:55:19\",\r\n        \"price\": 10.0},\r\n        {\"date\" : \"2022-02-20 02:55:19\",\r\n        \"price\": 20.0},\r\n        {\"date\" : \"2022-03-20 03:55:19\",\r\n        \"price\": 30.0},\r\n        {\"date\" : \"2022-04-20 04:55:19\",\r\n        \"price\": 40.0}\r\n        ],\r\n        \"roomID\" : \"5766\",\r\n        \"roomTypeOriginal\" : \"luxury\",\r\n        \"roomTypeAssigned\" : \"origin\",\r\n        \"nationalityGuest\" : \"pakistani\",\r\n        \"roomTypeID\" : 67\r\n        }"
  const method = "POST";
  const baseUrl = "base_url";
  const endpoint = "reservations/all";
  useEffect(() => {
    if (language === "jsFetch") {
      setHighlightLanguage("javascript");
      setLanguageDemo(jsFetch(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "nodeJs") {
      setHighlightLanguage("javascript");
      setLanguageDemo(nodeJs(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonHttp") {
      setHighlightLanguage("python");
      setLanguageDemo(pythonHttp(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonRequest") {
      setHighlightLanguage("python");

      setLanguageDemo(
        pythonRequest(argumentsToPass, method, endpoint, baseUrl)
      );
    }
  }, [language]);
  return (
    <div >
      {" "}
      <h3> Reservation Capture WebHook</h3 >
<p>A webhook for gathering reservations from various PMS in brainyRMS's own database schema. Send each of your reservations into the request body, using the auth token provided to you in the headers.</p>
      <div className="">

        <div className="demo">
          <CopyBlock
            language={highlightLanguage}            text={languageDemo}
            showLineNumbers={true}
            theme={solarizedLight}
            wrapLines={true}
            codeBlock
          />
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default ReservationCaptureWebHook;
