import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontSize:"2vh"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AccordionTwo() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion style={{color:"rgba(126, 126, 126, 1)"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><b>Definitions and legal references</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
          <Typography style={{margin:"40px"}}>
            <p><b>Personal Data (or Data)</b></p>
            Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>Usage Data</b></p>
            Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>User</b></p>
            The individual using this Application who, unless otherwise specified, coincides with the Data Subject.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>Data Subject</b></p>
            The natural person to whom the Personal Data refers.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>Data Processor (or Data Supervisor)</b></p>
            The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>Data Controller (or Owner)</b></p>
            The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>This Application</b></p>
            The means by which the Personal Data of the User is collected and processed.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>Service</b></p>
            The service provided by this Application as described in the relative terms (if available) and on this site/application.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>European Union (or EU)</b></p>
            Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>Cookie</b></p>
            Cookies are Trackers consisting of small sets of data stored in the User's browser.
          </Typography>

          <Typography style={{margin:"40px"}}>
            <p><b>Tracker</b></p>
            Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the User’s device.
          </Typography>
          <Divider/>

          <Typography style={{margin:"40px"}}>
            <p><b>Legal information</b></p>
            This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
            <p>This privacy policy relates solely to this Application, if not stated otherwise within this document.</p>
          </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}
