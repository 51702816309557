import { Divider, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@emotion/react";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 350,
  },
  divider: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
      margin: "auto",
    },
  },
  statDetails: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-around",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  tableRow: {
    "&:nth-child(odd)": { backgroundColor: "white !important" },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:last-child td": {
      fontSize: "15px",
      fontWeight: "bold",
      borderTop: "2px solid black",
    },
  },
  cellTypo: {
    border: 0,
    color: "#000000",
  },
}));

const Bookwindow = ({ bookWindowApiCall }) => {
  const [bookWindowData, setBookWindowData] = useState([]);
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    setBookWindowData(bookWindowApiCall);
  }, [bookWindowApiCall]);

  return (
    <div className="mt-4">
      {" "}
      <br />
      <Divider />
      <br />
      <div
        style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-end" }}
      >
        {" "}
        <Typography variant="h4" align="left" style={{ fontWeight: 400 }}>
          Bookwindow information
        </Typography>
        <Typography
          className={window.matchMedia("(min-width:968)") ? "ml-4" : ""}
          variant="subtitle1"
          align="left"
        >
          See how far in advance your guests have booked your property
        </Typography>
      </div>
      <TableContainer className="mt-5">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.cellTypo} align="left">
                Bookwindow
              </TableCell>
              <TableCell className={classes.cellTypo} align="left">
                % Room Nights{" "}
              </TableCell>
              <TableCell className={classes.cellTypo} align="left">
                Revenue
              </TableCell>
              <TableCell className={classes.cellTypo} align="left">
                Length of Stay
              </TableCell>
              <TableCell className={classes.cellTypo} align="left">
                ADR
              </TableCell>{" "}
              <TableCell className={classes.cellTypo} align="left">
                No of gusts (Adult only)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookWindowData.map((row, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.cellTypo} align="left">
                  {row["Book_Window"]}
                </TableCell>
                <TableCell className={classes.cellTypo} align="left">
                  {Math.round(row["% Room Nights"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="left">
                  {Math.round(row["Revenue"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="left">
                  {Math.round(row["Length of stay"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="left">
                  {Math.round(row["ADR"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="left">
                  {Math.round(row["No. Of Guests (adults only)"])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Bookwindow;
