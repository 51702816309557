import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NoteIcon from '@material-ui/icons/Note';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontSize:"2vh"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AccordionOne() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <div className="row">
            <div className="col">
      <Accordion style={{color:"rgba(126, 126, 126, 1)"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
             <div style={{display:"flex"}}>
          <NoteIcon style={{marginRight:"10px", marginLeft:"10px"}}/>
          <Typography className={classes.heading} style={{paddingTop:"5px"}}><b>Analytics</b></Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         <p> The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>
         <br/>
         <b>Google Analytics (Google Ireland Limited)</b>
         <p>Google Analytics is a web analysis service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
            Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
         <p>Personal Data processed: Tracker; Usage Data.</p>  
         <p>Place of processing: Ireland – Privacy Policy – Opt Out</p>  
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className="col">
      <Accordion style={{ color:"rgba(126, 126, 126, 1)"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >

         <div style={{display:"flex", fontSize:"2vh"}}>
          <NoteIcon style={{marginRight:"10px", marginLeft:"10px"}}/>
          <Typography className={classes.heading} style={{paddingTop:"5px"}}><b>Contacting the User</b></Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>Contact form (this Application)</b>
            <p>By filling in the contact form with their Data, the User authorizes this Application to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.</p>
            <p>Personal Data processed: email address; first name; last name; various types of Data.</p>
            <br/>
            <b>Mailing list or newsletter (this Application)</b>
            <p>By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Application. Your email address might also be added to this list as a result of signing up to this Application or after making a purchase.</p>
            <p>Personal Data processed: email address; first name; last name; phone number; Tracker.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
        </div>
      </div>
    </div>
  );
}
