/* eslint-disable */




const nodeJs = (argumentsToPass,method,endpoint,baseUrl)=>{
  return `var axios = require('axios');
  var data = '${argumentsToPass}';
  var config = {
    method: '${method}',
    url: '{{${baseUrl}}}/${endpoint}',
    headers: { },
    data : data
  };
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });`
  
}
const jsFetch =(argumentsToPass,method,endpoint,baseUrl)=>{
return `var raw = "${argumentsToPass}";
var requestOptions = {
  method: '${method}',
  body: raw,
  redirect: 'follow'
};
fetch('{{${baseUrl}}}/${endpoint}', requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
`
}
const pythonHttp =(argumentsToPass,method,endpoint,baseUrl)=>{
  return `
  import http.client
  conn = http.client.HTTPSConnection("{{${baseUrl}}}")
  payload = f"${argumentsToPass}"
  headers = {}
  conn.request("${method}","/${endpoint}", payload, headers)
  res = conn.getresponse()
  data = res.read()
  print(data.decode("utf-8"))`
  }

const pythonRequest =(argumentsToPass,method,endpoint,baseUrl)=>{
return `
import requests
url = f"{{${baseUrl}}}/${endpoint}"
payload = ${argumentsToPass}
headers = {}
response = requests.request("${method}", url, headers=headers, data=payload)
print(response.text)
`
  }
 export {nodeJs,jsFetch,pythonHttp,pythonRequest}