import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import { validationSchema } from "./validation/formValidation";
import { toast } from "react-toastify";
import { changeAccountStatus, getAllGroupAccounts, getBase64 } from "./utils";
import { MuiColorInput } from "mui-color-input";
import axios from "axios";
import AttachIcon from "../../images/attachIcon.png";
import { DefaultUrl } from "../BaseUrl";

const useStyles = makeStyles((theme) => ({
  tablescroll: {
    minWidth: 350,
    maxHeight: 570,
    "&::-webkit-scrollbar": {
      height: 5,
    },
  },
  tableRow: {
    "&:nth-child(odd)": { backgroundColor: "white !important" },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  cellTypo: {
    border: 0,
    color: "#000000",
  },
}));

const ManageGroupAccounts = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [loading, setLoading] = useState(false);
  const [propertyColor, setPropertyColor] = useState("#3F51B5");
  const [propertyLogo, setPropertyLogo] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  // form fields
  const [groupName, setGroupName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pmsId, setPmsId] = useState("1");
  const [password, setPassword] = useState("");
  // form validators
  const emailValidator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const passwordValidator =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  // field helper texts
  const [emailHelper, setEmailHelper] = useState("");
  const [groupNameHelper, setGroupNameHelper] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");

  const [openStatus, setOpenStatus] = useState(false);
  const [accountForStatus, setAccountForStatus] = useState({});
  const [allAccount, setAllAccount] = useState([]);

  const handleColorChange = (newValue) => {
    setPropertyColor(newValue);
  };

  const addGroupAccount = (
    email,
    groupName,
    password,
    pmsId,
    color,
    phoneNumber,
    imageUrl
  ) => {
    axios
      .post("https://update-user-doc-3cuixb5lia-ew.a.run.app/addUserGroup", {
        email: email,
        groupName: groupName,
        password: password,
        pmsId: pmsId,
        phoneNumber: phoneNumber !== "" ? phoneNumber : null,
        color: color,
        imageUrl: imageUrl,
      })
      .then(() => {
        toast.success("Group Account Added");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const submitForm = () => {
    if (
      groupName.trim() !== "" &&
      emailValidator.test(email) &&
      passwordValidator.test(password)
    ) {
      setLoading(true);

      if (propertyLogo) {
        const logo = getBase64(propertyLogo);
        logo.then((b64) => {
          const arr = b64.split(",");
          axios
            .post("https://add-logo-3cuixb5lia-ew.a.run.app", {
              imageUrl: arr[1],
            })
            .then((res) =>
              addGroupAccount(
                email,
                groupName,
                password,
                pmsId,
                propertyColor,
                phone,
                res?.data
              )
            );
        });
      } else {
        addGroupAccount(
          email,
          groupName,
          password,
          pmsId,
          propertyColor,
          phone
        );
      }
    } else {
      toast.error("Invalid Data in Fields");
    }
  };

  const handlePasswordChange = (password) => {
    if (password.trim() !== "" && !passwordValidator.test(password)) {
      setPasswordHelper(
        "Password should contain atleast 8 character, 1 number, 1 letter and 1 special character"
      );
    } else {
      setPasswordHelper("");
    }
    setPassword(password);
  };

  const handleEmailChange = (email) => {
    if (email.trim() !== "" && !emailValidator.test(email)) {
      setEmailHelper("Enter a Valid Email");
    } else {
      setEmailHelper("");
    }
    setEmail(email);
  };

  // change status of account

  const changeStatusAccount = () => {
    changeAccountStatus(accountForStatus.id)
      .then((status) => {
        getAllGroupAccounts().then((res) => {
          setAllAccount(res.allAccount);
        });
        setOpenStatus(false);
        setAccountForStatus({});
        toast.success("Account Status Changed successfully");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleStatusDialogue = (data) => {
    setAccountForStatus(data);
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };
  const uploadFileHandler = (event) => {
    setPropertyLogo(event.target.files[0]);
  };
  useEffect(() => {
    getAllGroupAccounts().then((res) => {
      setAllAccount(res.allAccount);
    });
  }, []);

  return (
    <div>
      <h3>Create Group Account</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              className="mb-3"
              variant="outlined"
              fullWidth
              id="groupName"
              name="groupName"
              label="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              error={groupNameHelper.trim() !== ""}
              helperText={groupNameHelper}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className="mb-3"
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              error={email.trim() !== "" && !emailValidator.test(email)}
              helperText={emailHelper}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className="mb-3"
              variant="outlined"
              fullWidth
              id="phoneNo"
              name="phoneNo"
              label="Phone Number (optional)"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel style={{ transform: "translate(10px,-10px)" }}>
                PMS
              </InputLabel>
              <Select
                id="pms_id"
                value={pmsId}
                label="PMS"
                variant="outlined"
                onChange={(e) => setPmsId(e.target.value)}
                disabled={loading}
              >
                <MenuItem value={"1"}>Octorate</MenuItem>
                <MenuItem value={"2"}>Mews</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              className="mb-3"
              variant="outlined"
              fullWidth
              id="password"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handlePasswordChange(e.target.value.trim(" "))}
              error={
                password.trim() !== "" && !passwordValidator.test(password)
              }
              helperText={passwordHelper}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiColorInput
              value={propertyColor}
              onChange={handleColorChange}
              id="color"
              name="color"
              label="Select Primary Color"
              format="hex"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              component="label"
              className="upload-icon"
            >
              <img
                src={AttachIcon}
                height="20px"
                style={{ marginRight: "5px" }}
              />
              Dashboard Logo
              <input
                type="file"
                hidden
                onChange={uploadFileHandler}
                accept=".png,.jpg"
              />
            </Button>
            <p
              style={{
                fontSize: "10px",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              {" "}
              {propertyLogo?.name}
            </p>
          </Grid>
        </Grid>

        <Button
          color="primary"
          disabled={loading}
          variant="contained"
          fullWidth
          type="submit"
          onClick={() => submitForm()}
        >
          {loading && <CircularProgress size={20} />}
          {!loading && "Submit"}
        </Button>
      </div>
      <div className="mt-5">
        <h3>List of group Accounts</h3>
        <div
          style={{
            width: `100%`,
            padding: "20px 20px",
            borderRadius: "11px",
            backgroundColor: "#f8f8f8",
            overflowX: "hidden",
            height: "100%",
          }}
        >
          <TableContainer className={classes.tablescroll}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className={classes}>
                  <TableCell
                    className={classes.cellTypo}
                    style={{ fontWeight: "bold" }}
                  >
                    Account Title
                  </TableCell>
                  <TableCell
                    className={classes.cellTypo}
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    No. of Connedted properties
                  </TableCell>
                  <TableCell
                    className={classes.cellTypo}
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    Account Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allAccount?.map((account) => (
                  <TableRow className={classes.tableRow} key={account.id}>
                    <TableCell className={classes.cellTypo}>
                      {account.displayName}
                    </TableCell>

                    <TableCell className={classes.cellTypo} align="center">
                      {account.hotels.length}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="center">
                      <Switch
                        color="primary"
                        checked={account.active}
                        onClick={() =>
                          handleStatusDialogue({
                            id: account.id,
                            displayName: account.displayName,
                          })
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* Account status dialogue */}

      <Dialog
        open={openStatus}
        aria-labelledby="alert-dialog-status"
        aria-describedby="alert-dialog-status"
      >
        <DialogTitle id="alert-dialog-status">{`Are you sure you want to change status of this Account`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-status">
            {/* {`Account Name: ${accountForDelete.name}`} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => changeStatusAccount()} color="primary">
            Change Status
          </Button>
          <Button onClick={() => handleCloseStatus()} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageGroupAccounts;
