import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Switch,
  Button,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import {
  getAllImportances,
  updatingImportance,
} from "./utils/updateImportances";
import Fadee from "@material-ui/core/Fade";
import { db, db2 } from "../../../Auth/firebase";
import { doc, onSnapshot, getDoc, updateDoc } from "firebase/firestore";
import axios from "axios";
import { primaryColor } from "../../../colors";
import { DefaultUrl } from "../../BaseUrl";
import { reformatDate } from "../monthlyPrices/utils/utils";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
  },
  headingMain: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 20,
    marginLeft: 10,
  },
  tablescroll: {
    "&::-webkit-scrollbar": {
      height: 5,
    },
    maxHeight: 370,
  },
  tableCont: {
    "&::-webkit-scrollbar": {
      height: 5,
    },
  },
  table: {
    minWidth: 350,
  },

  cellTypo: {
    fontFamily: "Plus Jakarta Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",

    /* identical to box height */

    color: "#000000",
    border: 0,
  },
  tableRow: {
    "&:nth-child(odd)": { backgroundColor: "white !important" },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
}));

const ImportanceUpdate = ({ propertyId, disabled }) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const nextYear = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).getFullYear();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [importanceUpdates, setImportanceUpdates] = useState([]);
  const [newImportanceFirstYear, setnewImportanceFirstYear] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [newImportanceSecondYear, setnewImportanceSecondYear] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);


  //second year

  const [importanceEditable, setImportanceEditable] = useState(false);
  const [disableEditable, setDisableEditable] = useState(true);
  const [importanceChangesToLog, setImportanceChangesToLog] = useState([]);
  
  const logChanges = (
    propertyId,
    email,
    date,
    importanceValue,
    previousValue,
    editable,
    previousEditable
  ) => {
    axios
      .post(`${DefaultUrl}/user_activity_log`, {
        body: {
          propertyId: propertyId,
          email: email,
          variable: "importance",
          time: new Date(),
          importance: {
            date: date,
            importance_value: `${importanceValue}`,
            previous_value: `${previousValue}`,
            editable: editable === "true" ? "yes" : "no",
            previous_editable: previousEditable === "true" ? "yes" : "no",
          },
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => console.warn("log sent"))
      .catch((err) => console.error(err));
  };

  function getDatesInMonth(month, year) {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dates = [];

    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(new Date(year, month, i));
    }

    return dates;
  }

  function fetchMonthlyImportances(month, year, propertyId) {
    const dates = getDatesInMonth(month, year);

    for (let i = 0; i < dates.length; i++) {
      const date = dates[i];
      const docref = doc(
        db2,
        localStorage.getItem("pmsName"),
        propertyId,
        "days",
        reformatDate(date)
      );

      const unsubscribe = onSnapshot(docref, (doc) => {
        if (doc.exists()) {
          if (doc.data().userImportance) {
            if (year === currentYear) {
              let doesExist = false;

              for (let i = 0; i < newImportanceFirstYear[month].length; i++) {
                const element = newImportanceFirstYear[month][i];

                if (element.date === reformatDate(date)) {
                  doesExist = true;
                }
              }
              if (doesExist) {
                setnewImportanceFirstYear((data) =>
                  data.map((monthlyData, index) => {
                    if (index === month) {
                      monthlyData.map((importance) => {
                        if (importance.date === reformatDate(date)) {
                          importance.importance = Math.round(
                            doc.data().userImportance * 10
                          );
                          importance.editable = false;
                          return importance;
                        } else {
                          return importance;
                        }
                      });
                      return monthlyData;
                    } else {
                      return monthlyData;
                    }
                  })
                );
              } else {
                setnewImportanceFirstYear((data) =>
                  data.map((oldData, index) => {
                    if (index === month) {
                      oldData = [
                        ...oldData,
                        {
                          date: reformatDate(date),
                          importance: Math.round(
                            doc.data().userImportance * 10
                          ),
                          editable: false,
                        },
                      ];
                      return oldData;
                    } else {
                      return oldData;
                    }
                  })
                );
              }
            } else {
              let doesExist = false;

              for (let i = 0; i < newImportanceSecondYear[month].length; i++) {
                const element = newImportanceSecondYear[month][i];

                if (element.date === reformatDate(date)) {
                  doesExist = true;
                }
              }
              if (doesExist) {
                setnewImportanceSecondYear((data) =>
                  data.map((monthlyData, index) => {
                    if (index === month) {
                      monthlyData.map((importance) => {
                        if (importance.date === reformatDate(date)) {
                          importance.importance = Math.round(
                            doc.data().userImportance * 10
                          );
                          importance.editable = false;
                          return importance;
                        } else {
                          return importance;
                        }
                      });
                      return monthlyData;
                    } else {
                      return monthlyData;
                    }
                  })
                );
              } else {
                setnewImportanceSecondYear((data) =>
                  data.map((oldData, index) => {
                    if (index === month) {
                      oldData = [
                        ...oldData,
                        {
                          date: reformatDate(date),
                          importance: Math.round(
                            doc.data().userImportance * 10
                          ),
                          editable: false,
                        },
                      ];
                      return oldData;
                    } else {
                      return oldData;
                    }
                  })
                );
              }
            }
          } else {
            if (
              doc.data().systemImportance ||
              doc.data().systemImportance === 0
            ) {
              if (year === currentYear) {
                let doesExist = false;

                for (let i = 0; i < newImportanceFirstYear[month].length; i++) {
                  const element = newImportanceFirstYear[month][i];

                  if (element.date === reformatDate(date)) {
                    doesExist = true;
                  }
                }
                if (doesExist) {
                  setnewImportanceFirstYear((data) =>
                    data.map((monthlyData, index) => {
                      if (index === month) {
                        monthlyData.map((importance) => {
                          if (importance.date === reformatDate(date)) {
                            importance.importance = Math.round(
                              doc.data().systemImportance * 10
                            );
                            importance.editable = true;
                            return importance;
                          } else {
                            return importance;
                          }
                        });
                        return monthlyData;
                      } else {
                        return monthlyData;
                      }
                    })
                  );
                } else {
                  setnewImportanceFirstYear((data) =>
                    data.map((oldData, index) => {
                      if (index === month) {
                        oldData = [
                          ...oldData,
                          {
                            date: reformatDate(date),
                            importance: Math.round(
                              doc.data().systemImportance * 10
                            ),
                            editable: true,
                          },
                        ];
                        return oldData;
                      } else {
                        return oldData;
                      }
                    })
                  );
                }
              } else {
                let doesExist = false;

                for (
                  let i = 0;
                  i < newImportanceSecondYear[month].length;
                  i++
                ) {
                  const element = newImportanceSecondYear[month][i];

                  if (element.date === reformatDate(date)) {
                    doesExist = true;
                  }
                }
                if (doesExist) {
                  newImportanceSecondYear((data) =>
                    data.map((monthlyData, index) => {
                      if (index === month) {
                        monthlyData.map((importance) => {
                          if (importance.date === reformatDate(date)) {
                            importance.importance = Math.round(
                              doc.data().systemImportance * 10
                            );
                            importance.editable = true;
                            return importance;
                          } else {
                            return importance;
                          }
                        });
                        return monthlyData;
                      } else {
                        return monthlyData;
                      }
                    })
                  );
                } else {
                  setnewImportanceSecondYear((data) =>
                    data.map((oldData, index) => {
                      if (index === month) {
                        oldData = [
                          ...oldData,
                          {
                            date: reformatDate(date),
                            importance: Math.round(
                              doc.data().systemImportance * 10
                            ),
                            editable: true,
                          },
                        ];
                        return oldData;
                      } else {
                        return oldData;
                      }
                    })
                  );
                }
              }
            }
          }
        }
      });
    }
  }

  const updateNewImportanceData = (year, month, importanceValue, date) => {
    if (year === currentYear) {
      setnewImportanceFirstYear((data) =>
        data.map((oldData, index) => {
          if (index === month) {
            oldData.map((oldImportance, importanceIndex) => {
              if (oldImportance.date === date && 0 < importanceValue <= 100) {
                oldImportance.importance = importanceValue;
                oldImportance.editable = false;
                return oldImportance;
              } else {
                return oldImportance;
              }
            });
            return oldData;
          } else {
            return oldData;
          }
        })
      );
    } else {
      setnewImportanceSecondYear((data) =>
        data.map((oldData, index) => {
          if (index === month) {
            oldData.map((oldImportance, importanceIndex) => {
              if (oldImportance.date === date && 0 < importanceValue <= 100) {
                oldImportance.importance = importanceValue;
                oldImportance.editable = false;
                return oldImportance;
              } else {
                return oldImportance;
              }
            });
            return oldData;
          } else {
            return oldData;
          }
        })
      );
    }
    var alreadyExists = false;
    for (let i = 0; i < importanceUpdates.length; i++) {
      const update = importanceUpdates[i];
      if (update.date === date) {
        alreadyExists = true;
      }
    }
    if (alreadyExists) {
      setImportanceUpdates((data) =>
        data.map((change) => {
          if (change.date === date) {
            change.importance = importanceValue;
          }
          return change;
        })
      );
    } else {
      setImportanceUpdates((data) => [
        ...data,
        { date: date, importance: importanceValue, editable: false },
      ]);
    }
  };

  const updateEditableSwitch = (year, month, date, editable) => {
    if (year === currentYear) {
      setnewImportanceFirstYear((data) =>
        data.map((oldData, index) => {
          if (index === month) {
            oldData.map((oldImportance) => {
              if (oldImportance.date === date) {
                oldImportance.editable = editable;
                return oldImportance;
              } else {
                return oldImportance;
              }
            });
            return oldData;
          } else {
            return oldData;
          }
        })
      );
      // const updatedData = newImportanceFirstYear
    } else {
      setnewImportanceSecondYear((data) =>
        data.map((oldData, index) => {
          if (index === month) {
            oldData.map((oldImportance) => {
              if (oldImportance.date === date) {
                oldImportance.editable = editable;
                return oldImportance;
              } else {
                return oldImportance;
              }
            });
            return oldData;
          } else {
            return oldData;
          }
        })
      );
    }
    setImportanceUpdates((data) =>
      data.map((change) => {
        if (change.date === date) {
          change.editable = editable;
        }
        return change;
      })
    );
  };

  const updateNewImportancesFirebase = () => {
    for (let i = 0; i < importanceUpdates.length; i++) {
      const update = importanceUpdates[i];
      if (!update.editable) {
        const docRef = doc(
          db2,
          localStorage.getItem("pmsName"),
          propertyId,
          "days",
          update.date
        );

        updateDoc(docRef, { userImportance: update.importance / 10 });
      }
    }
  };

  const sendCalculatePrice = (propertyId, importanceUpdates) => {
    const dates = [];
    for (let i = 0; i < importanceUpdates.length; i++) {
      const update = importanceUpdates[i];
      dates.push(update.date);
    }
    axios
      .post("https://publish-message-3cuixb5lia-ew.a.run.app/calculatePrices", {
        topicName: "calculatePrices",
        payload: {
          pmsId: "1",
          propertyId: propertyId,
          dates: dates,
        },
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{
            margin: "32px 0px",
          }}
        >
          <b className="setting_title_typo">Update Importance</b>
        </Typography>
        <div>
          {importanceEditable ? (
            <>
              <Button
                style={{
                  backgroundColor: localStorage.getItem("primaryColor"),
                  color: "white",
                  marginRight: "20px",
                }}
                onClick={() => {
                  // updateImportancesFirebase(propertyId);
                  updateNewImportancesFirebase();
                  setImportanceEditable(false);
                  setDisableEditable(true);
                  sendCalculatePrice(propertyId,importanceUpdates)
                  // sendDatesToApi(importanceChanges, pmsName, propertyId);
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setImportanceEditable(false);
                  setDisableEditable(true);
                  setImportanceUpdates([]);
                }}
              >
                cancel
              </Button>
            </>
          ) : (
            <Button
              onClick={() => {
                !disabled;
                setImportanceEditable(true);
                setDisableEditable(false);
              }}
            >
              <EditIcon fontSize="small" />
            </Button>
          )}
        </div>
      </div>

      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{ display: "block" }}
          >
            <Typography className={classes.headingMain}>
              {currentYear}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            {newImportanceFirstYear.map((month, monthIndex) => {
              return (
                <Accordion
                  onChange={(event, expanded) => {
                    if (expanded) {
                      fetchMonthlyImportances(
                        monthIndex,
                        currentYear,
                        propertyId
                      );
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>
                      {months[monthIndex]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer className={classes.tablescroll}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                        stickyHeader
                      >
                        <TableHead>
                          {month.length > 0 ? (
                            <TableRow>
                              <TableCell
                                className={classes.cellTypo}
                                align="left"
                              >
                                Date
                              </TableCell>
                              <TableCell
                                className={classes.cellTypo}
                                align="left"
                              >
                                Importance
                              </TableCell>
                              <TableCell
                                className={classes.cellTypo}
                                align="left"
                              >
                                System Importance
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{ textAlign: "center" }}
                              >
                                No Importance Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableHead>
                        <TableBody>
                          {month.map((item, index) => (
                            <TableRow className={classes.tableRow} key={index}>
                              <TableCell
                                align="left"
                                className={classes.cellTypo}
                              >
                                {item.date}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.cellTypo}
                              >
                                {importanceEditable ? (
                                  <input
                                    value={parseInt(item?.importance)}
                                    style={{
                                      border: `1px solid ${localStorage.getItem(
                                        "primaryColor"
                                      )}`,
                                    }}
                                    type="number"
                                    min={1}
                                    max={100}
                                    onChange={(e) => {
                                      updateNewImportanceData(
                                        currentYear,
                                        monthIndex,
                                        parseInt(e.target.value) <= 100
                                          ? parseInt(e.target.value)
                                          : parseInt(item?.importance),
                                        item.date
                                      );
                                    }}
                                  />
                                ) : (
                                  item?.importance
                                )}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.cellTypo}
                              >
                                <Tooltip
                                  title={
                                    item.editable
                                      ? "System Editing"
                                      : "User Editing"
                                  }
                                  TransitionComponent={Fadee}
                                  TransitionProps={{ timeout: 600 }}
                                >
                                  <Switch
                                    color="primary"
                                    onChange={() => {
                                      updateEditableSwitch(
                                        currentYear,
                                        monthIndex,
                                        item.date,
                                        !item.editable
                                      );
                                    }}
                                    checked={item.editable}
                                    disabled={disableEditable}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* // )} */}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{ display: "block" }}
          >
            <Typography className={classes.headingMain}>{nextYear}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            {newImportanceSecondYear.map((month, monthIndex) => {
              return (
                <Accordion
                  onChange={(event, expanded) => {
                    if (expanded) {
                      fetchMonthlyImportances(monthIndex, nextYear, propertyId);
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>
                      {months[monthIndex]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer className={classes.tablescroll}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                        stickyHeader
                      >
                        <TableHead>
                          {month.length > 0 ? (
                            <TableRow>
                              <TableCell
                                className={classes.cellTypo}
                                align="left"
                              >
                                Date
                              </TableCell>
                              <TableCell
                                className={classes.cellTypo}
                                align="left"
                              >
                                Importance
                              </TableCell>
                              <TableCell
                                className={classes.cellTypo}
                                align="left"
                              >
                                System Importance
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{ textAlign: "center" }}
                              >
                                No Importance Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableHead>
                        <TableBody>
                          {month.map((item, index) => (
                            <TableRow className={classes.tableRow} key={index}>
                              <TableCell
                                align="left"
                                className={classes.cellTypo}
                              >
                                {item.date}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.cellTypo}
                              >
                                {importanceEditable ? (
                                  <input
                                    value={parseInt(item?.importance)}
                                    style={{
                                      border: `1px solid ${localStorage.getItem(
                                        "primaryColor"
                                      )}`,
                                    }}
                                    type="number"
                                    min={1}
                                    max={100}
                                    onChange={(e) => {
                                      updateNewImportanceData(
                                        nextYear,
                                        monthIndex,
                                        parseInt(e.target.value) <= 100
                                          ? parseInt(e.target.value)
                                          : parseInt(item?.importance),
                                        item.date
                                      );
                                    }}
                                  />
                                ) : (
                                  item?.importance
                                )}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.cellTypo}
                              >
                                <Tooltip
                                  title={
                                    item.editable
                                      ? "System Editing"
                                      : "User Editing"
                                  }
                                  TransitionComponent={Fadee}
                                  TransitionProps={{ timeout: 600 }}
                                >
                                  <Switch
                                    color="primary"
                                    onClick={() => {
                                      updateEditableSwitch(
                                        nextYear,
                                        monthIndex,
                                        item.date,
                                        !item.editable
                                      );
                                    }}
                                    checked={item.editable}
                                    disabled={disableEditable}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ImportanceUpdate;
