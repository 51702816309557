import "react-circular-progressbar/dist/styles.css";
import "../dashboard.scss";
import "../Index.scss";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import AnimatedProgressProvider from "../monthlyPrices/AnimatedProgressProvider";
import Card from "react-bootstrap/Card";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import { easeQuadInOut } from "d3-ease";
import { useStylesBootstrap } from "../../utils/tooltipDesign";
import { formatInEuropeanNoSymbol } from "../../utils";

export default function RevParCard({ revParAchieved, revParTarget }) {
  const toolTipClasses = useStylesBootstrap();
  let trail;
  let path;
  let counter;
  // let revParAchieved = cardsData[2].achievedValue *(cardsData[1].achievedValue / 100);
  // let revParTarget = cardsData[2].targetValue * (cardsData[1].targetValue / 100)
  // let progressVal = cardsData[2].progressValue + cardsData[1].progressValue;
  let progressVal;
  if (revParAchieved < revParTarget) {
    progressVal = Math.floor(
      ((revParAchieved - revParTarget) / revParTarget) * 100
    );
  } else {
    progressVal = Math.ceil(
      ((revParAchieved - revParTarget) / revParTarget) * 100
    );
  }

  if (progressVal < 0) {
    trail = Math.abs(progressVal);
    path = "red";
    counter = true;
  } else {
    path = "#1FE279";
    trail = Math.ceil(progressVal);
    counter = false;
  }

  const revparProgressTooltip =
    progressVal < 0
      ? `We are ${progressVal}% behind from our target`
      : progressVal > 0
      ? `We are ${progressVal}% ahead of our target`
      : `We have acheived our target`;

  return (
    <div>
      <Card
        style={{
          minWidth: "300px",
          maxWidth: "440px",
          height: "260px",
          background: "#F7F8FC",
          borderRadius: "8px",
          padding: "8px 10px 10px 23px",
          borderWidth: "0",
        }}
      >
        <div className="row">
          <img
            className="img-responsive"
            src="/images/Emoji.svg"
            alt="img"
            style={{ padding: "0px 16px" }}
          />
          <span
            id="title"
            className="sell_stat_card_title"
            style={{ maxWidth: "calc(100% - 180px)" }}
          >
            {" "}
            RevPar
          </span>
          <span className="i__icon">
            {" "}
            <Tooltip
              arrow
              title={revparProgressTooltip}
              classes={toolTipClasses}
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </span>
        </div>
        <div className="row" style={{ maxWidth: "calc(100% - 90px)" }}>
          <span id="heading">
            €{formatInEuropeanNoSymbol(revParAchieved)}
          </span>
          <span id="heading2">
            {`/ €${formatInEuropeanNoSymbol(revParTarget)}`}
          </span>
        </div>

        <div className={"sell_cards_progress_bar"}>
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={trail}
            duration={1.4}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              return (
                <CircularProgressbar
                  value={value}
                  text={
                    // eslint-disable-next-line
                    progressVal == "NaN" ? (
                      <>{`${progressVal}`}</>
                    ) : (
                      <>{`${progressVal}%`}</>
                    )
                  }
                  counterClockwise={counter}
                  styles={buildStyles({
                    pathTransition: "none",
                    pathColor: path,
                    trailColor: "#000000",
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
        </div>
      </Card>
    </div>
  );
}
