import "../Index.scss";

import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import FadeIn from "react-fade-in";
import { Grid } from "@material-ui/core";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);

export default function RoomStatusTable({ dataRoomStatus,dataRoomStatusV2 }) {
  const [data, setData] = useState({});
  // colors to set in the chart
  const colors = [
    "#67B7DC",
    "#6794DC",
    "#6771DC",
    "#8067DC",
    "#A367DC",
    "#C767DC",
    "#DC67CE",
    "#DC67AB",
    "#DC6788",
    "#DC6967",
    "#DC8C67",
    "#DCAF67",
  ];

  //Use effect calls
  useEffect(() => {
    setData(reformatData(dataRoomStatusV2));
  }, [dataRoomStatusV2]);
  //Use effect calls end
  // labels and data set of the graph
  const state = {
    labels: data?.rooms,
    datasets: [
      {
        backgroundColor: data?.colors,
        borderColor: data?.colors,
        borderWidth: 1,
        barPercentage: 0.5,
        //  barThickness: 100,
        borderRadius: 10,
        data: data?.occupancies,
      },
    ],
  };

  // reformatting the data to adapt to the chart format
  const reformatData = (data) =>{
    let reformattedData = {colors:[],occupancies:[],rooms:[]}
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      reformattedData.colors.push(colors[i])
      reformattedData.occupancies.push(element.value)
      reformattedData.rooms.push(element.name)
    }
    return reformattedData;
  }

  return (
    <>
      {" "}
      <FadeIn transitionDuration="4000">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8}>
            <Bar
              data={state}
              options={{
                responsive: true,

                plugins: {
                  autocolors: false,
                  legend: {
                    maintainAspectRatio: true,
                    display: false,
                    labels: {
                      color: "rgb(255, 99, 132)",
                    },
                  },
                },

                title: {
                  display: false,
                },
                legend: {
                  labels: { display: false },
                  position: "bottom",
                },
                scales: {
                  x: {
                    grid: { display: false },
                  },
                  y: {
                    ticks: {
                      min: 0,
                      max: 100,
                      callback: function (value) {
                        return value + "%";
                      },
                    },
                    scaleLabel: {
                      display: true,
                      labelString: "Percentage",
                    },
                    gird: {
                      borderDash: [8, 4],
                      drawBorder: false,
                    },
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            style={{
              padding: "30px 30px 0px 40px",
              backgroundColor: "aliceblue",
              borderRadius: "11px",
              height: "fit-content",
            }}
          >
            {data?.rooms?.map((legend, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                }}
                key={index}
              >
                <div
                  style={{
                    backgroundColor: data.colors[index],
                    width: "10px",
                    height: "15px",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                />
                <p style={{ padding: "0", margin: "0", fontSize: "15px" }}>
                  {legend}
                </p>
              </div>
            ))}
          </Grid>
        </Grid>{" "}
      </FadeIn>{" "}
    </>
  );
}
