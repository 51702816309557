import pdf from "./termsAndConditions.pdf";
import { useEffect } from "react";

function TermsAndConditions() {
  useEffect(() => {
    window.open(pdf, "_self");
  }, []);

  return <div>redirecting...</div>;
}

export default TermsAndConditions;
