import { makeStyles } from "@material-ui/core";

export const useStylesProportion = makeStyles({
  tablescroll: {
    '&::-webkit-scrollbar': {
      height: 5,
    },
    maxHeight: 370,
  },
  tableCont: {
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
  table: {
    minWidth: 350,
  },

  cellTypo: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',

    /* identical to box height */

    color: '#000000',
    border: 0,
  },
  tableRow: {
    '&:nth-child(odd)': { backgroundColor: 'white !important' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
})
export const useStylesSetting = makeStyles({
  tablescroll: {
    '&::-webkit-scrollbar': {
      height: 5,
    },
    maxHeight: 370,
  },
  tableCont: {
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
  table: {
    minWidth: 350,
  },

  cellTypo: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',

    /* identical to box height */

    color: '#000000',
    border: 0,
  },
  tableRow: {
    '&:nth-child(odd)': { backgroundColor: 'white !important' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
})
export const useStylesSeason = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));