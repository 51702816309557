import { ValidationSchema } from './components/Context'

export const initialValues: ValidationSchema = {
  firstName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  userPhone: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
    minLength: 3,
    maxLength: 20,
    
  },
  businessType: {
    value: '',
    error: '',
    required: true,
 
    
  },
  hotelName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 3,
    maxLength: 20,
    helperText: 'Enter hotel name'
  },
  connectedPms: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 3,
    maxLength: 20,
    helperText: 'Enter PMS Name'
  },
  hotelPhone: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
    minLength: 3,
    maxLength: 20,
    
  },

  totalRooms: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
    minLength: 1,
    maxLength: 20,
  },
  totalLinkedProperties: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
    minLength: 1,
    maxLength: 2000,
  },
  totalHotels: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
    minLength: 1,
    maxLength: 200,
  },
pmsName:{
  value: '',
  error: '',
  required: true,
  validate: 'text',
  minLength: 3,
  maxLength: 20,
  helperText: 'Enter PMS Name'
},
  country: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 3,
    maxLength: 20,
  },


  city: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 3,
    maxLength: 20,
  },
}
