import { makeStyles } from "@material-ui/core";
import { primaryColor } from "../../../colors";

export const useStylesMonthlyPrices = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: '#6260EF',
        borderColor: localStorage.getItem("primaryColor"),
        borderWidth: 2,
      },
    },
  },
}))

export const useStylesCards = makeStyles({
  tablescroll: {
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
  table: {
    backgroundColor: 'transparent',
  },
  tableRow: {
    backgroundColor: 'white !important',
    '&:last-child td, &:last-child th': { border: 0 },
  },
  selectBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: localStorage.getItem("primaryColor"),
        borderWidth: 2,
      },
    },
  },
})