import { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { Typography } from "@material-ui/core";
import noData from "../../../../images/noDataSellStats.png";

const RateType = ({ salesStatFreeCancellationData }) => {
  const [data, setData] = useState(salesStatFreeCancellationData);
  useEffect(() => {
    setData(salesStatFreeCancellationData);
  }, [salesStatFreeCancellationData]);
  const series = data?.map((da) => Math.round(da.percentage));
  const options = {
    colors: ["#3ab6cb", "#0072f0"],
    dataLabels: {
      style: {
        colors: ["white", "white"],
        fontSize: "14px",
        textAlign: "center",
        fontWeight: "light",
      },
    },
    fill: {
      colors: ["#3ab6cb", "#0072f0"],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        colors: ["#3ab6cb", "#0072f0"],
        useSeriesColors: false,
      },
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
    labels: data?.map((da) => da.reservation_type),
  };

  return (
    <div>
      {salesStatFreeCancellationData.length > 0 ? (
        <>
          <Typography variant="subtitle1" align="center">
            RATE TYPE: Free Cancellation (True), Non Refundable (False)
          </Typography>
          <Chart options={options} series={series} type="donut" />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noData} style={{ textAlign: "center" }}></img>
          <Typography variant="subtitle1" align="center">
            No data available for this KPI
          </Typography>
        </div>
      )}
    </div>
  );
};

export default RateType;
