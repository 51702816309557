import '../LandingPage/Header.scss'

import * as Yup from 'yup'

import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import { Formik } from 'formik'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '../Context/AuthUserContext'
import { useHistory } from 'react-router-dom'


const useStyles = makeStyles({
  selectBorder: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      color: '#000000',
    },
    '& .MuiOutlinedInput-input': {
      color: '#000000',
    },
  },
})
const SignInForm = () => {
  const { signin } = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const handleClick = async (values) => {
    setLoading(true)
    try {
      await signin(values.username, values.password)
      history.push('/dashboard/monthlyprices')
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('Email Required'),
        password: Yup.string()
          .required('Password Required')
          .min(8, 'Password is too short - should be 8 chars minimum.'),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,

          handleChange,
          handleBlur,
          handleSubmit,
        } = props
        return (
          <div>
            <div style={{ margin: '30px 0 50px' }}>
              <Typography variant='h4' align='center'>
                <b
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '32px',
                    lineHeight: '40px',
                    color: '#000000',
                  }}
                >
                  {' '}
                  Sign In{' '}
                </b>
              </Typography>
            </div>
            <Grid container spacing={2}>
              <form
                onSubmit={handleSubmit}
                name='contactform'
                className='signin_form'
              >
                <Grid item xs={12} sm={12}>
                  <TextField
                    name='username'
                    id='outlined-required'
                    label='Email'
                    variant='outlined'
                    size='small'
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.selectBorder}
                  />

                  {errors.username && touched.username && (
                    <div style={{ color: 'red', fontSize: '10px' }}>
                      {errors.username}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id='password'
                    label='Password'
                    type='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.selectBorder}
                    margin='dense'
                    variant='outlined'
                  />
                  {errors.password && touched.password && (
                    <div style={{ color: 'red', fontSize: '10px' }}>
                      {errors.password}
                    </div>
                  )}
                </Grid>
                <br />
                <br />
                {loading ? (
                  <Button
                    variant='outlined'
                    style={{
                      fontSize: '18px',
                      background: "#6260EF",
                      display: 'flex',
                      justifyContent: 'center',
                      color: 'white',
                      borderRadius: '8px',
                      height: '44px',
                      width: '100%',
                      borderWidth: '0',
                      marginRight: '10px',
                      paddingTop: '8px',
                    }}
                  >
                    Signing In
                  </Button>
                ) : (
                  <button
                    onClick={() => {
                      handleClick(values)
                    }}
                    style={{
                      fontSize: '18px',
                      background: "#6260EF",
                      display: 'flex',
                      justifyContent: 'center',
                      color: 'white',
                      borderRadius: '8px',
                      height: '44px',
                      width: '100%',
                      borderWidth: '0',
                      marginRight: '10px',
                      paddingTop: '8px',
                    }}
                  >
                    Sign In
                  </button>
                )}
                <p
                  style={{
                    fontSize: '13px',
                    display: 'flex',
                    marginLeft: '12px',
                  }}
                >
                  Don’t have an account?
                  <span>
                    <strong>
                      <a
                        href='/SignUp'
                        style={{ textDecoration: 'none', color: "#6260EF" }}
                      >
                        Sign Up
                      </a>
                    </strong>
                  </span>
                </p>
              </form>
            </Grid>
          </div>
        )
      }}
    </Formik>
  )
}

export default SignInForm
