import { CopyBlock, solarizedLight } from 'react-code-blocks'
import React, { useEffect, useState } from 'react'
import { jsFetch, nodeJs, pythonHttp, pythonRequest } from '../ApiCode'

const LoadInternalImportance = ({ language }) => {
  const [languageDemo, setLanguageDemo] = useState('')
  const [highlightLanguage, setHighlightLanguage] = useState('javascript')

  const argumentsToPass =
"{\r\n    \"hotel_id\":\"41006\",\r\n    \"start_date\":\"2022-07-28\",\r\n    \"end_date\": \"2022-07-30\"\r\n}"  
  const method = 'POST'
  const baseUrl = 'base_url'
  const endpoint = 'get_internal_importances'
  useEffect(() => {
    if (language === 'jsFetch') {
      setHighlightLanguage('javascript')
      setLanguageDemo(jsFetch(argumentsToPass, method, endpoint, baseUrl))
    }
    if (language === 'nodeJs') {
      setHighlightLanguage('javascript')
      setLanguageDemo(nodeJs(argumentsToPass, method, endpoint, baseUrl))
    }
    if (language === 'pythonHttp') {
      setHighlightLanguage('python')
      setLanguageDemo(pythonHttp(argumentsToPass, method, endpoint, baseUrl))
    }
    if (language === 'pythonRequest') {
      setHighlightLanguage('python')

      setLanguageDemo(pythonRequest(argumentsToPass, method, endpoint, baseUrl))
    }
  }, [language])
  return (
    <div>
      {' '}
      <h3>Get Internal Importances</h3>
      <p>
        For the given date range of the provided property, load the respective
        importances.
        <br />
        <strong>Parameters Info:</strong>
        <br /> <br />
        <strong>hotel_id:</strong> The id of your hotel which your <br />
        <strong>system uses start_date:</strong> The starting date of the range
        (inclusive) <br />
        <strong>end_date:</strong> The ending date of the range (inclusive)
      </p>
      <div className=''>
        <div className='demo'>
          <CopyBlock
            language={highlightLanguage}
            text={languageDemo}
            showLineNumbers={true}
            theme={solarizedLight}
            wrapLines={true}
            codeBlock
          />
        </div>{' '}
      </div>{' '}
    </div>
  )
}

export default LoadInternalImportance
