import "react-calendar/dist/Calendar.css";
import "../dashboard.scss";
import "./dailyView.scss";
import {
  Card,
  Tooltip,
  IconButton,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  getWeatherInfo,
  sendCalculatePricesMessage,
} from "./utils/dailyViewApiCalls";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import Calendar from "react-calendar";
import CompetetorsTable from "./CompetetorsTable";
import DayPricesRoomType from "./DayPricesRoomType";
import EventsTable from "./EventsTable";
import FadeIn from "react-fade-in";
import Fadee from "@material-ui/core/Fade";
import LatestReservationsTable from "./LatestReservationsTable";
import Occupancegraph from "./Occupancegraph";
import { OnMonthlyLoaderEnd } from "../../redux/actions/monthlyLoader";
import { OnSettingLoaderStart } from "../../redux/actions/loader";
import PropTypes from "prop-types";
import RemoveIcon from "@material-ui/icons/Remove";
import RevParCard from "./RevParCard";
import RoomStatusTable from "./RoomStatusTable";
import SellStatsCards from "../monthlyPrices/SellStatsCards";
import { auth2, db, db2 } from "../../../Auth/firebase";
import { useHistory } from "react-router-dom";
import { useStylesBootstrap } from "../../utils/tooltipDesign";
import { getDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { ReactComponent as NoData } from "../../../images/No_data.svg";
import axios from "axios";
function DailyView(props) {
  const toolTipClasses = useStylesBootstrap();
  const dateFromMonthlyView = props?.location?.state?.date;
  const propertyStatus = props?.propertyStatus;
  const history = useHistory();
  const [date, setDate] = useState(
    dateFromMonthlyView != undefined
      ? new Date(dateFromMonthlyView)
      : new Date()
  );
  const [importance, setImportance] = useState(1);
  const [systemImportance, setSystemImportance] = useState();
  const [editable, setEditableStatus] = useState();
  const [startUpdatingImportance, setStartUpdatingImport] = useState(false);
  const [disablePriceSend, setDisablePriceSend] = useState(false);
  const [dynamicity, setDynamicity] = useState(3);
  const [dynamicityEditable, setDynamicityEditable] = useState(false);
  const dynamicityArray = ["low", "medium", "high"];
  const [weather, setWeather] = useState("");
  const [competitorsData, setCompetitorsData] = useState({});
  const [occupancyGraphData, setOccupancyGraphData] = useState({});
  const [dataRoomStatus, setRoomStatus] = useState({});
  const [dataUpdating, setDataUpdating] = useState(false);
  const [noCardsData, setNoCardsData] = useState(false);
  const [noEventData, setNoEventsData] = useState(false);
  const [noOccupancyGraphData, setNoOccupancyGraphData] = useState(false);
  const [noLatestReservationsData, setNoLatestReservationsData] =
    useState(false);
  const [noCompetitorsData, setNoCompetitorsData] = useState(false);
  const [noDayPriceEachRoom, setNoDayPriceEachRoom] = useState(false);
  const [noDayRoomStatus, setNoDayRoomStatus] = useState(false);

  // V2 States from here
  const [allRootData, setAllRootData] = useState({});
  // Targets
  const [ADRTargets, setADRTargets] = useState([]);
  const [OCCTargets, setOCCTargets] = useState([]);
  const [REVTargets, setREVTargets] = useState([]);
  // Cards Acieved Value
  const [RevParAchieved, setRevParAchieved] = useState();
  const [ADRAchieved, setADRAchieved] = useState();
  const [OCCAchieved, setOCCAchieved] = useState();
  const [REVAchieved, setREVAchieved] = useState();
  // All Room Types
  const [allV2Rooms, setAllV2Rooms] = useState([]);
  const [roomCount, setRoomCount] = useState();
  // Day Room Prices
  const [dayRoomStatus, setDayRoomStatus] = useState([]);
  const [dayRoomPrices, setDayRoomPrices] = useState([]);
  // Events Data
  const [allEventsData, setAllEventsData] = useState([]);
  const [dayEventsData, setDayEventsData] = useState([]);
  //latest reservations Data
  const [latestReservationsDataV2, setLatestReservationsDataV2] = useState([]);
  //Data Triggers
  const [dayDataTrigger, setDayDataTrigger] = useState(false);
  const [rootDataTrigger, setRootDataTrigger] = useState(false);
  const [eventsDataTrigger, setEventsDataTrigger] = useState(false);
  // importance for targets
  const [targetImportance, setTargetImportance] = useState(1);

  let { propertyId } = props;
  const dispatch = useDispatch();

  //fetch disable price send data and create if it does not exist
  const getPriceSendInfo = (propertyId, date) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      propertyId,
      "days",
      reformatDate(date)
    );
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const priceSendInfo = doc.get("pushPriceDaily");
        if (priceSendInfo === true || priceSendInfo === false) {
          setDisablePriceSend(priceSendInfo);
        } else {
          if (docRef.path.split("/")[3] === reformatDate(date)) {
            updateDoc(docRef, { pushPriceDaily: true });
          }
        }
      }
    });
  };

  //update price send info
  const updatePriceSendInfo = (propertyId, date, value) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      propertyId,
      "days",
      reformatDate(date)
    );
    if (docRef.path.split("/")[3] === reformatDate(date)) {
      updateDoc(docRef, { pushPriceDaily: value });
    }
  };

  // fetches the user dynamicity
  const getUserDynamicity = (propertyId, date) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      propertyId,
      "days",
      reformatDate(date)
    );
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const userDynamicity = doc.get("irev_dynamicity");
        if (userDynamicity && userDynamicity !== null) {
          setDynamicity(dynamicityArray.indexOf(userDynamicity));
          setDynamicityEditable(true);
        } else {
          setDynamicityEditable(false);
        }
      }
    });
  };

  // sets the user dynamicity
  const setUserDynamicity = (dynamicity, date) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      propertyId,
      "days",
      reformatDate(date)
    );
    if (docRef.path.split("/")[3] === reformatDate(date)) {
      updateDoc(docRef, { irev_dynamicity: dynamicity });
      sendCalculatePricesMessage("1", propertyId, reformatDate(date));
    }
  };

  // unsets the user dynamicity
  const unsetUserDynamicity = (date) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      propertyId,
      "days",
      reformatDate(date)
    );
    if (docRef.path.split("/")[3] === reformatDate(date)) {
      updateDoc(docRef, { irev_dynamicity: null });
    }
    sendCalculatePricesMessage("1", propertyId, reformatDate(date));
  };

  // updating importance in the daily document
  const updateImportance = (propertyId, date, importance) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      String(propertyId),
      "days",
      String(date)
    );
    if (editable) {
      if (docRef.path.split("/")[3] === String(date)) {
        updateDoc(docRef, { userImportance: importance });
      }
      sendCalculatePricesMessage("1", propertyId, date);
    }
  };

  //unset userImportance
  const unsetUserImportance = (propertyId, date) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      String(propertyId),
      "days",
      String(date)
    );
    setSystemImportance("");
    if (docRef.path.split("/")[3] === String(date)) {
      updateDoc(docRef, { userImportance: null });
    }
    sendCalculatePricesMessage("1", propertyId, date);
  };

  //Changing date format to retrieve data from backend
  function appendLeadingZeroes(n) {
    if (n <= 9) {
      return "0" + n;
    }
    return n;
  }
  let current_datetime = date;

  let formatted_date =
    current_datetime.getFullYear() +
    "-" +
    appendLeadingZeroes(current_datetime.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(current_datetime.getDate());

  const [reqDate, setReqDate] = useState(formatted_date);
  const onChangeDate = (newDate) => {
    setDataUpdating(true);
    setDate(newDate);
    setReqDate(reformatDate(newDate));
  };

  const reformatDate = (date) => {
    let formatted_date =
      date.getFullYear() +
      "-" +
      appendLeadingZeroes(date.getMonth() + 1) +
      "-" +
      appendLeadingZeroes(date.getDate());
    return formatted_date;
  };

  // changing switch
  const onChangeSwitch = () => {
    setEditableStatus(!editable);
    // setStartUpdatingImport(true);
  };

  const NoDataComponent = () => {
    return (
      <div className="noData-container">
        <NoData
          className="noData-svg"
          fill={localStorage.getItem("primaryColor")}
        />
        <p>
          Sorry!
          <br />
          No Data available for selected parameters
        </p>
      </div>
    );
  };

  // Getting Document for a Day
  const getDailyViewDataV2 = (propertyId, db2, date) => {
    // setting day data trigger
    setDayDataTrigger(false);

    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      String(propertyId),
      "days",
      reformatDate(date)
    );
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        var roomPrice;
        const data = doc.data();

        // Setting Achieved Values of cards
        if (
          data.revPar &&
          data.adr &&
          data.totalOccupancy &&
          data.totalRevenue
        ) {
          setRevParAchieved(data.revPar);
          setADRAchieved(data.adr);
          setOCCAchieved(data.totalOccupancy);
          setREVAchieved(data.totalRevenue);
          setNoCardsData(false);
        } else {
          setNoCardsData(true);
        }

        // setting importance
        if (data.systemImportance || data.systemImportance === 0) {
          if (data.userImportance || data.userImportance === 0) {
            setImportance(data.userImportance);
            setEditableStatus(true);
            setSystemImportance(data.systemImportance);
          } else {
            setImportance(data.systemImportance);
            setEditableStatus(false);
          }
        } else {
          setImportance(0);
          setEditableStatus(false);
        }

        if (data.occProgress) {
          setOccupancyGraphData({
            Date: data.occProgress.creationDate,
            occupancy_changes: data.occProgress.cumulativeOcc,
            price: data.occProgress.adr,
          });
          setNoOccupancyGraphData(false);
        } else {
          setNoOccupancyGraphData(true);
        }

        // setting prices data for day prices
        const dummyRoomPrice = [];
        if (data.irevPerRoomType) {
          for (let i = 0; i < data.irevPerRoomType.length; i++) {
            const irevElement = data.irevPerRoomType[i];
            for (let j = 0; j < allV2Rooms.length; j++) {
              const roomElement = allV2Rooms[j];
              // adding room types from v2 to day room prices
              if (String(irevElement.parentRoomId) === roomElement.id) {
                dummyRoomPrice.push({
                  id: roomElement.id,
                  name: roomElement.name,
                  price: irevElement.iRevPrice,
                  minPrice: irevElement.minPrice,
                  maxPrice: irevElement.maxPrice,
                  fixedPrice: irevElement.fixedPrice,
                });
                if (roomElement.primary_room) {
                  roomPrice = irevElement.iRevPrice;
                }
              }
            }
          }
        } else {
          for (let j = 0; j < allV2Rooms.length; j++) {
            const roomElement = allV2Rooms[j];
            // adding room types from v2 to day room prices
            dummyRoomPrice.push({
              id: roomElement.id,
              name: roomElement.name,
              price: 0,
              minPrice: null,
              maxPrice: null,
              fixedPrice: null,
            });
          }
        }
        setDayRoomPrices(dummyRoomPrice);

        //reformatting market leaders Data
        if (data.marketLeaders && data.marketMedianPrice) {
          let competitorsData = {
            medianPrice: data.marketMedianPrice,
            roomPrice: roomPrice,
            compNames: [],
            compPrice: [],
          };

          for (let i = 0; i < data.marketLeaders.length; i++) {
            const competitor = data.marketLeaders[i];
            competitorsData.compNames.push(competitor.name);
            competitorsData.compPrice.push(competitor.price);
          }
          setCompetitorsData(competitorsData);
          setNoCompetitorsData(false);
        } else {
          setNoCompetitorsData(true);
        }

        // setting room Status Data
        if (data.occPerRoomType) {
          const dummyRoomStatus = [];
          for (let i = 0; i < data.occPerRoomType.length; i++) {
            const occElement = data.occPerRoomType[i];
            for (let j = 0; j < allV2Rooms.length; j++) {
              const roomElement = allV2Rooms[j];
              if (String(occElement.roomTypeId) === roomElement.id) {
                dummyRoomStatus.push({
                  name: roomElement.name,
                  value: occElement.value * 100,
                });
              }
            }
          }
          setDayRoomStatus(dummyRoomStatus);
          setNoDayRoomStatus(false);
        } else {
          setNoDayRoomStatus(true);
        }

        // Filtering Out Events Data for Current Day

        const dummyEventsData = [];
        for (let i = 0; i < allEventsData.length; i++) {
          const event = allEventsData[i];
          if (event.theDate === reformatDate(date)) {
            dummyEventsData.push({
              name: event.name,
              location: event.area,
            });
          }
        }
        setDayEventsData(dummyEventsData);

        //setting latest reservations Data
        if (data.latestReservations) {
          setLatestReservationsDataV2(data.latestReservations);
          setNoLatestReservationsData(false);
        } else {
          setNoLatestReservationsData(true);
        }
        setDayDataTrigger(true);
      } else {
        //setting triggers
        setDayDataTrigger(true);
        setNoOccupancyGraphData(true);
        setNoCompetitorsData(true);
        // setNoDayPriceEachRoom(true);
        setNoDayRoomStatus(true);
        setNoLatestReservationsData(true);
        setNoCardsData(true);

        // setting data
        const dummyRoomPrice = [];
        for (let j = 0; j < allV2Rooms.length; j++) {
          const roomElement = allV2Rooms[j];
          // adding room types from v2 to day room prices
          dummyRoomPrice.push({
            id: roomElement.id,
            name: roomElement.name,
            price: 0,
            minPrice: null,
            maxPrice: null,
            fixedPrice: null,
          });
        }
        setDayRoomPrices(dummyRoomPrice);
        setImportance("");
        setEditableStatus(false);
      }
    });
  };

  //Getting all events Data
  const getEventsData = async (db2, city) => {
    const docRef = doc(db2, "localEvents", city);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      setNoEventsData(true);
      setEventsDataTrigger(true);
    }
  };

  // Getting the Root Document and all Events for a property
  const getPropertyRootDocument = async (db2, propertyId) => {
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      String(propertyId)
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();

      //setting all data
      setAllRootData(data);
      //setting targets
      setADRTargets(data.targets.adr_targets);
      setOCCTargets(data.targets.occ_targets);
      setREVTargets(data.targets.revenue_targets);
      //setting all rooms
      setAllV2Rooms(data.roomtypes);
      setRoomCount(data.room_count);

      if (data.city) {
      }
      // getting events Data
      getEventsData(db2, data.city)
        .then((res) => {
          setAllEventsData(res.events);
          setEventsDataTrigger(true);
        })
        .catch((err) => {
          setNoEventsData(true);
          setEventsDataTrigger(true);
        });

      //setting First Time Data Trigger
      setRootDataTrigger(true);
    }
  };

  // stop loading when propertyStatus is false

  useEffect(() => {
    if (propertyStatus === false) {
      setDataUpdating(false);
      dispatch(OnMonthlyLoaderEnd());
      dispatch(OnSettingLoaderStart());
    }
  }, [propertyStatus]);
  //setting importance in DB
  useEffect(() => {
    if (propertyId != undefined) {
      updateImportance(propertyId, reformatDate(date), importance);
    }
  }, [importance, propertyStatus]);
  //Use effect calls

  useEffect(() => {
    // checking for expiration if idToken and get new one
    if (
      new Date().getTime() >
      new Date(localStorage.getItem("expirationDate")).getTime()
    ) {
      auth2.currentUser.getIdToken(/* forceRefresh */ true).then((response) => {
        localStorage.setItem("token", response);
        const expirationDate = new Date(new Date().getTime() + 3600000);
        localStorage.setItem("expirationDate", expirationDate);
      });
    }
    //Weather data
    getWeatherInfo(reqDate)
      .then((response) => {
        setWeather(response?.data.days[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date, propertyId, reqDate, propertyStatus]);

  // V2 UseEffect Calls
  useEffect(() => {
    // getting root data
    propertyId && date && getPropertyRootDocument(db2, propertyId);
  }, [propertyId]);

  useEffect(() => {
    setTargetImportance(
      Math.round(importance) === 0
        ? 1
        : Math.round(importance) === 10
        ? 9
        : Math.round(importance)
    );
  }, [importance]);

  useEffect(() => {
    // getting root data
    propertyId &&
      date &&
      (allEventsData.length > 0 || noEventData) &&
      getDailyViewDataV2(propertyId, db2, date);
  }, [date, propertyId, allEventsData,noEventData]);

  useEffect(() => {
    if (editable) {
      updateImportance(propertyId, reformatDate(date), importance);
    } else {
      unsetUserImportance(propertyId, reformatDate(date));
    }
  }, [editable]);

  useEffect(() => {
    if (!dynamicityEditable) {
      setDynamicity(3);
    }
  }, [dynamicityEditable]);

  useEffect(() => {
    if (dynamicity > 2) {
      unsetUserDynamicity(date);
    } else {
      if (dynamicity >= 0) {
        setUserDynamicity(dynamicityArray[dynamicity], date);
      } else {
        setDynamicity(0);
      }
    }
  }, [dynamicity]);

  useEffect(() => {
    setDisablePriceSend(false);
    propertyId && getUserDynamicity(propertyId, date);
    propertyId && getPriceSendInfo(propertyId, date);
  }, [date]);

  //Use effect calls end
  return (
    <div>
      {localStorage.getItem("token") !== null ? (
        <>
          {!eventsDataTrigger || !rootDataTrigger || !dayDataTrigger ? (
            <div className="loader-design">
              <div>
                <img
                  src="/images/loading.gif"
                  alt="competatorTableList"
                  width="350"
                />
              </div>
            </div>
          ) : propertyStatus ? (
            <>
              {importance === NaN ? (
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <Card className="calender_card_dailyview">
                    <Calendar
                      showNavigation={true}
                      next2Label={null}
                      prev2Label={null}
                      showNeighboringMonth={false}
                      onChange={onChangeDate}
                      value={date}
                      className="Dailycalendar"
                      maxDate={new Date()}
                      minDate={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1
                        )
                      }
                    />
                    <div className="calendarImp">
                      <p>Importance</p>
                      <div className="calender_update">
                        <>
                          <h3>{Math.round(importance * 10)}/100</h3>
                        </>
                        <div>
                          <IconButton
                            onClick={() => {
                              setImportance(
                                importance <= 10 && importance > 1
                                  ? importance - 1
                                  : importance
                              );
                            }}
                            className={
                              editable == true
                                ? "editable_true"
                                : "editable_false"
                            }
                            aria-label="upload picture"
                            component="span"
                            disabled={!editable}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setImportance(
                                importance < 10 ? importance + 1 : importance
                              );
                            }}
                            className={
                              editable ? "editable_true" : "editable_false"
                            }
                            aria-label="upload picture"
                            component="span"
                            disabled={!editable}
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </div>
                      <Tooltip
                        title={!editable ? "System Editing" : "User Editing"}
                        TransitionComponent={Fadee}
                        TransitionProps={{ timeout: 600 }}
                      >
                        <Switch
                          onChange={onChangeSwitch}
                          className="toggle_switch"
                          checked={editable}
                        />
                      </Tooltip>
                      <br /> <br />
                    </div>{" "}
                    <div className="calendarImp">
                      <p>Weather</p>

                      <div className="calender_update">
                        <>
                          <img
                            alt={weather.icon}
                            src={`/weathericons/${weather.icon}.svg`}
                            height="45"
                          />
                        </>
                        <>
                          {" "}
                          <h3 className="weather_div">
                            {weather.conditions} ,{" "}
                            {Math.round((weather.temp - 32) / 1.8)} °C{" "}
                          </h3>
                        </>
                      </div>

                      <br />
                    </div>
                  </Card>
                </Grid>
              ) : (
                <>
                  <FadeIn transitionDuration="1000">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={7} xl={8}>
                        {noCardsData ? (
                          <NoDataComponent />
                        ) : (
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={5} lg={6}>
                              <RevParCard
                                sideBar={props.sideBar}
                                revParAchieved={RevParAchieved}
                                revParTarget={
                                  ADRTargets[targetImportance] *
                                  (REVTargets[targetImportance] /
                                    ADRTargets[targetImportance] /
                                    roomCount)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={5} lg={6}>
                              <SellStatsCards
                                title={"Total Occupancy"}
                                amount={Math.round(OCCAchieved * 100)}
                                actualamount={Math.round(
                                  (REVTargets[targetImportance] /
                                    ADRTargets[targetImportance] /
                                    roomCount) *
                                    100
                                )}
                                value={Math.round(
                                  ((OCCAchieved * 100 -
                                    (REVTargets[targetImportance] /
                                      ADRTargets[targetImportance] /
                                      roomCount) *
                                      100) /
                                    ((REVTargets[targetImportance] /
                                      ADRTargets[targetImportance] /
                                      roomCount) *
                                      100)) *
                                    100
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={5} lg={6}>
                              <SellStatsCards
                                title={"Average Daily Rate"}
                                amount={`${Math.round(ADRAchieved)}`}
                                actualamount={Math.round(
                                  ADRTargets[targetImportance]
                                )}
                                value={Math.round(
                                  ((ADRAchieved -
                                    ADRTargets[targetImportance]) /
                                    ADRTargets[targetImportance]) *
                                    100
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={5} lg={6}>
                              <SellStatsCards
                                title={"Total Revenue"}
                                amount={`${Math.round(REVAchieved)}`}
                                actualamount={Math.round(
                                  REVTargets[targetImportance]
                                )}
                                value={Math.round(
                                  ((REVAchieved -
                                    REVTargets[targetImportance]) /
                                    REVTargets[targetImportance]) *
                                    100
                                )}
                              />
                            </Grid>
                          </Grid>
                        )}

                        {noDayPriceEachRoom ? (
                          <NoDataComponent />
                        ) : (
                          <DayPricesRoomType
                            dayPriceEachRoomV2={dayRoomPrices}
                            date={reformatDate(date)}
                            propertyId={propertyId}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={12} lg={4} xl={4}>
                        <Card className="calender_card_dailyview">
                          <Calendar
                            showNavigation={true}
                            next2Label={null}
                            prev2Label={null}
                            onChange={onChangeDate}
                            value={date}
                            className="Dailycalendar"
                            minDate={
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                1
                              )
                            }
                          />
                          <div className="calendarImp">
                            <div>
                              <div className="dynamicity-header-container">
                                <p className="dynamicity-title">Dynamicity</p>
                                <Switch
                                  onChange={() => {
                                    setDynamicityEditable(!dynamicityEditable);
                                    unsetUserDynamicity(date);
                                  }}
                                  className="toggle_switch"
                                  checked={dynamicityEditable}
                                />
                              </div>
                              <div className="dynamicity-values">
                                {dynamicityArray.map((dyn, index) => {
                                  return (
                                    <div
                                      onClick={() =>
                                        dynamicityEditable &&
                                        setDynamicity(index)
                                      }
                                      className={
                                        dynamicity === index
                                          ? "dynamicity-pill-selected"
                                          : "dynamicity-pill"
                                      }
                                    >
                                      {dyn}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div>
                              <div className="disable-price-send-header">
                                <p>Disable Price Send</p>
                                <Switch
                                  onChange={() => {
                                    setDisablePriceSend(!disablePriceSend);
                                    updatePriceSendInfo(
                                      propertyId,
                                      date,
                                      !disablePriceSend
                                    );
                                  }}
                                  className="toggle_switch"
                                  checked={!disablePriceSend}
                                />
                              </div>
                            </div>
                            <p>Importance</p>
                            <div className="calender_update">
                              <>
                                <h3>
                                  {Math.round(importance * 10)}/100
                                  <p>
                                    {(systemImportance ||
                                      systemImportance === 0) &&
                                      `(System ${Math.round(
                                        systemImportance * 10
                                      )}/100)`}
                                  </p>
                                </h3>
                              </>
                              <div>
                                <IconButton
                                  onClick={() => {
                                    setImportance(
                                      importance <= 100 && importance > 0.1
                                        ? importance - 0.1
                                        : importance
                                    );
                                  }}
                                  className={
                                    !editable
                                      ? "editable_true"
                                      : "editable_false"
                                  }
                                  aria-label="upload picture"
                                  component="span"
                                  disabled={!editable}
                                >
                                  <RemoveIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setStartUpdatingImport(true);
                                    setImportance(
                                      importance < 100
                                        ? importance + 0.1
                                        : importance
                                    );
                                  }}
                                  className={
                                    !editable
                                      ? "editable_true"
                                      : "editable_false"
                                  }
                                  aria-label="upload picture"
                                  component="span"
                                  disabled={!editable}
                                >
                                  <AddIcon />
                                </IconButton>
                              </div>
                            </div>
                            <Tooltip
                              title={
                                !editable ? "System Editing" : "User Editing"
                              }
                              TransitionComponent={Fadee}
                              TransitionProps={{ timeout: 600 }}
                            >
                              <Switch
                                onChange={onChangeSwitch}
                                className="toggle_switch"
                                checked={!editable}
                              />
                            </Tooltip>
                            <br /> <br />
                          </div>{" "}
                          <div className="calendarImp">
                            <p>Weather</p>
                            <div className="calender_update">
                              <>
                                <img
                                  alt={weather.icon}
                                  src={`/weathericons/${weather.icon}.svg`}
                                  height="45"
                                />
                              </>
                              <>
                                {" "}
                                <h3 className="weather_div">
                                  {weather.conditions} ,{" "}
                                  {Math.round((weather.temp - 32) / 1.8)} °C{" "}
                                </h3>
                              </>
                            </div>

                            <br />
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </FadeIn>
                  <div>
                    <Typography variant="h5" className="daily-view-h5">
                      <Tooltip
                        arrow
                        title="Occupancy for each roomtype"
                        classes={toolTipClasses}
                      >
                        <b className="dailyview_title_typo">Room Status</b>
                      </Tooltip>
                    </Typography>
                    {noDayRoomStatus ? (
                      <NoDataComponent />
                    ) : (
                      <RoomStatusTable
                        dataRoomStatus={dataRoomStatus}
                        dataRoomStatusV2={dayRoomStatus}
                      />
                    )}
                  </div>
                  <br />
                  <br />
                  <CompetetorsTable
                    competitorsData={competitorsData}
                    Date={reqDate}
                    propertyId={propertyId}
                    noData={noCompetitorsData}
                  />
                  <br />
                  <br />
                  <div>
                    <Typography variant="h5" className="daily-view-h5">
                      <Tooltip
                        arrow
                        title="Showing date-by-date occupancy status of current day"
                        classes={toolTipClasses}
                      >
                        <b className="dailyview_title_typo">
                          Occupance Progress
                        </b>
                      </Tooltip>
                    </Typography>
                    {noOccupancyGraphData ? (
                      <NoDataComponent />
                    ) : (
                      <Occupancegraph occupancyGraphData={occupancyGraphData} />
                    )}
                  </div>
                  <br />
                  <br />
                  {/* <div>
                    <Typography variant="h5" style={{ margin: "32px 0px" }}>
                      <b className="dailyview_title_typo">
                        Suggested Day Prices With Room Type
                      </b>
                    </Typography>
                    {noDayPriceEachRoom ? (
                      <NoDataComponent />
                    ) : (
                      <DayPricesRoomType dayPriceEachRoom={dayPriceEachRoom} />
                    )}
                  </div> */}
                  <div>
                    <Typography variant="h5" className="daily-view-h5">
                      <Tooltip
                        arrow
                        title="Local and global events"
                        classes={toolTipClasses}
                      >
                        <b className="dailyview_title_typo">Events</b>
                      </Tooltip>
                    </Typography>
                    {noEventData ? (
                      <NoDataComponent />
                    ) : (
                      <EventsTable eventsData={dayEventsData} />
                    )}
                  </div>
                  <br />
                  <br />
                  <div className="latest-reservation-section">
                    <Typography variant="h5">
                      <b className="dailyview_title_typo">
                        Latest Reservations
                      </b>
                    </Typography>
                    {noLatestReservationsData ? (
                      <NoDataComponent />
                    ) : (
                      <LatestReservationsTable
                        sideBar={props.sideBar}
                        latestReservationsData={latestReservationsDataV2}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <h3>Contact support to activate your Property</h3>
            </>
          )}
        </>
      ) : (
        history.push("/SignInPage")
      )}
    </div>
  );
}

DailyView.propTypes = {
  window: PropTypes.func,
};

export default DailyView;
