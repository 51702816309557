import { makeStyles } from "@material-ui/core"

export const useStylesEvent = makeStyles({
  table: {
    maxHeight: 370,
    minWidth: 450,
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
  tableRow: {
    border: 'none',
  },
  tablescroll: {
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
})
export const useStylesReservation = makeStyles({
  tablescroll: {
    '&::-webkit-scrollbar': {
      height: 5,
    },
    maxHeight: 490,
    '&:hover': {},
  },
  table: {
    backgroundColor: 'transparent',
  },
  tableRow: {
    '&:nth-child(even)': { backgroundColor: 'white !important' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  cellTypo: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    /* identical to box height */

    border: 0,

    color: '#000000',
  },
})