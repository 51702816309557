import React, { useCallback, useContext } from "react";

import { AppContext } from "../Context";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

export default function SecondStep() {
  const { formValues, handleChange, handleBack, handleNext } =
    useContext(AppContext);
  const { businessType } = formValues;
  const isError = useCallback(
    () =>
      Object.keys({ businessType }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, businessType]
  );

  return (
    <div style={{ maxWidth: "500px" }}>
      <Grid container spacing={2}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="businessType"
            value={businessType.value}
            onChange={handleChange}
          >
            <FormControlLabel value="hotel" control={<Radio />} label="hotel" />
            <FormControlLabel
              value="hotel chain"
              control={<Radio />}
              label="hotel chain"
            />
            <FormControlLabel value="pms" control={<Radio />} label="pms" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <div
        style={{
          display: "flex",
          marginTop: 30,
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ width: "45%", height: "45px" }}
        >
          Back
        </Button>

        <Button
          variant="contained"
          disabled={isError()}
          color="primary"
          onClick={!isError() ? handleNext : () => null}
          style={{ width: "45%", height: "45px" }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
