import { ACTION_TYPES } from './types'

const OnGetAllHotels = (hotels) => {
  return {
    type: ACTION_TYPES.getHotels,
    payload: hotels,
  }
}

export { OnGetAllHotels }
