import React, { useCallback, useContext } from "react";

import { AppContext } from "../Context";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({

  selectBorder: {
    "& .MuiOutlinedInput-root": {
   
      
        borderRadius: "8px",
        color:"#000000"
    
      
    },
"& .MuiOutlinedInput-input":{
  color:"#000000"
}
    
  },
});
export default function FirstStep() {
  const classes = useStyles();

  const { formValues, handleChange, handleNext, variant, margin } = useContext(
    AppContext
  );
  const { firstName, userPhone } = formValues;

  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({ firstName, userPhone }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, firstName, userPhone]
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
              className={classes.selectBorder}
            variant={variant}
            margin={margin}
            fullWidth
            label="Name"
            name="firstName"
            placeholder="Your name"
            value={firstName.value}
            onChange={handleChange}
            error={!!firstName.error}
            helperText={firstName.error}
            required={firstName.required}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
              className={classes.selectBorder}
            variant={variant}
            margin={margin}
            fullWidth
            label="Phone"
            name="userPhone"
            placeholder="Your Phone number"
           value={userPhone.value}
            onChange={handleChange}
            error={!!userPhone.error}
            helperText={userPhone.error}
            required={userPhone.required}
          />
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "center" }}
      >
        <Button
          variant="contained"
          disabled={isError()}
          color="primary"
          onClick={!isError() ? handleNext : () => null}
          style={{width:"100%", height:"45px"}}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
