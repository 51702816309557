import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { primaryColor } from '../../colors';
const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3),
    marginTop: 150
  },
  title: {
    marginTop: 30
  }
}))

export default function Success() {
  const classes = useStyles()
  return (
    <div className={classes.box}>
      <Typography variant="h2" align="center">
        Thank you!
      </Typography>
      <Typography component="p" align="center" className={classes.title}>
      The password has been emailed to you, please use it to <span><strong><a href="/SignInPage" style={{textDecoration:"none"}}>Sign In</a></strong></span>
      </Typography>
      <br/>
      <Typography component="p" align="center" className={classes.title}>
      Didn’t receive the password?<span><strong><a href="/" style={{textDecoration:"none"}}> Resend </a></strong></span>
      </Typography>
    </div>
  )
}
