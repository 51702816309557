import { useEffect, useState } from "react";

import PiGraphBi from "../../PiGraphBi";
import { Typography } from "@material-ui/core";

const Origin = ({paceReportOrigin}) => {
      const [data,setData] = useState([])
    const series = data?.map((da)=> da.percentage);
    const labels = data?.map((da)=> da.origin)
  
  
  useEffect(() => {
    setData(paceReportOrigin)
  },[paceReportOrigin])
  

  return (
    <div>
     <Typography className="mt-3 mb-3" variant="h5" align="center" style={{ fontWeight: 600 }}>
Origin
      </Typography>
     <PiGraphBi seriesData={series} labels={labels}/>
    </div>
  );
};

export default Origin;
