import "../Index.scss";
import "../dashboard.scss";

import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import FadeIn from "react-fade-in";
import { Grid, Button, TextField, Typography } from "@material-ui/core";

import { gettingHotelID, updateHotelID } from "./utils/hotelSeasonData";
import { updateBasePrice } from "./utils/settingApiCalls";
import { primaryColor } from "../../../colors";
import { getYearDates } from "./utils/utils";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { reformatDate } from "../monthlyPrices/utils/utils";
import { db2 } from "../../../Auth/firebase";

const HotelInfo = ({
  propertyId,
  basePriceValue,
  hotelID,
  disabledTrigger,
}) => {
  const [editHotelInfo, setEditHotelInfo] = useState(false);
  const [savingHotelInfo, setSavingHotelInfo] = useState(false);
  const [updatedID, setUpdatedID] = useState("");
  const [basePriceValueParent, setBasePriceValueParent] =
    useState(basePriceValue);
  const [hotelIDFromParent, setHotelIDFromParent] = useState(hotelID);

  useEffect(() => {
    setBasePriceValueParent(basePriceValue);
    setHotelIDFromParent(hotelID);
  }, [basePriceValue, hotelID]);

  const gettingBasePrice = (propertyId) => {
    const docRef = doc(db2, localStorage.getItem("pmsName"), propertyId);

    const unSubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const basePrice = doc.data().base_prices_irev[1];
        setBasePriceValueParent(parseInt(basePrice));
      }
    });
  };
  // getting Base price again after update
  const gettingBasePriceFunc = () => {
    gettingBasePrice(propertyId);
  };

  // updating hotel info
  const updateHotelInfo = () => {
    setEditHotelInfo(false);
    
    if (updatedID != "") {
      const updatingHotelIDFunc = async () => {
        await updateHotelID(propertyId, updatedID);
      };
      updatingHotelIDFunc();
    }
    if (basePriceValueParent != "") {
      const { currentDate, finalDate } = getYearDates();
      const dates = [reformatDate(currentDate), reformatDate(finalDate)];
      updateBasePrice(propertyId, basePriceValueParent, dates).then(() =>
        setSavingHotelInfo(false)
      );
    }
    let timer = setTimeout(() => {
      // getting hotel ID
      const gettingHotelIdFunc = async () => {
        const gettingHotelIDResponse = await gettingHotelID(propertyId);
        setHotelIDFromParent(gettingHotelIDResponse);
        setSavingHotelInfo(false);
      };

      gettingBasePriceFunc();

      // getting current season
      gettingHotelIdFunc();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <div>
      {" "}
      <FadeIn transitionDuration="4000">
        {savingHotelInfo ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25vh",
            }}
          >
            <div
              class="loading setting_title_typo"
              data-loading-text="Processing Data ..."
            ></div>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h5" style={{ margin: "32px 0px" }}>
                <b className="setting_title_typo">Update Hotel Info</b>
              </Typography>

              {editHotelInfo ? (
                <div>
                  <Button
                    variant="outlined"
                    startIcon={<DoneIcon />}
                    style={{
                      height: 50,
                      marginRight: 2,
                      backgroundColor: localStorage.getItem("primaryColor"),
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      updateHotelInfo();
                      setSavingHotelInfo(true);
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    style={{
                      height: 50,
                      marginRight: 2,
                      backgroundColor: localStorage.getItem("primaryColor"),
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setEditHotelInfo(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    height: "50px",
                    width: "70px",
                    marginRight: 2,
                    backgroundColor: "#e0e0e0",
                    borderRadius: "10px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    disabledTrigger && setEditHotelInfo(true);
                  }}
                >
                  <img
                    src="/images/edit.svg"
                    height="25"
                    width="70"
                    alt="edit_proportion"
                  />
                </div>
              )}
            </div>

            <Grid container spacing={2}>
              <>
                {" "}
                <Grid item xs={6}>
                  <TextField
                    id="outlined-full-width"
                    label="Base Price"
                    placeholder="Enter your base price"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    disabled={!editHotelInfo}
                    defaultValue={basePriceValueParent}
                    value={basePriceValueParent}
                    onChange={(e) => {
                      setBasePriceValueParent(e.target.value);
                    }}
                  />
                </Grid>
              </>
            </Grid>
          </>
        )}
      </FadeIn>
    </div>
  );
};

export default HotelInfo;
