import axios from "axios";
import { DefaultUrl } from "../../../BaseUrl";


// -------------------- assigning token to header -------------------
export const getProportions = async (propertyId) => {
  const result = await axios.post(`${DefaultUrl}/get_proportions`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: {
      propertyId: propertyId,
      pms_name: localStorage.getItem("pmsName"),
    },
  });

  return result;
};

export const updateBasePrice = async (propertyId, basePriceValue,dates) => {

  // await axios.post(`${DefaultUrl}/update_base_prices`, {
  //   method: "POST",
  //   headers: {
  //     Authorization: localStorage.getItem("token"),
  //   },
  //   body: {
  //     propertyId: propertyId,
  //     base_price: parseInt(basePriceValue),
  //     pms_name: localStorage.getItem("pmsName"),
  //   },
  // });
  await axios.post("https://publish-message-3cuixb5lia-ew.a.run.app/updateBasePrice", {
    topicName:"updateBasePrice",
    payload:{
      pmsId:"1",
      propertyId:propertyId,
      lowest_price:basePriceValue,
      dates:dates,
      isDateRange:true
    }
  });

  return true;
};

export const setPricingTable = async (dynamicityVal, potential, propertyId) => {
  const result = await axios.post(`${DefaultUrl}/set_pricing_table`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: {
      propertyId: propertyId,
      payload: [{ DYNAMICITY: dynamicityVal, POTENTIAL: potential }],
      pms_name: localStorage.getItem("pmsName"),
    },
  });
  return result;
};

export const getPricingTable = async (propertyId) => {
  const result = await axios.post(`${DefaultUrl}/get_pricing_table`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: {
      propertyId: propertyId,
      pms_name: localStorage.getItem("pmsName"),
    },
  });
  return result;
};


export const setProportionsApiCall = async (proportions, propertyId) => {
  await axios.post(`${DefaultUrl}/set_proportions`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: {
      room_types: proportions,
      propertyId: propertyId,
      pms_name: localStorage.getItem("pmsName"),
    },
  });

  return true;
};

export const getProportionsApiCall = async (propertyId) => {
  const result = await axios.post(`${DefaultUrl}/get_proportions`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: {
      propertyId: propertyId,
      pms_name: localStorage.getItem("pmsName"),
    },
  });

  return result;
};
