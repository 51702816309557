import { Button, CircularProgress, Grid, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getSelectedGroupData, updateDisplayNameAndPhone } from '../../utils'
import { toast } from 'react-toastify'
const UpdateProfile = () => {
  const [displayName, setDisplayName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const updateProfile = () => {
    setLoading(true)
    updateDisplayNameAndPhone(displayName, phoneNumber)
      .then((res) => {
        setLoading(false)
        toast.success('Profile updated successfully')
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error updating profile')
      })
  }

  useEffect(() => {
    getSelectedGroupData().then((res) => {
      const { displayName, phone_number } = res
      setDisplayName(displayName)
      setPhoneNumber(phone_number)
    })
  }, [])

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            className='mb-3'
            variant='outlined'
            fullWidth
            id='display-name'
            name='display-name'
            label='Display Name'
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className='mb-3'
            variant='outlined'
            fullWidth
            id='phone-number'
            name='phone-number'
            label='Phone Number'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            disabled={loading}
          />
        </Grid>
        <div style={{ margin: '0 auto' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              updateProfile()
            }}
            disabled={loading}
          >
            {loading && <CircularProgress size={20} />}
            {!loading && ' Update Profile'}
          </Button>
        </div>
      </Grid>
    </div>
  )
}

export default UpdateProfile
