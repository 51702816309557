import { Grid, TextField } from "@material-ui/core";
import React, { useCallback, useContext } from "react";

import { AppContext } from "../Context";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-root": {
     
      borderRadius: "8px",
      color: "#000000",
    },
    "& .MuiOutlinedInput-input": {
      color: "#000000",
    },
  },
});
export default function ThirdStep() {
  const classes = useStyles();

  const { formValues, handleNext, handleChange, handleBack, variant, margin } =
    useContext(AppContext);
  const {
    hotelName,
    totalRooms,
    hotelPhone,
    country,
    city,
    connectedPms,
    totalHotels,
    pmsName,
    totalLinkedProperties,
  } = formValues;


  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({
        hotelName,
        totalRooms,
        hotelPhone,
        country,
        city,
        connectedPms,
        totalHotels,
        pmsName,
        totalLinkedProperties,
      }).some((name) => formValues[name].error),
    [
      formValues,
      hotelName,
      totalRooms,
      hotelPhone,
      country,
      city,
      connectedPms,
      totalHotels,
      pmsName,
      totalLinkedProperties,
    ]
  );

  const handleSubmit = () => {
    // Remove unwanted properties from formValue object
    let form = {};

    Object.keys(formValues).map((name) => {
      form = {
        ...form,
        [name]: formValues[name].value,
      };
      return form;
    });
    // Do whatever with the values
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    fetch("http://127.0.0.1:8000/sign_up", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    // Show last component or success message
    handleNext();
  };

  return (
    <div style={{ maxWidth: "550px" }}>
      {formValues.businessType.value === "hotel" ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Hotel Name"
              name="hotelName"
              placeholder="Enter your hotelName"
              value={hotelName.value}
              onChange={handleChange}
              error={!!hotelName.error}
              helperText={hotelName.error}
              required={hotelName.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Total Rooms"
              name="totalRooms"
              placeholder="Enter your totalRooms"
              value={totalRooms.value}
              onChange={handleChange}
              error={!!totalRooms.error}
              helperText={totalRooms.error}
              required={totalRooms.required}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Connected PMS"
              name="connectedPms"
              placeholder="Enter connected PMS Name"
              value={connectedPms.value}
              onChange={handleChange}
              error={!!connectedPms.error}
              helperText={connectedPms.error}
              required={connectedPms.required}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Hotel Phone"
              name="hotelPhone"
              placeholder="Enter your hotelPhone"
              value={hotelPhone.value}
              onChange={handleChange}
              error={!!hotelPhone.error}
              helperText={hotelPhone.error}
              required={hotelPhone.required}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="City"
              name="city"
              placeholder="Enter your city"
              value={city.value}
              onChange={handleChange}
              error={!!city.error}
              helperText={city.error}
              required={city.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Country"
              name="country"
              placeholder="Enter your country"
              value={country.value}
              onChange={handleChange}
              error={!!country.error}
              helperText={country.error}
              required={country.required}
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      {formValues.businessType.value === "hotel chain" ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Total Hotels"
              name="totalHotels"
              placeholder="Enter connected Hotels"
              value={totalHotels.value}
              onChange={handleChange}
              error={!!totalHotels.error}
              helperText={totalHotels.error}
              required={totalHotels.required}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Connected PMS"
              name="connectedPms"
              placeholder="Enter connected PMS Name"
              value={connectedPms.value}
              onChange={handleChange}
              error={!!connectedPms.error}
              helperText={connectedPms.error}
              required={connectedPms.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="City"
              name="city"
              placeholder="Enter your city"
              value={city.value}
              onChange={handleChange}
              error={!!city.error}
              helperText={city.error}
              required={city.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Country"
              name="country"
              placeholder="Enter your country"
              value={country.value}
              onChange={handleChange}
              error={!!country.error}
              helperText={country.error}
              required={country.required}
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      {formValues.businessType.value === "pms" ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Enter PMS Name"
              name="pmsName"
              placeholder="Enter your PMS Name"
              value={pmsName.value}
              onChange={handleChange}
              error={!!pmsName.error}
              helperText={pmsName.error}
              required={pmsName.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Total Hotels"
              name="totalLinkedProperties"
              placeholder="Enter connected Hotels"
              value={totalLinkedProperties.value}
              onChange={handleChange}
              error={!!totalLinkedProperties.error}
              helperText={totalLinkedProperties.error}
              required={totalLinkedProperties.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="City"
              name="city"
              placeholder="Enter your city"
              value={city.value}
              onChange={handleChange}
              error={!!city.error}
              helperText={city.error}
              required={city.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.selectBorder}
              variant={variant}
              margin={margin}
              fullWidth
              label="Country"
              name="country"
              placeholder="Enter your country"
              value={country.value}
              onChange={handleChange}
              error={!!country.error}
              helperText={country.error}
              required={country.required}
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <div
         style={{ display: 'flex', marginTop: 30, justifyContent: 'space-between' }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{width:"45%", height:"45px"}}
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isError()}
          onClick={handleSubmit}
          style={{width:"45%", height:"45px"}}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
