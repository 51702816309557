import { ACTION_TYPES } from '../actions/types'
const initialState = {
  hotelList: [],
}

export const loadHotels = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.getHotels:
      return action.payload
    default:
      return state
  }
}
