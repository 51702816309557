import './Header.scss'

import { Grid, Hidden } from '@material-ui/core'
import React, { Component, useState } from 'react'
// import logo from '../../images/logo_new.png'
import Header from './Header'
import Paper from '@material-ui/core/Paper'
import ReactFlow from 'react-flow-renderer'
import { makeStyles } from '@material-ui/core/styles'

// Graph Starts

const style = { width: '720px', height: '620px' }
// const BasicGraph = () => (<ReactFlow elements={customElement} style={style}><Controls /></ReactFlow>);

const BasicGraph = () => {
  const [elements] = useState(customElement)
  const [zoomOnScroll] = useState(false)
  const [paneMoveable] = useState(false)

  return (
    <ReactFlow
      elements={elements}
      zoomOnScroll={zoomOnScroll}
      paneMoveable={paneMoveable}
      style={style}
    ></ReactFlow>
  )
}

const customElement = [
  {
    id: '1',
    type: 'input',
    draggable: false,
    paneMoveable: false,
    className: 'grow',
    style: {
      background: 'rgba(238, 238, 255, 1)',
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <div style={{ display: 'flex' }}>
            <img
              className='img-responsive'
              src='./images/HistoricData.png'
              alt='Data_cleaning'
              width='30px'
              height='30px'
              style={{ paddingTop: '10px', paddingRight: '10px' }}
            />

            <h3>
              Historic data provided by
              <br /> client
            </h3>
          </div>
          <ul
            className='ul'
            style={{
              display: 'block',
              textAlign: 'left',
              paddingLeft: '50px',
              fontWeight: '800',
              lineHeight: '14.5px',
            }}
          >
            <li>Reservations</li>
            <li>Prices</li>
            <li>Occupancy</li>
          </ul>
        </div>
      ),
    },
    position: { x: 0, y: -3 },
  },
  {
    id: '2',
    type: 'input',
    draggable: false,
    className: 'grow',
    style: {
      background: 'rgba(238, 238, 255, 1)',
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <div style={{ display: 'flex', margin: '0px', padding: '0px' }}>
            <img
              className='img-responsive'
              src='./images/ExternalData.png'
              alt='Data_cleaning'
              width='30px'
              height='30px'
              style={{ paddingTop: '15px', paddingRight: '10px' }}
            />
            <h3>
              External data gathered by
              <br /> Brainy
            </h3>
          </div>
          <ul
            className='ul'
            style={{
              display: 'block',
              textAlign: 'left',
              paddingLeft: '50px',
              fontWeight: '800',
              lineHeight: '14.5px',
            }}
          >
            <li>Market pricing and saturation</li>
            <li>Competetor statistics</li>
            <li>Events</li>
            <li>Flights and travel</li>
          </ul>
        </div>
      ),
    },
    position: { x: 510, y: -17 },
  },
  {
    className: 'grow',
    id: '3',
    draggable: false,
    style: {
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <img
            className='img-responsive'
            src='./images/Data_cleaning.png'
            alt='Data_cleaning'
            width='25px'
            height='25px'
            style={{ padding: '0px', animation: 'rotation 3s infinite linear' }}
          />
          <h3>Data cleaning & quality scoring</h3>
        </div>
      ),
    },
    position: { x: 265, y: 170 },
  },
  {
    id: '4',
    type: 'input',
    draggable: false,
    className: 'grow',
    style: {
      background: 'rgba(238, 238, 255, 1)',
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <img
            className='img-responsive'
            src='./images/FutureReservation.png'
            alt='Data_cleaning'
            width='30px'
            height='30px'
            style={{ padding: '5px' }}
          />
          <h3>Future reservation data</h3>
        </div>
      ),
    },
    position: { x: 286, y: 25 },
  },
  {
    className: 'grow',
    id: '5',
    draggable: false,
    style: {
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <img
            className='img-responsive'
            src='./images/BrainyEngine.png'
            alt='Data_cleaning'
            width='30px'
            height='30px'
            style={{ padding: '0px', animation: 'zoom 700ms infinite ease-in' }}
          />
          <h3>Brainy Intelligent Engine</h3>
        </div>
      ),
    },
    position: { x: 200, y: 280 },
  },
  {
    id: '6',
    type: 'output',
    draggable: false,
    className: 'grow',
    style: {
      background: 'rgba(98, 96, 239, 1)',
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#fff',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <img
            className='img-responsive'
            src='./images/DataAnalytics.png'
            alt='Data_cleaning'
            width='20px'
            height='20px'
            style={{ padding: '0px', animation: 'zoom 700ms infinite ease-in' }}
          />
          <h3>BI & Data Analytics</h3>
        </div>
      ),
    },
    position: { x: 380, y: 280 },
  },
  {
    id: '7',
    draggable: false,
    className: 'grow',
    style: {
      background: 'rgba(98, 96, 239, 1)',
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#fff',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <div style={{ display: 'flex' }}>
            <img
              className='img-responsive'
              src='./images/output.png'
              alt='Data_cleaning'
              width='40px'
              height='40px'
              style={{
                marginTop: '5px',
                padding: '10px',
                animation: 'rotation 2s infinite linear',
              }}
            />
            <h3>Output</h3>
          </div>
          <ul
            style={{
              display: 'block',
              textAlign: 'left',
              paddingLeft: '50px',
              fontWeight: '500',
              lineHeight: '18.5px',
            }}
          >
            <li>Price suggestion (% change)</li>
            <li>Recommendations</li>
            <li>Price directional change</li>
          </ul>
        </div>
      ),
    },
    position: { x: 183, y: 475 },
  },
  {
    id: '8',
    type: 'output',
    draggable: false,
    className: 'grow',
    style: {
      background: 'rgba(98, 96, 239, 1)',
      border: 'none',
      borderRadius: '12px',
      width: 'auto',
      maxWidth: '100%',
      color: '#fff',
      fontSize: '13px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div>
          <div style={{ display: 'flex' }}>
            <img
              className='img-responsive'
              src='./images/RealTimeUpdates.png'
              alt='Data_cleaning'
              width='30px'
              height='30px'
              style={{
                marginLeft: '62px',
                paddingLeft: '5px',
                animation: 'zoom 2s infinite ease-in',
              }}
            />
          </div>
          <h3>
            Real time updates on client’s
            <br /> hotel management system
          </h3>
        </div>
      ),
    },
    position: { x: 450, y: 490 },
  },
  {
    className: 'grow',
    id: '9',
    draggable: false,
    style: {
      background: 'rgba(238, 238, 255, 1)',
      border: 'solid rgba(98, 96, 239, 1)',
      borderRadius: '52px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div style={{ display: 'flex' }}>
          <img
            className='img-responsive'
            src='./images/Profile1.png'
            alt='Data_cleaning'
            width='25px'
            height='25px'
            style={{
              margin: '5px',
              marginTop: '12px',
              marginRight: '25px',
              animation: 'rotation 3s infinite linear',
            }}
          />

          <h3 style={{ paddingRight: '20px' }}>Profile 2</h3>
        </div>
      ),
    },
    position: { x: 205, y: 390 },
  },
  {
    id: '10',
    draggable: false,
    type: 'output',
    className: 'grow',
    style: {
      border: 'none',
      borderRadius: '52px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div style={{ display: 'flex' }}>
          <img
            className='img-responsive'
            src='./images/Profile2.png'
            alt='Data_cleaning'
            width='25px'
            height='25px'
            style={{ margin: '5px', marginTop: '12px', marginRight: '25px' }}
          />

          <h3 style={{ marginRight: '20px' }}>Profile 1</h3>
        </div>
      ),
    },
    position: { x: 10, y: 390 },
  },
  {
    id: '11',
    draggable: false,
    type: 'output',
    className: 'grow',
    style: {
      border: 'none',
      borderRadius: '52px',
      width: 'auto',
      maxWidth: '100%',
      color: '#454052',
      fontSize: '9px',
      boxShadow: '25px 25px 25px 0px rgba(0,0,0,.10)',
    },
    data: {
      label: (
        <div style={{ display: 'flex' }}>
          <img
            className='img-responsive'
            src='./images/Profile3.png'
            alt='Profile3'
            width='25px'
            height='25px'
            style={{ margin: '5px', marginTop: '12px', marginRight: '25px' }}
          />

          <h3 style={{ marginRight: '20px' }}>Profile 3</h3>
        </div>
      ),
    },
    position: { x: 410, y: 390 },
  },

  {
    id: 'e1-01',
    source: '1',
    target: '3',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-02',
    source: '2',
    target: '3',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-03',
    source: '4',
    target: '3',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-04',
    source: '3',
    target: '5',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-05',
    source: '3',
    target: '6',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-06',
    source: '5',
    target: '9',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-07',
    source: '5',
    target: '10',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-08',
    source: '5',
    target: '11',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-09',
    source: '9',
    target: '7',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
  {
    id: 'e1-10',
    source: '7',
    target: '8',
    arrowHeadType: 'arrowclosed',
    type: 'step',
    animated: true,
    style: { strokeWidth: '2' },
  },
]

// graph for mobile view
// const style1 = { width: "720px", height: "1060px" };
// const BasicGraph1 = () => {
//   const [elements1] = useState(customElement1);
//   const [zoomOnScroll1] = useState(false);
//   const [paneMoveable1] = useState(false);

//   return (
//     <ReactFlow
//       elements={elements1}
//       zoomOnScroll={zoomOnScroll1}
//       paneMoveable={paneMoveable1}
//       style={style1}
//     ></ReactFlow>
//   );
// };

// const customElement1 = [
//   {
//     id: "1",
//     type: "input",
//     draggable: false,
//     paneMoveable: false,
//     className: "grow",
//     style: {
//       background: "rgba(238, 238, 255, 1)",
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#454052",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <div style={{ display: "flex" }}>
//             <img
//               className="img-responsive"
//               src="./images/HistoricData.png"
//               alt="Data_cleaning"
//               width="30px"
//               height="30px"
//               style={{ paddingTop: "10px", paddingRight: "10px" }}
//             />

//             <h3>
//               Historic data provided by
//               <br /> client
//             </h3>
//           </div>
//           <ul
//             className="ul"
//             style={{
//               display: "block",
//               textAlign: "left",
//               paddingLeft: "50px",
//               fontWeight: "800",
//               lineHeight: "14.5px",
//             }}
//           >
//             <li>Reservations</li>
//             <li>Prices</li>
//             <li>Occupancy</li>
//           </ul>
//         </div>
//       ),
//     },
//     position: { x: 0, y: 15 },
//   },
//   {
//     id: "2",
//     type: "input",
//     draggable: false,
//     className: "grow",
//     style: {
//       background: "rgba(238, 238, 255, 1)",
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#454052",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <div style={{ display: "flex", margin: "0px", padding: "0px" }}>
//             <img
//               className="img-responsive"
//               src="./images/ExternalData.png"
//               alt="Data_cleaning"
//               width="30px"
//               height="30px"
//               style={{ paddingTop: "15px", paddingRight: "10px" }}
//             />
//             <h3>
//               External data gathered by
//               <br /> Brainy
//             </h3>
//           </div>
//           <ul
//             className="ul"
//             style={{
//               display: "block",
//               textAlign: "left",
//               paddingLeft: "50px",
//               fontWeight: "800",
//               lineHeight: "14.5px",
//             }}
//           >
//             <li>Market pricing and saturation</li>
//             <li>Competetor statistics</li>
//             <li>Events</li>
//             <li>Flights and travel</li>
//           </ul>
//         </div>
//       ),
//     },
//     position: { x: 215, y: 0 },
//   },
//   {
//     className: "grow",
//     id: "3",
//     draggable: false,
//     style: {
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#454052",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <img
//             className="img-responsive"
//             src="./images/Data_cleaning.png"
//             alt="Data_cleaning"
//             width="25px"
//             height="25px"
//             style={{ padding: "0px", animation: "rotation 3s infinite linear" }}
//           />
//           <h3>Data cleaning & quality scoring</h3>
//         </div>
//       ),
//     },
//     position: { x: 189, y: 285 },
//   },
//   {
//     id: "4",
//     type: "input",
//     draggable: false,
//     className: "grow",
//     style: {
//       background: "rgba(238, 238, 255, 1)",
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#454052",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <img
//             className="img-responsive"
//             src="./images/FutureReservation.png"
//             alt="Data_cleaning"
//             width="30px"
//             height="30px"
//             style={{ padding: "5px" }}
//           />
//           <h3>Future reservation data</h3>
//         </div>
//       ),
//     },
//     position: { x: 0, y: 270 },
//   },
//   {
//     className: "grow",
//     id: "5",
//     draggable: false,
//     style: {
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#454052",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <img
//             className="img-responsive"
//             src="./images/BrainyEngine.png"
//             alt="Data_cleaning"
//             width="30px"
//             height="30px"
//             style={{ padding: "0px", animation: "zoom 700ms infinite ease-in" }}
//           />
//           <h3>Brainy Intelligent Engine</h3>
//         </div>
//       ),
//     },
//     position: { x: 208, y: 450 },
//   },
//   {
//     id: "6",
//     type: "output",
//     draggable: false,
//     className: "grow",
//     style: {
//       background: "rgba(98, 96, 239, 1)",
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#fff",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <img
//             className="img-responsive"
//             src="./images/DataAnalytics.png"
//             alt="Data_cleaning"
//             width="20px"
//             height="20px"
//             style={{ padding: "0px", animation: "zoom 700ms infinite ease-in" }}
//           />
//           <h3>BI & Data Analytics</h3>
//         </div>
//       ),
//     },
//     position: { x: 10, y: 450 },
//   },
//   {
//     id: "7",
//     draggable: false,
//     className: "grow",
//     style: {
//       background: "rgba(98, 96, 239, 1)",
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#fff",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <div style={{ display: "flex" }}>
//             <img
//               className="img-responsive"
//               src="./images/output.png"
//               alt="Data_cleaning"
//               width="40px"
//               height="40px"
//               style={{
//                 marginTop: "5px",
//                 padding: "10px",
//                 animation: "rotation 2s infinite linear",
//               }}
//             />
//             <h3>Output</h3>
//           </div>
//           <ul
//             style={{
//               display: "block",
//               textAlign: "left",
//               paddingLeft: "50px",
//               fontWeight: "500",
//               lineHeight: "18.5px",
//             }}
//           >
//             <li>Price suggestion (% change)</li>
//             <li>Recommendations</li>
//             <li>Price directional change</li>
//           </ul>
//         </div>
//       ),
//     },
//     position: { x: 188, y: 700 },
//   },
//   {
//     id: "8",
//     type: "output",
//     draggable: false,
//     className: "grow",
//     style: {
//       background: "rgba(98, 96, 239, 1)",
//       border: "none",
//       borderRadius: "12px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#fff",
//       fontSize: "13px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div>
//           <div style={{ display: "flex" }}>
//             <img
//               className="img-responsive"
//               src="./images/RealTimeUpdates.png"
//               alt="Data_cleaning"
//               width="30px"
//               height="30px"
//               style={{
//                 marginLeft: "62px",
//                 paddingLeft: "5px",
//                 animation: "zoom 2s infinite ease-in",
//               }}
//             />
//           </div>
//           <h3>
//             Real time updates on client’s
//             <br /> hotel management system
//           </h3>
//         </div>
//       ),
//     },
//     position: { x: 193, y: 890 },
//   },
//   {
//     className: "grow",
//     id: "9",
//     draggable: false,
//     style: {
//       background: "rgba(238, 238, 255, 1)",
//       border: "solid rgba(98, 96, 239, 1)",
//       borderRadius: "52px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#454052",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div style={{ display: "flex" }}>
//           <img
//             className="img-responsive"
//             src="./images/Profile1.png"
//             alt="Data_cleaning"
//             width="25px"
//             height="25px"
//             style={{
//               margin: "5px",
//               marginTop: "12px",
//               marginRight: "25px",
//               animation: "rotation 3s infinite linear",
//             }}
//           />

//           <h3 style={{ paddingRight: "20px" }}>Profile 2</h3>
//         </div>
//       ),
//     },
//     position: { x: 210, y: 595 },
//   },
//   {
//     id: "10",
//     draggable: false,
//     type: "output",
//     className: "grow",
//     style: {
//       border: "none",
//       borderRadius: "52px",
//       width: "auto",
//       maxWidth: "100%",
//       color: "#454052",
//       fontSize: "9px",
//       boxShadow: "25px 25px 25px 0px rgba(0,0,0,.10)",
//     },
//     data: {
//       label: (
//         <div style={{ display: "flex" }}>
//           <img
//             className="img-responsive"
//             src="./images/Profile2.png"
//             alt="Data_cleaning"
//             width="25px"
//             height="25px"
//             style={{ margin: "5px", marginTop: "12px", marginRight: "25px" }}
//           />

//           <h3 style={{ marginRight: "20px" }}>Profile 1</h3>
//         </div>
//       ),
//     },
//     position: { x: 15, y: 600 },
//   },

//   {
//     id: "e1-1",
//     source: "1",
//     target: "3",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-2",
//     source: "2",
//     target: "3",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-3",
//     source: "4",
//     target: "3",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-4",
//     source: "3",
//     target: "5",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-5",
//     source: "3",
//     target: "6",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-6",
//     source: "5",
//     target: "9",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-7",
//     source: "5",
//     target: "10",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-8",
//     source: "5",
//     target: "11",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-9",
//     source: "9",
//     target: "7",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
//   {
//     id: "e1-10",
//     source: "7",
//     target: "8",
//     arrowHeadType: "arrowclosed",
//     type: "step",
//     animated: true,
//     style: { strokeWidth: "2" },
//   },
// ];
// Graph Ends
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: theme.spacing(5),
      marginRight: theme.spacing(9),
      borderRadius: '30px',
      width: '270.02px',
      height: '270.58px',
    },
  },
  root_one: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: theme.spacing(12),
      borderRadius: '30px',
      width: 'auto',
      height: 'auto',
    },
  },
  root_two: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0),
      borderRadius: '61px',
      width: 'auto',
      height: 'auto',
    },
  },
  root_three: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: theme.spacing(5),
      marginRight: theme.spacing(10),
      borderRadius: '30px',
      width: '370.02px',
      height: 'auto',
    },
  },
  root_foot: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    '& > *': {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(20),
      paddingLeft: theme.spacing(80),
      borderRadius: '30px',
      width: '1510px',
      height: '270.58px',
    },
  },
}))

class Image extends Component {
  constructor(props) {
    super(props)
    this.state = { matches: window.matchMedia('(min-width: 830px)').matches }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 830px)').addListener(handler)
  }
  render() {
    return (
      <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid item md={12} lg={6}>
          <Paper elevation={0}>
            <div
              style={{
                marginTop: '30px',
                fontFamily: 'Plus Jakarta Sans',
                width: 'auto',
                height: 'auto',
              }}
            >
              <h1
                style={{
                  fontSize: '45px',
                  fontWeight: '1000',
                  lineHeight: '56.7px',
                  textShadow: '1.5px 0px 0px',
                }}
              >
                <span style={{ color: "#6260EF" }}>BrainyRMS</span>{' '}
                <strong>
                  {' '}
                  - The next
                  <br /> generation platform for
                  <br /> hospitality revenue <br />
                  management
                </strong>
              </h1>
              <div
                style={{
                  marginTop: '22.24px',
                  fontSize: '20px',
                  fontWeight: '700',
                  lineHeight: '32.5px',
                  paddingRight: '100px',
                }}
              >
                <p>
                  Brainy RMS is a programmable revenue management platform
                  <br /> that gives your company complete control over how, when
                  <br /> and what you deploy.
                </p>
                <p>
                  Find the{' '}
                  <span style={{ color: "#6260EF" }}>
                    documentation, sample code,
                  </span>{' '}
                  and <span style={{ color: "#6260EF" }}>developer tools</span>
                  <br /> needed to tailor the revenue management platform to
                  your
                  <br /> unique needs
                </p>
              </div>

              <div
                className='A'
                style={{ marginTop: '28.25px', fontWeight: '700' }}
              >
                <button style={{ marginRight: '15px', marginBottom: '15px' }}>
                  <a
                    href='/SignInPage'
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    Sign In
                  </a>
                </button>
                <button>
                  <a
                    href='/SignUp'
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    Sign Up
                  </a>
                </button>
              </div>
            </div>
          </Paper>
        </Grid>
        <Hidden mdDown>
          <Grid item lg={6}>
            <Paper
              elevation={0}
              style={{
                paddingTop: '50px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '10px',
              }}
            >
              <img
                className='img-responsive'
                src='./images/HeroImage.png'
                alt='HeroImage'
                width='100%'
                maxWidth='100%'
              />
            </Paper>
          </Grid>
        </Hidden>
      </Grid>
    )
  }
}

class Goal extends Component {
  constructor(props) {
    super(props)
    this.state = { matches: window.matchMedia('(min-width: 830px)').matches }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 830px)').addListener(handler)
  }
  render() {
    return (
      <div>
        {this.state.matches && (
          <div style={{ marginTop: '100px' }}>
            <Paper
              elevation={0}
              style={{ width: 'auto', height: 'auto', maxWidth: '100%' }}
            >
              <h3
                style={{
                  fontSize: '17px',
                  color: 'rgba(98, 96, 239, 1)',
                  paddingBottom: '5px',
                }}
              >
                <span>
                  {' '}
                  <img
                    className='img-responsive'
                    src='./images/arrow.png'
                    alt='arrow'
                  />{' '}
                </span>
                Our Goal
              </h3>
              <h1
                style={{
                  fontSize: '40px',
                  fontWeight: '800',
                  marginTop: '0px',
                  lineHeight: '55.7px',
                  textShadow: '1px 0px 0px',
                }}
              >
                A technology - first approach to data <br />
                analysis and big data
              </h1>
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  lineHeight: '32.5px',
                  paddingTop: '10px',
                  paddingRight: '100px',
                }}
              >
                <p>
                  Through the integration of state-of-the-art A.I technology and
                  machine-learning capabilities, Brainy RMS analyzes, organizes,
                  and interprets big data through a series of optimized profiles
                  that rate risk differently to optimize rate and inventory
                  strategies as well as qualitative outputs.
                </p>
              </div>
            </Paper>
            <Grid container>
              <Grid item sm={12} md={6} lg={3}>
                <Paper
                  elevation={5}
                  style={{
                    textAlign: 'center',
                    paddingTop: '20px',
                    boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                    marginRight: '20px',
                    width: '270px',
                    height: '290px',
                    borderRadius: '30px',
                    marginTop: '20px',
                  }}
                >
                  <img
                    className='img-responsive'
                    src='./images/pms_external_scrapping.png'
                    alt='pms_external_scrapping'
                  />
                  <h5
                    style={{
                      fontWeight: 'bolder',
                      paddingTop: '10px',
                      textShadow: '0.2px 0px 0px',
                    }}
                  >
                    PMS & External
                    <br /> Scraping
                  </h5>
                  <ul
                    className='ul'
                    style={{
                      display: 'block',
                      textAlign: 'left',
                      paddingLeft: '60px',
                      fontWeight: '500',
                      lineHeight: '26px',
                    }}
                  >
                    <li>Performance Data</li>
                    <li>Competetive Rates</li>
                    <li>Market Rates</li>
                    <li>Events</li>
                    <li>Flights</li>
                  </ul>
                </Paper>
              </Grid>{' '}
              <Grid item sm={12} md={6} lg={3}>
                <Paper
                  elevation={5}
                  style={{
                    textAlign: 'center',
                    paddingTop: '20px',
                    boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                    marginRight: '20px',
                    width: '270px',
                    borderRadius: '30px',
                    marginTop: '20px',
                    height: '290px',
                  }}
                >
                  <img
                    className='img-responsive'
                    src='./images/Data_cleaning.png'
                    alt='Data_cleaning'
                  />
                  <h5
                    style={{
                      fontWeight: 'bolder',
                      paddingTop: '10px',
                      textShadow: '0.2px 0px 0px',
                    }}
                  >
                    Data Cleaning
                  </h5>
                  <ul
                    className='ul'
                    style={{
                      display: 'block',
                      textAlign: 'left',
                      paddingLeft: '60px',
                      fontWeight: '500',
                      lineHeight: '26px',
                    }}
                  >
                    <br />
                    <li>Risk profiles</li>
                    <li>Algorithms</li>
                    <li>Artificial Intelligence</li>
                  </ul>
                </Paper>
              </Grid>{' '}
              <Grid item sm={12} md={6} lg={3}>
                <Paper
                  elevation={5}
                  style={{
                    textAlign: 'center',
                    paddingTop: '20px',
                    boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                    marginRight: '20px',
                    width: '270px',
                    borderRadius: '30px',
                    marginTop: '20px',
                    height: '290px',
                  }}
                >
                  <img
                    className='img-responsive'
                    src='./images/data_analysis.png'
                    alt='data_analysis'
                  />
                  <h5
                    style={{
                      fontWeight: 'bolder',
                      paddingTop: '10px',
                      textShadow: '0.2px 0px 0px',
                    }}
                  >
                    Data Analysis
                  </h5>
                  <ul
                    className='ul'
                    style={{
                      display: 'block',
                      textAlign: 'left',
                      paddingLeft: '65px',
                      fontWeight: '500',
                      lineHeight: '26px',
                    }}
                  >
                    <li>Performance Data</li>
                    <li>Competetive Rates</li>
                    <li>Market Rates</li>
                    <li>Events</li>
                    <li>Flights</li>
                  </ul>
                </Paper>
              </Grid>{' '}
              <Grid item sm={12} md={6} lg={3}>
                <Paper
                  elevation={5}
                  style={{
                    textAlign: 'center',
                    paddingTop: '20px',
                    boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                    marginRight: '20px',
                    width: '270px',
                    borderRadius: '30px',
                    marginTop: '20px',
                    height: '290px',
                  }}
                >
                  <img
                    className='img-responsive'
                    src='./images/output.png'
                    alt='output'
                  />
                  <h5
                    style={{
                      fontWeight: 'bolder',
                      paddingTop: '10px',
                      textShadow: '0.2px 0px 0px',
                    }}
                  >
                    Output - Qualitative & <br />
                    Quantitative
                  </h5>
                  <ul
                    className='ul'
                    style={{
                      display: 'block',
                      textAlign: 'left',
                      paddingLeft: '50px',
                      fontWeight: '500',
                      lineHeight: '26px',
                    }}
                  >
                    <li>Rate potential</li>
                    <li>Inventory management</li>
                    <li>Booking window</li>
                    <li>Minimum stay</li>
                  </ul>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        {!this.state.matches && (
          <div
            style={{
              marginLeft: '25px',
              marginTop: '144px',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Paper
              elevation={0}
              style={{ width: 'auto', height: 'auto', maxWidth: '100%' }}
            >
              <h3
                style={{
                  fontSize: '17px',
                  color: 'rgba(98, 96, 239, 1)',
                  paddingBottom: '0px',
                }}
              >
                <span>
                  {' '}
                  <img
                    className='img-responsive'
                    src='./images/arrow.png'
                    alt='arrow'
                  />{' '}
                </span>
                Our Goal
              </h3>
              <h1
                style={{
                  fontSize: '32px',
                  fontWeight: '800',
                  marginTop: '0px',
                  lineHeight: '40.7px',
                  paddingRight: '50px',
                  textShadow: '1.3px 0px 0px',
                }}
              >
                A technology - first approach to data analysis and big data
              </h1>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '26.5px',
                  paddingTop: '20px',
                  paddingRight: '30px',
                }}
              >
                <p>
                  Through the integration of state-of-the-art A.I technology and
                  machine-learning capabilities, Brainy RMS analyzes, organizes,
                  and interprets big data through a series of optimized profiles
                  that rate risk differently to optimize rate and inventory
                  strategies as well as qualitative outputs.
                </p>
              </div>
            </Paper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '10px',
                flexWrap: 'wrap',
                marginLeft: '0px',
              }}
            >
              <Paper
                elevation={5}
                style={{
                  textAlign: 'center',
                  paddingTop: '20px',
                  boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                  marginRight: '20px',
                  width: '270px',
                  height: '270px',
                  borderRadius: '30px',
                  marginTop: '30px',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/pms_external_scrapping.png'
                  alt='pms_external_scrapping'
                />
                <h5 style={{ fontWeight: 'bolder', paddingTop: '10px' }}>
                  PMS & External
                  <br /> Scraping
                </h5>
                <ul
                  className='ul'
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    paddingLeft: '60px',
                    fontWeight: '500',
                    lineHeight: '26px',
                  }}
                >
                  <li>Performance Data</li>
                  <li>Competetive Rates</li>
                  <li>Market Rates</li>
                  <li>Events</li>
                  <li>Flights</li>
                </ul>
              </Paper>
              <Paper
                elevation={5}
                style={{
                  textAlign: 'center',
                  paddingTop: '20px',
                  boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                  marginRight: '20px',
                  width: '270px',
                  height: '270px',
                  borderRadius: '30px',
                  marginTop: '30px',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/Data_cleaning.png'
                  alt='Data_cleaning'
                />
                <h5 style={{ fontWeight: 'bolder', paddingTop: '10px' }}>
                  Data Cleaning
                </h5>
                <ul
                  className='ul'
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    paddingLeft: '60px',
                    fontWeight: '500',
                    lineHeight: '26px',
                  }}
                >
                  <br />
                  <li>Risk profiles</li>
                  <li>Algorithms</li>
                  <li>Artificial Intelligence</li>
                </ul>
              </Paper>
              <Paper
                elevation={5}
                style={{
                  textAlign: 'center',
                  paddingTop: '20px',
                  boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                  marginRight: '20px',
                  width: '270px',
                  height: '270px',
                  borderRadius: '30px',
                  marginTop: '30px',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/data_analysis.png'
                  alt='data_analysis'
                />
                <h5 style={{ fontWeight: 'bolder', paddingTop: '10px' }}>
                  Data Analysis
                </h5>
                <ul
                  className='ul'
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    paddingLeft: '65px',
                    fontWeight: '500',
                    lineHeight: '26px',
                  }}
                >
                  <li>Performance Data</li>
                  <li>Competetive Rates</li>
                  <li>Market Rates</li>
                  <li>Events</li>
                  <li>Flights</li>
                </ul>
              </Paper>
              <Paper
                elevation={5}
                style={{
                  textAlign: 'center',
                  paddingTop: '20px',
                  boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                  marginRight: '20px',
                  width: '270px',
                  height: '270px',
                  borderRadius: '30px',
                  marginTop: '30px',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/output.png'
                  alt='output'
                />
                <h5 style={{ fontWeight: 'bolder', paddingTop: '10px' }}>
                  Output - Qualitative & <br />
                  Quantitative
                </h5>
                <ul
                  className='ul'
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    paddingLeft: '50px',
                    fontWeight: '500',
                    lineHeight: '26px',
                  }}
                >
                  <li>Rate potential</li>
                  <li>Inventory management</li>
                  <li>Booking window</li>
                  <li>Minimum stay</li>
                </ul>
              </Paper>
            </div>
          </div>
        )}
      </div>
    )
  }
}

class Map extends Component {
  constructor(props) {
    super(props)
    this.state = { matches: window.matchMedia('(min-width: 900px)').matches }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 900px)').addListener(handler)
  }
  render() {
    return (
      <div>
        <div
          id='HowItWorks'
          style={{
            margin: 0,
            padding: '0%',
            marginTop: '130px',
            width: '100%',
            paddingTop: '100px',
            background: 'rgba(247, 248, 255, 1)',
          }}
        >
          <div
            className='content'
            style={{ background: 'rgba(247, 248, 255, 1)' }}
          >
            <Paper
              elevation={0}
              style={{
                textAlign: 'left',
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                paddingBottom: '40px',
                background: 'rgba(247, 248, 255, 1)',
              }}
            >
              <h3
                style={{
                  fontSize: '18px',
                  color: 'rgba(98, 96, 239, 1)',
                  paddingBottom: '5px',
                }}
              >
                <span>
                  {' '}
                  <img
                    className='img-responsive'
                    src='./images/arrow.png'
                    alt='arrow'
                  />{' '}
                </span>
                How it works
              </h3>
              <h1
                style={{
                  fontSize: '45px',
                  fontWeight: '800',
                  marginTop: '0px',

                  lineHeight: '60.7px',
                  textShadow: '1px 0px 0px',
                }}
              >
                BrainyRMS analyzes <br /> organizes& interprets big <br />
                data
              </h1>
            </Paper>

            <Paper
              elevation={0}
              style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',

                width: 'auto',

                background: 'rgba(247, 248, 255, 1)',
                fontSize: '12px',
              }}
              className='container1'
            >
              <BasicGraph />
            </Paper>
          </div>
        </div>
      </div>
    )
  }
}

class Model extends Component {
  constructor(props) {
    super(props)
    this.state = { matches: window.matchMedia('(min-width: 830px)').matches }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 830px)').addListener(handler)
  }
  render() {
    return (
      <div>
        <div
          style={{
            marginTop: '200px',
            display: 'grid',
            marginBottom: '200px',
          }}
        >
          <Paper
            elevation={0}
            style={{ width: 'auto', height: 'auto', maxWidth: '100%' }}
          >
            <h3
              style={{
                fontSize: '18px',
                color: 'rgba(98, 96, 239, 1)',
                paddingBottom: '5px',
              }}
            >
              <span>
                {' '}
                <img
                  className='img-responsive'
                  src='./images/arrow.png'
                  alt='arrow'
                />{' '}
              </span>
              Our Business Model
            </h3>
            <h1
              style={{
                fontSize: '40px',
                fontWeight: '800',
                marginTop: '0px',
                lineHeight: '76.7px',
                textShadow: '1px 0px 0px',
              }}
            >
              We help in creating value for our clients
            </h1>
          </Paper>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '10px',
              flexWrap: 'wrap',
            }}
          >
            <Grid item xs={12} md={4}>
              <Paper
                elevation={10}
                style={{
                  textAlign: 'center',
                  paddingTop: '20px',
                  Overflow: 'hidden',
                  boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                  marginRight: '20px',
                  width: '350px',
                  height: '300px',
                  borderRadius: '30px',
                  marginTop: '30px',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/Scalability.png'
                  alt='Scalability'
                />
                <h5
                  style={{
                    fontWeight: 'bolder',
                    paddingTop: '10px',
                    textShadow: '0.2px 0px 0px',
                  }}
                >
                  Scalablility
                </h5>
                <p
                  style={{
                    textAlign: 'left',
                    paddingLeft: '70px',
                    paddingRight: '70px',
                    paddingTop: '10px',
                    fontWeight: '500',
                    lineHeight: '24.5px',
                    display: 'grid',
                  }}
                >
                  The platform is suitable for any size, Structure, from small
                  guesthouses to Hotels with hundreds of rooms.
                </p>
              </Paper>
            </Grid>{' '}
            <Grid item xs={12} md={4}>
              <Paper
                elevation={10}
                style={{
                  textAlign: 'center',
                  paddingTop: '20px',
                  Overflow: 'hidden',
                  boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                  marginRight: '20px',
                  width: '350px',
                  borderRadius: '30px',
                  marginTop: '30px',
                  height: '300px',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/PlugnPlay.png'
                  alt='PlugnPlay'
                />
                <h5
                  style={{
                    fontWeight: 'bolder',
                    paddingTop: '10px',
                    textShadow: '0.2px 0px 0px',
                  }}
                >
                  Plug & Play
                </h5>
                <p
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    paddingLeft: '70px',
                    paddingRight: '50px',
                    paddingTop: '10px',
                    fontWeight: '500',
                    lineHeight: '24.5px',
                  }}
                >
                  Our API-first approach makes Integration seamless and
                  hassle-free For all major PMS and Channel Managers on the
                  market today.
                </p>
              </Paper>
            </Grid>{' '}
            <Grid item xs={12} md={4}>
              <Paper
                elevation={10}
                style={{
                  textAlign: 'center',
                  paddingTop: '20px',
                  Overflow: 'hidden',
                  boxShadow: ' 0px 20px 60px rgba(0, 0, 0, 0.17)',
                  marginRight: '20px',
                  width: '350px',
                  borderRadius: '30px',
                  marginTop: '30px',
                  height: '300px',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/PayasYougo.png'
                  alt='PayasYougo'
                />
                <h5
                  style={{
                    fontWeight: 'bolder',
                    paddingTop: '10px',
                    textShadow: '0.2px 0px 0px',
                  }}
                >
                  Pay as you go
                </h5>
                <p
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    paddingLeft: '70px',
                    paddingRight: '50px',
                    paddingTop: '10px',
                    fontWeight: '500',
                    lineHeight: '24.5px',
                  }}
                >
                  Billing structure is lean, flexible and Transparent. You pay
                  based on Occupied room volume, which means you’re only charged
                  for rooms that make you money.
                </p>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = { matches: window.matchMedia('(min-width: 750px)').matches }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 750px)').addListener(handler)
  }
  render() {
    return (
      <div>
        {this.state.matches && (
          <div
            id='ContactUs'
            style={{
              display: 'grid',
              marginBottom: '100px',
              backgroundImage: 'url(./images/bg_desktop.png)',
              backgroundSize: '1500px',
            }}
          >
            <div
              style={{
                marginTop: '150px',
                marginLeft: '60px',
                marginBottom: '150px',
                display: 'grid',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Paper
                  elevation={10}
                  style={{
                    textAlign: 'left',
                    marginLeft: '0px',
                    marginRight: '50px',
                    borderRadius: '61px',
                  }}
                >
                  <Paper
                    style={{
                      height: '212.05px',
                      backgroundColor: "#6260EF",
                      borderTopLeftRadius: '61px',
                      borderTopRightRadius: '61px',
                      paddingTop: '34px',
                      paddingLeft: '62.27px',
                      color: 'white',
                    }}
                  >
                    <h4
                      style={{
                        paddingBottom: '15px',
                        textShadow: '1px 0px 0px',
                      }}
                    >
                      Contact Us
                    </h4>
                    <h2>Get in touch</h2>
                    <p
                      style={{
                        fontSize: '20px',
                        paddingTop: '10px',
                        paddingRight: '40px',
                      }}
                    >
                      Feel free to reach out incase of any queries or feedback
                    </p>
                  </Paper>
                  <div
                    style={{
                      paddingLeft: '62.27px',
                      paddingTop: '26.3px',
                      paddingRight: '82.27px',
                    }}
                  >
                    <h4>BRAINY - S.R.L. SEMPLIFICATA</h4>
                    <br />
                    <p style={{ fontSize: '18px' }}>
                      <strong style={{ paddingRight: '52px' }}>Address</strong>
                      ROMA (RM) VIA DI S CROCE IN <br />
                      <span style={{ paddingLeft: '120px' }}>
                        GERUSALEMME 63 CAP 00185
                      </span>
                    </p>
                    <p style={{ fontSize: '18px' }}>
                      <strong style={{ paddingRight: '79px' }}>Email</strong>
                      pietro.gabbuti@brainyrms.com
                    </p>
                    <p style={{ fontSize: '18px' }}>
                      <strong style={{ paddingRight: '95px' }}>VAT</strong>
                      16067241006
                    </p>
                    <p style={{ fontSize: '18px' }}>
                      <strong style={{ paddingRight: '90px' }}>PEC</strong>
                      brainy@legalmail.it
                    </p>
                    <br />
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        )}
        {!this.state.matches && (
          <div
            id='ContactUs'
            style={{
              marginTop: '100px',
              flexShrink: '0',
              backgroundImage: 'url(./images/bg_desktop.png)',
              backgroundSize: '600px',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginBottom: '90px',
            }}
          >
            <div
              style={{
                marginTop: '150px',
                marginLeft: '20px',
                marginBottom: '150px',
                display: 'grid',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Paper
                  elevation={10}
                  style={{
                    textAlign: 'left',
                    marginLeft: '0px',
                    marginRight: '0px',
                    borderRadius: '61px',
                  }}
                >
                  <Paper
                    style={{
                      height: '212.05px',
                      backgroundColor: "#6260EF",
                      borderTopLeftRadius: '61px',
                      borderTopRightRadius: '61px',
                      paddingTop: '34px',
                      paddingLeft: '60.27px',
                      color: 'white',
                    }}
                  >
                    <h4 style={{ paddingBottom: '15px' }}>Contact Us</h4>
                    <h2>Get in touch</h2>
                    <p style={{ fontSize: '20px', paddingTop: '10px' }}>
                      Feel free to reach out incase of any <br />
                      queries or feedback
                    </p>
                  </Paper>
                  <div
                    style={{
                      paddingLeft: '62.27px',
                      paddingTop: '16.3px',
                      paddingRight: '82.27px',
                    }}
                  >
                    <h4>BRAINY - S.R.L. SEMPLIFICATA</h4>
                    <br />
                    <p style={{ fontSize: '15px' }}>
                      <strong style={{ paddingRight: '52px' }}>Address</strong>
                      ROMA (RM) VIA DI S CROCE IN <br />
                      <span style={{ paddingLeft: '110px' }}>
                        GERUSALEMME 63 CAP 00185
                      </span>
                    </p>
                    <p style={{ fontSize: '15px' }}>
                      <strong style={{ paddingRight: '69px' }}>Email</strong>
                      pietro.gabbuti@brainyrms.com
                    </p>
                    <p style={{ fontSize: '15px' }}>
                      <strong style={{ paddingRight: '79px' }}>VAT</strong>
                      16067241006
                    </p>
                    <p style={{ fontSize: '15px' }}>
                      <strong style={{ paddingRight: '75px' }}>PEC</strong>
                      brainy@legalmail.it
                    </p>
                    <br />
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = { matches: window.matchMedia('(min-width: 869px)').matches }
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 869px)').addListener(handler)
  }
  render() {
    return (
      <div>
        {this.state.matches && (
          <div
            style={{ display: 'flex', flexWrap: 'wrap', padding: '50px 0px ' }}
          >
            <Paper
              elevation={0}
              style={{
                background: 'rgba(247, 248, 255, 1)',
                textAlign: 'left',
                borderRadius: '0',
                borderRight: '3px solid #E6E6E6',
                paddingRight: '30px',
                height: '300px',
                width: '290px',
                color: '#7E7E7E',
              }}
            >
              <div
                style={{
                  fontFamily: 'Plus Jakarta Sans',
                  width: 'auto',
                  height: 'auto',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/logo.png'
                  alt='logo'
                  width='170px'
                  height='100%'
                  style={{
                    marginLeft: '10px',
                    paddingBottom: '40px',
                    paddingTop: '25px',
                  }}
                />
                <p style={{ marginLeft: '20px', fontSize: '15px' }}>
                  <strong>Address</strong>
                  <br />
                  ROMA (RM) VIA DI S CROCE IN GERUSALEMME 63 CAP 00185
                </p>
                <p style={{ marginLeft: '20px', fontSize: '15px' }}>
                  <strong>Email</strong>
                  <br />
                  pietro.gabbuti@brainyrms.com
                </p>
              </div>
            </Paper>
            <Paper
              elevation={0}
              style={{
                background: 'rgba(247, 248, 255, 1)',
                textAlign: 'center',
                marginLeft: '0px',
                display: 'flex',
              }}
            >
              <p
                style={{
                  padding: '30px',
                  fontSize: '15px',
                  paddingTop: '50px',
                  lineHeight: '50px',
                  fontWeight: '800',
                  paddingLeft: '100px',
                }}
              >
                <span>
                  {' '}
                  <a
                    href='/LandingPage#HowItWorks'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    How it Works
                  </a>
                </span>
                <br />
                <span>
                  <a
                    href='/LandingPage#Pricing'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Pricing
                  </a>
                </span>
                <br />
                <span>
                  {' '}
                  <a
                    href='/LandingPage#ContactUs'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Contact Us
                  </a>
                </span>
              </p>

              <p
                style={{
                  padding: '30px',
                  fontSize: '15px',
                  paddingTop: '50px',
                  lineHeight: '50px',
                  fontWeight: '800',
                  paddingLeft: '30px',
                }}
              >
                <span>
                  <a
                    href='/Doc'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Documentation
                  </a>
                </span>
                <br />
                <span>
                  <a
                    href='/PrivacyPolicy'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Privacy Policy
                  </a>
                </span>
                <br />
                <span>
                  {' '}
                  <a
                    href='/SignInPage'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Sign In
                  </a>
                </span>
              </p>

              <p
                style={{
                  padding: '30px',
                  fontSize: '15px',
                  paddingTop: '50px',
                  lineHeight: '50px',
                  fontWeight: '800',
                  paddingLeft: '30px',
                }}
              >
                <span>
                  <a
                    href='/SignUp'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Sign Up
                  </a>
                </span>
              </p>
            </Paper>
          </div>
        )}
        {!this.state.matches && (
          <div style={{ marginTop: '100px' }}>
            <Paper
              elevation={0}
              style={{
                background: 'rgba(247, 248, 255, 1)',
                textAlign: 'left',
                borderRadius: '0',
                borderBottom: '3px solid #E6E6E6',
                paddingRight: '30px',
                height: '300px',
                width: '290px',
                color: '#7E7E7E',
              }}
            >
              <div
                style={{
                  fontFamily: 'Plus Jakarta Sans',
                  width: 'auto',
                  height: 'auto',
                }}
              >
                <img
                  className='img-responsive'
                  src='./images/logo.png'
                  alt='logo'
                  width='170px'
                  height='100%'
                  style={{
                    marginLeft: '57px',
                    paddingBottom: '40px',
                    paddingTop: '25px',
                  }}
                />
                <p style={{ marginLeft: '50px', fontSize: '15px' }}>
                  <strong>Address</strong>
                  <br />
                  ROMA (RM) VIA DI S CROCE IN GERUSALEMME 63 CAP 00185
                </p>
                <p style={{ marginLeft: '50px', fontSize: '15px' }}>
                  <strong>Email</strong>
                  <br />
                  pietro.gabbuti@brainyrms.com
                </p>
              </div>
            </Paper>
            <Paper
              elevation={0}
              style={{
                background: 'rgba(247, 248, 255, 1)',
                textAlign: 'center',
                marginLeft: '0px',
                display: 'flex',
              }}
            >
              <p
                style={{
                  padding: '30px',
                  fontSize: '15px',
                  paddingTop: '50px',
                  lineHeight: '50px',
                  fontWeight: '800',
                  paddingLeft: '100px',
                }}
              >
                <span>
                  {' '}
                  <a
                    href='/LandingPage#HowItWorks'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    How it Works
                  </a>
                </span>
                <br />
                <span>
                  <a
                    href='/LandingPage#Pricing'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Pricing
                  </a>
                </span>
                <br />
                <span>
                  {' '}
                  <a
                    href='/LandingPage#ContactUs'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Contact Us
                  </a>
                </span>
              </p>
            </Paper>
            <Paper
              elevation={0}
              style={{
                background: 'rgba(247, 248, 255, 1)',
                textAlign: 'center',
                marginLeft: '0px',
                display: 'flex',
              }}
            >
              <p
                style={{
                  padding: '30px',
                  fontSize: '15px',
                  paddingTop: '50px',
                  lineHeight: '50px',
                  fontWeight: '800',
                  paddingLeft: '90px',
                }}
              >
                <span>
                  <a
                    href='/Doc'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Documentation
                  </a>
                </span>
                <br />
                <span>
                  <a
                    href='/PrivacyPolicy'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Privacy Policy
                  </a>
                </span>
                <br />
                <span>
                  {' '}
                  <a
                    href='/SignInPage'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Sign In
                  </a>
                </span>
              </p>
            </Paper>
            <Paper
              elevation={0}
              style={{
                background: 'rgba(247, 248, 255, 1)',
                textAlign: 'center',
                marginLeft: '0px',
                display: 'flex',
              }}
            >
              <p
                style={{
                  padding: '30px',
                  fontSize: '15px',
                  paddingTop: '50px',
                  lineHeight: '50px',
                  fontWeight: '800',
                  paddingLeft: '115px',
                }}
              >
                <span>
                  <a
                    href='/SignUp'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Sign Up
                  </a>
                </span>
              </p>
            </Paper>
          </div>
        )}
      </div>
    )
  }
}

function LandingPage() {
  const classes = useStyles()


  return (
    <div
      style={{ maxWidth: '1800px', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <Header />
      <div className='content'>
        <div className={classes.root_one}>
          <Image />
        </div>
      </div>
      <div className='content'>
        <Goal />
      </div>

      <Map />
      <div className='content'>
        <Model />{' '}
      </div>

      <div className='content'>
        <Contact />
      </div>

      <div
        className='Footer'
        style={{
          background: 'rgba(247, 248, 255, 1)',
          display: 'flex',
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
        }}
      >
        <Footer />
      </div>
    </div>
  )
}

export default LandingPage
