import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5map from '@amcharts/amcharts5/map'
import { useEffect, useLayoutEffect, useState } from 'react'
import axios from 'axios'
import { DefaultUrl } from '../../BaseUrl'
import { Typography } from '@material-ui/core'

const RevenueTargets = ({ propertyStatus, propertyId }) => {
  const [loading, setLoading] = useState(true)
  const [revenueData, setRevenueData] = useState([])

  useEffect(() => {
    if (propertyId !== undefined) {
      axios
        .post(`${DefaultUrl}/revenue/targets`, {
          method: 'POST',
          headers: {
            Authorization: localStorage.getItem('token'),
          },
          body: {
            propertyId: propertyId,
            pms_name: localStorage.getItem('pmsName'),
          },
        })
        .then((response) => {
          const obj = response.data.payload
          Object.assign(obj, {
            date: obj.Date,
            open: obj.actual_revenue,
            close: obj.generated_revenue,
          })
          delete obj.Date
          delete obj.actual_revenue
          delete obj.generated_revenue
          var result = obj[Object.keys(obj)[0]].reduce((a, b, i) => {
            var newObj = {}
            Object.keys(obj).forEach(function (item) {
              newObj[item] = obj[item][i]
            })
            return a.concat(newObj)
          }, [])
          setRevenueData(result)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }, [propertyId])

  useEffect(() => {
    if (!loading && propertyStatus) {
      let root = am5.Root.new('chartdiv')
      root.setThemes([am5themes_Animated.new(root)])
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          pinchZoomX: true,
        })
      )
      chart.get('colors').set('step', 5)
      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
        })
      )

      cursor.lineY.set('visible', false)
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'day', count: 1 },
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      )

      let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'open',
          openValueYField: 'close',
          valueXField: 'date',
          stroke: root.interfaceColors.get('positive'),
          fill: root.interfaceColors.get('positive'),
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}',
          }),
        })
      )

      series1.fills.template.setAll({
        fillOpacity: 0.6,
        visible: true,
      })
      series1.events.once('datavalidated', function (ev) {
        ev.target
          .get('xAxis')
          // zoom to first month in date range
          .zoomToDates(
            new Date(revenueData[0].date),
            new Date(revenueData[30].date)
          )
      })
      let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'close',
          valueXField: 'date',
          stroke: root.interfaceColors.get('negative'),
          fill: root.interfaceColors.get('negative'),
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}',
          }),
        })
      )
      chart.set(
        'scrollbarX',
        am5.Scrollbar.new(root, {
          orientation: 'horizontal',
        })
      )

      revenueData.forEach((item) => {
        item.date = new Date(item.date).getTime()
      })
      let data = revenueData
      series1.data.setAll(data)
      series2.data.setAll(data)
      let i = 0
      let baseInterval = xAxis.get('baseInterval')
      let baseDuration = xAxis.baseDuration()
      let rangeDataItem

      am5.array.each(series1.dataItems, function (s1DataItem) {
        let s1PreviousDataItem
        let s2PreviousDataItem

        let s2DataItem = series2.dataItems[i]

        if (i > 0) {
          s1PreviousDataItem = series1.dataItems[i - 1]
          s2PreviousDataItem = series2.dataItems[i - 1]
        }

        let startTime = am5.time
          .round(
            new Date(s1DataItem.get('valueX')),
            baseInterval.timeUnit,
            baseInterval.count
          )
          .getTime()
        if (s1PreviousDataItem && s2PreviousDataItem) {
          let x0 =
            am5.time
              .round(
                new Date(s1PreviousDataItem.get('valueX')),
                baseInterval.timeUnit,
                baseInterval.count
              )
              .getTime() +
            baseDuration / 2
          let y01 = s1PreviousDataItem.get('valueY')
          let y02 = s2PreviousDataItem.get('valueY')

          let x1 = startTime + baseDuration / 2
          let y11 = s1DataItem.get('valueY')
          let y12 = s2DataItem.get('valueY')

          let intersection = getLineIntersection(
            { x: x0, y: y01 },
            { x: x1, y: y11 },
            { x: x0, y: y02 },
            { x: x1, y: y12 }
          )

          startTime = Math.round(intersection.x)
        }
        if (s2DataItem.get('valueY') > s1DataItem.get('valueY')) {
          if (!rangeDataItem) {
            rangeDataItem = xAxis.makeDataItem({})
            let range = series1.createAxisRange(rangeDataItem)
            rangeDataItem.set('value', startTime)
            range.fills.template.setAll({
              fill: series2.get('fill'),
              fillOpacity: 0.6,
              visible: true,
            })
            range.strokes.template.setAll({
              stroke: series1.get('stroke'),
              strokeWidth: 1,
            })
          }
        } else {
          if (rangeDataItem) {
            rangeDataItem.set('endValue', startTime)
          }

          rangeDataItem = undefined
        }
        // end if last
        if (i == series1.dataItems.length - 1) {
          if (rangeDataItem) {
            rangeDataItem.set(
              'endValue',
              s1DataItem.get('valueX') + baseDuration / 2
            )
            rangeDataItem = undefined
          }
        }

        i++
      })
      series1.appear(1000)
      series2.appear(1000)
      chart.appear(1000, 100)

      function getLineIntersection(pointA1, pointA2, pointB1, pointB2) {
        let x =
          ((pointA1.x * pointA2.y - pointA2.x * pointA1.y) *
            (pointB1.x - pointB2.x) -
            (pointA1.x - pointA2.x) *
              (pointB1.x * pointB2.y - pointB1.y * pointB2.x)) /
          ((pointA1.x - pointA2.x) * (pointB1.y - pointB2.y) -
            (pointA1.y - pointA2.y) * (pointB1.x - pointB2.x))
        let y =
          ((pointA1.x * pointA2.y - pointA2.x * pointA1.y) *
            (pointB1.y - pointB2.y) -
            (pointA1.y - pointA2.y) *
              (pointB1.x * pointB2.y - pointB1.y * pointB2.x)) /
          ((pointA1.x - pointA2.x) * (pointB1.y - pointB2.y) -
            (pointA1.y - pointA2.y) * (pointB1.x - pointB2.x))
        return { x: x, y: y }
      }

      return () => {
        root.dispose()
      }
    }
  }, [propertyId, propertyStatus, revenueData])

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <img
            src='/images/loading.gif'
            alt='competatorTableList'
            width='350'
          />
        </div>
      ) : (
        <>
          {propertyStatus && !loading ? (
            <>
              <Typography variant='h5' style={{ margin: '32px 0px' }}>
                <b className='dailyview_title_typo'>Revenue Targets</b>
              </Typography>
              <div
                id='chartdiv'
                style={{ width: '100%', height: '500px' }}
              ></div>
            </>
          ) : (
            <>
              <h3>Contact support to activate your Property</h3>
            </>
          )}
        </>
      )}
    </>
  )
}

export default RevenueTargets
