import "../Index.scss";
import "../dashboard.scss";
import { Card1, Header } from "../styles";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import FadeIn from "react-fade-in";
import Fadee from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import { Grid } from "@material-ui/core";
import {
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Avatar,
  Switch,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import { updatingImportance } from "../dailyview/utils/getSetImportance";
import { useHistory } from "react-router-dom";
import { useStylesCards } from "./muiStyles";
import { formatInEuropeanNoSymbol } from "../../utils";
import { onSnapshot, doc } from "firebase/firestore";
import { reformatDate } from "./utils/utils";
import { db2 } from "../../../Auth/firebase";

const today = moment().format("D");
function Cards({
  propertyId,
  apiData,
  room,
  totalRooms,
  firMonth,
  roomIds,
  city,
}) {
  const history = useHistory();
  const [roomFromParent, setRoomFromParent] = React.useState(totalRooms[0]);
  const [totalRoomsFromParent, settotalRoomsFromParent] = useState([]);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(1 + new Date().getMonth());
  const [loading, setLoading] = useState(true);
  const [firMonthFromParent, setFirMonthFromParent] = useState(0);
  const [editingImportance, setEditingImportance] = useState(false);
  const [importanceValue, setImportanceValue] = useState(0);
  const currentYear = new Date().getFullYear();
  const nextYear = new Date().getFullYear() + 1;

  // V2 states
  const [allCalendarData, setAllCalendarData] = useState([]);
  const [finalV2Calendar, setFinalV2Calendar] = useState([]);
  const [firstFetchArray, setFirstFetchArray] = useState([]);
  const [allEventsData, setAllEventsData] = useState([]);
  const Days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thrusday",
    "Friday",
    "Saturday",
  ];
  const placeholderCalendarData = [
    {
      iRevPrice: 0,
      parentRoomId: 397354,
    },
    {
      parentRoomId: 0,
      iRevPrice: 0,
    },
    {
      iRevPrice: 0,
      parentRoomId: 397357,
    },
    {
      iRevPrice: 0,
      parentRoomId: 397356,
    },
    {
      iRevPrice: 0,
      parentRoomId: 397358,
    },
    {
      parentRoomId: 397355,
      iRevPrice: 0,
    },
    {
      parentRoomId: 456159,
      iRevPrice: 0,
    },
  ];

  const classes = useStylesCards();

  // dropdown value change handlers
  const handleChangeRoom = (event) => {
    setRoomFromParent(event.target.value);
  };
  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };
  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  // update click handler
  const handleUpdateClick = () => {
    setLoading(true);
    getMonthlyCalendarDataV2(propertyId, year, month);
  };

  // function to break down the reformatted calendar array into weeks
  const reformatCalendarArray = (array) => {
    const chunkSize = 7;
    let dummy = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      dummy.push(chunk);
    }
    return dummy;
  };

  // this function adds leading zeros if the date is single digit
  function addLeadingZeros(date) {
    if (String(date).length < 2) {
      return "0" + date;
    } else {
      return date;
    }
  }

  // Getting the events Data
  function getEventsData(city, month) {
    const docRef = doc(db2, "localEvents", city);
    const unSub = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const events = docSnap.get("events");
        setAllEventsData(events);
      }
    });
  }

  // filtering events data
  function filterEventsData(events, month) {
    const reformattedMonth = addLeadingZeros(month);
    const monthEvents = [];
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (reformattedMonth === event.theDate.split("-")[1]) {
        monthEvents.push(event);
      }
    }
    return monthEvents;
  }

  // filter data by room name
  function filterByRoomType(data, roomId) {
    const reformattedCalendar = [];
    for (let i = 0; i < data.length; i++) {
      const dayData = data[i];
      if (i === 0) {
        /*
        getting the day index of the selected date in the week
         0 is for sunday and 6 is for saturday
        */
        const dayInWeekIndex = new Date(year, month - 1, i + 1).getDay();
        // Adding padding values to calendar start
        for (let i = 0; i < dayInWeekIndex; i++) {
          /* padding values are added based on the number of the first day in the week
           for example if it is wednesday, the day number is 3, then 3 values are added as paddings */
          reformattedCalendar.push({
            importanceData: "",
            date: "",
            eventName: [],
            roomOcc: "",
            suggestedPrice: "",
            competitorPrice: "",
          });
        }
        reformattedCalendar.push({
          importanceData: dayData.importanceData,
          date: dayData.date,
          eventName: dayData.eventName,
          roomOcc: dayData[roomId]?.roomOcc,
          dayNum: i + 1,
          suggestedPrice: dayData[roomId]?.suggestedPrice,
          competitorPrice: dayData[roomId]?.competitorPrice,
        });
      } else {
        reformattedCalendar.push({
          importanceData: dayData.importanceData,
          date: dayData.date,
          eventName: dayData.eventName,
          roomOcc: dayData[roomId]?.roomOcc,
          dayNum: i + 1,
          suggestedPrice: dayData[roomId]?.suggestedPrice,
          competitorPrice: dayData[roomId]?.competitorPrice,
        });
      }
    }
    return reformattedCalendar;
  }

  /*
  This Function Fetches and reformats all the data 
  from the days collection for the select month
  */

  function getMonthlyCalendarDataV2(propertyId, year, month) {
    setLoading(true);
    const daysInMonth = new Date(year, month, 0).getDate();
    setFirstFetchArray([]);
    for (let i = 0; i < daysInMonth; i++) {
      // reformats the date to YYYY-MM-DD
      const date = reformatDate(new Date(year, month - 1, i + 1));
      const docRef = doc(
        db2,
        "octorate",
        String(propertyId),
        "days",
        String(date)
      );

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        //Checking if the document exists in the firebase
        if (docSnap.exists()) {
          const mainData = [];
          const priceData = docSnap.get("irevPerRoomType");
          const occData = docSnap.get("occPerRoomType");
          const competitorPrice = docSnap.get("marketMedianPrice");
          const importance =
            docSnap.get("userImportance") || docSnap.get("systemImportance");
          const editable = docSnap.get("userImportance") ? true : false;

          //adding the occupancy data per room type
          try {
            for (let i = 0; i < priceData.length; i++) {
              const priceElement = priceData[i];
              for (let j = 0; j < occData.length; j++) {
                const occElement = occData[j];
                if (occElement.roomTypeId === priceElement.parentRoomId) {
                  mainData.push({
                    parentRoomId: priceElement.parentRoomId || 0,
                    iRevPrice: priceElement.iRevPrice || 0,
                    occData: occElement.value * 100 || 0,
                    competitorPrice: competitorPrice || 0,
                  });
                }
              }
            }
            mainData.date = date;
            mainData.importance = importance;
            mainData.editable = editable;

            setFirstFetchArray((firstFetchArray) => [
              ...firstFetchArray,
              mainData,
            ]);
          } catch (error) {
            setFirstFetchArray((firstFetchArray) => [
              ...firstFetchArray,
              placeholderCalendarData,
            ]);
          }
        } else {
          setFirstFetchArray((firstFetchArray) => [
            ...firstFetchArray,
            placeholderCalendarData,
          ]);
        }
      });
    }
  }

  //useEffect calls

  useEffect(() => {
    settotalRoomsFromParent(totalRooms);
    setFirMonthFromParent(firMonth);
  }, [apiData, room, totalRooms, firMonth]);

  useEffect(() => {
    totalRooms &&
      propertyId &&
      getMonthlyCalendarDataV2(propertyId, year, month);
  }, [totalRooms, propertyId]);

  useEffect(() => {
    if (firstFetchArray.length === new Date(year, month, 0).getDate()) {
      setAllCalendarData([]);
      const reformattedData = [];
      for (let i = 0; i < firstFetchArray.length; i++) {
        const dayData = firstFetchArray[i];
        let dummyData = {};
        dummyData.importanceData = {
          importance: dayData.importance,
          editable: dayData.editable,
        }; // will be present inside the data afterwards
        dummyData.eventName = [];
        dummyData.dayNum = i + 1;
        dummyData.date = dayData.date;

        // setting room type data as roomId for key and its data for value
        for (let j = 0; j < firstFetchArray[i].length; j++) {
          const element = firstFetchArray[i][j];
          dummyData[`${element.parentRoomId}`] = {
            suggestedPrice: element.iRevPrice,
            roomOcc: Math.round(element.occData),
            // Actual competitor price will be added once available
            competitorPrice: element.competitorPrice,
          };
        }

        // adding data to the allCalendar Data dummy array
        setAllCalendarData((allCalendarData) => [
          ...allCalendarData,
          dummyData,
        ]);
      }
    }
    // case where onSnapshot adds the updated data in the array
    if (firstFetchArray.length > new Date(year, month, 0).getDate()) {
      // getting the last element added
      const lastElement = firstFetchArray[firstFetchArray.length - 1];
      firstFetchArray.pop(); // removing the last element from the array
      // replacing the data to the correct date
      setFirstFetchArray(
        firstFetchArray.map((data) => {
          if (data.date === lastElement.date) {
            return lastElement;
          } else {
            return data;
          }
        })
      );
    }
  }, [firstFetchArray]);

  useEffect(() => {
    // getting the events of a particular city
    getEventsData(city);
  }, [city]);

  useEffect(() => {
    // checking if all the reformatted data is present in all calendar data
    if (allCalendarData.length === new Date(year, month, 0).getDate()) {
      const allDataWithEvents = [];
      // filtered data for the current month
      const filteredEventsData = filterEventsData(allEventsData, month);
      // adding that data to all calendar array according to the date
      for (let i = 0; i < allCalendarData.length; i++) {
        const dayData = allCalendarData[i];
        for (let j = 0; j < filteredEventsData.length; j++) {
          const event = filteredEventsData[j];
          if (dayData.date === event.theDate) {
            dayData.eventName.push(event);
          }
        }
        allDataWithEvents.push(dayData);
      }
      // data filtered by roomType and divided into weeks.
      const allReformatted = reformatCalendarArray(
        filterByRoomType(
          allDataWithEvents,
          roomIds[totalRooms.indexOf(roomFromParent)]
        )
      );
      // setting the final Data
      setFinalV2Calendar(allReformatted);
      setLoading(false);
    }
  }, [allCalendarData, roomFromParent, month, allEventsData]);

  const calenderAfterUpdateCall = () => {
    getMonthlyCalendarDataV2(propertyId, year, month);
  };

  // changing switch

  const onChangeSwitch = (entry) => {
    setLoading(true); // update

    const reqDate = entry.Date;
    const importance = entry.importanceData.Importance;
    const editable = !entry.importanceData.Editable;
    const updatingImportanceFunc = async () => {
      await updatingImportance(propertyId, reqDate, importance, editable);
    };

    updatingImportanceFunc();

    let timer3 = setTimeout(() => {
      calenderAfterUpdateCall();
    }, 200);
    return () => {
      clearTimeout(timer3);
    };
  };
  //--------------

  //update importance
  const updateImportance = (entry) => {
    setLoading(true);

    const reqDate = entry.Date;
    const editable = entry.importanceData.Editable;
    const updatingImportanceFunc = async () => {
      await updatingImportance(propertyId, reqDate, importanceValue, editable);
    };

    updatingImportanceFunc();
    calenderAfterUpdateCall();
  };

  return (
    <div>
      <>
        <FadeIn transitionDuration="1000">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.selectBorder}
              >
                <InputLabel id="month" className="select_label">
                  Month
                </InputLabel>
                <Select
                  MenuProps={{ disableScrollLock: false }}
                  labelId="month"
                  id="month"
                  defaultValue={month}
                  label="month"
                  onChange={handleChangeMonth}
                  className="cardsForm"
                >
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.selectBorder}
              >
                <InputLabel id="year" className="select_label">
                  Year
                </InputLabel>
                <Select
                  MenuProps={{ disableScrollLock: false }}
                  labelId="year"
                  id="year"
                  defaultValue={year}
                  label="year"
                  onChange={handleChangeYear}
                  className="cardsForm"
                  style={{
                    "&:hover": {
                      border: `1px solid ${localStorage.getItem(
                        "primaryColor"
                      )}`,
                      "&& fieldset": {},
                    },
                    "& .MuiSelectSelect:focus": {
                      backgroundColor: "white",
                      border: `1px solid ${localStorage.getItem(
                        "primaryColor"
                      )}`,
                    },
                  }}
                >
                  <MenuItem value={currentYear}>{currentYear}</MenuItem>
                  <MenuItem value={nextYear}>{nextYear}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.selectBorder}
              >
                <InputLabel id="roomFromParent" className="select_label">
                  Room
                </InputLabel>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  labelId="roomFromParent"
                  id="roomFromParent"
                  value={roomFromParent}
                  label="roomFromParent"
                  onChange={handleChangeRoom}
                  defaultValue={roomFromParent}
                  className="cardsForm"
                  style={{
                    maxWidth: "250px",
                    "&:hover": {
                      "&& fieldset": {
                        // border: "1px solid #6260ef",
                        border: `1px solid ${localStorage.getItem(
                          "primaryColor"
                        )}`,
                        borderRadius: "8px",
                      },
                    },
                    "& .MuiSelectSelect:focus": {
                      backgroundColor: "white",
                      border: `1px solid ${localStorage.getItem(
                        "primaryColor"
                      )}`,
                    },
                  }}
                >
                  {totalRoomsFromParent?.map((Room, index) => (
                    <MenuItem key={index} value={Room}>
                      {Room}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  marginTop: "0px",
                  width: "120px",
                  height: "40px",
                  background: localStorage.getItem("primaryColor"),
                }}
                onClick={() => handleUpdateClick()}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <div
                class="loading setting_title_typo"
                data-loading-text="Processing Data ..."
              ></div>
            </div>
          ) : (
            <TableContainer className={classes.tablescroll}>
              <Table
                aria-label="simple table"
                className={classes.table}
                styles={{ minWidth: "450px" }}
              >
                <TableHead>
                  <TableRow>
                    {Days.map((Heading, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        style={{ textTransform: "uppercase" }}
                      >
                        {Heading}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalV2Calendar.length > 0 &&
                    finalV2Calendar.map((row, index) => {
                      return (
                        <TableRow key={index} className={classes.tableRow}>
                          {row.map((entry, index) => (
                            <TableCell
                              key={index}
                              component="th"
                              scope="row"
                              style={{ borderBottom: "none", padding: "5px" }}
                            >
                              {entry.dayNum ? (
                                <Card1
                                  style={{
                                    width: "100%",
                                    padding: "0",
                                    marginBottom: "0",
                                  }}
                                >
                                  <PopupState
                                    variant="popper"
                                    popupId="demo-popup-popper"
                                  >
                                    {(popupState, index) => (
                                      <div key={index}>
                                        <Header>
                                          <div className="col">
                                            <div className="row">
                                              <Typography
                                                style={{
                                                  marginBottom: "10px",
                                                  paddingLeft: "18px",
                                                  fontSize: "15px",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {entry.suggestedPrice
                                                    ? "€" +
                                                      formatInEuropeanNoSymbol(
                                                        entry.suggestedPrice
                                                      )
                                                    : "N/A"}
                                                </span>
                                              </Typography>
                                              <Avatar
                                                style={{
                                                  fontSize: 16,
                                                  backgroundColor:
                                                    Math.round(entry.dayNum) ==
                                                      today &&
                                                    new Date().getMonth() + 1 ==
                                                      month
                                                      ? localStorage.getItem(
                                                          "primaryColor"
                                                        )
                                                      : "#F4F4F4",
                                                  position: "absolute",
                                                  right: "0",
                                                  marginRight: "15px",
                                                  marginTop: "0px",
                                                  width: "26px",
                                                  height: "26px",

                                                  color:
                                                    Math.round(entry.dayNum) ==
                                                      today &&
                                                    new Date().getMonth() + 1 ==
                                                      month
                                                      ? "white"
                                                      : "grey",
                                                }}
                                              >
                                                {Math.round(entry.dayNum)}
                                              </Avatar>
                                            </div>

                                            {entry?.eventName?.length > 0 ? (
                                              <div className="row">
                                                <span
                                                  style={{
                                                    background: "#FFD600",
                                                    padding: "0px 5px 0px 5px",
                                                    borderRadius: "5px",
                                                    fontSize: "10px",
                                                    marginLeft: "15px",
                                                    marginRight: "5px",
                                                  }}
                                                >
                                                  <b>
                                                    {entry.eventName[0].name
                                                      .substr(0, 7)
                                                      .concat("...")}{" "}
                                                  </b>
                                                </span>
                                                {entry.eventName.length > 1 ? (
                                                  <b
                                                    style={{
                                                      background: "#FFD600",
                                                      borderRadius: "5px",
                                                      fontSize: "10px",
                                                      padding:
                                                        "0px 5px 0px 5px",
                                                    }}
                                                  >
                                                    {" "}
                                                    +{" "}
                                                    {entry.eventName.length -
                                                      1 >
                                                    0
                                                      ? entry.eventName.length -
                                                        1
                                                      : ""}{" "}
                                                  </b>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            ) : (
                                              <p
                                                style={{
                                                  margin: "29px 0px 0px 0px",
                                                }}
                                              ></p>
                                            )}

                                            <div className="row">
                                              <Typography
                                                style={{
                                                  marginBottom: "10px",
                                                  marginLeft: "18px",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                <b>
                                                  {entry.competitorPrice
                                                    ? "€" +
                                                      formatInEuropeanNoSymbol(
                                                        entry.competitorPrice
                                                      )
                                                    : "N/A"}
                                                </b>
                                              </Typography>

                                              <Button
                                                className="Button"
                                                {...bindTrigger(popupState)}
                                                style={{
                                                  position: "absolute",
                                                  right: "0",
                                                }}
                                              >
                                                <img
                                                  className="img-responsive"
                                                  src="/images/more.png"
                                                  alt="more"
                                                  width="12"
                                                  height="7"
                                                  maxwidth="100%"
                                                />{" "}
                                              </Button>
                                            </div>
                                          </div>
                                        </Header>
                                        {/* //popup card  */}
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "350px",
                                              minHeight: "278px",
                                              background: "#F7F8FC",
                                              border: "1px solid #D6D9FF",
                                              boxSizing: "border-box",
                                              boxShadow:
                                                "0px 0px 45px rgba(0, 0, 0, 0.18)",
                                              borderRadius: "9.41499px",
                                              padding: "20px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div></div>
                                              <div>
                                                <Avatar
                                                  style={{
                                                    fontSize: 14,
                                                    backgroundColor:
                                                      Math.round(
                                                        entry.dayNum
                                                      ) == today &&
                                                      firMonthFromParent ==
                                                        month
                                                        ? localStorage.getItem(
                                                            "primaryColor"
                                                          )
                                                        : "#F4F4F4",

                                                    width: "26px",
                                                    height: "26px",
                                                    color:
                                                      Math.round(
                                                        entry.dayNum
                                                      ) == today &&
                                                      firMonthFromParent ==
                                                        month
                                                        ? "white"
                                                        : "grey",
                                                  }}
                                                >
                                                  {" "}
                                                  {Math.round(entry.dayNum)}
                                                </Avatar>
                                              </div>
                                            </div>

                                            <Grid
                                              container
                                              style={{
                                                margin: "0px",
                                                paddingTop: "0px",
                                              }}
                                            >
                                              <Grid item xs={6}>
                                                <Typography
                                                  style={{
                                                    marginTop: "5px",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {" "}
                                                  Price{" "}
                                                </Typography>

                                                <Typography
                                                  style={{
                                                    marginBottom: "10px",
                                                    fontSize: "26px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span>
                                                    {entry.suggestedPrice
                                                      ? "€" +
                                                        formatInEuropeanNoSymbol(
                                                          entry.suggestedPrice
                                                        ) +
                                                        " "
                                                      : "N/A"}
                                                  </span>{" "}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={6}>
                                                <Typography
                                                  style={{
                                                    marginTop: "5px",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {" "}
                                                  Occupancy{" "}
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    marginBottom: "10px",
                                                    fontSize: "26px",
                                                  }}
                                                >
                                                  <b>
                                                    {entry.roomOcc
                                                      ? entry.roomOcc + "%"
                                                      : "N/A"}
                                                  </b>
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              container
                                              style={{
                                                margin: "0px",
                                                paddingTop: "0px",
                                              }}
                                            >
                                              <Grid item xs={6}>
                                                <Typography
                                                  style={{
                                                    marginTop: "5px",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {" "}
                                                  Importance{" "}
                                                </Typography>
                                                <div className="edit__importance__block">
                                                  <div>
                                                    {/* {editingImportance ? (
                                                      <div
                                                        style={{
                                                          marginBottom: "10px",
                                                          fontSize: "26px",
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <input
                                                          style={{
                                                            border: `1px solid ${localStorage.getItem(
                                                              "primaryColor"
                                                            )}`,
                                                          }}
                                                          type="number"
                                                          min={1}
                                                          max={9}
                                                          onChange={(e) =>
                                                            setImportanceValue(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                        <div>/10</div>
                                                      </div>
                                                    ) : ( */}
                                                    <Typography
                                                      style={{
                                                        marginBottom: "10px",
                                                        fontSize: "26px",
                                                      }}
                                                    >
                                                      <b>
                                                        {entry.importanceData
                                                          .importance
                                                          ? Math.round(
                                                              entry
                                                                .importanceData
                                                                .importance * 10
                                                            ) + " /100"
                                                          : "N/A"}
                                                      </b>
                                                    </Typography>
                                                    {/* )} */}
                                                  </div>
                                                  {/* <div>
                                                    {editingImportance ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                          marginLeft: "-10px",
                                                          marginTop: "-15px",
                                                        }}
                                                      >
                                                        <IconButton
                                                          onClick={() =>
                                                            updateImportance(
                                                              entry
                                                            )
                                                          }
                                                          aria-label="save"
                                                        >
                                                          <CheckIcon />
                                                        </IconButton>
                                                        <IconButton
                                                          onClick={() => {
                                                            setEditingImportance(
                                                              false
                                                            );
                                                          }}
                                                          aria-label="cancel"
                                                        >
                                                          <ClearIcon />
                                                        </IconButton>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {" "}
                                                        <img
                                                          alt="edit importance"
                                                          onClick={() => {
                                                            setEditingImportance(
                                                              true
                                                            );
                                                          }}
                                                          src="/images/edit-pen.svg"
                                                          height="20px"
                                                          className={
                                                            entry.importanceData
                                                              .editable
                                                              ? "importance__edit__icon"
                                                              : "importance__edit__icon_not_disable"
                                                          }
                                                        />{" "}
                                                      </div>
                                                    )}
                                                  </div> */}
                                                </div>
                                                <Tooltip
                                                  title={
                                                    entry.importanceData
                                                      .editable
                                                      ? "User Editing"
                                                      : "System Editing"
                                                  }
                                                  TransitionComponent={Fadee}
                                                  TransitionProps={{
                                                    timeout: 600,
                                                  }}
                                                >
                                                  <Switch
                                                    size="small"
                                                    // onClick={() =>
                                                    //   onChangeSwitch(entry)
                                                    // }
                                                    className="toggle_switch"
                                                    checked={
                                                      !entry.importanceData
                                                        .editable
                                                    }
                                                    // value={
                                                    //   entry.importanceData
                                                    //     .editable
                                                    // }
                                                  />
                                                </Tooltip>
                                              </Grid>
                                              <Grid item xs={6}>
                                                <Typography
                                                  style={{
                                                    marginTop: "5px",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Competetor Price{" "}
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    marginBottom: "10px",
                                                    fontSize: "26px",
                                                  }}
                                                >
                                                  <span>
                                                    {entry.competitorPrice
                                                      ? "€" +
                                                        formatInEuropeanNoSymbol(
                                                          entry.competitorPrice
                                                        ) +
                                                        " "
                                                      : "N/A"}
                                                  </span>{" "}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                            {entry.eventName.length !== 0 ? (
                                              <>
                                                <Typography
                                                  style={{
                                                    marginTop: "5px",
                                                    fontSize: "16px",
                                                    marginLeft: "15px",
                                                  }}
                                                >
                                                  {" "}
                                                  Event{" "}
                                                </Typography>
                                                <div
                                                  className="row"
                                                  style={{ marginLeft: "5px" }}
                                                >
                                                  {entry.eventName.map(
                                                    (t, index) => {
                                                      return (
                                                        <Button
                                                          key={index}
                                                          style={{
                                                            background:
                                                              "#FFD600",
                                                            padding: "1px",
                                                            borderRadius: "5px",
                                                            fontSize: "10px",
                                                            margin: "5px",
                                                          }}
                                                        >
                                                          {" "}
                                                          <b>{t.name} </b>
                                                        </Button>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <Button
                                              onClick={() =>
                                                history.push({
                                                  pathname:
                                                    "/dashboard/dailyview",
                                                  state: {
                                                    date: entry.Date,
                                                  },
                                                })
                                              }
                                              className="Button"
                                            >
                                              See More
                                            </Button>
                                            <Button
                                              className="Button"
                                              onClick={popupState.close}
                                              style={{
                                                position: "absolute",
                                                right: "0",
                                              }}
                                            >
                                              <img
                                                className="img-responsive"
                                                src="/images/less.png"
                                                alt="more"
                                                width="20"
                                                height="10"
                                                maxwidth="100%"
                                              />{" "}
                                            </Button>
                                          </div>
                                        </Popover>
                                      </div>
                                    )}
                                  </PopupState>
                                </Card1>
                              ) : (
                                <div />
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </FadeIn>
      </>
    </div>
  );
}

export default Cards;
