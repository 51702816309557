import "react-circular-progressbar/dist/styles.css";
import "../dashboard.scss";
import "../Index.scss";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import AnimatedProgressProvider from "./AnimatedProgressProvider";
import Card from "react-bootstrap/Card";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import { easeQuadInOut } from "d3-ease";
import { useStylesBootstrap } from "../../utils/tooltipDesign";
import { formatInEuropeanNoSymbol } from "../../utils";

export default function DummySellStatsCards(props) {
  const toolTipClasses = useStylesBootstrap();

  return (
    <div>
      <Card
        style={{
          minWidth: "280px",
          maxWidth: "440px",
          height: "260px",
          background: "#F7F8FC",
          borderRadius: "8px",
          padding: "8px 10px 10px 23px",
          borderWidth: "0",
        }}
      >
        <div className="row" style={{ display: "flex" }}>
          <img
            className="img-responsive"
            src={"/images/Emoji.svg"}
            alt="img"
            style={{ padding: "0px 16px" }}
          />
          <span
            id="title"
            className="sell_stat_card_title"
            style={{ maxWidth: "calc(100% - 150px)" }}
          >
            ...
          </span>
        </div>
        <div className="row" style={{ display: "flex" }}>
    
            <span id="heading">...</span>
          


            <span id="heading2">
              /...
            </span>

        </div>

          <div
            className="row"
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              width: "100%",
              padding: "8px 10px 10px 23px",
            }}
          >
            <img
              className="img-responsive"
              //src="/images/event_note.svg"
              src={props.subimg}
              alt="arrow_up"
              style={{ margin: "8px 0px 0px 15px" }}
            />
            <span
              id="heading2"
              style={{ marginTop: "20px", marginLeft: "5px" }}
            >
              ...
            </span>
          </div>
        <div
          className={
            // sideBar
            //   ? "sell_cards_progress_bar_on_opensidebar"
            //   :
            "sell_cards_progress_bar"
          }
        >

            {/* <AnimatedProgressProvider
              valueStart={0}
              valueEnd={trail}
              duration={1.4}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                return (
                  <CircularProgressbar
                    value={value}
                    text={
                      // eslint-disable-next-line
                      props.value == "NaN" ? (
                        <>{`${props.value}`}</>
                      ) : (
                        <>{`${props.value}%`}</>
                      )
                    }
                    counterClockwise={counter}
                    styles={buildStyles({
                      pathTransition: "none",
                      pathColor: path,
                      trailColor: "#000000",
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider> */}

        </div>
      </Card>
    </div>
  );
}
