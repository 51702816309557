import { DefaultUrl } from '../../../../BaseUrl'
import axios from 'axios'

export const paceReportInsight = async (
  paceReportStartDate,
  paceReportEndDate,
  propertyId
) => {
  const result = await axios.post(`${DefaultUrl}/pace_report_insights`, {
    mathod: 'post',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      start_date: paceReportStartDate,
      end_date: paceReportEndDate,
      propertyId: propertyId,
      pace_type: 'pace_report',
      pms_name: localStorage.getItem('pmsName'),
    },
  })

  return result
}

export const GOSPaceReportInsight = async (payload) => {
  const result = await axios.post(`${DefaultUrl}/GOS_pace_report_insights`, {
    mathod: 'post',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      start_date: payload.paceReportStartDate,
      end_date: payload.paceReportEndDate,
      propertyId: payload.propertyId,
      pace_report: payload.pace_report,
      pms_name: localStorage.getItem('pmsName'),
    },
  })

  return result
}

export const weeklyPaceReportInsight = async (
  paceReportStartDate,
  paceReportEndDate,
  propertyId
) => {
  const result = await axios.post(
    `${DefaultUrl}/reservations_track_pace_report_insights`,
    {
      mathod: 'post',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        start_date: paceReportStartDate,
        end_date: paceReportEndDate,
        propertyId: propertyId,
        pace_report: 'weekly_pace_report',
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )

  return result
}

export const GOSPaceReportInsightTable = async (
  paceReportStartDate,
  paceReportEndDate,
  propertyId
) => {
  const result = await axios.post(
    `${DefaultUrl}/GOS_pace_report_insights_table_data`,
    {
      mathod: 'post',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        start_date: paceReportStartDate,
        end_date: paceReportEndDate,
        propertyId: propertyId,
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )

  return result
}

export const bookReportFuture = async (
  paceReportStartDate,
  paceReportEndDate,
  propertyId
) => {
  const result = await axios.post(
    `${DefaultUrl}/reservations_track_pace_report_insights`,
    {
      mathod: 'post',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        start_date: paceReportStartDate,
        end_date: paceReportEndDate,
        propertyId: propertyId,
        pace_report: 'monthly_pace_report',
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )

  return result
}
export const guestInsight = async (payload) => {
  const result = await axios.post(
    `${DefaultUrl}/guest_insights_by_nationality_origin`,
    {
      mathod: 'post',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        insight_type: payload.insight_type,
        propertyId: payload.propertyId,
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )

  return result
}
