import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { auth } from '../../Auth/firebase'
import { auth2 } from '../../Auth/firebase'
import { OnGetAllHotels } from '../redux/actions/getAllHotels'
import { getAccountType, getPmsName } from '../utils'
import { gettingHotelInfo } from '../utils/getHotelInfo'
const AuthContext = React.createContext()

export const useAuth = () => useContext(AuthContext)
/* eslint-disable-next-line */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const signin = (email, password) => {
    const abc = auth2
      .signInWithEmailAndPassword(email, password)
      .then(async () => {
        localStorage.setItem("email",email)
        getAccountType().then((res) => {
          localStorage.setItem('accountType', res)
        })
        gettingHotelInfo().then((res) => {
          localStorage.setItem('hotelLength', res.hotelsExtractedVal.length)
          dispatch(OnGetAllHotels(res))
        })
        getPmsName().then((res) => {
          localStorage.setItem('pmsName', res)
        }
        )
        const y = await auth2.currentUser.getIdToken(
          /* forceRefresh */ true
        )
        localStorage.setItem('token', y)
        const expirationDate = new Date(new Date().getTime() + 3600000)
        localStorage.setItem('expirationDate', expirationDate)
      })
      .catch((err) => {
        switch (err.code) {
          case 'auth/Invalid-email':
          case 'auth/user-disabled':
          case 'auth/user-not-found':
            toast.error('Invalid email address', 'Try again!', 'error')
            break
          case 'auth/wrong-password':
            toast.error('Incorrect password', 'Try again!', 'error')
            break
          default:
        }
      })
    return abc
  }

  function logOut() {
    auth2.signOut().then(() => {
      setCurrentUser(null)
      setLoading(true)
      localStorage.removeItem('token')
      localStorage.removeItem('accountType')
      localStorage.removeItem('hotelLength')
      localStorage.removeItem('pmsName')
      localStorage.removeItem('primaryColor')
      localStorage.removeItem('propertyId')
      localStorage.removeItem('email')
      window.location.pathname = '/'
    })
  }

  useEffect(() => {
    const unsubscribe = auth2.onAuthStateChanged((user) => {
      setCurrentUser(user)

      setLoading(false)
    })
    return unsubscribe
  }, [currentUser])

  const value = {
    currentUser,
    signin,
    logOut,
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
