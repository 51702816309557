import "../Index.scss";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";

function Documentation(props) {

    const history = useHistory();


    return (
        <div>
            {localStorage.getItem("token") !== null ?
             
                        <div style={{ marginBottom: "90px", position: "relative" }}>
                  
                            <div >
                                <img src="/images/ComingSoon.svg"  alt="ComingSoon"  width="600px" height="600px" />
                                <Typography variant="h4" noWrap component="div" style={{fontWeight:"bolder"}}>Coming Soon!</Typography>
                            </div>
                        </div>
                   
                : history.push("/SignInPage")}</div>
    );
}

Documentation.propTypes = {
    window: PropTypes.func,
};

export default Documentation;
