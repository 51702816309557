import "./Proportions.scss";
import { useEffect, useState } from "react";
import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
  Switch,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import FadeIn from "react-fade-in";
import SaveIcon from "@material-ui/icons/Save";
import { useStylesProportion } from "../muiStyles";
import { toast } from "react-toastify";
import { doc, updateDoc } from "firebase/firestore";
import { db2 } from "../../../../Auth/firebase";
import axios from "axios";
import { DefaultUrl } from "../../../BaseUrl";
import { getYearDates } from "../utils/utils";
import { reformatDate } from "../../monthlyPrices/utils/utils";

const Proportions = ({ propertyId, proportions, disableTrigger }) => {
  const classes = useStylesProportion();

  // eslint-disable-next-line
  const [editBase, setEditBase] = useState(false);
  const [saveBase, setSaveBase] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [proportionsFromParent, setProportionsFromParent] =
    useState(proportions);
  const [proportionsUnchanged, setProportionsUnchanged] = useState(proportions);
  useEffect(() => {
    setProportionsFromParent(proportions);
  }, [proportions]);

  const updateDisableRoomtype = (updatedRoomId, value) => {
    const updatedData = proportionsFromParent.map((room) => {
      if (room.id === updatedRoomId && !room.primary_room) {
        return { ...room, disabled: value };
      } else {
        return room;
      }
    });
    setProportionsFromParent(updatedData);
  };

  const HandleBaseSubmit = () => {
    const valueChanges = [];

    for (let i = 0; i < proportionsUnchanged.length; i++) {
      const element = proportionsUnchanged[i];
      if (
        element.proportion_value !==
          proportionsFromParent[i].proportion_value ||
        element.proportion_type !== proportionsFromParent[i].proportion_type ||
        element.primary_roomtype !== proportionsFromParent[i].primary_roomtype
      ) {
        element.previous_proportion_value = element.proportion_value;
        element.proportion_value = proportionsFromParent[i].proportion_value;
        element.previous_proportion_type = element.proportion_type;
        element.proportion_type = proportionsFromParent[i].proportion_type;
        element.previous_primary_roomtype = element.primary_roomtype;
        element.primary_roomtype = proportionsFromParent[i].primary_roomtype;
        valueChanges.push(element);
      }
    }

    for (let i = 0; i < valueChanges.length; i++) {
      const element = valueChanges[i];
      axios
        .post(`${DefaultUrl}/user_activity_log`, {
          body: {
            propertyId: propertyId,
            email: localStorage.getItem("email"),
            time: new Date(),
            variable: "proportion",
            proportion: {
              room_type: element.name,
              proportion_value: `${element.proportion_value}`,
              previous_value: `${element.previous_proportion_value}`,
              proportion_type: element.proportion_type,
              previous_type: element.previous_proportion_type,
              primary_roomtype: element.primary_roomtype ? "true" : "false",
              previous_primary: element.previous_primary_roomtype
                ? "true"
                : "false",
            },
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .catch((err) => console.error(err));
    }
    setSavingData(true);
    // eslint-disable-next-line
    if (propertyId !== undefined) {
      const docRef = doc(db2, localStorage.getItem("pmsName"), propertyId);
      updateDoc(docRef, { roomtypes: proportionsFromParent })
        .then(() => {
          setEditBase(false);
          setSaveBase(false);
          setSavingData(false);
          setProportionsUnchanged(proportionsFromParent);
          toast.success("Proportions saved successfully");
        })
        .catch((error) => {
          toast.error("Error saving proportions");
        });
      const { currentDate, finalDate } = getYearDates();
      const dates = [reformatDate(currentDate), reformatDate(finalDate)];

      sendCalculatePricesMessage("1", propertyId, dates);
    }
  };

  async function sendCalculatePricesMessage(pmsId, propertyId, dates) {
    axios.post(
      "https://publish-message-3cuixb5lia-ew.a.run.app/calculatePrices",
      {
        topicName: "calculatePrices",
        payload: {
          pmsId: pmsId,
          propertyId: propertyId,
          dates: dates,
          isDateRange: true,
        },
      }
    );
  }

  const changeBaseRoomType = (id) => {
    const newArr = proportionsFromParent.map((obj) => {
      return { ...obj, primary_room: false };
    });

    const newArrWithNewBaseRoom = newArr.map((obj) => {
      // eslint-disable-next-line
      if (obj.id === id) {
        return { ...obj, primary_room: true };
      }
      return obj;
    });

    setProportionsFromParent(newArrWithNewBaseRoom);
  };

  const changeProportionType = (e, id) => {
    let test =
      e.target.innerText.charAt(0).toUpperCase() +
      e.target.innerText.slice(1).toLocaleLowerCase();

    const newArr = proportionsFromParent.map((obj) => {
      if (obj.id === id) {
        return { ...obj, proportion_type: test };
      }
      return obj;
    });
    setProportionsFromParent(newArr);
  };

  const changeProportionValue = (e, id) => {
    var index;
    const newArr = proportionsFromParent.map((obj, i) => {
      // eslint-disable-next-line
      if (obj.id === id) {
        index = i;
        return { ...obj, proportion_value: parseFloat(e.target.value) };
      }
      return obj;
    });
    setProportionsFromParent(newArr);
  };

  return (
    <div className="proportions">
      {savingData ? (
        <div className="loader_main_container">
          <div
            className="loading setting_title_typo"
            data-loading-text="Processing Data ..."
          ></div>
        </div>
      ) : (
        <>
          <FadeIn transitionDuration="4000">
            <div className="proportions_main_container">
              <Typography variant="h5" style={{ margin: "32px 0px" }}>
                <b className="setting_title_typo">Proportion</b>
              </Typography>
              {editBase ? (
                <div>
                  {saveBase ? (
                    <Button
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      className="save_button"
                    >
                      Saving
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={<DoneIcon />}
                      className="save_button"
                      onClick={() => {
                        HandleBaseSubmit();
                        setSaveBase(true);
                      }}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    className="save_button"
                    onClick={() => {
                      setEditBase(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div
                  className="edit_button"
                  onClick={() => {
                    disableTrigger && setEditBase(true);
                  }}
                >
                  <img
                    src="/images/edit.svg"
                    height="25"
                    width="70"
                    alt="edit_proportion"
                  />
                </div>
              )}
            </div>
            <section>
              <TableContainer
                component={Paper}
                elevation={0}
                className={classes.tablescroll}
              >
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="table_head">
                        Room Type
                      </TableCell>
                      <TableCell align="left" className="table_head">
                        Proportion Type
                      </TableCell>
                      <TableCell align="left" className="table_head">
                        Proportion
                      </TableCell>
                      <TableCell align="left" className="table_head">
                        disabled
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {proportionsFromParent?.map((proportionsData) => (
                      <TableRow
                        key={proportionsData.id}
                        className={classes.tableRow}
                      >
                        {!editBase ? (
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={classes.cellTypo}
                          >
                            <div
                              className={
                                // eslint-disable-next-line
                                proportionsData.primary_room == true
                                  ? "base_room_type_symbol"
                                  : "no_base_room_type_symbol"
                              }
                            >
                              {" "}
                              {proportionsData.name}
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={classes.cellTypo}
                          >
                            <div
                              className={
                                // eslint-disable-next-line
                                proportionsData.primary_room == true
                                  ? "base_room_type_symbol"
                                  : "no_base_room_type_symbol"
                              }
                              onClick={() =>
                                changeBaseRoomType(proportionsData.id)
                              }
                            >
                              {" "}
                              {proportionsData.name}
                            </div>
                          </TableCell>
                        )}

                        <TableCell align="left" className={classes.cellTypo}>
                          <ButtonGroup>
                            <Button
                              disabled={!editBase}
                              className={
                                // eslint-disable-next-line
                                proportionsData.proportion_type == "Fixed"
                                  ? "proportion_btn_one_active"
                                  : "proportion_btn_one"
                              }
                              value="Fixed"
                              onClick={(e) => {
                                changeProportionType(e, proportionsData.id);
                              }}
                            >
                              Fixed
                            </Button>
                            <Button
                              disabled={!editBase}
                              className={
                                // eslint-disable-next-line
                                proportionsData.proportion_type == "%"
                                  ? "proportion_btn_two_active"
                                  : "proportion_btn_two"
                              }
                              value="%"
                              onClick={(e) => {
                                changeProportionType(e, proportionsData.id);
                              }}
                            >
                              %
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                        {editBase ? (
                          <TableCell align="left" className={classes.cellTypo}>
                            <TextField
                              defaultValue={proportionsData.proportion_value}
                              required={true}
                              variant="standard"
                              onChange={(e) => {
                                changeProportionValue(e, proportionsData.id);
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell align="left" className={classes.cellTypo}>
                            {Math.round(
                              (proportionsData.proportion_value +
                                Number.EPSILON) *
                                100
                            ) / 100}
                          </TableCell>
                        )}
                        <TableCell>
                          <Switch
                            onChange={(e) =>
                              updateDisableRoomtype(
                                proportionsData.id,
                                !proportionsData.disabled
                              )
                            }
                            className="toggle_switch"
                            checked={proportionsData.disabled}
                            color={"6260EF"}
                            disabled={!editBase}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </section>
          </FadeIn>
        </>
      )}
    </div>
  );
};

export default Proportions;
