import '../Index.scss'
import '../dashboard.scss'

import { Card, Grid, Snackbar, Typography } from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import CheckIcon from '@material-ui/icons/Check'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Iframe from 'react-iframe'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

function DevTools(props) {
  const { totalHotel } = props
  const history = useHistory()
  // eslint-disable-next-line
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [copyAlert, setCopyAlert] = useState(false)

  //copy the token to clipboard

  const copy = async () => {
    await navigator.clipboard.writeText(token)
    setCopyAlert(true)
  }
  return (
    <div>
      {localStorage.getItem('token') !== null ? (
        <div>
          {copyAlert ? (
            <>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={copyAlert}
                autoHideDuration={2000}
                onClose={() => {
                  setCopyAlert(false)
                }}
              >
                <Alert
                  icon={<CheckIcon fontSize='inherit' />}
                  severity='success'
                >
                  Token Copied
                </Alert>
              </Snackbar>
            </>
          ) : (
            ''
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                <Typography className='devtools-card-title'>Token</Typography>
                <div className='token_copy'>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {' '}
                    <Typography
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {token}
                    </Typography>
                  </div>
                  <div>
                    {' '}
                    <FileCopyOutlinedIcon
                      onClick={copy}
                      style={{ cursor: 'pointer' }}
                    ></FileCopyOutlinedIcon>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <Typography className='devtools-card-title'>
                  Properties
                </Typography>
                <div className='token_copy'>
                  <Grid container spacing={2}>
                    {totalHotel?.map((hotel) => (
                      <Grid item xs={6}>
                        <div key={hotel.hotel_id}>
                          <Typography
                            variant='h5'
                            component='h2'
                            className='dialogue_items_heading_child'
                          >
                            {hotel.hotel_name}
                          </Typography>
                          <Typography color='textSecondary'>
                            {hotel.hotel_id}
                          </Typography>
                        </div>
                      </Grid>
                    ))}{' '}
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      ) : (
        history.push('/SignInPage')
      )}
    </div>
  )
}

DevTools.propTypes = {
  window: PropTypes.func,
}

export default DevTools
