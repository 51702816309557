import { DefaultUrl } from '../../../../BaseUrl'
import axios from 'axios'

//--------------------- sales ratio---------------------

export const salesRatio = async (payload) => {
  const result = await axios.post(`${DefaultUrl}/sales_ratio_insights`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      start_date: payload.start_date,
      end_date: payload.end_date,
      propertyId: payload.propertyId,
      sales_type: payload.sales_type,
      pms_name: localStorage.getItem('pmsName'),
    },
  })

  return result
}
//--------------------- dales ratio by room_type ---------------------
export const salesRatioRoomType = async (payload) => {
  const result = await axios.post(
    `${DefaultUrl}/sales_ratio_insights_by_roomType`,
    {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        start_date: payload.start_date,
        end_date: payload.end_date,
        propertyId: payload.propertyId,
        room_type: payload.room_type,
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )
  return result
}

//--------------------- booker insight---------------------

export const bookerInsight = async (payload) => {
  const result = await axios.post(
    `${DefaultUrl}/booker_insights_by_nationality_origin`,
    {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      body: {
        start_date: payload.start_date,
        end_date: payload.end_date,
        propertyId: payload.propertyId,
        insight_type: payload.insight_type,
        pms_name: localStorage.getItem('pmsName'),
      },
    }
  )
  return result
}

//---------------------book window---------------------

export const bookWindow = async (propertyId) => {
  const result = await axios.post(`${DefaultUrl}/book_window_information`, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      propertyId: propertyId,
      pms_name: localStorage.getItem('pmsName'),
    },
  })
  return result
}

// pace report sales stat
export const salesReportInsight = async (payload) => {
  const result = await axios.post(`${DefaultUrl}/pace_report_insights`, {
    mathod: 'post',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
    body: {
      start_date: payload.start_date,
      end_date: payload.end_date,
      propertyId: payload.propertyId,
      pace_type: payload.pace_type,
      pms_name: localStorage.getItem('pmsName'),
    },
  })

  return result
}
