import { auth, auth2, db, db2 } from "../../Auth/firebase";

export const gettingPropertyStatus = async (propertyId) => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const hotel_id = propertyId;
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();

    //getting hotels

    const userHotels = getUserData.data()?.hotels;
    // filtering hotels to get data of specific hotelid

    const filterHotels = userHotels?.filter((hotel) => {
      // eslint-disable-next-line
      return hotel?.hotel_id == hotel_id;
    });

    const pmsName = getUserData.data()?.pms_name;
    const pmsData = await db2.collection(pmsName).doc(hotel_id).get();
    const status = pmsData.data()?.active;
    return status;
  }
};
export const getDisplayName = async () => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();

    const getDisplayName = getUserData.data()?.displayName;

    return getDisplayName;
  }
};
export const formatInEuropeanNoSymbol = (eineZahl) => {
  return new Intl.NumberFormat("de-DE", {
    style: "decimal",
    currency: "EUR",
    signDisplay: "never",
  }).format(eineZahl);
};
export const getAccountType = async () => {
  if (auth2.currentUser.uid !== null) {
    const getPmsDetail = await db2
      .collection(`users`)
      .doc(auth2.currentUser.uid)
      .get();
    const accountType = getPmsDetail.data().type;
    return accountType;
  }
};
// date formatting
function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}
export const dataFormatting = (date) => {
  let formatted_date =
    date.getFullYear() +
    "-" +
    appendLeadingZeroes(date.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(date.getDate());

  return formatted_date;
};
// value formatter
var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
export const abbreviateNumber = (number) => {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};
export const getPriceSendTrigger = async (propertyId) => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const hotel_id = propertyId;

    const accountType = localStorage.getItem("accountType");

    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();
    const getGroupId =
      accountType === "pms" ? getUserData?.data()?.currentSelectedGroup.id : "";

    const filterAccordingToGroupType =
      accountType === "group"
        ? getUserData
        : accountType === "pms"
        ? await db2.collection(`users`).doc(getGroupId).get()
        : "";
    //getting hotels

    const userHotels = filterAccordingToGroupType.data()?.hotels;

    const filterHotels = userHotels?.filter((hotel) => {
      return hotel?.hotel_id === hotel_id;
    });

    const pmsName = filterHotels[0]?.pms;
    const pmsData = await db2.collection(pmsName).doc(hotel_id).get();
    const getTriggerFlag = pmsData.data()?.price_send_date_trigger;

    return getTriggerFlag;
  }
};
export const getPmsName = async () => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();

    const getType = getUserData.data()?.type;

    const getName =
      getType === "pms"
        ? getUserData.data()?.displayName
        : getUserData.data()?.pms_name;
    return getName;
  }
};

export const getSelectedGroupData = async () => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();
    const getGroupId = getUserData.data()?.currentSelectedGroup.id;
    const getGroupIdData = await db2.collection(`users`).doc(getGroupId).get();
    const { displayName, phone_number } = getGroupIdData.data();
    return { displayName, phone_number, getGroupId };
  }
};

export const updateDisplayNameAndPhone = async (displayName, phoneNumber) => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const getPmsAccount = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();

    const getGroupId = getPmsAccount.data()?.currentSelectedGroup.id;

    const getUser = await db2.collection(`users`).doc(getGroupId).update({
      displayName: displayName,
      phone_number: phoneNumber,
    });

    return getUser;
  }
};

export const uploadMissingData = async (data, hotelId) => {
  // create new document with name hotelId in missing_data_octorate collection

  const newDocument = await db2
    .collection(`missing_data_${localStorage.getItem("pmsName")}`)
    .doc(hotelId)
    .set({
      data,
    });
  return newDocument;
};

export const getAllHotels = async () => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();
    const getGroupId = getUserData.data()?.currentSelectedGroup.id;
    const getGroupIdData = await db2.collection(`users`).doc(getGroupId).get();

    const userHotels = getGroupIdData.data()?.hotels;
    return userHotels;
  }
};

export const gettingCurrentSelectdProperty = async () => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();

    //getting hotels

    const currentHotel = getUserData.data()?.currentSelectedProperty;

    return currentHotel;
  }
};

// updating impoetance data

export const updateCurrentSelectedProperty = async (hotel) => {
  // getting userdata
  const getUserData = db2.collection(`users`).doc(auth2?.currentUser?.uid);

  // upddating the data in firestore
  await getUserData.update({
    // eslint-disable-next-line
    ["currentSelectedProperty"]: hotel,
  });
  return true;
};
