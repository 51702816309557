import { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { Typography } from "@material-ui/core";
import noData from "../../../../images/noDataSellStats.png";

const CancellationRatio = ({ salesStatCancellationData }) => {
  const [data, setDate] = useState(salesStatCancellationData);
  useEffect(() => {
    setDate(salesStatCancellationData);
  }, [salesStatCancellationData]);

  const series = data?.map((da) => da.percentage);
  const options = {
    colors: ["#c03419", "#397d33", "#84bdd7"],
    dataLabels: {
      style: {
        colors: ["white", "white"],
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "light",
      },
    },
    fill: {
      colors: ["#c03419", "#397d33", "#84bdd7"],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        colors: ["#c03419", "#397d33", "#84bdd7"],
        useSeriesColors: false,
      },
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
    labels: data?.map((da) => da.status),
  };
  return (
    <div>
      {salesStatCancellationData.length > 0 ? (
        <>
          <Typography variant="subtitle1" align="center">
            CANCELLATION RATIO
          </Typography>
          <Chart options={options} series={series} type="donut" />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noData} style={{ textAlign: "center" }}></img>
          <Typography variant="subtitle1" align="center">
            No data available for this KPI
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CancellationRatio;
