import Papa from 'papaparse'
import { useState } from 'react'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import { dataFormatting, uploadMissingData } from '../../utils'
import { toast } from 'react-toastify'
import Alert from '@material-ui/lab/Alert'

const ImportCsv = () => {
  const [parsedData, setParsedData] = useState([])
  const [hotelId, setHotelId] = useState('')
  const [loading, setLoading] = useState(false)
  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results)
        // change the data datatype to array of objects
        const data = results.data.map((row) => {
          return {
            Date: dataFormatting(new Date(row.Date)),
            ROOMS_REVENUE: parseFloat(
              row.ROOMS_REVENUE.toString().replace(/,/g, '')
            ),
            ROOMS_SOLD: parseInt(row.ROOMS_SOLD),
          }
        })
        setParsedData(data)
      },
    })
  }

  const uploadMissingDataHandler = () => {
    setLoading(true)
    uploadMissingData(parsedData, hotelId)
      .then((res) => {
        toast.success('Data uploaded successfully')
        setLoading(false)
        setParsedData([])
        setHotelId('')
      })
      .catch((err) => {
        toast.error('Error uploading data')
        setLoading(false)
      })
  }

  return (
    <>
      <Alert severity='info'>
        CSV should contain these headers: <br/><strong>Date, ROOMS_REVENUE (It should be in this format (2530.00)) , ROOMS_SOLD </strong>
      </Alert>
      <br/>
      <TextField
        className='mb-3'
        variant='outlined'
        fullWidth
        id='hotel__id'
        name='hotel__id'
        label='Hotel ID'
        value={hotelId}
        onChange={(e) => setHotelId(e.target.value)}
      />

      <TextField
        className='mb-3'
        type='file'
        name='file'
        fullWidth
        variant='outlined'
        onChange={changeHandler}
        accept='.csv'
      />

      <Button
        variant='contained'
        color='primary'
        onClick={uploadMissingDataHandler}
      >
        {loading && <CircularProgress size={20} />}
        {!loading && 'Upload Missing Data'}{' '}
      </Button>
    </>
  )
}

export default ImportCsv
