import { ACTION_TYPES } from '../actions/types'
const initialState = {
  loader: true,
}

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.setLoader:
      return action.payload

    default:
      return state
  }
}
