import { CopyBlock, solarizedLight } from "react-code-blocks";
import React, { useEffect, useState } from "react";
import { jsFetch, nodeJs, pythonHttp, pythonRequest } from "../ApiCode";

const UpdateSeason = ({language}) => {
  const [languageDemo, setLanguageDemo] = useState("");
  const [highlightLanguage, setHighlightLanguage] = useState("javascript");

  const argumentsToPass =
  "{\r\n    \"date_ranges\": [[\"2021-01-01\",\"2021-12-31\"]],\r\n    \"propertyid\": \"415024\"\r\n  }";
  const method = "POST";
  const baseUrl = "base_url";
  const endpoint = "update_season";
  useEffect(() => {
    if (language === "jsFetch") {
      setHighlightLanguage("javascript");
      setLanguageDemo(jsFetch(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "nodeJs") {
      setHighlightLanguage("javascript");
      setLanguageDemo(nodeJs(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonHttp") {
      setHighlightLanguage("python");
      setLanguageDemo(pythonHttp(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonRequest") {
      setHighlightLanguage("python");

      setLanguageDemo(
        pythonRequest(argumentsToPass, method, endpoint, baseUrl)
      );
    }
  }, [language]);
  return (
    <div >
      {" "}
      <h3>Update Season</h3 >
<p>Updates the season for which your hotel was operational for. Provide an array of date ranges for a given property id. Also, include the auth token that is provided to you.</p>
      <div className="">

        <div className="demo">
          <CopyBlock
            language={highlightLanguage}            text={languageDemo}
            showLineNumbers={true}
            theme={solarizedLight}
            wrapLines={true}
            codeBlock
          />
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default UpdateSeason;
