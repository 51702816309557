import '../Dashboard/dashboard.scss'
import '../Dashboard/Index.scss'
import { Route, Switch } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'

import AppBar from "@material-ui/core/AppBar";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CssBaseline from "@material-ui/core/CssBaseline";

import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";

import clsx from "clsx";
import { getAuth } from "firebase/auth";
import logo from "../../images/logo.svg";
import ManagePropeties from "./ManagePropeties";
import ManageGroupAccounts from "./ManageGroupAccounts";
import {
  Box,
  Modal,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { getAllGroupAccounts, updateCurrentSelectedGroup } from "./utils";
import { authSecondary } from "../../Auth/firebase";
import { toast } from "react-toastify";
import { PmsDashboardStyle } from "./MuiStyles";
import { useEffect, useState } from "react";
import Settings from "../Dashboard/setting/Settings";
import { getAllHotels, getDisplayName } from "../utils";
import UpdateProfile from "./UpdateProfile/UpdateProfile";
import ImportCsv from "./ImportCsv/ImportCsv";
import { useAuth } from "../Context/AuthUserContext";
import { auth2 } from '../../Auth/firebase';

export default function PmsDashboard(props) {
  const classes = PmsDashboardStyle();
  const theme = useTheme();

  const { logOut } = useAuth();

  const [open, setOpen] = useState(true);
  const [allAccount, setAllAccount] = useState([]);
  const [openGroupAccount, setOpenGroupAccount] = useState(false);
  const [currentGroup, setCurrentGroupAccount] = useState({});
  const [updateGroupFlag, setUpdateGroupFlag] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [hotelsList, setHotelList] = useState([]);
  // ------------------ drawer and dialogue box conroller ------------------
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpenGroupAccount(true);
  };
  const handleClose = () => {
    setOpenGroupAccount(false);
  };

  // ------------------ Changing selected group account ------------------

  const handleSetAccount = (account) => {
    const { id, email, displayName } = account;
    setCurrentGroupAccount({ id, email, displayName });
    setUpdateGroupFlag(true);
  };

  // ------------------ UseEffect calls ------------------

  useEffect(() => {
    if (
      new Date().getTime() >
      new Date(localStorage.getItem("expirationDate")).getTime()
    ) {
      auth2.currentUser.getIdToken(/* forceRefresh */ true).then((response) => {
        localStorage.setItem("token", response);
        const expirationDate = new Date(new Date().getTime() + 3600000);
        localStorage.setItem("expirationDate", expirationDate);
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("currentGroup",currentGroup.id)
  }, [currentGroup]);

  useEffect(() => {
    getDisplayName().then((response) => {
      setDisplayName(response);
    });
    console.log("getting GA")
    getAllGroupAccounts().then((res) => {

      setCurrentGroupAccount(res.currentSelectedGroup);
      setAllAccount(res.allAccount);
      //   // sigin with group accc
      //   const email = res[0].email
      //   const password = res[0].password
      //   authSecondary
      //     .signInWithEmailAndPassword(email, password)
      //     .then(async () => {
      //         getAccountType().then((res) => {
      //         localStorage.setItem('GroupAccountTypeInPmsPortal', res)
      // })
      //       const y = await authSecondary.currentUser.getIdToken(
      //         /* forceRefresh */ true
      //       )
      //       localStorage.setItem('tokenTwo', y)
      //       const expirationDate = new Date(new Date().getTime() + 3600000)
      //       localStorage.setItem('expirationDateSecond', expirationDate)
      //     })
      // })
      // .catch((err) => {
      //   switch (err.code) {
      //     case 'auth/Invalid-email':
      //     case 'auth/user-disabled':
      //     case 'auth/user-not-found':
      //       toast.error('Invalid email address', 'Try again!', 'error')
      //       break
      //     case 'auth/wrong-password':
      //       toast.error('Incorrect password', 'Try again!', 'error')
      //       break
      //     default:
      //   }
    });
    // ------------------ update selected group account in firebase ------------------

    if (updateGroupFlag === true) {
      updateCurrentSelectedGroup(currentGroup)
        .then((res) => {
          setUpdateGroupFlag(false);
          setOpenGroupAccount(false);
          toast.success(
            "Group account updated successfully",
            "Success!",
            "success"
          );
        })
        .catch((err) => {
          setOpenGroupAccount(false);

          toast.error("Error updating group account", "Error!", "error");
        });
    }
  }, [updateGroupFlag]);

  useEffect(() => {
    getAllHotels().then((res) => {
      setHotelList(res);
      console.log("all hotels", res);
    });
  }, [currentGroup]);

  const drawer = (
    <div>
      <Link to="/LandingPage" className="MonthlyLogo">
        <img src={logo} alt="logo" width="236px" height="40px" />
      </Link>
      <List className="List">
        <ListItem
          button
          component={Link}
          className={
            location.pathname === "/pmsdashboard/manageaccount"
              ? "activeItem"
              : "notactiveItem"
          }
          to={"/pmsdashboard/manageaccount"}
        >
          <ListItemText> Manage Account</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          className={
            location.pathname === "/pmsdashboard/manageproperties"
              ? "activeItem"
              : "notactiveItem"
          }
          to={"/pmsdashboard/manageproperties"}
        >
          <ListItemText> Manage Proporties</ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          className={
            location.pathname === "/pmsdashboard/missingdata"
              ? "activeItem"
              : "notactiveItem"
          }
          to={"/pmsdashboard/missingdata"}
        >
          <ListItemText> Import Missing Data</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          className={
            location.pathname === "/pmsdashboard/updateprofile"
              ? "activeItem"
              : "notactiveItem"
          }
          to={"/pmsdashboard/updateprofile"}
        >
          <ListItemText>Update Profile</ListItemText>
        </ListItem>

        <ListItem
          button
          component={Link}
          className={
            location.pathname === "/pmsdashboard/setting"
              ? "activeItem"
              : "notactiveItem"
          }
          to={"/pmsdashboard/setting"}
        >
          <ListItemText> Setting</ListItemText>
        </ListItem>
        <ListItem
          button
          className="notactiveItem"
          component={Link}
          onClick={logOut}
          to="/"
          style={{ fontWeight: "bold", color: "#111" }}
        >
          <ListItemText
            className=""
            style={{ fontWeight: "bold", color: "#111" }}
          >
            {" "}
            Logout
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx([classes.appBar, classes.appBarClr], {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <Tooltip title="Select Group Account">
            <Button
              variant="outlined"
              className={classes.formControl}
              color="primary"
              onClick={handleClickOpen}
            >
              {currentGroup?.displayName}
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Dialog
        open={openGroupAccount}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.dialogBox}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography className="dialogue_items_heading">
                PMS Account
              </Typography>
              <Divider className="dialogue_divider" />

              <Typography
                variant="h5"
                component="h2"
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "#000",
                  marginLeft: "auto",
                }}
              >
                {displayName}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="dialogue_items_heading">
                Group Accounts{" "}
              </Typography>{" "}
              <Divider className="dialogue_divider" />
              {allAccount?.map((account) => (
                <div
                  key={account.id}
                  className="propertyList"
                  onClick={() => {
                    handleSetAccount(account);
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      marginTop: "10px",
                      color: "#000",
                      margin: "8px 0 8px auto",
                    }}
                  >
                    {account.displayName}
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        {drawer}
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <div>
          <Switch>
            <Route path="/pmsdashboard/manageaccount">
              <ManageGroupAccounts />
            </Route>
            <Route path="/pmsdashboard/manageproperties">
              <ManagePropeties />
            </Route>
            <Route path="/pmsdashboard/missingdata">
              <ImportCsv />
            </Route>
            <Route path="/pmsdashboard/updateprofile">
              <UpdateProfile />
            </Route>
            <Route path="/pmsdashboard/setting">
              <Settings hotelsList={hotelsList} />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  );
}
