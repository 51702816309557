import 'chartjs-adapter-moment'

import React, { useEffect, useState } from 'react'

import { Chart } from 'chart.js'
import FadeIn from 'react-fade-in'
import { Line } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'

const Occupancegraph = ({ occupancyGraphData }) => {
  Chart.register(annotationPlugin)
  const [data, setData] = useState(occupancyGraphData)
  //Use effect calls
  useEffect(() => {
    setData(occupancyGraphData)
  }, [occupancyGraphData])
  //Use effect calls end
  const maxBar = () => {
    return data?.total_rooms + Math.round(data?.total_rooms * 0.1)
  }

  return (
    <div style={{ width: '100%' }}>
      <FadeIn transitionDuration='4000'>
        <Line
          data={{
            labels: data?.Date,
            datasets: [
              {
                label: 'My booking Pace',
                data: data?.occupancy_changes,
                fill: false,
                backgroundColor: 'rgba(72 , 217, 165 ,1)',
                borderColor: 'rgba(72 , 217, 165 ,1)',
                lineTension: 0.2,
                yAxisID: 'y',
              },
              {
                label: 'ADR Pace',
                data: data?.price,
                fill: false,
                backgroundColor: 'rgba(72 , 27, 165 ,1)',
                borderColor: 'rgba(72 , 27, 165 ,1)',
                lineTension: 0.2,
                yAxisID: 'y1',
              },
            ],
          }}
          options={{
            spanGaps: 10000 * 160 * 160 * 24 * 30, // 2 days
            bezierCurve: true,
            interaction: {
              mode: 'nearest',
            },
            plugins: {
              autocolors: false,

              legend: {
                maintainAspectRatio: true,
                display: true,
              },
            },
            scales: {
              x: {
                type: 'time',
                display: true,
                grid: { display: false },
                time: {
                  unit: 'week',
                  tooltipFormat: 'MMM DD , YYYY',
                },
                title: {
                  display: false,
                },
              },
              y: {
                type: 'linear',
                display: true,
                position: 'right',
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
            legend: {
              labels: {
                display: true,
                // fontSize: 25,
              },
            },
          }}
        />
      </FadeIn>
    </div>
  )
}

export default Occupancegraph
