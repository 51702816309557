import { useState } from 'react'
import { Link } from 'react-router-dom'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import logo from '../../images/logo.svg'

const Sidebar = () => {
	const sidebarArray = [
		{
			title: 'Admin Portal App',
			id: 'portal_app',
			children: [
				{
					id: 'add_group_account',
					title: 'add_group_account',
					requestType: 'POST',
				},
				{
					id: 'add_property',
					title: 'add_property',
					requestType: 'POST',
				},
				{
					id: 'remove_property',
					title: 'remove_property',
					requestType: 'POST',
				},
				{
					id: 'add_pms_account',
					title: 'add_pms_account',
					requestType: 'POST',
				},
			],
		},

		{
			title: 'Admin Portal App 2',
			id: 'portal_app_2',
			children: [
				{
					id: 'add_group_account',
					title: 'add_group_account',
					requestType: 'POST',
				},
				{
					id: 'add_property',
					title: 'add_property',
					requestType: 'POST',
				},
				{
					id: 'remove_property',
					title: 'remove_property',
					requestType: 'POST',
				},
				{
					id: 'add_pms_account',
					title: 'add_pms_account',
					requestType: 'POST',
				},
			],
		},
	]

	const [expanded, setExpanded] = useState(false)
	// const [activeClass, setActiveClass] = useState('')

	const handleChange = (id) => (event, isExpanded) => {
		setExpanded(isExpanded ? id : false)
	}

	return (
		<div className="sidebar">
			<div className="sidebar_logo">
				<Link to="/LandingPage">
					<img src={logo} alt="logo" />
				</Link>
			</div>

			<div className="close_icon">
				<i className="fas fa-arrow-left"></i>
			</div>

			<div className="input_container">
				<input type="text" placeholder="Search Here" />
				<i className="fas fa-search"></i>
			</div>

			<div className="links_container">
				{sidebarArray.map((item, index) => (
					<Accordion
						key={index}
						style={{ boxShadow: 'none', background: 'transparent' }}
						expanded={expanded === `${item.id}`}
						onChange={handleChange(`${item.id}`)}
						className={expanded === `${item.id}` ? 'active' : ''}
					>
						<AccordionSummary className="title" expandIcon={<ExpandMoreIcon style={{ color: expanded === `${item.id}` && '#fff' }} />}>
							<Link className="link" to={`#${item.id}`}>
								{item.title}
							</Link>
						</AccordionSummary>

						<div className="child_links_container">
							{item.children.map((child, index) => (
								<AccordionDetails key={index}>
									<div className="child_links">
										<span>{child.requestType}</span>
										<Link className="link" to={`#${child.id}`}>
											{child.title}
										</Link>
									</div>
								</AccordionDetails>
							))}
						</div>
					</Accordion>
				))}
			</div>
		</div>
	)
}

export default Sidebar
