// getting impoetance data

import { auth2, db2 } from "../../Auth/firebase";
// const baseLogo = "https://storage.googleapis.com/static-files-for-brainy_logos/textToImage.jpeg?Expires=1706369139&GoogleAccessId=brainy-dev-storage%40brainy-dev-309221.iam.gserviceaccount.com&Signature=f%2Fq2yWcHtA%2Fr4HzjAZo7Mi7jsDBZwCjzZSefrDem2nNsP5SHoufYN%2Bo05OBvpuNtfxfGsH6iYutWOjLk%2BifpR9iShXpLJD5nMPmh43bQxL4dyLdZ%2FfxcCbsSu5gYrA1vBH62qabIv5OohcgqvUJkfjJ%2BTy6zypDAD6xMKDJCwf0QAbammBIKB0K8oJMVOJKQfJZd4u1Pvc701U2yiJVEQSARImAY8fMP5iGi%2BaIaRUlyI3Xzm6hWBihROci%2FAz%2Frk1VmrhCCKECVFMOKHQb%2FKJSGWP%2FRwA0rjVM2gxoDRtmFrcFp6dQFrxnPNupUWAZb%2BHc%2BX17ooDaFnd6tCUMIRA%3D%3D"
const baseLogo =
  "https://storage.googleapis.com/static-files-for-brainy_logos/textToImage.jpeg?Expires=1706287860&GoogleAccessId=brainy-dev-storage%40brainy-dev-309221.iam.gserviceaccount.com&Signature=IjUSprl6IWd0mjXepoX%2Fu6jJe4xTZV3vac9%2BmBtfYBWMOPoE9jXot8s1CfzdZDK3sLf%2BRqrEYNx5DV1OEQp7hHsq9QUKVA8zDDiAU1mSsDwE713UMN8Z5D8x07eU1sbh1AiPd%2FD9Y3dvGAZ2JIZcXKLDjSmkEQcU2RVn6LH%2F7ffKP9kQMHuXGZrlgx5Mkr0P8K3apS96l5OQHb8gZtjiPK9z6RkRMbX4iiW6u19kzy43vCTVAT6B9W1vdlqIJe1aVtqZVCgQUm5r8yuWw7OPHDomrOmg9y7QJ3AeOAWwZj%2FpSvetMfwv3zk0j4pQ%2BppgFr1QfzFpyTaDKqg%2B8jIBsg%3D%3D";

const gettingHotelInfo = async () => {
  // eslint-disable-next-line
  if (auth2?.currentUser?.uid != undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();

    //getting hotels
    const userHotels = getUserData.data()?.hotels;
    const currentHotel = getUserData.data()?.currentSelectedHotel;
    const logo = getUserData.data()?.logo || baseLogo;
    const pmsId = getUserData.data()?.pmsId
    console.log("pms id",pmsId)
  
    localStorage.setItem(
      "primaryColor",
      getUserData.data()?.color_code || "#6260EF"
    );
    const hotelsExtractedVal = userHotels.map(({ hotel_name, hotel_id }) => {
      return { hotel_id, hotel_name };
    });

    return { hotelsExtractedVal, currentHotel, logo,pmsId };
  }
};

// updating importance data

const updateHotelInfo = async (hotel) => {
  // getting userdata
  const getUserData = db2.collection(`users`).doc(auth2?.currentUser?.uid);

  // updating the data in firestore
  await getUserData.update({
    // eslint-disable-next-line
    ["currentSelectedHotel"]: hotel,
  });
  return true;
};

export { gettingHotelInfo, updateHotelInfo };
