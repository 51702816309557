import { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { Typography } from "@material-ui/core";
import noData from "../../../../images/noDataSellStats.png";

const ReservationByWeek = ({ salesStatRevenueWeekData }) => {
  const [data, setData] = useState(salesStatRevenueWeekData);

  useEffect(() => {
    setData(salesStatRevenueWeekData);
  }, [salesStatRevenueWeekData]);

  const series = [{ data: data?.map((da) => Math.round(da.revenue)) }];
  const options = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: data?.map((da) => {
        return da.weekdays;
      }),
    },
  };
  return (
    <div>
      {salesStatRevenueWeekData.length > 0 ? (
        <>
          <Typography variant="subtitle1" align="center">
            RESERVATION BY DAY OF THE WEEK
          </Typography>
          <Chart options={options} series={series} type="bar" />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noData} style={{ textAlign: "center" }}></img>
          <Typography variant="subtitle1" align="center">
            No data available for this KPI
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ReservationByWeek;
