import { ACTION_TYPES } from './types'

const OnSettingLoaderStart = () => {
  const testBool = true

  return {
    type: ACTION_TYPES.setLoader,
    payload: testBool,
  }
}

const OnSettingLoaderEnd = () => {
  const testBool = false

  return {
    type: ACTION_TYPES.setLoader,
    payload: testBool,
  }
}

export { OnSettingLoaderStart, OnSettingLoaderEnd }

