import '../bi.css'

import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  monthlyPaceFunc,
  revenuePace,
  yesterdayPaceFunc,
} from '../utils/revenueStatisticsAPiCalls'
import React, { useEffect, useState } from 'react'

import { Chart } from 'chart.js'
import { DateRange } from 'react-date-range'
import { Line } from 'react-chartjs-2'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import annotationPlugin from 'chartjs-plugin-annotation'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import MonthToDateOverview from '../monthOverview/MonthToDateOverview'
import YearToDateOverview from '../yearOverView/YearToDateOverview'
import { auth } from '../../../../../Auth/firebase'
import { useSelector } from 'react-redux'
import {
  abbreviateNumber,
  dataFormatting,
  formatInEuropeanNoSymbol,
} from '../../../../utils'
const useStyles = makeStyles((theme) => ({
  tablescroll: {
    minWidth: 350,
    maxHeight: 370,
    '&::-webkit-scrollbar': {
      height: 5,
    },
  },
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      margin: 'auto',
    },
  },
  statDetails: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  tableRow: {
    '&:nth-child(odd)': { backgroundColor: 'white !important' },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  cellTypo: {
    border: 0,

    color: '#000000',
  },
}))

const DailyBookingPace = (props) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(true)
  const [hotel, setHotel] = useState({})
  const classes = useStyles(theme)
  Chart.register(annotationPlugin)
  let { propertyId, propertyStatus } = props
  //--------------- ------ MISC---------------------

  const [dataUpdating, setDataUpdating] = useState(false)
  //--------------------- yesterdayPace---------------------

  // getting yesterday date
  const [showDateRangeYesterday, setShowDateRangeYesterday] = useState(false)
  const [yesterdayPaceStartDate, setYesterdayPaceStartDate] = useState(
    // dataFormatting(yesterday)
    dataFormatting(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  )

  const [yesterdayPaceEndDate, setYesterdayPaceEndDate] = useState(
    // dataFormatting(yesterday)
    dataFormatting(new Date())
  )
  const [yesterdayPaceData, setYesterdayPaceData] = useState({})
  const [updatingmonthlyPaceFunc, setUpdatingmonthlyPaceFunc] = useState(false)
  //------------------ Month to date future date nd data------------------
  // eslint-disable-next-line
  const [futureMonthStartDate, setFutureMonthlyStartDate] = useState(
    dataFormatting(new Date())
  )
  // eslint-disable-next-line
  const [futureMonthEndDate, setFutureMonthlyEndDate] = useState(
    dataFormatting(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    )
  )
  const [futureMonthDataApiCall, setFutureMonthDataApiCall] = useState({})

  //------------------ Month to date historical ------------------
  // eslint-disable-next-line
  const [historicalMonthStartDate, setHistoricalMonthlyStartDate] = useState(
    dataFormatting(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  )
  // eslint-disable-next-line
  const [historicalMonthEndDate, setHistoricalMonthlyEndDate] = useState(
    dataFormatting(new Date())
  )
  const [historicalMonthDataApiCall, setHistoricalMonthDataApiCall] = useState(
    {}
  )
  const [historicalMonthGraphDataApiCall, setHistoricalMonthGraphDataApiCall] =
    useState([])
  const [futureMonthGraphDataApiCall, setHistoricalMonthFutureDataApiCall] =
    useState([])

  //--------------------- monthly pace ---------------------
  const [showDateRangeMonthly, setShowDateRangeMonthly] = useState(false)
  const [monthlyPaceStartDate, setMonthlyPaceStartDate] = useState(
    dataFormatting(new Date(new Date().setDate(new Date().getDate() - 30)))
  )
  const [monthlyPaceEndDate, setMonthlyPaceEndDate] = useState(
    dataFormatting(new Date())
  )
  const [monthlyPaceData, setMonthlyPaceData] = useState([])
  //----------------- year to date historical -----------------
  // eslint-disable-next-line
  const [historicalYearStartDate, setHistoricalYearStartDate] = useState(
    dataFormatting(new Date(new Date().getFullYear(), 0, 1))
  )
  // eslint-disable-next-line
  const [historicalYearEndDate, setHistoricalYearEndDate] = useState(
    dataFormatting(new Date())
  )
  const [historicalYearDataApiCalls, setHistoricalYearDataApiCalls] = useState(
    {}
  )
  const [historicalYearDataGraph, setHistoricalYearDataGraph] = useState([])
  //----------------- year to date future -----------------
  // eslint-disable-next-line
  const [futureYearStartDate, setFutureYearStartDate] = useState(
    dataFormatting(new Date())
  )
  // eslint-disable-next-line
  const [futureYearEndDate, setFutureYearEndDate] = useState(
    dataFormatting(new Date(new Date().getFullYear(), 11, 31))
  )
  const [futureYearDataApiCalls, setFutureYearDataApiCalls] = useState({})
  const [futureYearDataGraph, setFutureYearDataGraph] = useState([])

  // date range picker ranges
  const [dateRangeStateYesterday, setDateRangeStateYesterday] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [dateRangeStateThirtyDays, setDateRangeStateThirtyDays] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  //--------------------- yesterdayPace ---------------------
  const handleSelectYesterday = (ranges) => {
    setDateRangeStateYesterday([ranges.selection])
    setYesterdayPaceStartDate(dataFormatting(ranges.selection.startDate))
    setYesterdayPaceEndDate(dataFormatting(ranges.selection.endDate))
  }
  const updatehandleSelectYesterday = () => {
    setShowDateRangeYesterday(false)
    setDataUpdating(true)
    // get yesterday pace
    yesterdayPaceFunc(yesterdayPaceStartDate, yesterdayPaceEndDate, propertyId)
      .then((resp) => {
        setYesterdayPaceData(resp.data.payload)
        setDataUpdating(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  //--------------------- last 30 days graph ---------------------
  const handleSelectMonthly = (ranges) => {
    setDateRangeStateThirtyDays([ranges.selection])
    setMonthlyPaceStartDate(dataFormatting(ranges.selection.startDate))
    setMonthlyPaceEndDate(dataFormatting(ranges.selection.endDate))
  }

  const updatehandleSelectMonthly = () => {
    setShowDateRangeMonthly(false)
    setUpdatingmonthlyPaceFunc(true)

    monthlyPaceFunc(monthlyPaceStartDate, monthlyPaceEndDate, propertyId)
      .then((resp) => {
        setMonthlyPaceData(resp.data.payload)
        setUpdatingmonthlyPaceFunc(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const hotelInfo = useSelector((state) => state.hotelList.currentHotel)
  useEffect(() => {
    // refresh token
    if (
      new Date().getTime() >
      new Date(localStorage.getItem('expirationDate')).getTime()
    ) {
      auth.currentUser.getIdToken(/* forceRefresh */ true).then((response) => {
        localStorage.setItem('token', response)
        const expirationDate = new Date(new Date().getTime() + 3600000)
        localStorage.setItem('expirationDate', expirationDate)
      })
    }

    // get hotel
    setHotel(hotelInfo)

    //future month
    const futureMonthPayload = {
      start_date: futureMonthStartDate,
      end_date: futureMonthEndDate,
      periodicity: 'monthly',
      time_stage: 'future',
      propertyId: propertyId,
    }
    //historical month
    const historicalMonthPayload = {
      start_date: historicalMonthStartDate,
      end_date: historicalMonthEndDate,
      periodicity: 'monthly',
      time_stage: 'historical',
      propertyId: propertyId,
    }
    //future month graph
    const futureMonthGraphPayload = {
      start_date: futureMonthStartDate,
      end_date: futureMonthEndDate,
      periodicity: 'monthly_graph',
      time_stage: 'future',
      propertyId: propertyId,
    }
    //historical month graph
    const historicalMonthGraphPayload = {
      start_date: historicalMonthStartDate,
      end_date: historicalMonthEndDate,
      periodicity: 'monthly_graph',
      time_stage: 'historical',
      propertyId: propertyId,
    }
    // ------- historical year -------
    const historicalYearlyPayload = {
      start_date: historicalYearStartDate,
      end_date: historicalYearEndDate,
      periodicity: 'yearly',
      time_stage: 'historical',
      propertyId: propertyId,
    }
    // ------- future year -------

    const futureYearlyPayload = {
      start_date: futureYearStartDate,
      end_date: futureYearEndDate,
      periodicity: 'yearly',
      time_stage: 'future',
      propertyId: propertyId,
    }
    // ------- historical year -------
    const historicalYearlyGraphPayload = {
      start_date: historicalYearStartDate,
      end_date: historicalYearEndDate,
      periodicity: 'yearly_week_graph',
      time_stage: 'historical',
      propertyId: propertyId,
    }
    // ------- future year -------

    const futureYearlyGraphPayload = {
      start_date: futureYearStartDate,
      end_date: futureYearEndDate,
      periodicity: 'yearly_month_graph',
      time_stage: 'future',
      propertyId: propertyId,
    }
    axios
      .all([
        monthlyPaceFunc(monthlyPaceStartDate, monthlyPaceEndDate, propertyId),
        revenuePace(futureMonthPayload),
        revenuePace(historicalMonthPayload),
        revenuePace(historicalYearlyPayload),
        revenuePace(futureYearlyPayload),
        revenuePace(historicalMonthGraphPayload),
        revenuePace(futureMonthGraphPayload),
        revenuePace(historicalYearlyGraphPayload),
        revenuePace(futureYearlyGraphPayload),
        yesterdayPaceFunc(
          yesterdayPaceStartDate,
          yesterdayPaceEndDate,
          propertyId
        ),
      ])
      .then(
        axios.spread((...responses) => {
          setMonthlyPaceData(responses[0].data.payload)
          setFutureMonthDataApiCall(responses[1].data.payload)
          setHistoricalMonthDataApiCall(responses[2].data.payload)
          setHistoricalYearDataApiCalls(responses[3].data.payload)
          setFutureYearDataApiCalls(responses[4].data.payload)
          setHistoricalMonthGraphDataApiCall(responses[5].data.payload)
          setHistoricalMonthFutureDataApiCall(responses[6].data.payload)
          setHistoricalYearDataGraph(responses[7].data.payload)
          setFutureYearDataGraph(responses[8].data.payload)
          setYesterdayPaceData(responses[9].data.payload)
          setLoading(false)
        })
      )
      .catch((errors) => {
        setMonthlyPaceData([])
        setFutureMonthDataApiCall([])
        setHistoricalMonthDataApiCall([])
        setHistoricalYearDataApiCalls([])
        setFutureYearDataApiCalls([])
        setHistoricalMonthGraphDataApiCall([])
        setHistoricalMonthFutureDataApiCall([])
        setHistoricalYearDataGraph([])
        setFutureYearDataGraph([])
        setYesterdayPaceData([])
      })
    // eslint-disable-next-line
  }, [propertyId])

  //labels and data
  const labels =
    monthlyPaceData &&
    monthlyPaceData?.map((d) => {
      return `${d['creation_date']?.substring(0, 10)}`
    })
  const data =
    monthlyPaceData &&
    monthlyPaceData?.map((d) => {
      return Math.round(d['Revenue'])
    })
  //--------------------- last 30 days graph end ---------------------

  //--------------------- stop loading when propertyStatus is false ---------------------

  useEffect(() => {
    if (propertyStatus === false) {
      setLoading(false)
    }
  }, [propertyStatus])
  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <img
            src='/images/loading.gif'
            alt='competatorTableList'
            width='350'
          />
        </div>
      ) : propertyStatus ? (
        <>
          <Typography variant='h4' align='center' style={{ fontWeight: 600 }}>
            DAILY BOOKING PACE OVERVIEW{' '}
          </Typography>
          <Typography variant='subtitle1' align='center'>
            This is the booking pace at which gross reservation are made.
            (Cancellations are included). More details in the "Pace Report"
            page.
          </Typography>

          {/* grid for stats */}

          <Grid container spacing={2}>
            {/* Yesterday Pace */}

            <Grid xs={12} lg={5} item>
              {dataUpdating ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '500px',
                  }}
                >
                  <div
                    className='loading setting_title_typo'
                    data-loading-text='Processing Data ...'
                  ></div>
                </div>
              ) : (
                <div>
                  <Typography
                    variant='h5'
                    className='mt-3'
                    align='center'
                    style={{ fontWeight: 600 }}
                  >
                    Yesterday Pace
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    className='mt-3'
                    alignItems='stretch'
                    justifyContent='space-between'
                  >
                    <Grid md={12} lg={5} item>
                      <Typography variant='subtitle1' align='left'>
                        {' '}
                        Yesterday breakdown or choose an interval.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        id='standard-basic'
                        onClick={() => setShowDateRangeYesterday(true)}
                        value={`${yesterdayPaceStartDate} - ${yesterdayPaceEndDate}`}
                        variant='outlined'
                      />
                    </Grid>{' '}
                  </Grid>
                  {showDateRangeYesterday && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '350px',
                      }}
                    >
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelectYesterday}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRangeStateYesterday}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          variant='contained'
                          onClick={() => setShowDateRangeYesterday(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='contained'
                          onClick={() => updatehandleSelectYesterday()}
                        >
                          Apply
                        </Button>
                      </div>{' '}
                    </div>
                  )}
                  <div className='comp__top mt-3 mb-4'>
                    <div>
                      <Typography variant='subtitle1' align='center'>
                        {' '}
                        Total gross (cancellation included)
                      </Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle1' align='center'>
                        {' '}
                        Date of stay overview
                      </Typography>
                    </div>
                  </div>

                  {/* RevenueStatistics table */}

                  <Grid container spacing={2}>
                    <Grid xs={12} lg={4} item>
                      <div
                        style={{ marginTop: '20px' }}
                        className={classes.statDetails}
                      >
                        <div className='yd__pace_spacing'>
                          <Typography variant='subtitle1'>
                            {' '}
                            Gross Revenue
                          </Typography>
                          <Typography variant='h5' style={{ fontWeight: 500 }}>
                            {abbreviateNumber(
                              yesterdayPaceData?.total_gross_revenue
                            )}{' '}
                            €
                          </Typography>
                        </div>
                        <div className='yd__pace_spacing'>
                          <Typography variant='subtitle1'>
                            {' '}
                            Gross Room Nights
                          </Typography>
                          <Typography variant='h5' style={{ fontWeight: 500 }}>
                            {abbreviateNumber(
                              yesterdayPaceData.gross_room_nights
                            )}
                          </Typography>
                        </div>

                        <div className='yd__pace_spacing'>
                          <Typography variant='subtitle1'>
                            {' '}
                            Gross ADR
                          </Typography>
                          <Typography variant='h5' style={{ fontWeight: 500 }}>
                            {abbreviateNumber(
                              Math.round(yesterdayPaceData.gross_adr)
                            )}{' '}
                            €
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} lg={8} item>
                      <TableContainer className={classes.tablescroll}>
                        <Table aria-label='simple table'>
                          <TableHead>
                            <TableRow className={classes.tableRow}>
                              <TableCell className={classes.cellTypo}>
                                Daily Stay Date
                              </TableCell>
                              <TableCell
                                className={classes.cellTypo}
                                align='right'
                              >
                                Gross Revenue
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {yesterdayPaceData?.stay_overview?.map(
                              (row, index) => (
                                <TableRow
                                  key={index}
                                  className={classes.tableRow}
                                >
                                  <TableCell
                                    className={classes.cellTypo}
                                    component='th'
                                    scope='row'
                                  >
                                    {row['Daily Stay Date'].substring(0, 10)}
                                  </TableCell>
                                  <TableCell
                                    className={classes.cellTypo}
                                    align='right'
                                  >
                                    {formatInEuropeanNoSymbol(
                                      Math.round(row['Gross Revenue'])
                                    )}{' '}
                                    €
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
            {/* Last 30 days Pace */}
            <Grid xs={12} lg={1} item>
              <Divider
                className={classes.divider}
                orientation='vertical'
                variant='middle'
                textAlign='center'
              />
            </Grid>

            <Grid xs={12} lg={6} item>
              {updatingmonthlyPaceFunc ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '500px',
                  }}
                >
                  <div
                    className='loading setting_title_typo'
                    data-loading-text='Processing Data ...'
                  ></div>
                </div>
              ) : (
                <>
                  {' '}
                  <Typography
                    variant='h5'
                    className='mt-3'
                    align='center'
                    style={{ fontWeight: 600 }}
                  >
                    Last 30 days Pace
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    className='mt-3'
                    alignItems='stretch'
                    justifyContent='space-between'
                  >
                    <Grid md={12} lg={6} item>
                      <Typography variant='subtitle1' align='left'>
                        {' '}
                        Booking pace for the last 30 days but you can choose{' '}
                        <br /> a different time range. Cancellation included.
                      </Typography>
                    </Grid>{' '}
                    <Grid item>
                      <TextField
                        id='standard-basic'
                        onClick={() => setShowDateRangeMonthly(true)}
                        value={`${monthlyPaceStartDate} - ${monthlyPaceEndDate}`}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                  {showDateRangeMonthly && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '350px',
                      }}
                    >
                      s
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelectMonthly}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRangeStateThirtyDays}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          variant='contained'
                          onClick={() => setShowDateRangeMonthly(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='contained'
                          onClick={() => updatehandleSelectMonthly()}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  )}
                  <Line
                    data={{
                      labels: labels,
                      datasets: [
                        {
                          label: hotel.hotel_name,
                          data: data,
                          fill: false,
                          backgroundColor: 'rgba(72 , 217, 165 ,1)',
                          borderColor: 'rgba(72 , 217, 165 ,1)',
                          lineTension: 0.5,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      animations: {
                        radius: {
                          duration: 400,
                          easing: 'linear',
                          loop: (context) => context.active,
                        },
                      },
                      hoverRadius: 12,
                      hoverBackgroundColor: '#84bdd7',
                      interaction: {
                        mode: 'nearest',
                        intersect: false,
                        axis: 'x',
                      },
                      plugins: {
                        tooltip: {
                          enabled: true,
                        },
                      },
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
          <hr />
          <MonthToDateOverview
            propertyId={propertyId}
            historicalMonthDataApiCall={historicalMonthDataApiCall}
            futureMonthDataApiCall={futureMonthDataApiCall}
            historicalMonthGraphDataApiCall={historicalMonthGraphDataApiCall}
            futureMonthGraphDataApiCall={futureMonthGraphDataApiCall}
          />

          <hr />
          <YearToDateOverview
            propertyId={propertyId}
            historicalYearDataApiCalls={historicalYearDataApiCalls}
            futureYearDataApiCalls={futureYearDataApiCalls}
            historicalYearDataGraph={historicalYearDataGraph}
            futureYearDataGraph={futureYearDataGraph}
          />
        </>
      ) : (
        <>
          <h3>Contact support to activate your Property</h3>
        </>
      )}
    </>
  )
}

export default DailyBookingPace
