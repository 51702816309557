import { Chart } from 'chart.js'
import { Line } from 'react-chartjs-2'
import React, { useEffect, useState } from 'react'
import annotationPlugin from 'chartjs-plugin-annotation'
import { useSelector } from 'react-redux'

const FutureLineChart = ({ futureMonthDataGraph }) => {
  const [hotel, setHotel] = useState({})
  const [data, setData] = useState([])
  Chart.register(annotationPlugin)
  const hotelInfo = useSelector((state) => state.hotelList.currentHotel)

  useEffect(() => {
    setHotel(hotelInfo)
    setData(futureMonthDataGraph)
  }, [futureMonthDataGraph])

  const labels = data?.map((da) => da.stay_date.substring(0, 10))
  const dataValues = data?.map((da) => Math.round(da.revenue))
  function average(ctx) {
    const values = ctx.chart.data.datasets[0].data
    return values.reduce((a, b) => a + b, 0) / values.length
  }
  return (
    <div>
      {' '}
      <Line
        data={{
          labels: labels,
          datasets: [
            {
              label: hotel.hotel_name,
              data: dataValues,
              fill: false,
              backgroundColor: 'rgba(72 , 217, 165 ,1)',
              borderColor: 'rgba(72 , 217, 165 ,1)',
              lineTension: 0.5,
            },
          ],
        }}
        options={{
          animations: {
            radius: {
              duration: 400,
              easing: 'linear',
              loop: (context) => context.active,
            },
          },
          hoverRadius: 12,
          hoverBackgroundColor: 'yellow',
          interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x',
          },
          plugins: {
            tooltip: {
              enabled: true,
            },
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  borderColor: 'black',
                  borderDash: [6, 6],
                  borderDashOffset: 0,
                  borderWidth: 3,
                  label: {
                    enabled: true,
                    content: (ctx) => 'Average: ' + average(ctx).toFixed(2),
                    position: 'end',
                  },
                  scaleID: 'y',
                  value: (ctx) => average(ctx),
                },
              },
            },
          },
        }}
      />
    </div>
  )
}

export default FutureLineChart
