import { DateRange } from "react-date-range";
// import { addYears, endOfYear, isSameDay, startOfYear } from 'date-fns'
import { useEffect, useState } from "react";

import { Button, Chip, Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DefaultUrl } from "../../BaseUrl";
import DoneIcon from "@material-ui/icons/Done";
import FadeIn from "react-fade-in";
import { OnSettingLoaderStart } from "../../redux/actions/loader";
import { useDispatch } from "react-redux";
import { useStylesSeason } from "./muiStyles";
import { dataFormatting } from "../../utils";
import { getYearDates } from "./utils/utils";
import axios from "axios";
import { reformatDate } from "../monthlyPrices/utils/utils";
const UpdateSeason = ({ currentSeason, disableTrigger }) => {
  // const [qualityLink, setQualityLink] = useState("");
  // const [qualityLoader, setQualityLoader] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRanges, setDateRanges] = useState([]);
  const classes = useStylesSeason();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [updatingSeason, setUpdatingSeason] = useState(false);
  const [minRange, setMinRange] = useState();
  const [maxRange, setMaxRange] = useState();
  const [yearDateArray, setYearDateArray] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    setDateRanges(currentSeason);

    if (dateRanges[0] != undefined) {
      const nextYear = new Date().getFullYear() + 1;

      setMinRange(new Date("2020-01-01"));
      setMaxRange(new Date(nextYear, 11, 31));
    }
  }, [currentSeason]);

  useEffect(() => {
    let {currentDate,finalDate} = getYearDates();
    setYearDateArray([reformatDate(currentDate),reformatDate(finalDate)]);
  }, []);
  useEffect(() => {
    console.log(dateRanges);
  }, [dateRanges]);

  // selecting different date ranges
  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);

    if (
      dataFormatting(ranges.selection.startDate) !=
      dataFormatting(ranges.selection.endDate)
    ) {
      const selectedRange = [
        dataFormatting(ranges.selection.startDate),
        dataFormatting(ranges.selection.endDate),
      ];
      setDateRanges((arr) => [...arr, selectedRange]);
    }
  };
  // deleting selected date ranges
  const handleDelete = (index) => {
    const removeDaterange = dateRanges.filter((_, i) => i != index);

    setDateRanges(removeDaterange);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  // update seasons call
  ////////////////////////////////////////////////////////////
  const currentYear = new Date().getFullYear(); // 2020
  const previousYear = currentYear - 1;
  const firstDayOfYear = new Date(previousYear, 0, 1);
  const lastDayOfYear = new Date(previousYear, 11, 31);

  const lastYearRange = [
    dataFormatting(firstDayOfYear),
    dataFormatting(lastDayOfYear),
  ];
  ////////////////////////////////////////////////////////////

  const updateSeason = () => {
    // dispatch(OnSettingLoaderStart());
    axios
      .post("https://publish-message-3cuixb5lia-ew.a.run.app/smartBudget", {
        topicName: "smartBudget",
        payload: {
          pmsId: "1",
          propertyId: localStorage.getItem("propertyId"),
          dates: yearDateArray,
          season: dateRanges,
          isDateRange:true,
        },
      })
      .then((res) => {
        {
          console.log(res);
          setUpdatingSeason(false);
          setShowDateRange(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setUpdatingSeason(false);
        setShowDateRange(false);
      });
    // dateRanges.length === 0
    //   ? axios
    //       .post(
    //         `${DefaultUrl}/update_season`,

    //         {
    //           method: "POST",
    //           headers: {
    //             Authorization: localStorage.getItem("token"),
    //           },
    //           body: {
    //             propertyId: propertyId,
    //             date_ranges: [lastYearRange],
    //             pms_name: localStorage.getItem("pmsName"),
    //           },
    //         }
    //       )
    //       .then((res) => {
    // setUpdatingSeason(false);
    // setShowDateRange(false);
    //       })
    //       .catch((err) => {
    // setUpdatingSeason(false);
    // setShowDateRange(false);
    //       })
    //   : axios
    //       .post(`${DefaultUrl}/update_season`, {
    //         method: "POST",
    //         headers: {
    //           Authorization: localStorage.getItem("token"),
    //         },
    //         body: {
    //           propertyId: propertyId,
    //           date_ranges: dateRanges,
    //           pms_name: localStorage.getItem("pmsName"),
    //         },
    //       })
    //       .then((res) => {
    //         setUpdatingSeason(false);
    //         setShowDateRange(false);
    //       })
    //       .catch((err) => {
    //         setUpdatingSeason(false);
    //         setShowDateRange(false);
    //       });
  };

  // const getQualitReport = () => {
  //   setQualityLoader(true);
  //   axios
  //     .post(`${DefaultUrl}/data_quality_report`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //       body: {
  //         propertyId: propertyId,
  //         pms_name: localStorage.getItem("pmsName"),
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       setQualityLink(res.data.message);
  //       setQualityLoader(false);
  //     })
  //     .catch((err) => {
  //       setQualityLoader(false);
  //       console.log(err);
  //     });
  // };

  return (
    <div>
      <FadeIn transitionDuration="4000">
        {updatingSeason ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25vh",
            }}
          >
            <div
              class="loading setting_title_typo"
              data-loading-text="Processing Data ..."
            ></div>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h5" style={{ margin: "32px 0px" }}>
                <b className="setting_title_typo">Reference Season</b>
              </Typography>
              {/* {qualityLink !== "" && qualityLoader === false ? (
                <Button
                  style={{
                    height: 50,
                    marginRight: 2,
                    backgroundColor: localStorage.getItem("primaryColor"),
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                    href={qualityLink}
                    target="_blank"
                  >
                    View Report
                  </a>
                </Button>
              ) : (
                <Button
                  onClick={() => disableTrigger && getQualitReport()}
                  style={{
                    height: 50,
                    marginRight: 2,
                    backgroundColor: localStorage.getItem("primaryColor"),
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {qualityLoader && (
                    <>
                      <CircularProgress style={{ color: "white" }} size={20} />{" "}
                      Generating Report...
                    </>
                  )}
                  {!qualityLoader && "Get Data Quality Report"}
                </Button>
              )} */}

              {showDateRange ? (
                <div>
                  <Button
                    variant="outlined"
                    startIcon={<DoneIcon />}
                    style={{
                      height: 50,
                      marginRight: 2,
                      backgroundColor: localStorage.getItem("primaryColor"),
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      updateSeason();
                      setUpdatingSeason(true);
                    }}
                  >
                    Update
                  </Button>

                  <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    style={{
                      height: 50,
                      marginRight: 2,
                      backgroundColor: localStorage.getItem("primaryColor"),
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setShowDateRange(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    height: "50px",
                    width: "70px",
                    marginRight: 2,
                    backgroundColor: "#e0e0e0",
                    borderRadius: "10px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    disableTrigger && setShowDateRange(true);
                  }}
                >
                  <img
                    src="/images/edit.svg"
                    height="25"
                    width="70"
                    alt="edit_proportion"
                  />
                </div>
              )}
            </div>

            <Grid container spacing={2}>
              <Grid item>
                <div className={classes.root}>
                  {dateRanges && (
                    <>
                      {dateRanges?.map((range, index) => (
                        <Chip
                          key={index}
                          onDelete={() => {
                            disableTrigger && handleDelete(index);
                          }}
                          style={{
                            backgroundColor:
                              localStorage.getItem("primaryColor"),
                            color: "white",
                          }}
                          label={`${range[0]} - ${range[1]}`}
                        />
                      ))}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                {showDateRange && (
                  <DateRange
                    maxDate={maxRange}
                    minDate={minRange}
                    editableDateInputs={false}
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    rangeColors={[localStorage.getItem("primaryColor")]}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </FadeIn>
    </div>
  );
};

export default UpdateSeason;
