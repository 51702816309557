import Sidebar from './Sidebar'

import './documentationPage.scss'

const DocumentationPage = () => {
	return (
		<div className="documentation_page">
			<div className="left_sidebar">
				<Sidebar />
			</div>
		</div>
	)
}

export default DocumentationPage
