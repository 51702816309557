import styled from "styled-components";

const Card1 = styled.div`
  background-color: ${({ isEmpty }) => (isEmpty ? "#F4F4F4" : "#F4F4F4")};
  height: auto;
  width: 165px;
  border-radius: 11px;
  overflow: auto;
  margin: 5px;
  margin-right: 30px;
`;

const DropDown = styled.div`
  background-color: "#F4F4F4";
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 25px;
  color: #000000;

`;
const Paper = styled.div`
display: flex;
justify-content: space-between;
margin-top: 10px;
font-family: Plus Jakarta Sans;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 25px;
color:  "#F4F4F4";

  span {
    padding: 0;
  }

  .accodion {
    padding: 0px; /* changed */
  }

  &.show {
    height: ${({ setHeight }) => setHeight}px;
  }
`;

export { Card1, Header, Paper, DropDown };
