import React, { useEffect, useState } from "react";
import "./UserProgress.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
} from "@material-ui/core";
import { db2 } from "../../Auth/firebase";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import Header from "../LandingPage/Header";
import CheckIcon from "../../images/checkIcon.png";
import processOngoing from "../../images/processOngoing.png";
import info from "../../images/info.png";
import { ReactComponent as OnboardingCompleted } from "../../images/onboardingCompleted.svg";
import { ReactComponent as OnboardingIncomplete } from "../../images/onboardingIncomplete.svg";
import { useHistory } from "react-router";

function UserProgress({ propertyId }) {
  const [statusData, setStatusData] = useState();
  const history = useHistory();

  const getStatus = (propertyId) => {
    const docRef = doc(db2, "first_time_status", `1_${propertyId}`);

    const unSubscribe = onSnapshot(docRef, (data) => {
      if (data.exists()) {
        console.log(data.data());
        if (
          data.data().local_events_scraped &&
          data.data().market_importance_loaded &&
          data.data().market_prices_loaded &&
          data.data().onresnotif_executed &&
          data.data().roomtypes_loaded &&
          data.data().smart_budget_executed
        ) {
          deleteDoc(docRef);
        }
        setStatusData(data.data());
      }else{
        if (location.pathname !== "/dashboard/monthlyprices") {
          history.push("/dashboard/monthlyprices");
        }
      }
    });
    // const docSnap = await getDoc(docRef);
    // if (docSnap.exists()) {
    //   return docSnap.data();
    // }
  };

  useEffect(() => {
    propertyId && getStatus(propertyId);
  }, [propertyId]);
  return (
    <div>
      {/* <Header /> */}

      {statusData && (
        <>
          {statusData.local_events_scraped &&
          statusData.market_importances_loaded &&
          statusData.market_prices_loaded &&
          statusData.onresnotif_executed &&
          statusData.roomtypes_loaded &&
          statusData.smart_budget_executed ? (
            <div style={{ textAlign: "center", marginTop: "80px" }}>
              <OnboardingCompleted className="main-svg" />
              <h3>Property Data Loaded</h3>
              <button
                style={{
                  backgroundColor: "#6260EF",
                  fontFamily: "Plus Jakarta Sans",
                  border: "solid #6260EF",
                  padding: "10px 15px",
                  outline: "none",
                  fontSize: "14px",
                  color: "#fff",
                  fontWeight: "600",
                  borderRadius: "11px",
                  width: "150px",
                  marginTop: "20px",
                }}
                onClick={() => history.push("/dashboard/monthlyprices")}
              >
                Go to Dashboard
              </button>
            </div>
          ) : (
            <div style={{ textAlign: "center", marginTop: "80px" }}>
              <OnboardingIncomplete className="main-svg" />
              <h3>Property Data Loading</h3>
              <p style={{ fontSize: "12px" }}>
                <span>
                  <img
                    src={info}
                    alt="info"
                    height={"15px"}
                    style={{ marginRight: "10px" }}
                  />
                </span>
                Please be patient. This May Take up to one hour
              </p>
            </div>
          )}

          <div className="progress-container">
            <div className="process-container">
              <p>Loading Competitor Prices</p>
              {statusData.market_prices_loaded ? (
                <img src={CheckIcon} alt="done" className="check-icon" />
              ) : (
                <img
                  src={processOngoing}
                  alt="done"
                  className="rotate-image"
                  width={"30px"}
                />
              )}
            </div>
            <div className="process-container">
              <p>Loading Room Type Data</p>
              {statusData.roomtypes_loaded ? (
                <img src={CheckIcon} alt="done" className="check-icon" />
              ) : (
                <img
                  src={processOngoing}
                  alt="done"
                  className="rotate-image"
                  width={"30px"}
                />
              )}
            </div>
            <div className="process-container">
              <p>Setting Up Pricing</p>
              {statusData.smart_budget_executed &&
              statusData.market_importances_loaded ? (
                <img src={CheckIcon} alt="done" className="check-icon" />
              ) : (
                <img
                  src={processOngoing}
                  alt="done"
                  className="rotate-image"
                  width={"30px"}
                />
              )}
            </div>
            <div className="process-container">
              <p>Loading Events Data</p>
              {statusData.local_events_scraped ? (
                <img src={CheckIcon} alt="done" className="check-icon" />
              ) : (
                <img
                  src={processOngoing}
                  alt="done"
                  className="rotate-image"
                  width={"30px"}
                />
              )}
            </div>
            <div className="process-container">
              <p>Setting Up Dashboard Data</p>
              {statusData.onresnotif_executed ? (
                <img src={CheckIcon} alt="done" className="check-icon" />
              ) : (
                <img
                  src={processOngoing}
                  alt="done"
                  className="rotate-image"
                  width={"30px"}
                />
              )}
            </div>
          </div>
          {/* {statusData.monthly_view_first_time_status && (
            // statusData.daily_view_first_time_status &&
            // statusData.property_data_status &&
            // statusData.irev_v1_status &&
            // statusData.market_importances_status &&
            // statusData.hotel_data_status &&
            // statusData.market_data_status &&
            <h3 style={{ textAlign: "center", marginTop: "10px" }}>
              Finishing up Setup{" "}
              <span>
                <img
                  src={processOngoing}
                  alt="done"
                  className="rotate-image"
                  width={"30px"}
                />
              </span>
            </h3>
          )} */}
        </>
      )}
    </div>
  );
}

export default UserProgress;
