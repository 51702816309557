import { CopyBlock, solarizedLight } from "react-code-blocks";
import React, { useEffect, useState } from "react";
import { jsFetch, nodeJs, pythonHttp, pythonRequest } from "../ApiCode";

const GetMarketPrices = ({language}) => {
  const [languageDemo, setLanguageDemo] = useState("");
  const [highlightLanguage, setHighlightLanguage] = useState("javascript");

  const argumentsToPass =
  "{  \r\n    \"hotel_id\" : \"1612456\",\r\n    \"start_date\" : \"2022-03-01\",\r\n    \"end_date\" : \"2022-03-04\"\r\n  '''}"
  const method = "GET";
  const baseUrl = "base_url";
  const endpoint = "get_market_prices";
  useEffect(() => {
    if (language === "jsFetch") {
      setHighlightLanguage("javascript");
      setLanguageDemo(jsFetch(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "nodeJs") {
      setHighlightLanguage("javascript");
      setLanguageDemo(nodeJs(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonHttp") {
      setHighlightLanguage("python");
      setLanguageDemo(pythonHttp(argumentsToPass, method, endpoint, baseUrl));
    }
    if (language === "pythonRequest") {
      setHighlightLanguage("python");

      setLanguageDemo(
        pythonRequest(argumentsToPass, method, endpoint, baseUrl)
      );
    }
  }, [language]);
  return (
    <div >
      {" "}
      <h3>Get Market Prices</h3>
      <p>Get prices of market leaders of given hotel ID between two dates ( Consumer will Provide that dates )</p>
      <div className="">

        <div className="demo">
          <CopyBlock
            language={highlightLanguage}  
            text={languageDemo}
            showLineNumbers={true}
            theme={solarizedLight}
            wrapLines={true}
            codeBlock
          />
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default GetMarketPrices;
