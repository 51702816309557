import "../Index.scss";
import "../dashboard.scss";

import { useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  FormControl,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";

import { getCurrentSeasons, gettingHotelID } from "./utils/hotelSeasonData";
import { DefaultUrl } from "../../BaseUrl";
import HotelInfo from "./HotelInfo";
import {
  OnSettingLoaderEnd,
  OnSettingLoaderStart,
} from "../../redux/actions/loader";
import Proportions from "./Proportions/Proportions";
import UpdateRms from "./UpdateRms";
import UpdateSeason from "./updateSeason";
import { auth, auth2, db2 } from "../../../Auth/firebase";
import ImportanceUpdate from "./ImportanceUpdate";
import { useStylesSetting } from "./muiStyles";
import DoneIcon from "@material-ui/icons/Done";
import { MuiColorInput } from "mui-color-input";
import {
  getSelectedGroupData,
  gettingCurrentSelectdProperty,
  updateCurrentSelectedProperty,
} from "../../utils";
import { db } from "../../../Auth/firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import AttachIcon from "../../../images/attachIcon.png";
import { getYearDates } from "./utils/utils";
import { reformatDate } from "../monthlyPrices/utils/utils";

function Settings({ propertyIdP, propertyStatus, hotelsList }) {
  const [editDashboardPref, setEditDashboardPref] = useState(false);
  const [propertyColor, setPropertyColor] = useState(
    localStorage.getItem("primaryColor")
  );
  const [propertyLogo, setPropertyLogo] = useState();
  const classes = useStylesSetting();
  const [pricingTableData, setPricingTableData] = useState([]);
  const [allDynamicities, setAllDynamicities] = useState([]);
  const [basePricesIrev, setBasePriceIrev] = useState([]);
  const [irevCoefficients, setIrevCoefficients] = useState({});
  const [allPrices, setAllPrices] = useState({});
  const [hotelID, setHotelID] = useState("");
  const [currentSeason, setCurrentSeason] = useState([]);
  const [allHotels, setAllHotels] = useState([]);
  const [basePriceValue, setBasePriceValue] = useState("");
  const [proportions, setProportions] = useState([]);
  const [priceTableWithNoFilter, setPriceTableWithNoFilter] = useState([]);
  const [potential, setPotential] = useState(1);
  const [settingLoading, setSettingLoading] = useState(true);
  const [disableTrigger, setDisableTrigger] = useState(true);
  const [monthlyTrigger, setMonthlyTrigger] = useState(false);
  const [dailyTrigger, setDailyTrigger] = useState(false);
  const [dashboardPreferencesUpdating, setDashboardPreferencesUpdating] =
    useState(false);
  const [groupAccountId, setGroupAccountId] = useState("");
  const dispatch = useDispatch();
  const settingReduxLoading = useSelector((state) => state.loader);
  const [propertyId, setPropertyId] = useState("");
  const pmsName = localStorage.getItem("pmsName");
  const potentialValuesHeader = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ];
  const powerDict = { low: "con", medium: "normal", high: "agg" };
  const dynamicityValuesHeader = ["low", "medium", "high"];

  // FUNCTIONS

  function formatPrices(prices, dynamicities) {
    /* 
    Function reformats the prices by changing the rows and columns of multidimentional array

    prices: Object of arrays
    dynamicities: array of strings

    returns the reformatted array
    */

    let arr = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    for (let i in prices) {
      for (let j = 0; j < prices[i].length; j++) {
        arr[j][i] = prices[i][j];
        arr[parseInt(i.split("_")[1])]["DYNAMICITY"] =
          dynamicities[parseInt(i.split("_")[1])];
        arr[parseInt(i.split("_")[1])]["POTENTIAL"] =
          parseInt(i.split("_")[1]) + 1;
      }
    }
    return arr;
  }

  function reformatSeasons(seasons) {
    const formatedSeasons = [];
    for (let i = 0; i < seasons.length; i++) {
      const season = seasons[i];
      formatedSeasons.push([season.start_date, season.end_date]);
    }
    return formatedSeasons;
  }
  const getRootDoc = (propertyId, pmsName) => {
    /* 
    Function fetches the data in the root doc of a property and sets states accordingly

    propertyId: String
    pmsName: String

    does not return anything
    */

    const docRef = doc(db2, pmsName, propertyId);

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const proportions = doc.get("roomtypes");
        const dynamicities = doc.get("dynamicities");
        const prices = doc.get("pricing_table");
        const basePrices = doc.get("base_prices_irev");
        const irevCoeffs = doc.get("irev_coefficients");
        const refSeason = reformatSeasons(doc.get("season"));
        console.log("refSeason");
        const formattedPrices = formatPrices(prices, dynamicities);

        setProportions(proportions);
        setAllDynamicities(dynamicities);
        setPriceTableWithNoFilter(formattedPrices);
        setBasePriceIrev(basePrices);
        setIrevCoefficients(irevCoeffs);
        setAllPrices(prices);
        setCurrentSeason(refSeason);
      } else {
        setProportions([]);
        setAllDynamicities([]);
        setPriceTableWithNoFilter({});
        setBasePriceIrev([]);
        setIrevCoefficients({});
        setAllPrices([]);
        setCurrentSeason([]);
      }
    });
  };

  useEffect(() => {
    //filter the pricing table value when potential changes
    if (priceTableWithNoFilter) {
      let filterWithPotential = priceTableWithNoFilter.filter((el) => {
        return el.POTENTIAL == potential;
      });
      setPricingTableData(filterWithPotential);
      setSettingLoading(false);
      dispatch(OnSettingLoaderEnd());
    }
  }, [potential, priceTableWithNoFilter]);

  function getUpdatedBasePrices() {
    // Function gets all prices and returns the first array index of all prices in an array

    const updatedBasePrices = [];
    for (let i in allPrices) {
      updatedBasePrices.push(allPrices[i][0]);
    }
    updatedBasePrices.sort();

    return updatedBasePrices;
  }

  function formatCoefficients(allDynamicities) {
    /*
    formats and filters the coefficients according the dynamicities 

    allDynamicities: array of Strings

    returns reformatted power array
    */
    const powers = [];

    for (let i = 0; i < 10; i++) {
      powers.push({
        a: irevCoefficients[`${powerDict[allDynamicities[i]]}_a`][i],
        b: irevCoefficients[`${powerDict[allDynamicities[i]]}_b`][i],
      });
    }
    return powers;
  }

  function calculateFinalPrices(powers, basePrices) {
    /*
    Calculates the prices again using coefficients

    powers: array of objects
    basePrices: array

    returns calculated prices
    */
    const finalPrices = [[], [], [], [], [], [], [], [], [], []];
    for (let level = 0; level < 10; level++) {
      const { a, b } = powers[level];
      for (let occ = 0; occ < 10; occ++) {
        finalPrices[level].push(a * (occ * occ) + b * occ + basePrices[level]);
      }
    }
    return finalPrices;
  }

  function reformatFinalPrices(finalPrices) {
    /*
    reformats the calculated data

    finalPrices: array of arrays

    returns reformatted prices
    */
    const reformattedPrices = {
      occupancy_0: [],
      occupancy_1: [],
      occupancy_2: [],
      occupancy_3: [],
      occupancy_4: [],
      occupancy_5: [],
      occupancy_6: [],
      occupancy_7: [],
      occupancy_8: [],
      occupancy_9: [],
    };

    for (let i = 0; i < finalPrices.length; i++) {
      for (let j = 0; j < finalPrices[i].length; j++) {
        reformattedPrices[`occupancy_${i}`].push(finalPrices[j][i]);
      }
    }
    return reformattedPrices;
  }

  async function updateFirebase(
    pmsName,
    propertyId,
    allDynamicities,
    basePrices,
    finalPrices
  ) {
    /*
    updates the new prices, dynamicities and base prices in firebase

    pmsName: String
    propertyId: String
    allDynamicities: array of Strings
    basePrices: array of numbers
    finalPrices: array of arrays

    does not return anything
    */
    const docRef = doc(db2, pmsName, propertyId);
    try {
      await updateDoc(docRef, { dynamicities: allDynamicities });
      await updateDoc(docRef, { base_prices_irev: basePrices });
      await updateDoc(docRef, { pricing_table: finalPrices });
    } catch (error) {
      console.error(error);
    }
  }
  function updatePricingTableV2(allDynamicities) {
    /* 
    This Function is the main function to change the prices when dynamicity is changed

    allDynamicities : array of Strings

    does not return anything
    */

    const irevPrice = basePricesIrev[0];
    const powers = formatCoefficients(allDynamicities);
    const basePrices = getUpdatedBasePrices();
    const finalPrices = reformatFinalPrices(
      calculateFinalPrices(powers, basePrices)
    );
    const { currentDate, finalDate } = getYearDates();
    const dates = [reformatDate(currentDate), reformatDate(finalDate)];

    basePrices.unshift(irevPrice);
    updateFirebase(
      localStorage.getItem("pmsName"),
      propertyId,
      allDynamicities,
      basePrices,
      finalPrices
    );

    sendCalculatePricesMessage("1", propertyId, dates);
  }

  async function sendCalculatePricesMessage(pmsId, propertyId, dates) {
    axios.post(
      "https://publish-message-3cuixb5lia-ew.a.run.app/calculatePrices",
      {
        topicName: "calculatePrices",
        payload: {
          pmsId: pmsId,
          propertyId: propertyId,
          dates: dates,
          isDateRange: true,
        },
      }
    );
  }

  const handlePropertyChange = (event) => {
    const hotelID = event.target.value;
    // filter hotelID from hotelList to get singlevalue
    const hotel = hotelsList.filter((hotel) => {
      return hotel.hotel_id == hotelID;
    });
    setPropertyId(event.target.value);
    dispatch(OnSettingLoaderStart());
    updateCurrentSelectedProperty(hotel)
      .then((resp) => {
        toast.success("Property updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating property");
      });
  };

  const triggerConnection = async (propertyId, db) => {
    if (propertyId) {
      const docRef = doc(
        db,
        "settings_page_status",
        localStorage.getItem("pmsName"),
        propertyId,
        "data"
      );
      const realtime = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          let data = doc.data();
          setDailyTrigger(data.daily_status);
          setMonthlyTrigger(data.monthly_status);
          // setDisableTrigger(data.daily_status && data.monthly_status);
        }
      });
    }
  };

  function handleColorChange(newValue) {
    setPropertyColor(newValue);
  }

  const sendDynamicityLog = (
    propertyId,
    email,
    potential,
    previousDynamicity,
    dynamicity
  ) => {
    axios
      .post(`${DefaultUrl}/user_activity_log`, {
        body: {
          propertyId: propertyId,
          email: email,
          variable: "pricing",
          pricing: {
            potential: `${potential}`,
            dynamicity: dynamicity,
            previous_dynamicity: previousDynamicity,
          },
          time: new Date(),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .catch((err) => console.error(err));
  };

  // ------------------ changing pricing table by changing dynamicity value ------------------
  const handleDynamicitychange = (dynamicityVal) => {
    sendDynamicityLog(
      propertyId,
      localStorage.getItem("email"),
      potential,
      allDynamicities[potential - 1],
      dynamicityVal
    );

    let dummy = allDynamicities;
    dummy[potential - 1] = dynamicityVal;
    setAllDynamicities(dummy);

    propertyId && updatePricingTableV2(dummy);
  };

  function getBase64(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const saveDashboardPref = () => {
    if (propertyLogo) {
      setDashboardPreferencesUpdating(true);
      const logo = getBase64(propertyLogo);
      logo.then((b64) => {
        const arr = b64.split(",");
        propertyLogo &&
          axios
            .post(`${DefaultUrl}/add_logo`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: {
                userId: localStorage.getItem("currentGroup"),
                file: arr[1],
                colorCode: propertyColor,
              },
            })
            .then(() => {
              toast.success("Dashboard Preferences Updated Successfully");
              setEditDashboardPref(false);
              setDashboardPreferencesUpdating(false);
            })
            .catch((err) => {
              console.error(err);
              toast.error("Error! could not update preferences");
              setEditDashboardPref(false);
              setPropertyColor(localStorage.getItem("primaryColor"));
              setDashboardPreferencesUpdating(false);
            });
      });
    } else {
      setDashboardPreferencesUpdating(true);
      axios
        .post(`${DefaultUrl}/add_logo`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: {
            userId: localStorage.getItem("currentGroup"),
            file: "",
            colorCode: propertyColor,
          },
        })
        .then(() => {
          toast.success("Color Updated Successfully");
          setEditDashboardPref(false);
          setDashboardPreferencesUpdating(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error! could not update preferences");
          setEditDashboardPref(false);
          setPropertyColor(localStorage.getItem("primaryColor"));
          setDashboardPreferencesUpdating(false);
        });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accountType") === "pms") {
      gettingCurrentSelectdProperty().then((resp) => {
        setPropertyId(resp[0].hotel_id);
      });
    }
  }, []);

  const gettingBasePrice = (propertyId) => {
    const docRef = doc(db2, localStorage.getItem("pmsName"), propertyId);

    const unSubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const basePrice = doc.data().base_prices_irev[1];
        setBasePriceValue(parseInt(basePrice));
      }
    });
  };

  useEffect(() => {
    // checking for expiration if idToken and get new one
    if (propertyId && pmsName) {
      propertyId && triggerConnection(propertyId, db);
      if (
        new Date().getTime() >
        new Date(localStorage.getItem("expirationDate")).getTime()
      ) {
        auth2.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then((response) => {
            localStorage.setItem("token", response);
            const expirationDate = new Date(new Date().getTime() + 3600000);
            localStorage.setItem("expirationDate", expirationDate);
          });
      }
      if (
        (localStorage.getItem("accountType") === "group" &&
          propertyId !== undefined &&
          propertyStatus) ||
        (localStorage.getItem("accountType") === "pms" &&
          propertyId !== undefined)
      ) {
        propertyId &&
          pmsName &&
          getRootDoc(propertyId, localStorage.getItem("pmsName"));

        // gettingHotelID(propertyId)
        //   .then((resp) => {
        //     setHotelID(resp);
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //   });

        gettingBasePrice(propertyId);

        // .then((resp) => {
        //   console.log(resp)
        //   setBasePriceValue(parseInt(resp));
        // })
        // .catch((err) => {
        //   console.error(err);
        // });
      }
    } // eslint-disable-next-line
  }, [propertyId, propertyStatus]);

  useEffect(() => {
    const accountType = localStorage.getItem("accountType");
    if (accountType === "pms") {
      getSelectedGroupData().then((res) => {
        // encode res in base 64 to get the account id
        const { getGroupId } = res;
        const accountId = btoa(getGroupId);
        setGroupAccountId(accountId);

        setAllHotels(hotelsList);
      });
    }
    if (accountType === "group") {
      setPropertyId(propertyIdP);
      const getGroupId = auth?.currentUser?.uid;
      const accountId = btoa(getGroupId);
      setGroupAccountId(accountId);

      setPropertyId(propertyIdP);
    }
  }, [propertyId, hotelsList, propertyIdP]);

  return (
    <>
      {settingReduxLoading || settingLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <img
            src="/images/loading.gif"
            alt="monthlyprices loader"
            width="350"
          />
        </div>
      ) : (localStorage.getItem("accountType") === "group" && propertyStatus) ||
        localStorage.getItem("accountType") === "pms" ? (
        <>
          {localStorage.getItem("accountType") === "pms" && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectBorder}
                >
                  <InputLabel id="property" className="select_label">
                    Select Property
                  </InputLabel>
                  <Select
                    labelId="property"
                    id="property"
                    label="Property"
                    className="cardsForm"
                    defaultValue={propertyId}
                    onChange={handlePropertyChange}
                  >
                    {allHotels?.map((hotel, index) => (
                      <MenuItem value={hotel.hotel_id}>
                        {hotel.hotel_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <br />
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={6} lg={6}>
              <Grid container alignContent="space-between">
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "-9px",
                    marginBottom: "30px",
                    padding: "10px 10px",
                    borderRadius: "11px",
                    backgroundColor: "#f8f8f8",
                    overflowX: "hidden",
                  }}
                >
                  <UpdateRms
                    propertyId={propertyId}
                    currentSeason={currentSeason}
                    groupAccountId={groupAccountId}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "-9px",
                    marginBottom: "10px",
                    padding: "10px 10px",
                    borderRadius: "11px",
                    backgroundColor: "#f8f8f8",
                    overflowX: "hidden",
                  }}
                >
                  {" "}
                  <HotelInfo
                    propertyId={propertyId}
                    basePriceValue={basePriceValue}
                    hotelID={hotelID}
                    groupAccountId={groupAccountId}
                    disabledTrigger={disableTrigger}
                  />
                </Grid>
                {/* removed this */}
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "-9px",
                    marginBottom: "30px",
                    marginTop: "10px",
                    padding: "10px 10px",
                    borderRadius: "11px",
                    backgroundColor: "#f8f8f8",
                    overflowX: "hidden",
                  }}
                >
                  <div className="dash-pref-header">
                    <Typography variant="h5" style={{ margin: "32px 0px" }}>
                      <b className="setting_title_typo">
                        Update Dashboard Preferences
                      </b>
                    </Typography>
                    {editDashboardPref ? (
                      <div>
                        {!dashboardPreferencesUpdating ? (
                          <Button
                            variant="outlined"
                            startIcon={<DoneIcon />}
                            style={{
                              height: 50,
                              marginRight: 2,
                              backgroundColor:
                                localStorage.getItem("primaryColor"),
                              color: "white",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              saveDashboardPref();
                            }}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            style={{
                              height: 50,
                              marginRight: 2,
                              backgroundColor: "#6260EF",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            <div class="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </Button>
                        )}

                        <Button
                          variant="outlined"
                          // startIcon={<CloseIcon />}
                          style={{
                            height: 50,
                            marginRight: 2,
                            backgroundColor: "#e0e0e0",
                            color: "#333333",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            setEditDashboardPref(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "50px",
                          width: "70px",
                          marginRight: 2,
                          backgroundColor: "#e0e0e0",
                          borderRadius: "10px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          disableTrigger && setEditDashboardPref(true);
                        }}
                      >
                        <img
                          src="/images/edit.svg"
                          height="25"
                          width="70"
                          alt="edit_proportion"
                        />
                      </div>
                    )}
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <MuiColorInput
                        value={propertyColor}
                        onChange={handleColorChange}
                        disabled={!editDashboardPref}
                        id="color"
                        name="color"
                        label="Primary Color"
                        format="hex"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="outlined"
                        component="label"
                        className="upload-icon"
                        disabled={!editDashboardPref}
                      >
                        <img
                          src={AttachIcon}
                          height="20px"
                          style={{ marginRight: "5px" }}
                        />
                        Dashboard Logo
                        <input
                          type="file"
                          hidden
                          onChange={(event) => {
                            setPropertyLogo(event.target.files[0]);
                          }}
                        />
                      </Button>
                      <p
                        style={{
                          fontSize: "10px",
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        {propertyLogo?.name}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid
									item
									xs={12}
									style={{
										margin: '-9px',
										marginBottom: '30px',
										padding: '10px 10px',
										borderRadius: '11px',
										backgroundColor: '#f8f8f8',
										overflowX: 'hidden',
									}}
								>
									<UpdatePriceFrequency propertyId={propertyId} groupAccountId={groupAccountId} />
								</Grid> */}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              style={{
                margin: "-9px",
                padding: "20px 20px",
                borderRadius: "11px",
                backgroundColor: "#f8f8f8",
                overflowX: "hidden",
              }}
            >
              <Grid container alignContent="space-between">
                <Grid item xs={12}>
                  <Proportions
                    propertyId={propertyId}
                    proportions={proportions}
                    disableTrigger={disableTrigger}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UpdateSeason
                    propertyId={propertyId}
                    currentSeason={currentSeason}
                    groupAccountId={groupAccountId}
                    disableTrigger={disableTrigger}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <br />
          <br />
          <br />
          <br />

          <Grid container spacing={2} className="mb-4">
            <Grid
              item
              xs={12}
              style={{
                width: `100%`,
                padding: "20px 20px",
                borderRadius: "11px",
                backgroundColor: "#f8f8f8",
                overflowX: "hidden",
                height: "100%",
              }}
            >
              <FadeIn transitionDuration="4000">
                <ImportanceUpdate
                  propertyId={propertyId}
                  disabled={disableTrigger}
                />
              </FadeIn>
            </Grid>
          </Grid>

          {/* //update season date range */}
          {/* before pricing */}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                width: `100%`,
                padding: "20px 20px",
                borderRadius: "11px",
                backgroundColor: "#f8f8f8",
                overflowX: "hidden",
                height: "100%",
              }}
            >
              <FadeIn transitionDuration="4000">
                <Typography variant="h4" style={{ margin: "20px 0px" }}>
                  <b className="setting_title_typo">Pricing</b>
                </Typography>
                <section className="pricing__section__width">
                  <div>
                    <Grid
                      container
                      spacing={2}
                      className="pricing_table_alignments"
                    >
                      <Grid item sm={12} md={2}>
                        <Typography variant="h5" style={{ margin: "20px 0px" }}>
                          <b className="">Potential</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={9}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexWrap: "wrap",
                          }}
                        >
                          {potentialValuesHeader.map((potentialVal, index) => (
                            <Button
                              key={index}
                              variant="outlined"
                              elevation={0}
                              onClick={() => {
                                setPotential(potentialVal);
                              }}
                              className={
                                potentialVal == pricingTableData[0]?.POTENTIAL
                                  ? "selected__pricing__btns"
                                  : "unselected__pricing__btns"
                              }
                            >
                              {potentialVal}
                            </Button>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className="pricing_table_alignments"
                    >
                      <Grid item sm={12} md={2}>
                        <Typography variant="h5" style={{ margin: "20px 0px" }}>
                          <b className="">Dynamicity</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={9}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexWrap: "wrap",
                          }}
                        >
                          {dynamicityValuesHeader.map(
                            (dynamicityVal, index) => (
                              <Button
                                key={index}
                                variant="outlined"
                                elevation={0}
                                className={
                                  dynamicityVal ===
                                  allDynamicities[potential - 1]
                                    ? "selected__pricing__btns"
                                    : "unselected__pricing__btns"
                                }
                                onClick={() =>
                                  handleDynamicitychange(dynamicityVal)
                                }
                              >
                                {dynamicityVal}
                              </Button>
                            )
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {/* show data in tables */}
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    className={classes.tableCont}
                  >
                    <Table
                      style={{ minWidth: 450, backgroundColor: "#f8f8f8" }}
                      aria-label="simple table"
                    >
                      {pricingTableData.map((row, index) => (
                        <>
                          <TableRow
                            style={{ backgroundColor: "white" }}
                            key={index}
                          >
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>{" "}
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                0%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_0.toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                60%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_6.toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            {" "}
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                10%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_1.toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                70%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_7.toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>{" "}
                          </TableRow>
                          <TableRow style={{ backgroundColor: "white" }}>
                            {" "}
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                20%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_2.toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                80%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_8.toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>{" "}
                          </TableRow>

                          <TableRow>
                            {" "}
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                30%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{parseFloat(row.occupancy_3).toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                90%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{parseFloat(row.occupancy_9).toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>{" "}
                          </TableRow>

                          <TableRow style={{ backgroundColor: "white" }}>
                            {" "}
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                40%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_4.toFixed(0)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>{" "}
                          </TableRow>
                          <TableRow>
                            {" "}
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                50%
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            >
                              <Typography className="pricing_cell">
                                €{row.occupancy_5.toFixed(0)}
                              </Typography>
                            </TableCell>{" "}
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellTypo}
                            ></TableCell>{" "}
                          </TableRow>
                        </>
                      ))}
                    </Table>
                  </TableContainer>{" "}
                </section>
              </FadeIn>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <h3>Contact support to activate your Property</h3>
        </>
      )}
    </>
  );
}

Settings.propTypes = {
  window: PropTypes.func,
};
//   window: PropTypes.func,
// };

export default Settings;
// export default Settings;
