import "chartjs-adapter-moment";
import "../dashboard.scss";
import "../Index.scss";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Cards from "./Cards";
import Chart from "chart.js/auto";
import FadeIn from "react-fade-in";
import LatestChanges from "./LatestChangesTable";
import { OnMonthlyLoaderEnd } from "../../redux/actions/monthlyLoader";
import { OnSettingLoaderStart } from "../../redux/actions/loader";
import PropTypes from "prop-types";
import SellStatsCards from "./SellStatsCards";
import Typography from "@material-ui/core/Typography";
import annotationPlugin from "chartjs-plugin-annotation";
import { auth2, db, db2 } from "../../../Auth/firebase";
import { useHistory } from "react-router-dom";
import { useStylesMonthlyPrices } from "./muiStyles";
import DummySellStatsCards from "./DummySellStats";
import { getRoomTypesFirebase } from "./monthlyViewApiCalls";
import { calendarDummyData } from "./CalendarDummyData";
import { onSnapshot, doc, getDoc } from "firebase/firestore";

import { ReactComponent as NoData } from "../../../images/No_data.svg";
import { getSellStatsDummyData, reformatDate } from "./utils/utils";
const selectLoader = (state) => state.monthlyLoader;

Chart.register(annotationPlugin);
function MonthlyPrices(props) {
  const classes = useStylesMonthlyPrices();
  let { propertyId, sideBar, propertyStatus } = props;
  const history = useHistory();
  const [room, setRoom] = useState("");
  const [allRoomIds, setAllRoomIds] = useState("");
  const [roomId, setRoomId] = useState("");
  const [totalRooms, setTotalRooms] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month] = useState(1 + new Date().getMonth());
  const [firMonth, setFirMonth] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [data, setData] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [noData, setNoData] = useState(true);
  const dispatch = useDispatch();

  //------------sellstats card ----------------
  const [sellStatCardLoading, setSellStatCardLoading] = useState(false);
  const [sellStatCardYear, setSellStatCardYear] = useState(
    new Date().getFullYear()
  );
  const [sellStatCardMonth, setSellStatCardMonth] = useState(
    new Date().getMonth() + 1
  );

  // V2 States
  const [allCalendarData, setAllCalendarData] = useState([]);
  const [allLatestChangesData, setAllLatestChangesData] = useState([]);
  const [allRootData, setAllRootData] = useState([]);
  const [roomsMap, setRoomsMap] = useState([]);
  const [hotelCity, setHotelCity] = useState();
  // V2 triggers
  const [rootDocTrigger, setRootDocTrigger] = useState(false);
  // cards data scaffolding
  const cardsDataScaffolding = {
    achievedValue: null,
    date: "",
    name: "",
    progressValue: null,
    startDate: "",
    targetValue: null,
    type: "",
  };

  const handleChangeYear = (event) => {
    setSellStatCardYear(event.target.value);
  };
  const handleChangeMonth = (event) => {
    setSellStatCardMonth(monthsArray.indexOf(event.target.value));
  };

  let monthsArray = [
    "", // do not disturb
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthsArrayFiltered =
    new Date().getFullYear() === sellStatCardYear
      ? monthsArray.slice(new Date().getMonth() + 1)
      : monthsArray;

  //------------useEffect calls----------------
  useEffect(() => {
    propertyId && year && month && getOccProgressData(propertyId, year, month);
  }, [propertyId, year, month]);

  // getting sell stat cards data for first time
  useEffect(() => {
    propertyId &&
      sellStatCardMonth &&
      sellStatCardYear &&
      getSellStatsData(propertyId, reformatDate(new Date(), true));
  }, [propertyId]);

  useEffect(() => {
    propertyId && getPropertyRootDoc(propertyId);
  }, [propertyId]);

  useEffect(() => {
    setFirMonth(new Date().getMonth())
    if (localStorage.getItem("accountType")==="pms") {
      history.push("/pmsdashboard/manageaccount")
    }
  }, []);

  useEffect(() => {
    if (propertyStatus === false) {
      setLoadingCards(false);
      dispatch(OnSettingLoaderStart());
      dispatch(OnMonthlyLoaderEnd());
    }
    console.log("property status", propertyStatus);
  }, [propertyStatus]);

  useEffect(() => {
    // checking for expiration if idToken and get new one
    if (
      new Date().getTime() >
      new Date(localStorage.getItem("expirationDate")).getTime()
    ) {
      auth2.currentUser.getIdToken(/* forceRefresh */ true).then((response) => {
        localStorage.setItem("token", response);
        const expirationDate = new Date(new Date().getTime() + 3600000);
        localStorage.setItem("expirationDate", expirationDate);
      });
    }

    if (propertyId !== undefined && propertyStatus) {
      getRoomTypesFirebase(propertyId, db)
        .then((rooms) => {
          let allRooms = [];
          let roomIds = [];
          rooms.map((room) => {
            allRooms.push(room?.name);
            roomIds.push(room?.id);
          });
          setRoom(allRooms[0]);
          setRoomId(roomIds[0]);
          let room = allRooms[0];
          let roomId = roomIds[allRooms.indexOf(room)];
        })
        .catch((err) => console.error(err));
    }
  }, [propertyId, propertyStatus]);

  const updateSellStatCard = () => {
    if (propertyId != undefined) {
      setSellStatCardLoading(true);
      getSellStatsData(
        propertyId,
        reformatDate(new Date(sellStatCardYear, sellStatCardMonth, 1), true)
      );
    }
  };

  // getting cards data

  const getSellStatsData = (propertyId, month) => {
    setSellStatCardLoading(true);
    const docRef = doc(
      db2,
      localStorage.getItem("pmsName"),
      String(propertyId),
      "monthly_data",
      month
    );

    const unSubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const dummyData = [];
        const monthlyData = doc.get("monthly");
        const yearlyData = doc.get("yearly");
        
        // reformatting to older format
        let revOtb = {};
        revOtb.achievedValue = monthlyData.ss_rev;
        revOtb.targetValue = monthlyData.sb_rev;
        revOtb.name = "Revenue OTB";
        revOtb.type = "Current Month";
        revOtb.date = reformatDate(new Date(year,sellStatCardMonth,0));
        revOtb.startDate = sellStatCardMonth+1 === new Date().getMonth()+2 ? reformatDate(new Date()) : reformatDate(new Date(year,parseInt(sellStatCardMonth-1),1));
        revOtb.progressValue =
          Math.round(((monthlyData.ss_rev - monthlyData.sb_rev) / monthlyData.sb_rev) * 100);
        dummyData.push(revOtb);

        let adrOtb = {};
        adrOtb.achievedValue = monthlyData.ss_adr;
        adrOtb.targetValue = monthlyData.sb_adr;
        adrOtb.name = "Average Daily Revenue OTB";
        adrOtb.type = "Current Month";
        adrOtb.date = reformatDate(new Date(year,sellStatCardMonth,0));
        adrOtb.startDate = sellStatCardMonth+1 === new Date().getMonth()+2 ? reformatDate(new Date()) : reformatDate(new Date(year,parseInt(sellStatCardMonth-1),1));
        adrOtb.progressValue =
          Math.round(((monthlyData.ss_adr - monthlyData.sb_adr) / monthlyData.sb_adr) * 100);
        dummyData.push(adrOtb);

        let occupancy = {};
        occupancy.achievedValue = monthlyData.ss_occ * 100;
        occupancy.targetValue = monthlyData.sb_occ * 100;
        occupancy.name = "Occupancy";
        occupancy.type = "Current Month";
        occupancy.date = reformatDate(new Date(year,sellStatCardMonth,0));
        occupancy.startDate = sellStatCardMonth+1 === new Date().getMonth()+2 ? reformatDate(new Date()) : reformatDate(new Date(year,parseInt(sellStatCardMonth-1),1));
        occupancy.progressValue =
        Math.round(((monthlyData.ss_occ - monthlyData.sb_occ) / monthlyData.sb_occ) * 100);
        dummyData.push(occupancy);

        let revOtbYearly = cardsDataScaffolding;
        revOtbYearly.achievedValue = yearlyData.ss_rev;
        revOtbYearly.targetValue = yearlyData.sb_rev;
        revOtbYearly.name = "Revenue OTB(Yearly)";
        revOtbYearly.type = "Total";
        revOtbYearly.date = yearlyData.latestReservationDate;
        revOtbYearly.startDate = sellStatCardMonth+1 === new Date().getMonth()+2 ? reformatDate(new Date()) : reformatDate(new Date(year,parseInt(sellStatCardMonth-1),1));
        revOtbYearly.progressValue =
        Math.round(((yearlyData.ss_rev - yearlyData.sb_rev) / yearlyData.sb_rev) * 100);
        dummyData.push(revOtbYearly);

        let adrOtbYearly = {};
        adrOtbYearly.achievedValue = yearlyData.ss_adr;
        adrOtbYearly.targetValue = yearlyData.sb_adr;
        adrOtbYearly.name = "Average Daily Revenue OTB(Yearly)";
        adrOtbYearly.type = "Total";
        adrOtbYearly.date = yearlyData.latestReservationDate;
        adrOtbYearly.startDate = sellStatCardMonth+1 === new Date().getMonth()+2 ? reformatDate(new Date()) : reformatDate(new Date(year,parseInt(sellStatCardMonth-1),1));
        adrOtbYearly.progressValue =
        Math.round(((yearlyData.ss_adr - yearlyData.sb_adr )/ yearlyData.sb_adr) * 100);
        dummyData.push(adrOtbYearly);

        let roomsSoldYearly = {};
        roomsSoldYearly.achievedValue = yearlyData.numRoomsSold;
        roomsSoldYearly.name = "Total Rooms Sold(Yearly)";
        roomsSoldYearly.type = "Total";
        roomsSoldYearly.targetValue = "";
        roomsSoldYearly.date = "";
        roomsSoldYearly.startDate = "";
        roomsSoldYearly.progressValue = "";
        dummyData.push(roomsSoldYearly);

        //pushing data to cards data
        setCardData(dummyData);
        setNoData(false);
        setSellStatCardLoading(false);
      }else{
        const dummyData = getSellStatsDummyData(sellStatCardYear,sellStatCardMonth);
        setCardData(dummyData);
        setNoData(false);
        setSellStatCardLoading(false);
      }
    });
  };
  // -------------- getting root document ----------------

  async function getPropertyRootDoc(propertyId) {
    setRootDocTrigger(false);
    setRoomsMap([]);
    const docRef = doc(db2, "octorate", String(propertyId));
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setAllRootData(docSnap.data());
      setTotalRooms([]);
      setAllRoomIds([]);
      for (let i = 0; i < docSnap.data().roomtypes.length; i++) {
        const room = docSnap.data().roomtypes[i];
        setTotalRooms((rooms) => [...rooms, room.name]);
        setAllRoomIds((rooms) => [...rooms, room.id]);
        setRoomsMap((rooms) => [
          ...rooms,
          { roomName: room.name, roomId: room.id },
        ]);
        setHotelCity(docSnap.data().city);
      }
      setRootDocTrigger(true);
    }
  }

  //  -------------------- Fetching OCC progress data -------------------

  function getOccProgressData(propertyId, year, month) {
    const daysInMonth = new Date(year, month, 0).getDate();
    for (let i = 0; i < daysInMonth; i++) {
      // reformats the date to YYYY-MM-DD
      const date = reformatDate(new Date(year, month - 1, i + 1));
      const docRef = doc(
        db2,
        "octorate",
        String(propertyId),
        "days",
        String(date)
      );
      const unSubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          let dummyData = {};
          dummyData.date = date;
          dummyData.prices = doc.data().irevPerRoomType;
          setAllLatestChangesData((data) => [...data, dummyData]);
        } else {
          console.error("no data");
        }
      });
    }
  }

  return (
    <div>
      {props?.location?.state?.token !== null ||
      localStorage.getItem("token") ? (
        <>
          {propertyStatus !== false ? (
            <div>
              <div>
                {allRoomIds && propertyId && rootDocTrigger && hotelCity && (
                  <Cards
                    propertyId={propertyId}
                    room={totalRooms[0]}
                    totalRooms={totalRooms}
                    firMonth={firMonth}
                    roomIds={allRoomIds}
                    parentLoading={loadingCards}
                    city={hotelCity}
                  />
                )}
              </div>
              {/****** Sell Stats ******/}
              {sellStatCardLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <div
                    class="loading setting_title_typo"
                    data-loading-text="Processing Data ..."
                  ></div>
                </div>
              ) : (
                <div>
                  <FadeIn transitionDuration="4000">
                    <Typography variant="h5" style={{ margin: "20px 0px" }}>
                      <p className="sell-stats-heading">
                        Sell Stats
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "5px",
                          }}
                        >
                          / Smart budget
                        </span>
                      </p>
                    </Typography>

                    <Grid
                      container
                      spacing={2}
                      className={props.sideBar ? "sell_cards" : ""}
                      alignItems={"center"}
                    >
                      {sellStatCardLoading ? (
                        <Grid item xs={12} md={2}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.selectBorder}
                          >
                            <InputLabel className="select_label" id="month">
                              Month
                            </InputLabel>
                            <Select
                              MenuProps={{ disableScrollLock: false }}
                              labelId="month"
                              id="month"
                              value={1}
                              label="month"
                              style={{
                                width: "120px",
                              }}
                            >
                              <MenuItem value={1}>...</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={2}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.selectBorder}
                          >
                            <InputLabel className="select_label" id="month">
                              Month
                            </InputLabel>
                            <Select
                              MenuProps={{ disableScrollLock: false }}
                              labelId="month"
                              id="month"
                              value={monthsArray[sellStatCardMonth]}
                              label="month"
                              onChange={handleChangeMonth}
                              style={{
                                width: "120px",
                              }}
                            >
                              {monthsArrayFiltered.map((mon, index) => (
                                <MenuItem value={mon}>{mon}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {sellStatCardLoading ? (
                        <Grid item xs={12} md={2}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.selectBorder}
                          >
                            <InputLabel className="select_label" id="year">
                              Year
                            </InputLabel>
                            <Select
                              MenuProps={{ disableScrollLock: false }}
                              labelId="year"
                              id="year"
                              defaultValue={1}
                              label="year"
                              style={{
                                width: "120px",
                              }}
                              sx={{
                                "&:hover": {
                                  "&& fieldset": {
                                    border: `1px solid ${localStorage.getItem(
                                      "primaryColor"
                                    )}`,
                                    // border: "1px solid #6260ef",
                                    borderRadius: "8px",
                                  },
                                },
                                "& .MuiSelectSelect:focus": {
                                  backgroundColor: "white",
                                },
                              }}
                            >
                              <MenuItem value={1}>...</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={2}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.selectBorder}
                          >
                            <InputLabel className="select_label" id="year">
                              Year
                            </InputLabel>
                            <Select
                              MenuProps={{ disableScrollLock: false }}
                              labelId="year"
                              id="year"
                              defaultValue={sellStatCardYear}
                              label="year"
                              onChange={handleChangeYear}
                              style={{
                                width: "120px",
                              }}
                              sx={{
                                "&:hover": {
                                  "&& fieldset": {
                                    border: `1px solid ${localStorage.getItem(
                                      "primaryColor"
                                    )}`,
                                    // border: "1px solid #6260ef",
                                    borderRadius: "8px",
                                  },
                                },
                                "& .MuiSelectSelect:focus": {
                                  backgroundColor: "white",
                                },
                              }}
                            >
                              <MenuItem value={new Date().getFullYear()}>
                                {new Date().getFullYear()}
                              </MenuItem>
                              <MenuItem value={new Date().getFullYear() + 1}>
                                {new Date().getFullYear() + 1}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {sellStatCardLoading ? (
                        <Grid item xs={12} md={2}>
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              marginTop: "0px",
                              width: "120px",
                              height: "40px",
                              background: "rgba(98, 96, 239, 1)",
                            }}
                          >
                            ...
                          </Button>
                        </Grid>
                      ) : (
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            marginTop: "0px",
                            width: "120px",
                            height: "40px",
                            background: localStorage.getItem("primaryColor"),
                          }}
                          onClick={() => updateSellStatCard()}
                        >
                          update
                        </Button>
                      )}{" "}
                      <Grid item xs={12} md={6}></Grid>
                      {noData ? (
                        <Grid item xs={12}>
                          <div className="noData-container">
                            <NoData
                              className="noData-svg"
                              fill={localStorage.getItem("primaryColor")}
                            />
                            <p>
                              Sorry!
                              <br />
                              No Data available for selected parameters
                            </p>
                          </div>
                        </Grid>
                      ) : (
                        cardData?.map((card, index) => (
                          <Grid item xs={12} md={6} lg={4} key={index}>
                            {sellStatCardLoading ? (
                              <DummySellStatsCards></DummySellStatsCards>
                            ) : (
                              <SellStatsCards
                                title={card.name}
                                img="/images/Emoji.svg"
                                amount={Math.round(card.achievedValue)}
                                actualamount={Math.round(card.targetValue)}
                                subimg="/images/event_note.svg"
                                text={card.type}
                                textone={`${card.startDate} - `}
                                texttwo={card.date}
                                value={card.progressValue}
                                sideBar={sideBar}
                              />
                            )}
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </FadeIn>
                </div>
              )}

              {/****** Sell Stats end******/}

              {/****** Price Per Day chart ******/}
              <div>
                {propertyId && roomsMap && (
                  <FadeIn transitionDuration="1000">
                    <Typography variant="h5" style={{ margin: "20px 0px" }}>
                      <b className="dailyview_title_typo">System Suggested Price Per Day</b>
                    </Typography>
                    <LatestChanges
                      propertyId={propertyId}
                      roomsData={roomsMap}
                    />
                  </FadeIn>
                )}
              </div>
              <br />
            </div>
          ) : (
            <>
              <h3>Contact support to activate your Property</h3>{" "}
            </>
          )}
        </>
      ) : (
        history.push("/SignInPage")
      )}
    </div>
  );
}

MonthlyPrices.propTypes = {
  window: PropTypes.func,
};

export default MonthlyPrices;
