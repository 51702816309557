import './Header.scss'

import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuIcon from '@material-ui/icons/Menu'
import logo from '../../images/logo.svg'
// import logo from '../../images/logo_new.png'
import { useAuth } from '../Context/AuthUserContext'
import { useDispatch } from 'react-redux'
import { auth } from '../../Auth/firebase'
import { OnGetAllHotels } from '../redux/actions/getAllHotels'
import { gettingHotelInfo } from '../utils/getHotelInfo'

//drawer elements used

const useStyles = makeStyles((theme) =>
  createStyles({
    appBarClr: {
      backgroundColor: 'rgb(255 255 255)',
      marginBottom: '-70px',
    },
    drawer: {
      backgroundColor: 'rgb(255 255 255)',
      width: 200,
    },
  })
)

export default function Header() {
  const [open, setState] = useState(false)
  const { logOut } = useAuth()
  const classes = useStyles()
  const [hotels, setHotels] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (auth?.currentUser?.uid !== undefined) {
      gettingHotelInfo().then((response) => {
        setHotels(response.hotelsExtractedVal)
        dispatch(OnGetAllHotels(response))
      })
    }
    // eslint-disable-next-line
  }, [])
  //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    //changes the function state according to the value of open
    setState(open)
  }
  return (
    <AppBar position='static' className={classes.appBarClr}>
      <Container maxWidth='xl'>
        <div className='flex_nav'>
          <div>
            <Hidden lgUp>
              <IconButton
                edge='start'
                aria-label='open drawer'
                onClick={toggleDrawer(true)}
                className='burger_icon'
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Link to='/LandingPage' className='MonthlyLogo'>
              <img
                src={logo}
                alt='logo'
                style={{ height: '60px', padding: '15px 0 0 5px' }}
              />
            </Link>
          </div>
          <Hidden mdDown>
            <div className='Nav' style={{ fontFamily: 'Plus Jakarta Sans', zIndex:2 }}>
              <a href='/LandingPage#HowItWorks' className='nav_items'>
                How it Works
              </a>
              <a href='/LandingPage#Pricing' className='nav_items'>
                Pricing
              </a>

              <Link to='/documentation' className='nav_items'>
                Documentation
              </Link>

              <a href='/LandingPage#ContactUs' className='nav_items'>
                Contact Us
              </a>
              <Link to='/PrivacyPolicy' className='nav_items'>
                Privacy Policy
              </Link>

              {localStorage.getItem('token') &&
              localStorage.getItem('accountType') == 'group' ? (
                <>
                  {hotels.length !== 0 ||
                  localStorage.getItem('hotelLength') !== 0 ? (
                    <>
                      {' '}
                      <Link to='/dashboard/monthlyprices'>
                        <button
                          style={{
                            backgroundColor: 'white',
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#6260EF',
                            border: 'solid #6260EF',
                          }}
                          className='nav_items'
                        >
                          Dashboard
                        </button>
                      </Link>
                      <button
                        style={{
                          backgroundColor: '#6260EF',
                          fontFamily: 'Plus Jakarta Sans',
                          border: 'solid #6260EF',
                        }}
                        className='nav_items'
                        onClick={() => logOut()}
                      >
                        Log Out
                      </button>
                    </>
                  ) : (
                    <>
                      <Link to='/noproperty'>
                        <button
                          style={{
                            backgroundColor: 'white',
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#6260EF',
                            border: 'solid #6260EF',
                          }}
                          className='nav_items'
                        >
                          Dashboard
                        </button>
                      </Link>
                      <button
                        style={{
                          backgroundColor: '#6260EF',
                          fontFamily: 'Plus Jakarta Sans',
                          border: 'solid #6260EF',
                        }}
                        className='nav_items'
                        onClick={() => logOut()}
                      >
                        Log Out
                      </button>
                    </>
                  )}
                </>
              ) : localStorage.getItem('token') &&
                localStorage.getItem('accountType') == 'pms' ? (
                <>
                  <Link to='/pmsdashboard/manageaccount'>
                    <button
                      style={{
                        backgroundColor: 'white',
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#6260EF',
                        border: 'solid #6260EF',
                      }}
                      className='nav_items'
                    >
                      Dashboard
                    </button>
                  </Link>
                  <button
                    style={{
                      backgroundColor: '#6260EF',
                      fontFamily: 'Plus Jakarta Sans',
                      border: 'solid #6260EF',
                    }}
                    className='nav_items'
                    onClick={() => logOut()}
                  >
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  <Link to='/SignInPage'>
                    <button
                      style={{
                        backgroundColor: 'white',
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#6260EF',
                        border: 'solid #6260EF',
                      }}
                      className='nav_items'
                    >
                      Sign In
                    </button>
                  </Link>
                  <Link to='/SignUp'>
                    <button
                      style={{
                        backgroundColor: '#6260EF',
                        fontFamily: 'Plus Jakarta Sans',
                        border: 'solid #6260EF',
                      }}
                      className='nav_items'
                    >
                      Sign Up
                    </button>
                  </Link>
                </>
              )}
            </div>
          </Hidden>
        </div>
        {/* The outside of the drawer */}
        <Drawer
          //from which side the drawer slides in
          anchor='left'
          //if open is true --> drawer is shown
          open={open}
          //function that is called when the drawer should close
          onClose={toggleDrawer(false)}
          //function that is called when the drawer should open
          onOpen={toggleDrawer(true)}
        >
          {/* The inside of the drawer */}
          <div className={classes.drawer}>
            {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
            <IconButton sx={{ mb: 2 }}>
              <CloseIcon onClick={toggleDrawer(false)} />
            </IconButton>

            <Box sx={{ mb: 2 }}>
              <List component='nav' aria-label='main mailbox folders'>
                <ListItem button>
                  <a className='sidebar_items' href='/LandingPage#HowItWorks'>
                    How it Works
                  </a>
                </ListItem>

                <ListItem button>
                  <a className='sidebar_items' href='/LandingPage#Pricing'>
                    Pricing
                  </a>
                </ListItem>
                <ListItem button>
                  <a className='sidebar_items' href='/LandingPage#ContactUs'>
                    Contact Us
                  </a>
                </ListItem>
                <ListItem button>
                  <Link className='sidebar_items' to='/PrivacyPolicy'>
                    Privacy Policy
                  </Link>
                </ListItem>
              </List>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '0',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {localStorage.getItem('token') &&
              localStorage.getItem('accountType') == 'group' ? (
                <>
                  {hotels.length === 0 ? (
                    <>
                      <Link to='/noproperty'>
                        <button
                          style={{
                            backgroundColor: 'white',
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#6260EF',
                            border: '3px solid #6260EF',
                            width: '170px',
                            margin: '10px',
                          }}
                        >
                          Dashboard
                        </button>
                      </Link>
                      <button
                        style={{
                          backgroundColor: '#6260EF',
                          fontFamily: 'Plus Jakarta Sans',
                          border: 'solid #6260EF',
                          width: '170px',
                          marginBottom: '10px',
                        }}
                        onClick={() => logOut()}
                      >
                        Log Out
                      </button>
                    </>
                  ) : (
                    <>
                      <Link to='/dashboard/monthlyprices'>
                        <button
                          style={{
                            backgroundColor: 'white',
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#6260EF',
                            border: '3px solid #6260EF',
                            width: '170px',
                            margin: '10px',
                          }}
                        >
                          Dashboard
                        </button>
                      </Link>
                      <button
                        style={{
                          backgroundColor: '#6260EF',
                          fontFamily: 'Plus Jakarta Sans',
                          border: 'solid #6260EF',
                          width: '170px',
                          marginBottom: '10px',
                        }}
                        onClick={() => logOut()}
                      >
                        Log Out
                      </button>
                    </>
                  )}
                </>
              ) : localStorage.getItem('token') &&
                localStorage.getItem('accountType') == 'pms' ? (
                <>
                  <Link to='/pmsdashboard/manageaccount'>
                    <button
                      style={{
                        backgroundColor: 'white',
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#6260EF',
                        border: '3px solid #6260EF',
                        width: '170px',
                        margin: '10px',
                      }}
                    >
                      Dashboard
                    </button>
                  </Link>
                  <button
                    style={{
                      backgroundColor: '#6260EF',
                      fontFamily: 'Plus Jakarta Sans',
                      border: 'solid #6260EF',
                      width: '170px',
                      marginBottom: '10px',
                    }}
                    onClick={() => logOut()}
                  >
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  <Link to='/SignInPage'>
                    <button
                      style={{
                        backgroundColor: 'white',
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#6260EF',
                        border: '3px solid #6260EF',
                        width: '170px',
                        margin: '10px',
                      }}
                    >
                      Sign In
                    </button>
                  </Link>
                  <Link to='/SignUp'>
                    <button
                      style={{
                        backgroundColor: '#6260EF',
                        fontFamily: 'Plus Jakarta Sans',
                        border: 'solid #6260EF',
                        width: '170px',
                        marginBottom: '10px',
                      }}
                    >
                      Sign Up
                    </button>
                  </Link>
                </>
              )}
            </Box>
          </div>
        </Drawer>
      </Container>
    </AppBar>
  )
}
