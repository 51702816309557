import "../documentation.css"
export default function(props) {
	return (
		<div className="lanf__select__parent">
			<select
				{...props}
				value={props.value}
				className="lang__select">
				{props.options}
			</select>

		</div>
	);
}
