import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Typography,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import { Modal, Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import { changePropertyStatus, getAllProperties } from "./utils";
import axios from "axios";
import { DefaultUrl } from "../BaseUrl";
import { dataFormatting, getSelectedGroupData } from "../utils";
import { auth, auth2, db } from "../../Auth/firebase";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import NoData from "../../images/noData.png";
import AttachIcon from "../../images/attachIcon.png";
import "./adminPanel.css";
import { primaryColor } from "../../colors";
const useStyles = makeStyles((theme) => ({
  tablescroll: {
    minWidth: 350,
    maxHeight: 570,
    "&::-webkit-scrollbar": {
      height: 5,
    },
  },
  tableRow: {
    "&:nth-child(odd)": { backgroundColor: "white !important" },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  cellTypo: {
    border: 0,
    color: "#000000",
  },
}));
const ManagePropeties = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [hotelId, setHotelId] = useState();
  const [propertyColor, setPropertyColor] = useState("#3F51B5");
  const [pmsId, setPmsId] = useState(1);
  const [propertyId, setPropertyId] = useState();
  const [hotelName, setHotelName] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [open, setOpen] = useState(false);
  const [propertyForDelete, setPropertyForDelete] = useState({});
  const [propertyList, setAllProperties] = useState([]);
  const [propertyForStatus, setPropertyForStatus] = useState({});
  const [openStatus, setOpenStatus] = useState(false);
  const [selectedGroupAccountId, setSelectedGroupAccountId] = useState("");
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [showDelProperties, setShowDelProperties] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [noSeasonsData, setNoSeasonsData] = useState(false);
  const [existingProperty, setExistingProperty] = useState(false);
  const [lessSeasonsData, setLessSeasonsData] = useState(false);
  const [seasonsData, setSeasonsData] = useState([]);
  const [noProperty, setNoProperty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seasonDataResponseLoading, setSeasonDataResponseLoading] =
    useState(false);
  const [seasonStartDate, setSeasonStartDate] = useState(
    dataFormatting(new Date())
  );
  const [seasonEndDate, setSeasonEndDate] = useState(
    dataFormatting(new Date())
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 800,
    width: "60%",
    minHeight: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };
  const [dateRangeStatePaceReport, setDateRangeStatePaceReport] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setDateRangeStatePaceReport([ranges.selection]);
    setSeasonStartDate(dataFormatting(ranges.selection.startDate));
    setSeasonEndDate(dataFormatting(ranges.selection.endDate));
  };

  // ---------------- Delete Property --------------------
  const handleDeleteDialogue = (account) => {
    setPropertyForDelete(account);
    setOpen(true);
  };
  const deleteProperty = (id) => {
    axios
      .post(DefaultUrl + "/remove_property", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        body: {
          propertyId: [id],
          user_id: selectedGroupAccountId,
        },
      })
      .then((res) => {
        toast.success("Property deleted successfully");
        setOpen(false);
        getAllProperties();
      })
      .catch((err) => {
        toast.error("Something went wrong, please try again");
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const deletePropertyInBulk = () => {
    const data = propertyList.map((item) => {
      return item.id;
    });

    axios
      .post(DefaultUrl + "/remove_property", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        body: {
          propertyId: data,
          user_id: selectedGroupAccountId,
        },
      })
      .then((res) => {
        toast.success("Property deleted successfully");
        setOpen(false);
        getAllProperties();
      })
      .catch((err) => {
        toast.error("Something went wrong, please try again");
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const handleSelectProperty = (e, data) => {
    setShowDelProperties(true);
    const { name, checked } = e.target;
    if (checked) {
      if (name === "allSelect") {
        setSelectedProperties(propertyList);
      } else {
        setSelectedProperties([...selectedProperties, data]);
      }
    } else {
      if (name === "allSelect") {
        setSelectedProperties([]);
      } else {
        let tempuser = selectedProperties.filter((item) => item.id !== data.id);
        setSelectedProperties(tempuser);
      }
    }
  };

  const handlePmsChange = (event) => {
    /*
    handle the change in the value of PMS select field

    event: object

    does not return
    */
    setPmsId(event.target.value);
  };

  function addPropertyV2(
    pmsId,
    propertyId,
    propertyName,
    bookingId,
    city,
    country,
    userId
  ) {
    /*
    This functions calls the initiate onboarding cloud function
    
    pmsId: number (1 or 2)
    propertyId: String
    propertyName: String
    bookingId: String
    city: String
    country: String
    userId: String
    
    Does not return anything
    */

    // checks if the fields are empty or if they are just spaces
    if (
      propertyId.trim() !== "" &&
      propertyName.trim() !== "" &&
      bookingId.trim() !== "" &&
      userId.trim() !== ""
    ) {
      setLoading(true);
      //sends the call to the cloud function to initiate the add property
      axios
        .post(
          "https://publish-message-3cuixb5lia-ew.a.run.app/addProperty",

          {
            topicName: "addPropertyRMS",
            payload: {
              pmsId: String(pmsId),
              propertyId: propertyId,
              propertyName: propertyName,
              bookingcomId: bookingId,
              refSeason: { startDate: seasonStartDate, endDate: seasonEndDate },
              city: city,
              country: country,
              userId: userId,
            },
          }
        )
        .then((res) => {
          // when success is returned it shows the user a success message
          if (res.status === 200) {
            toast.success("Add property Initiated");
            setLoading(false);
            setModalOpen(false);
          }
        })
        // shows error toast to user in error case
        .catch((err) => {
          toast.error("Something went wrong! Try Again");
          console.error(err);
          setLoading(false);
        });
    } else {
      // shows error toast to user to fill empty fields
      toast.error("Please fill all fields");
    }
  }

  const LoadSeasonsData = () => {
    if (
      hotelId === "" &&
      propertyId === "" &&
      hotelName === "" &&
      city === "" &&
      country === ""
    ) {
      toast.error("Please fill all the fields");
    } else {
      setModalOpen(true);
      setSeasonDataResponseLoading(true);
      axios
        .post("https://load-season-info-3cuixb5lia-ew.a.run.app", {
          propertyId: hotelId,
          pmsId: pmsId,
        })
        .then((res) => {
          if (res.status === 200) {
            const main = res.data.payload.all_data;
            const recommended = res.data.payload.suggestions;
            for (let i = 0; i < main.length; i++) {
              for (let j = 0; j < recommended.length; j++) {
                if (main[i].year === recommended[j].year) {
                  main[i].recommended = true;
                }
              }
            }
            if (res.data.payload.warning) {
              setLessSeasonsData(true);
            }
            setSeasonsData(main);
            setSeasonDataResponseLoading(false);
            setExistingProperty(true);
          } else {
            setModalOpen(false);
            toast.error("Enter a valid Hotel id");
          }
        })
        .catch((err) => {
          if (err.response.status === 409) {
            //property exists
            // initiate onboarding
            setModalOpen(false);
            addPropertyV2(
              pmsId,
              hotelId,
              hotelName,
              propertyId,
              city,
              country,
              btoa(auth2.currentUser.uid)
            );
          } else if (err.status === 422) {
            // hotel id is invalid
            setModalOpen(false);
            toast.error("Enter a valid Hotel id");
          } else if (err.status === 404) {
            // data does not exist
            setModalOpen(false);
            toast.error("Data For the property does not exist");
          } else {
            setModalOpen(false);
            toast.error("unknown Error Occured");
          }
        });
    }
  };
  //---------------- Change Property status--------------------

  const handleStatusDialogue = (data) => {
    setPropertyForStatus(data);
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const changeStatusProperty = () => {
    changePropertyStatus(propertyForStatus.hotel_id)
      .then((status) => {
        getAllProperties().then((res) => {
          setAllProperties(res);
        });
        setOpenStatus(false);
        setPropertyForStatus({});
        toast.success("Property Status Changed successfully");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getAllProperties().then((resp) => {
      setAllProperties(resp);
    });

    getSelectedGroupData().then((res) => {
      // encode res in base 64 to get the account id
      const { getGroupId } = res;
      const accountId = btoa(getGroupId);
      setSelectedGroupAccountId(accountId);
    });
  }, []);

  return (
    <div>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box sx={modalStyle} className={"modal-main-container"}>
          {seasonDataResponseLoading ? (
            <div className="loader-container">
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="modal-heading">Seasons Data</h1>
              <div className="modal-container">
                {seasonsData && (
                  <div className="table-container">
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Year</TableCell>
                            <TableCell>Number of Reservation Dates</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {seasonsData.map((data) => {
                            return (
                              <TableRow>
                                <TableCell>{data.year}</TableCell>
                                <TableCell>
                                  {data.no_of_reservation_dates}
                                </TableCell>
                                <TableCell>
                                  {data.recommended && (
                                    <p className="suggested-text">Suggested!</p>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
                {lessSeasonsData && (
                  <p className="historical-data-warning">
                    Season Data is not Sufficient
                  </p>
                )}
                <div>
                  <TextField
                    style={{ margin: "20px 0", width: "100%" }}
                    fullWidth
                    id="standard-basic"
                    onClick={() => setShowDateRange(true)}
                    value={`${seasonStartDate} - ${seasonEndDate}`}
                    variant="outlined"
                    label="Select Season"
                    disabled={loading}
                  />
                  <br />
                  {showDateRange && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "350px",
                      }}
                    >
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRangeStatePaceReport}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => setShowDateRange(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setShowDateRange(false)}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    addPropertyV2(
                      pmsId,
                      hotelId,
                      hotelName,
                      propertyId,
                      city,
                      country,
                      btoa(auth2.currentUser.uid)
                    );
                  }}
                  disabled={loading}
                  style={{ marginTop: "10px", marginBottom: "40px" }}
                >
                  {loading ? <CircularProgress size={20} /> : "Add Property"}
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
      <div>
        <Typography variant="h6" gutterBottom>
          Add Properties
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="hotel__name"
              name="hotel__name"
              label="Hotel Name"
              value={hotelName}
              onChange={(e) => setHotelName(e.target.value)}
              error={hotelName === ""}
              helperText={hotelName === "" ? "Please Provide Hotel Name" : " "}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="hotelid"
              name="hotelid"
              label="Hotel ID"
              value={hotelId}
              onChange={(e) => setHotelId(e.target.value)}
              error={hotelId === ""}
              helperText={hotelId === "" ? "Provide HotelID" : " "}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              value={propertyId}
              id="Booking_property_id"
              name="Booking_property_id"
              label="Booking property ID"
              onChange={(e) => setPropertyId(e.target.value)}
              error={propertyId === ""}
              helperText={propertyId === "" ? "Please Provide PropertyId" : " "}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="city"
              name="city"
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              error={city === ""}
              helperText={city === "" ? "Please Provide City Name" : " "}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="country"
              name="country"
              label="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              error={country === ""}
              helperText={country === "" ? "Please Provide Country Name" : " "}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel style={{ transform: "translate(10px,-10px)" }}>
                PMS
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pmsId}
                label="PMS"
                variant="outlined"
                onChange={handlePmsChange}
              >
                <MenuItem value={1}>Octorate</MenuItem>
                <MenuItem value={2}>Mews</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid container xs={12} justifyContent="center">
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => LoadSeasonsData()}
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                {!loading && "  Get Season Data"}
              </Button>
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            padding: "20px 50px",
            borderRadius: "11px",
            backgroundColor: "#f8f8f8",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" style={{ margin: "32px 0px" }}>
              <b className="dailyview_title_typo">Properties List</b>
            </Typography>
            {selectedProperties.length > 0 && showDelProperties && (
              <>
                <div>
                  <Button
                    variant="outlined"
                    style={{
                      height: 50,
                      marginRight: 2,
                      backgroundColor: localStorage.getItem("primaryColor"),
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setShowDelProperties(false);
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    variant="outlined"
                    style={{
                      height: 50,
                      marginRight: 2,
                      backgroundColor: localStorage.getItem("primaryColor"),
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      deletePropertyInBulk();
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </>
            )}
          </div>
          <TableContainer className={classes.tablescroll}>
            <Table aria-label="Properties List">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.cellTypo}
                    style={{ fontWeight: "bold" }}
                  >
                    <Checkbox
                      checked={
                        selectedProperties?.length === propertyList?.length
                      }
                      onChange={(e) => handleSelectProperty(e, propertyList)}
                      name="allSelect"
                      color="primary"
                    />
                  </TableCell>
                  <TableCell
                    className={classes.cellTypo}
                    style={{ fontWeight: "bold" }}
                  >
                    Property Name
                  </TableCell>
                  <TableCell
                    className={classes.cellTypo}
                    align="right"
                    style={{ fontWeight: "bold" }}
                  >
                    Property Status
                  </TableCell>
                  <TableCell
                    className={classes.cellTypo}
                    align="right"
                    style={{ fontWeight: "bold" }}
                  >
                    Delete Property
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {propertyList?.map((property) => (
                  <TableRow className={classes.tableRow} key={property.id}>
                    <TableCell className={classes.cellTypo}>
                      <Checkbox
                        name={property.name}
                        checked={selectedProperties.some(
                          (item) => item?.id === property.id
                        )}
                        onChange={(e) => handleSelectProperty(e, property)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell className={classes.cellTypo}>
                      {property.name}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      <Switch
                        color="primary"
                        checked={property.active}
                        onClick={() =>
                          handleStatusDialogue({
                            hotel_id: property.id,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      <IconButton
                        onClick={(e) => handleDeleteDialogue(property)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* property delete dialogue */}
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete this Property`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Property Name: ${propertyForDelete?.name}`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => deleteProperty(propertyForDelete.id)}
                color="primary"
              >
                Delete Property
              </Button>
              <Button onClick={handleCloseDelete} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* property status dialogue */}

          <Dialog
            open={openStatus}
            aria-labelledby="alert-dialog-status"
            aria-describedby="alert-dialog-status"
          >
            <DialogTitle id="alert-dialog-status">{`Are you sure you want to change status of this Account`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-status">
                {/* {`Account Name: ${accountForDelete.name}`} */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => changeStatusProperty()} color="primary">
                Change Status
              </Button>
              <Button
                onClick={() => handleCloseStatus()}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ManagePropeties;
