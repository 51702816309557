import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'

import firebase from 'firebase/compat/app'

// live Credential
const firebaseCredentials = {
  apiKey: 'AIzaSyDcew8ljdVMXKhdP56-seHmvFi3XCNMuOU',
  authDomain: 'brainy-dev-309221.firebaseapp.com',
  projectId: 'brainy-dev-309221',
  storageBucket: 'brainy-dev-309221.appspot.com',
  messagingSenderId: '424442617544',
  appId: '1:424442617544:web:6b0ef2bd45f201a921cffd',
  measurementId: 'G-8ZTZ7EQCRP',
}
// V2 Credentials
const firebaseCredentials2 = {
  apiKey: "AIzaSyCX55dtSvLLlN8jVlw_279Hvr3rrLQTz2E",
  authDomain: "brainy-v2.firebaseapp.com",
  projectId: "brainy-v2",
  storageBucket: "brainy-v2.appspot.com",
  messagingSenderId: "27810994373",
  appId: "1:27810994373:web:8ad1773af30e56c00878ac",
  measurementId: "G-H7DWXZBD10"
};


// test Credential

// const firebaseCredentials = {
//   apiKey: "AIzaSyDpU3fId2y7JZITDfuJEmOTAkhNYb8x4cA",
//   authDomain: "brainy-test-e351b.firebaseapp.com",
//   projectId: "brainy-test-e351b",
//   storageBucket: "brainy-test-e351b.appspot.com",
//   messagingSenderId: "965670566907",
//   appId: "1:965670566907:web:2734df0a6036aba6e6295e",
//   measurementId: "G-E36MHWN97M"
// };

//If an firebase app hasn't already been created
firebase.initializeApp(firebaseCredentials)
const secondary = firebase.initializeApp(firebaseCredentials, 'secondary')

const db = firebase.firestore()
const auth = firebase.auth()
const authSecondary = secondary.auth()
export { firebase, auth, db, authSecondary }


// SAME FOR V2
//If an firebase app hasn't already been created
const v2Secondary = firebase.initializeApp(firebaseCredentials2, 'secondary2')

const db2 = firebase.firestore(v2Secondary)
const auth2 = firebase.auth(v2Secondary)
const authSecondary2 = v2Secondary.auth()
export { auth2, db2, authSecondary2 }

// import 'firebase/compat/auth'
// import 'firebase/compat/database'
// import 'firebase/compat/firestore'

// import firebase from 'firebase/compat/app'

// const firebaseCredentials = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID,
//     measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// };

// //If an firebase app hasn't already been created
// firebase.initializeApp(firebaseCredentials);
// const db = firebase.firestore();
// const auth = firebase.auth();
// export {firebase,auth,db};
