import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import PiGraphBi from "../../PiGraphBi";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@emotion/react";
import { formatInEuropeanNoSymbol } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  tablescroll: {
    minWidth: 350,
    maxHeight: 670,
    "&::-webkit-scrollbar": {
      height: 5,
    },
  },
  table: {
    minWidth: 350,
  },
  divider: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
      margin: "auto",
    },
  },
  statDetails: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-around",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  tableRow: {
    "&:nth-child(odd)": { backgroundColor: "white !important" },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  cellTypo: {
    border: 0,

    color: "#000000",
  },
}));

const BookerInsight = ({ bookerInsightNationality, bookerInsightOrigin }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  //---------------- insight type nationality ----------------

  const [nationalityData, setNationalityData] = useState({});
  const [nationalityDataTable, setNationalityDataTable] = useState([]);

  //---------------- insight type nationality graph----------------

  //series
  const seriesNationality = nationalityDataTable
    .slice(0, 5)
    .map((da) => da["% Room Nights"]);
  const seriesNationalityOthers = nationalityDataTable
    .slice(6)
    .map((da) => da["% Room Nights"]);
  const seriesNationalityOthersSum = seriesNationalityOthers.reduce(
    (a, b) => a + b,
    0
  );
  const seriesNationalityValues = [
    ...seriesNationality,
    seriesNationalityOthersSum,
  ];

  //labels
  const nationalityLabels = nationalityDataTable
    .slice(0, 5)
    .map((da) => da["nationalityGuest"]);
  const nationalityLabelsOthers = [...nationalityLabels, "others"];

  //---------------- insight type origin ----------------
  const [originDataTable, setOriginDataTable] = useState([]);

  //---------------- insight type origin graph----------------

  //series
  const seriesOrigin = originDataTable
    .slice(0, 5)
    .map((da) => da["% Room Nights"]);
  const seriesOriginOthers = originDataTable
    .slice(6)
    .map((da) => da["% Room Nights"]);
  const seriesOriginOthersSum = seriesOriginOthers.reduce((a, b) => a + b, 0);
  const seriesOriginValues = [...seriesOrigin, seriesOriginOthersSum];

  //labels
  const originLabels = originDataTable.slice(0, 5).map((da) => da["origin"]);
  const originLabelsOthers = [...originLabels, "others"];

  useEffect(() => {
    //nationality

    setNationalityData(bookerInsightNationality);
    setNationalityDataTable(bookerInsightNationality.nationality_table);

    //originDataTable

    setOriginDataTable(bookerInsightOrigin.booking_table);
  }, [bookerInsightNationality, bookerInsightOrigin]);

  return (
    <div className="mt-4">
      {" "}
      <br />
      <Divider />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div>
          <Typography variant="h3" align="left" style={{ fontWeight: 600 }}>
            Booker insights
          </Typography>
        </div>
      </div>
      <Grid container spacing={2}>
        {/* left graphs */}
        <Grid item xs={12} md={4} className="mt-5">
          <PiGraphBi
            seriesData={seriesNationalityValues}
            labels={nationalityLabelsOthers}
          />
          <PiGraphBi
            seriesData={seriesOriginValues}
            labels={originLabelsOthers}
          />
        </Grid>
        {/* right table */}
        <Grid item xs={12} md={8} className="mt-5">
          <Typography variant="h4" align="left" style={{ fontWeight: 400 }}>
            KPI COMPARISON VS PREVIUS YEAR
          </Typography>
          <Typography variant="subtitle1" align="left">
            Select your desired time range from the date picker and filter
            clicking on the tables or the graphs
          </Typography>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            <div>
              <Typography variant="subtitle1"> Revenue</Typography>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                {formatInEuropeanNoSymbol(Math.round(nationalityData.revenue))}{" "}
                €
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation="vertical"
                variant="middle"
                textAlign="center"
              />
            </div>
            <div>
              <Typography variant="subtitle1"> Adr</Typography>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                {formatInEuropeanNoSymbol(Math.round(nationalityData.adr))} €
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation="vertical"
                variant="middle"
                textAlign="center"
              />
            </div>
            <div>
              <Typography variant="subtitle1">Lead time</Typography>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                {Math.round(nationalityData.lead_time)}
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation="vertical"
                variant="middle"
                textAlign="center"
              />
            </div>
            <div>
              <Typography variant="subtitle1">Room Nights</Typography>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                {Math.round(nationalityData.room_nights)}
              </Typography>
            </div>
            <div>
              <Divider
                className={classes.divider}
                orientation="vertical"
                variant="middle"
                textAlign="center"
              />
            </div>
            <div>
              <Typography variant="subtitle1">Lenght of stay</Typography>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                {Math.round(nationalityData.length_of_stay)}
              </Typography>
            </div>
          </div>

          {/* table */}

          <TableContainer className={classes.tablescroll}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.cellTypo}>
                    Nationality
                  </TableCell>
                  <TableCell className={classes.cellTypo} align="right">
                    %Room Night
                  </TableCell>
                  <TableCell className={classes.cellTypo} align="right">
                    Room Nights
                  </TableCell>
                  <TableCell className={classes.cellTypo} align="right">
                    Revenue
                  </TableCell>
                  <TableCell className={classes.cellTypo} align="right">
                    Length of Stay
                  </TableCell>
                  <TableCell className={classes.cellTypo} align="right">
                    ADR
                  </TableCell>{" "}
                  <TableCell className={classes.cellTypo} align="right">
                    Lead Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nationalityDataTable.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell
                      className={classes.cellTypo}
                      component="th"
                      scope="row"
                    >
                      {row["nationalityGuest"]}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      {Math.round(row["% Room Nights"])}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      {row["Room Nights"]}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      {Math.round(row["Revenue"])}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      {Math.round(row["Length of stay"])}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      {Math.round(row["ADR"])}
                    </TableCell>
                    <TableCell className={classes.cellTypo} align="right">
                      {Math.round(row["Length of stay"])}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {/* seconf table */}
      <TableContainer className="mt-5">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.cellTypo}>Origin</TableCell>
              <TableCell className={classes.cellTypo} align="right">
                %Room Night
              </TableCell>
              <TableCell className={classes.cellTypo} align="right">
                Room Nights
              </TableCell>
              <TableCell className={classes.cellTypo} align="right">
                Revenue
              </TableCell>
              <TableCell className={classes.cellTypo} align="right">
                Length of Stay
              </TableCell>
              <TableCell className={classes.cellTypo} align="right">
                ADR
              </TableCell>{" "}
              <TableCell className={classes.cellTypo} align="right">
                Lead Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {originDataTable.map((row, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell
                  className={classes.cellTypo}
                  component="th"
                  scope="row"
                >
                  {row["origin"]}
                </TableCell>
                <TableCell className={classes.cellTypo} align="right">
                  {Math.round(row["% Room Nights"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="right">
                  {row["Room Nights"]}
                </TableCell>
                <TableCell className={classes.cellTypo} align="right">
                  {Math.round(row["Revenue"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="right">
                  {Math.round(row["Length of stay"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="right">
                  {Math.round(row["ADR"])}
                </TableCell>
                <TableCell className={classes.cellTypo} align="right">
                  {Math.round(row["Length of stay"])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BookerInsight;
