import { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import PiGraphBi from "../../PiGraphBi";
import { Typography } from "@material-ui/core";

const Status = ({paceReportStatus}) => {
    const [data,setData] = useState([])
    const series = data?.map((da)=> da.percentage);
    const labels = data?.map((da)=> da.status)  
  useEffect(() => {
    setData(paceReportStatus)
  },[paceReportStatus])
  
  return (
    <div>
      <Typography className="mt-3 mb-3" variant="h5" align="center" style={{ fontWeight: 600 }}>
Status
      </Typography>
     <PiGraphBi seriesData={series} labels={labels}/>
    </div>
  );
};

export default Status;
