import { auth2, db2 } from "../../../Auth/firebase";

export const getBase64 = (file) => {
  if (file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  } else {
    return null;
  }
};

export const getAllGroupAccounts = async () => {
  if (auth2?.currentUser?.uid !== undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();

    const pmsName = getUserData.data()?.displayName;
    const currentSelectedGroup = getUserData.data()?.currentSelectedGroup;
    console.log("getting GA", getUserData.data());
    //filter document in firebase by pms name
    const getAllAccounts = await db2
      .collection(`users`)
      .where("pms_name", "==", "octorate") // to be changed
      .get();
    const allAccount = getAllAccounts.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    return { currentSelectedGroup, allAccount };
  }
};

export const changeAccountStatus = async (id) => {
  //toggle active status in firebase database for account id
  const getAccount = await db2.collection(`users`).doc(id).get();
  const account = getAccount.data();
  const newStatus = !account.active;
  await db2.collection(`users`).doc(id).update({ active: newStatus });
  return newStatus;
};

export const getAllProperties = async () => {
  // eslint-disable-next-line
  console.log("this", auth2?.currentUser?.uid);
  if (auth2?.currentUser?.uid !== undefined) {
    const getUserData = await db2
      .collection(`users`)
      .doc(auth2?.currentUser?.uid)
      .get();
    const pmsName = getUserData.data()?.displayName;
    const currentSelectedGroup = getUserData.data()?.currentSelectedGroup.email;
    const getAllAccounts = await db2
      .collection(`users`)
      .where("pms_name", "==", pmsName)
      .get();
    const getAllAccountsMap = getAllAccounts.docs.map((doc) => {
      if (doc.data().email === currentSelectedGroup) {
        return { ...doc.data(), id: doc.id };
      }
    });
    const allAccount = getAllAccountsMap.filter(
      (account) => account !== undefined
    );
    const userHotels = allAccount[0]?.hotels;
    const userHotelsId = userHotels?.map((hotel) => {
      return hotel?.hotel_id;
    });
    const gettingPropertiesFromPmsFolder = userHotelsId?.map(async (idd) => {
      const getHotelData = await db2.collection(pmsName).doc(idd).get();
      return { ...getHotelData.data(), id: idd };
    });
    const hotelData = await Promise.all(gettingPropertiesFromPmsFolder);
    const minimizeData = hotelData.map((hotel) => {
      return { id: hotel.id, name: hotel.hotel_name, active: hotel.active };
    });
    return minimizeData;
  }
};

export const changePropertyStatus = async (id) => {
  const getUserData = await db2
    .collection(`users`)
    .doc(auth2?.currentUser?.uid)
    .get();
  const pmsName = "octorate";

  // update status active in octorate folder

  const getHotelData = await db2.collection(pmsName).doc(id).get();
  const hotelData = getHotelData.data();
  const newStatus = !hotelData.active;
  await db2.collection(pmsName).doc(id).update({ active: newStatus });
  return newStatus;
};

export const updateCurrentSelectedGroup = async (data) => {
  const getUserData = db2.collection(`users`).doc(auth2?.currentUser?.uid);

  await getUserData.update({
    ["currentSelectedGroup"]: data,
  });
  return true;
};
