import { ACTION_TYPES } from './types'

const OnMonthlyLoaderStart = () => {
  const testBool = true

  return {
    type: ACTION_TYPES.setMonthlyLoader,
    payload: testBool,
  }
}

const OnMonthlyLoaderEnd = () => {
  const testBool = false

  return {
    type: ACTION_TYPES.setMonthlyLoader,
    payload: testBool,
  }
}

export { OnMonthlyLoaderStart, OnMonthlyLoaderEnd }
