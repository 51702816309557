import Chart from "react-apexcharts";

const PiGraphBi = ({seriesData,labels}) => {
  const series =seriesData;
  const options = {
    dataLabels: {
      style: {
        colors: ["white", "white"],
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "light",
      },
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        useSeriesColors: false,
      },
    },
    plotOptions: {
      
      pie: {
        customScale: 0.8,
        expandOnClick: true,
      },
    },
    labels:labels
  };

  return (
    <div>
      <Chart options={options} series={series} type="donut" height="1000"/>
    </div>
  );
};

export default PiGraphBi;
