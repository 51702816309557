import '../Index.scss'

import React, { useEffect, useState } from 'react'

import FadeIn from 'react-fade-in'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useStylesEvent } from './muiStyles'

export default function EventsTable({ eventsData }) {
  const [data, setDate] = useState([])
  const classes = useStylesEvent()
  //Use effect calls
  useEffect(() => {
    setDate(eventsData)
    // console.log(eventsData);
  }, [eventsData])
  //Use effect calls end
  return (
    <div>
      <section>
        <FadeIn transitionDuration='4000'>
          {data.length >= 1 ? (
            <TableContainer
              component={Paper}
              elevation={0}
              className={classes.tablescroll}
            >
              <Table
                className={classes.table}
                aria-label='simple table'
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Event name</TableCell>
                    <TableCell
                      align='right'
                      style={{ textTransform: 'uppercase' }}
                    >
                      Location
                    </TableCell>
                  </TableRow>
                </TableHead>
                {data?.map((row, index) => (
                  <TableBody key={index}>
                    <TableRow hover className={classes.tableRow}>
                      <TableCell
                        component='th'
                        scope='row'
                        className={classes.tableRow}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align='left'
                        className={classes.tableRow}
                        style={{ textAlign: 'right' }}
                      >
                        {row.location}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          ) : (
            <Typography
              style={{
                width: '100%',
                textAlign: 'center',
                backgroundColor: '#f8f8f8',
                borderRadius: '8px',
                padding: '10px 10px',
                fontWeight: 'bold',
              }}
            >
              Event Data Not Found
            </Typography>
          )}
        </FadeIn>
      </section>
    </div>
  )
}
