import { dataFormatting } from "../../../utils";
import { useEffect, useState, useMemo } from "react";
export function getYearDates() {
  let dates = [];
  let currentDate = new Date();
  let finalDate = new Date(
    currentDate.getFullYear() + 1,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return {currentDate,finalDate};
}
