import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { Typography } from "@material-ui/core";

const BookReport = ({ onBookReportFuture }) => {

  const [currentYearData, setCurrentYearData] = useState([])
  const [previousYearData, setPreviousYearData] = useState([])
  useEffect(() => {
    setCurrentYearData(onBookReportFuture.current_year)
    setPreviousYearData(onBookReportFuture.previous_year)
  }, [onBookReportFuture])
  const series = [
    {
      name: "Revenue OTB ",
      data: currentYearData?.map((da) => da.revenue),
    },
    {
      name: "Revenue OTB (Previous Year)",
      data: previousYearData?.map((da) => da.revenue),
    },
  ];
  const categories = currentYearData?.map((da) => da.month_start)
  const options = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Revenue OTB",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "€" + val;
        },
      },
    },
  };
  return (
    <div>
      <Typography className="mt-3 mb-3" variant="h4" align="left" style={{ fontWeight: 600 }}>
        On the book report (Future)
      </Typography>
      <Typography className="mt-3 mb-3" variant="subtitle1" align="left" >
        Keep track of future reservations
      </Typography>
      {" "}
      <Chart options={options} series={series} type="bar" />
    </div>
  );
};

export default BookReport;
