import "../Index.scss";
import "../dashboard.scss";

import { gettingPMSPermession, updatingPMSPermession } from "./utils/rmsInfo";
import { useEffect, useState } from "react";

import { DefaultUrl } from "../../BaseUrl";
import FadeIn from "react-fade-in";
import { Grid, Typography, Button, Switch } from "@material-ui/core";
import { OnSettingLoaderStart } from "../../redux/actions/loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { primaryColor } from "../../../colors";
import { db2 } from "../../../Auth/firebase";

import { doc, onSnapshot, updateDoc } from "firebase/firestore";

const UpdateRms = ({ propertyId, currentSeason, disableTrigger }) => {
  const [allowrms, setAllowrms] = useState(true);
  const dispatch = useDispatch();
  const [dateRanges, setDateRanges] = useState([]);

  const updateAllowRms = (propertyId, value) => {
    setAllowrms(value)
    const docRef = doc(db2, localStorage.getItem("pmsName"), propertyId);
    updateDoc(docRef,{allowPushPrices:value})
  };

  const gettingRmsPermession = async (propertyId) => {
    const docRef = doc(db2, localStorage.getItem("pmsName"), propertyId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const rmsData = doc.data().allowPushPrices;
        setAllowrms(rmsData);
      }
    });
  };
  useEffect(() => {
    gettingRmsPermession(propertyId);
  }, [propertyId]);

  useEffect(() => {
    setDateRanges(currentSeason);
  }, [currentSeason]);

  // update RMS call

  const updateRms = () => {
    dispatch(OnSettingLoaderStart());
    axios
      .post(
        `${DefaultUrl}/update_season`,

        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          body: {
            propertyId: propertyId,
            date_ranges: dateRanges,
            pms_name: localStorage.getItem("pmsName"),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const removePriceSend = () => {
    axios.post(`${DefaultUrl}/remove_price_send`, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: {
        propertyId: propertyId,
        pms_name: localStorage.getItem("pmsName"),
      },
    });
    if (res.status === 200) {
      toast.success(res.message);
    }
    if (res.status === 400) {
      toast.error(res.message);
    }
  };

  return (
    <div>
      {" "}
      <FadeIn transitionDuration="4000">
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",

              width: "100%",
            }}
          >
            <Typography variant="h5" style={{ margin: "32px 0px" }}>
              <b className="setting_title_typo">Update RMS Info</b>
            </Typography>

            <Grid container spacing={2}>
              <Grid item sm={12}>
                <div className="allowRmsContainer">
                  <p>Allow RMS</p>
                  <Switch
                    onChange={() => updateAllowRms(propertyId,!allowrms)}
                    className="toggle_switch"
                    checked={allowrms}
                  />
                </div>
              </Grid>
              {localStorage.getItem("accountType") === "pms" && (
                <Grid item sm={12} md={6}>
                  <p>Click here to remove send Prices</p>
                  <Button
                    elevation={0}
                    variant="outlined"
                    style={{
                      height: 50,
                      marginRight: 2,
                      backgroundColor:
                        allowrms === undefined
                          ? "red"
                          : localStorage.getItem("primaryColor"),
                      color: "white",
                      fontWeight: "bold",
                    }}
                    disabled={!disableTrigger || allowrms === undefined}
                    onClick={() => {
                      removePriceSend();
                    }}
                  >
                    Remove Price send
                  </Button>
                </Grid>
              )}
              {/* <Grid item sm={12} md={6}>
                <p>Click here to refresh RMS</p>
                <Button
                  elevation={0}
                  variant='outlined'
                  style={{
                    height: 50,
                    marginRight: 2,
                    backgroundColor: localStorage.getItem("primaryColor"),
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  disabled={!disableTrigger}
                  onClick={() => {
                    updateRms()
                  }}
                >
                  Refresh RMS
                </Button>
              </Grid> */}
            </Grid>
          </div>
        </>
      </FadeIn>
    </div>
  );
};

export default UpdateRms;
