import { makeStyles } from '@material-ui/core/styles';

export const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize:"11px"
    }, selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: '#6260EF',
        borderColor: '#1F393B',
        borderWidth: 2,
      },
    },
    marginBottom:"10px"
  },
  }));